import professionalProfileHttp from '@/utils/matchmaking/professionalProfile.http';

export const addCertificatesByProfile = (body) => professionalProfileHttp.post('/professional-profile/certificate', body);

export const deleteCertificatesFromAccount = (certificateId, professionalProfileId) => professionalProfileHttp.delete(
  `/professional-profile/certificate?id=${certificateId}&professionalProfileId=${professionalProfileId}`,
);

export const getCertificatesByProfile = (professionalProfileId) => professionalProfileHttp.get(
  `/professional-profile/certificate?professionalProfileId=${professionalProfileId}`,
);
