import mixpanel from 'mixpanel-browser';

export default function (store) {
  const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'homolog';
  if (!isDev) {
    store.subscribeAction((action) => {
      const invalidMutations = [
        'syncRouter',
        'syncLoopback',
        'syncToken',
        'loadAccount',
        'updateToken',
        'onDrawerChange',
        'afterRouteUpdate',
        'loadCourse',
        'loadCategories',
        'loadPoints',
        'loadParameters',
      ];
      if (!invalidMutations.includes(action.type)) {
        mixpanel.track(action.type);
      }
    });
  }
}
