<template>
  <div class="submenu-filter">
    <button class="btn-filter" @click="toggle" type="button" :style="{ backgroundColor: this.color ?? '#5d4a99' }">
      <img src="@/assets/icons/search/filter.svg" />
    </button>

    <ul v-show="active" id="dropdown">
      <li v-for="( item ) in  items " :key="item.id">
        <label>
          <input type="checkbox" v-model="item.checked" @click="onChange" :name="item.name" />
          <span>{{ item.title }}</span>
        </label>
      </li>

    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FilterSearch',
  props: {
    color: String,
  },
  data() {
    return {
      active: false,
      searchTerm: '',
      items: [
        {
          id: 1, title: 'Trilhas', name: 'collections', checked: true,
        },
        {
          id: 2, title: 'Cursos', name: 'courses', checked: true,
        },
      ],
    };
  },
  watch: {
    active(active) {
      if (active) {
        document.addEventListener('click', this.closeIfClickedOutside);
      }
    },
  },
  computed: {
    ...mapGetters('courses', ['filterSearch']),
  },
  mounted() {
    this.searchTerm = this.$route.params.searchTerm;

    if (!this.searchTerm) {
      localStorage.setItem('@PARCEIROS_DA_CONSTRUCAO_FILTER_COLLECTTIONS', JSON.stringify('true'));
      localStorage.setItem('@PARCEIROS_DA_CONSTRUCAO_FILTER_COURSES', JSON.stringify('true'));
    } else {
      const index = this.searchTerm.indexOf('&');
      const queryString = this.searchTerm.slice(index, this.searchTerm.length);

      const params = new URLSearchParams(queryString);

      const collectionsValue = params.get('collections') === 'true';
      const coursesValue = params.get('courses') === 'true';

      const updatedArray = this.items.map((item) => {
        if (item.name === 'collections') {
          return { ...item, checked: collectionsValue };
        }
        if (item.name === 'courses') {
          return { ...item, checked: coursesValue };
        }
        return item;
      });
      this.items = updatedArray;
    }
  },
  methods: {
    ...mapActions('courses', ['setFilterSearch']),
    onChange(event) {
      const { name, checked } = event.target;
      if (name === 'collections') {
        localStorage.setItem('@PARCEIROS_DA_CONSTRUCAO_FILTER_COLLECTTIONS', JSON.stringify(checked));
      } if (name === 'courses') {
        localStorage.setItem('@PARCEIROS_DA_CONSTRUCAO_FILTER_COURSES', JSON.stringify(checked));
      }
    },
    toggle() {
      this.active = !this.active;
    },
  },
  created() {
    const self = this;

    window.addEventListener('click', (e) => {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        self.active = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.submenu-filter {
  position: relative;
}

.btn-filter {
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    height: 22px;
    pointer-events: none;
  }
}

ul {
  position: absolute;
  top: 43px;
  left: 10px;
  width: 200px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);
  list-style: none;
  margin: 0;
  padding: 5px;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    width: 0;
    height: 0;
    margin-top: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fefefe;
  }

  li+li {
    margin-top: 5px;
  }

  label {
    margin: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  span {
    color: #495057;
    margin-left: 3px;
  }

}
</style>
