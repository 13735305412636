<template>
  <div>
    <div class="answer-revision flex-col align-items-center">
      <img class="answer-revision--image" src="@/assets/icons/course-exame/answer-revision.png" alt="answer-revision" />
      <b class="text-align-center mt-32 answer-revision--text"> Você ainda tem alguns minutos restantes, aproveite para revisar suas questões. </b>
      <div class="answer-revision--description mt-32 mb-40">
        Agora que você respondeu todas as questões, é fundamental revisar suas respostas antes de finalizar o exame, ao revisar as questões você pode
        clicar em “Finalizar exame” para ver sua nota.
      </div>
      <div class="answer-revision--btns flex-col w-100 justify-content-center align-items-center">
        <PdcButton
          variant="primary" class="sm-w-100 md-w-50 lg-w-40" @click.native="goToCorrection">
          Finalizar exame
        </PdcButton>
        <a href="#" class="pdc-link sm-w-100 md-w-50 lg-w-40 my-40 text-align-center" @click.prevent="handlePreviousStep"> Revisar questões </a>
      </div>
    </div>
    <ChangeNameDialogVue v-if="showChangeNameModal"
      @handleClose="handleCloseDialog('showChangeNameModal')"/>
  </div>

</template>
<script>
import './AnswerRevision.scss';
import { mapActions, mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import isSingleName from '@/utils/functions/globalFunctions';
import ChangeNameDialogVue from '../../ChangeNameDialog/ChangeNameDialog.vue';

export default {
  name: 'AnswerRevision',
  props: {
    handlePreviousStep: Function,
  },
  components: {
    PdcButton,
    ChangeNameDialogVue,
  },
  data() {
    return {
      showChangeNameModal: false,
    };
  },
  methods: {
    ...mapActions('exame', ['setLastStepPage']),
    ...mapActions('questions', ['finishQuestions']),
    goToCorrection() {
      this.setLastStepPage('correcting');
    },
    handleCloseDialog(modalName) {
      this[modalName] = false;
      this.finishQuestions(false);
    },
    handleOpenDialog(modalName) {
      this[modalName] = true;
    },
  },
  computed: {
    ...mapGetters('auth', ['accountComplete']),
  },
  mounted() {
    const userName = this.accountComplete.name;
    const cantProceed = isSingleName(userName);
    if (cantProceed) {
      this.finishQuestions(true);
      this.handleOpenDialog('showChangeNameModal');
    }
  },
};
</script>
