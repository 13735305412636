/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getCookie, checkTokenExpiration, cleanLoginDataFromLocalStorage } from './helpers';
import { refreshToken } from './authsso';

/* Response Interceptors */
const interceptResErrors = (err) => {
  // eslint-disable-next-line no-console
  if (err?.response && err?.response?.status === 401) {
    cleanLoginDataFromLocalStorage();
  }
  try {
    err = Object.assign(new Error(), err.response);
  } catch (e) {
    // Will return err if something goes wrong
  }
  return Promise.reject(err);
};
const interceptResponse = (res) => {
  try {
    return res.data;
  } catch (e) {
    return res;
  }
};

export async function setTokenDefaultInstance(token, instance) {
  instance.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
}

/* Request Interceptors */
const interceptReqErrors = (err) => Promise.reject(err);

const interceptRequest = async (config) => {
  const token = getCookie('PDC_TOKEN') || localStorage.getItem('loopback-token');
  if (!token) return config;

  const { access_token, expires_in } = JSON.parse(token);
  const tokenExpired = checkTokenExpiration({ access_token, expires_in });

  if (tokenExpired) {
    // eslint-disable-next-line
    const { data } = await refreshToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${data.access_token}`,
      },
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${access_token}`,
    },
  };
};

function addTokenFromLocalStorage(instance) {
  let token = localStorage.getItem('loopback-token');
  if (token) {
    token = token.replace('__q_strn|', '');
    setTokenDefaultInstance(JSON.parse(token), instance);
  }
}
const apiBase = (baseUrl) => {
  const instance = axios.create({
    baseURL: baseUrl,
  });

  instance.interceptors.response.use(interceptResponse, interceptResErrors);
  instance.interceptors.request.use(interceptRequest, interceptReqErrors);

  addTokenFromLocalStorage(instance);

  return { instance };
};

export default apiBase;
