<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <g filter="url(#filter0_d_5457_5334)">
      <rect x="6" y="6" width="32" height="32" rx="5" fill="white" />
    </g>
    <mask
      id="mask0_5457_5334"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="12"
      y="12"
      width="20"
      height="20"
    >
      <rect
        x="12.8555"
        y="12.8594"
        width="18.2857"
        height="18.2857"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_5457_5334)">
      <path
        d="M16.6644 28.8587C16.2454 28.8587 15.8867 28.7095 15.5882 28.4111C15.2898 28.1127 15.1406 27.7539 15.1406 27.3349V16.6682C15.1406 16.2492 15.2898 15.8905 15.5882 15.592C15.8867 15.2936 16.2454 15.1444 16.6644 15.1444H23.4644L21.9406 16.6682H16.6644V27.3349H27.3311V22.0397L28.8549 20.5158V27.3349C28.8549 27.7539 28.7057 28.1127 28.4073 28.4111C28.1089 28.7095 27.7501 28.8587 27.3311 28.8587H16.6644ZM19.7121 24.2873V21.0492L26.7025 14.0587C26.8549 13.9063 27.0263 13.792 27.2168 13.7158C27.4073 13.6397 27.5978 13.6016 27.7882 13.6016C27.9914 13.6016 28.1851 13.6397 28.3692 13.7158C28.5533 13.792 28.7216 13.9063 28.874 14.0587L29.9406 15.1444C30.0803 15.2968 30.1882 15.4651 30.2644 15.6492C30.3406 15.8333 30.3787 16.0206 30.3787 16.2111C30.3787 16.4016 30.3438 16.5889 30.274 16.773C30.2041 16.9571 30.093 17.1254 29.9406 17.2778L22.9501 24.2873H19.7121ZM21.2359 22.7635H22.3025L26.7216 18.3444L26.1882 17.8111L25.6359 17.2778L21.2359 21.6778V22.7635Z"
        fill="#5D4998"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5457_5334"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5457_5334"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5457_5334"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EditV2',
};
</script>
