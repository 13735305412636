<template>
  <div class="CertificatesSection">
    <CoursesSkeleton type="finished" v-if="loadingCertificates" :options="swiperOptions" onShow />
    <div class="certificates-notfound" v-if="myCertificates.length < 1">Você não possui certificados nesse momento!
    </div>
    <template v-else>
      <CertificateCard v-for="(certificate, index) in myCertificates" :index="index" :key="index"
        :certificate="certificate" :onShow="handleShowCertificate" />
      <div class="certificate-modal" v-show="showCertificateModal">
        <span class="certificate-modal-close" @click="handleCloseModalCertificate">&times;</span>
        <img class="certificate-image" :src="certificateImage" />
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import CertificateCard from '@/components/organisms/CertificateCard/CertificateCard.vue';

export default {
  name: 'Certificates',
  components: {
    CoursesSkeleton,
    CertificateCard,
  },
  computed: {
    ...mapGetters('certificates', ['myCertificates', 'loadingCertificates']),
    swiperOptions() {
      return {
        slidesPerView: 1.04,
        breakpoints: {
          1200: {
            slidesPerView: 2.9,
            spaceBetween: 24,
          },
          980: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          700: {
            slidesPerView: 1.8,
            spaceBetween: 24,
          },
        },
      };
    },
  },
  data() {
    return {
      showCertificateModal: false,
      certificateImage: '',
    };
  },
  methods: {
    handleShowCertificate(urlImage) {
      this.showCertificateModal = true;
      this.certificateImage = urlImage;
    },
    handleCloseModalCertificate() {
      this.showCertificateModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.certificate-modal {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certificate-image {
  margin: auto;
}

.certificate-image {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-atransform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

.certificate-modal-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.certificate-modal-close:hover,
.certificate-modal-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.certificate-image {
  max-height: 600px;
}

.certificates-notfound {
  border: 1px dashed #ccc;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  color: #A9A9A9;
}
</style>
