<template>
  <div class="flex-col mt-48 sm-mt-0 mb-80">
    <div class="flex-col flex align-items-center justify-content-center">
      <div class="skeleton-container flex-col align-items-center justify-content-center">
        <VueSkeletonLoader class="w-100 h-392px br-10px" />
        <VueSkeletonLoader class="w-80 h-32px br-10px mt-32" />
        <VueSkeletonLoader class="w-60 h-24px br-10px mt-24" />
        <VueSkeletonLoader class="w-50 h-16px br-10px mt-16" />
        <div class="w-80 flex-row mt-64 justify-content-between">
          <VueSkeletonLoader class="w-45 h-56px sm-hide" />
          <VueSkeletonLoader class="w-45 sm-w-100 h-56px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'CourseExameResultSkeleton',
  components: { VueSkeletonLoader },
};
</script>
<style lang="scss">
.skeleton-container {
  width: 680px;
}
</style>
