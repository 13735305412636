import professionalProfileHttp from '@/utils/matchmaking/professionalProfile.http';
import communityHttp from '@/utils/community.http';
import HEADERS from '@/utils/constants';

export const getProfessionalProfile = (accountId) => professionalProfileHttp.get(`professional-profile?accountId=${accountId}`);

export const updateProfessionalProfile = (data) => professionalProfileHttp.post('professional-profile/updating', data);

export const getMySkills = (profileId) => communityHttp.get(`skills/my-skills/${profileId}`);
export const getSkills = () => communityHttp.get('skills/list-skills');

export const createProfessionalProfile = (data) => professionalProfileHttp.post('professional-profile', data);

export const addNewSkillProfessionalProfile = (data) => communityHttp.post('skills', data, { headers: HEADERS });
export const deleteSkillProfessionalProfile = (skillId, accountId) => professionalProfileHttp.delete(
  `skills?id=${skillId}&professionalProfileId=${accountId}`,
); export const editSkillProfessionalProfile = (data) => professionalProfileHttp.put(
  'skills',
  data,
);

export const addJobExperience = (body) => professionalProfileHttp.post('job-experience', {
  name: body.name,
  professionalProfileId: body.professionalProfileId,
  jobTypeId: body.jobTypeId,
  company: body.company,
  locationTypeId: body.locationTypeId,
  isActualJob: body.isActualJob,
  jobStartDate: body.jobStartDate,
  jobEndDate: body.jobEndDate,
  description: body.description,
});

export const updateJobExperience = (body) => professionalProfileHttp.put('job-experience', body);

export const deleteJobExperience = (accountId, jobExperienceId) => professionalProfileHttp.delete(
  `job-experience?professionalProfileId=${accountId}&id=${jobExperienceId}`,
);

export const changeProfilePicture = (body) => professionalProfileHttp.post('professional-profile/file', {
  extensionFile: body.extensionFile,
  professionalProfileId: body.professionalProfileId,
  base64File: body.base64File,
  contentType: body.contentType,
  type: body.type,
  enviroment: body.enviroment,
});

export const loadAcademicEducation = (professionalProfileId) => professionalProfileHttp.get(
  `academic-education?professionalProfileId=${professionalProfileId}`,
);

export const addAcademicEducation = (body) => professionalProfileHttp.post('academic-education', {
  professionalProfileId: body.professionalProfileId,
  educationalInstitution: body.educationalInstitution,
  degree: body.degree,
  startDate: body.startDate,
  endDate: body.endDate,
  note: body.note,
  studyArea: body.studyArea,
});

export const updateAcademicEducation = (body) => professionalProfileHttp.put('academic-education', body);

export const deleteAcademicEducation = (professionalProfileId, id) => professionalProfileHttp.delete(
  `academic-education?id=${id}&professionalProfileId=${professionalProfileId}`,
);

export const getListProfessions = (accountId) => professionalProfileHttp.get(`list-professions?requesterAccountId=${accountId}`);
export const saveUserProfessions = (body) => professionalProfileHttp.post('jobs', body);
export const getUserProfessions = (accountId) => professionalProfileHttp.get(`jobs?accountId=${accountId}`);
