import Vue from 'vue';

Vue.prototype.$randInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

// eslint-disable-next-line consistent-return
Vue.prototype.$waitForEl = (selector) => new Promise((resolve) => {
  if (document.querySelector(selector)) {
    return resolve(document.querySelector(selector));
  }

  const observer = new MutationObserver(() => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
      observer.disconnect();
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
});

window.sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
Vue.prototype.$sleep = window.sleep;

Vue.prototype.$courseDifficulty = (course) => {
  if (course.difficulty <= 3) {
    return 'Básico';
  }
  if (course.difficulty <= 4) {
    return 'Médio';
  }
  return 'Avançado';
};

Vue.prototype.$copy = (str) => new Promise((resolve) => {
  // eslint-disable-next-line global-require
  const copy = require('copy-to-clipboard');
  copy(str);
  return resolve(str);
});

Vue.prototype.$getDeviceAgent = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'WINDOWS PHONE';
  }
  if (/Android/i.test(userAgent)) {
    return 'ANDROID';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS';
  }
  return null;
};
