<!-- eslint-disable max-len -->
<template>
  <div class="categories-section">
    <template>
      <div class="personal-cards-finished__content-feed personal-card">
        <div class="header">
          <p class="personal-title">Conhecendo a plataforma</p>
          <div class="finished__slider-controls" :style="courses.length < 2 ? 'display: none;' : ''">
            <img src="@/assets/icons/card/chevron-left.svg" class="swiper-button-prev-ongoing" slot="button-prev"
            alt="" />
            <img src="@/assets/icons/card/chevron-right.svg" class="swiper-button-next-ongoing" slot="button-next"
            alt="" />
          </div>
        </div>

        <div class="personal-content">
          <div class="personal-video">
            <div v-if="!courses.length" class="loading">
              <Loading :width="'6rem'" :height="'6rem'" />
            </div>
            <Swiper v-else :ref="`mySwiper-one`" class="swiper" :options="swiperOptionOngoing">
              <SwiperSlide v-for="(course, index) in courses" :key="index">
                <VimeoPlayer @loadComponent="playerReady = false" @beforeNextAd="playerReady = false"
                  @loaded="playerReady = true" @destroy="playerReady = false" @created="playerReady = true"
                  v-show="playerReady" :id="Number(course.introVideo)" class="playerIntro sm-order-0"
                  :options="{ autoplay: false }" />
              </SwiperSlide>
            </Swiper>
          </div>

          <div class="personal-banner">
            <div v-if="!Object.keys(banner).length" class="loading">
              <Loading :width="'6rem'" :height="'6rem'" />
            </div>
            <a v-else :href="banner.linkTarget" target="_blank">
              <img :src="banner.imageUrl || '/android-chrome-512x512.png'" class="full-image" :alt="banner.alt" />
            </a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import VimeoPlayer from '@/components/atoms/VimeoPlayer/VimeoPlayer.vue';
import CONSTANTS from '@/utils/constants';
import Loading from '@/components/atoms/Loading/Loading.vue';

export default {
  name: 'HomeCourse',
  components: {
    VimeoPlayer,
    Loading,
  },
  data() {
    return {
      playerReady: false,
    };
  },
  props: {
    courses: {
      type: Array,
      default: CONSTANTS?.COURSES_BY_CATEGORY,
    },
  },
  computed: {
    ...mapGetters('courses', ['categories', 'finished', 'categories_loading']),
    ...mapGetters('application', ['tenantConfig']),
    swiperOptionOngoing() {
      return {
        slidesPerView: 1,
        centeredSlides: false,
        spaceBetween: 10,
        loop: false,
        grabCursor: false,
        transparent: true,
        navigation: {
          nextEl: '.swiper-button-next-ongoing',
          prevEl: '.swiper-button-prev-ongoing',
          enabled: false,
        },
        pagination: {
          el: '.swiper-pagination-one',
          clickable: true,
        },
        breakpoints: {
          767: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      };
    },
    // eslint-disable-next-line vue/return-in-computed-property, consistent-return
    banner() {
      if (Object.keys(this.tenantConfig).length) {
        return this.tenantConfig?.home_banner.length ? this.tenantConfig?.home_banner[0] : {
          imageUrl: '/android-chrome-512x512.png',
          linkTarget: '/feed',
          alt: 'Banner',
        };
      } return {};
    },
    getImageHomeBanner() {
      if (this.banner) {
        return this.banner.length ? this.banner[0].imageUrl : '/android-chrome-512x512.png';
      } return '/android-chrome-512x512.png';
    },
  },
  methods: {
    ...mapActions('courses', ['onTimeUpdate']),
  },
};
</script>
<style lang="scss" scoped>
.recommended {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin: 1rem 0;

  p {
    margin: 0;
    color: #212529;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    padding: 1rem 0;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.36px;
    margin-bottom: 0;
    width: 100%;
  }
}

.swiper {
  // position: absolute;
  max-width: 34rem;
  width: 100%;
  border-radius: 10px;
}

.finished__slider-controls {
  height: 2rem;
  width: 2rem;
  display: flex;
  gap: 1rem;
  margin-right: 3rem;

  img {
    cursor: pointer;
  }
}

.personal-content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr minmax(auto, 390px);

  .personal-video {
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin: 1rem 0;
    }
  }

  .personal-banner {
    margin-left: 2rem;
    background: #fff;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    a {
      max-width: 400px;
      max-height: 300px;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.secondary-actions a.btn-transparent {
  width: 150%;
}

.swiper-container {
  margin-left: 0;
}

// eslint-disable-next-line max-len, max-len
.personal-content {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr minmax(auto, 400px);
  margin-bottom: 3rem;

  .personal-banner {
    margin-left: 2rem;
    background: #fff;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    a {
      max-width: 400px;
      max-height: 300px;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    img {
      max-width: 400px;
      max-height: 300px;
      width: 100%;
      height: 100%;
    }
  }
}

@media(max-width: 500px) {

  .personal-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  .personal-video {
    max-width: 100%;

    .header {
      gap: 0.6rem;
      .personal-title {
        font-size: .8rem;
        font-weight: 600;
      }
    }
  }
}

@media(max-width: 376px) {

.personal-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;

  .personal-banner {
    margin-left: 0;
    margin-bottom: 1rem;
  }
}
  .personal-video {
    max-width: 100%;

    .header {
      p.personal-title {
        font-size: .8rem;
        font-weight: 600;
      }
    }
  }
}

@media(max-width: 322px) {

.personal-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}
  .personal-video {
    max-width: 100%;

    .header {
      p.personal-title {
        font-size: .6rem;
        font-weight: 600;
      }
    }
  }
}
</style>
