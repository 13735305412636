import * as AccountService from '../../../services/account.service';

const getters = {
  question_loading: (state) => state.question_loading,
  message: (state) => state.message,
  show_dialog_question: (state) => state.show_dialog_question,
  finish_questions: (state) => state.finish_questions,
};

const mutations = {
  SET_LOADING_QUESTION(state, payload) {
    state.question_loading = payload;
  },
  SET_MESSAGE(state, payload) {
    state.message = payload;
  },
  SET_DIALOG(state, payload) {
    state.show_dialog_question = payload;
  },
  SET_FINISH_QUESTIONS(state, payload) {
    state.finish_questions = payload;
  },
};

const actions = {
  setDialogQuestion({ commit }, payload) {
    commit('SET_DIALOG', payload);
  },
  setLoadingQuestion({ commit }, payload) {
    commit('SET_LOADING_QUESTION', payload);
  },
  onQuestionSendCourse({ commit, rootGetters }, { question }) {
    commit('SET_LOADING_QUESTION', true);
    const body = {
      subject: `[Sugestão de Curso] - ${rootGetters['auth/account'].name}`,
      question,
      name: rootGetters['auth/account'].name,
      email: rootGetters['auth/account'].email,
    };
    return AccountService.submitQuestion(body)
      .then(() => commit('SET_MESSAGE', { type: 'success', text: 'Solicitação enviada com sucesso!' }))
      .catch(() => commit('SET_MESSAGE', { type: 'error', text: 'Ocorreu um erro ao enviar sua solicitação' }))
      .finally(() => {
        commit('SET_LOADING_QUESTION', false);
      });
  },
  finishQuestions({ commit }, payload) {
    commit('SET_FINISH_QUESTIONS', payload);
  },
};

const state = {
  question_loading: false,
  message: {},
  show_dialog_question: false,
  finish_questions: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
