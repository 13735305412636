<!-- eslint-disable max-len -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
<mask id="mask0_11948_5157" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="50" height="50">
<rect width="50" height="50" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_11948_5157)">
<path d="M17.9173 46.875L13.959 40.2083L6.45898 38.5417L7.18815 30.8333L2.08398 25L7.18815 19.1667L6.45898 11.4583L13.959 9.79167L17.9173 3.125L25.0007 6.14583L32.084 3.125L36.0423 9.79167L43.5423 11.4583L42.8132 19.1667L47.9173 25L42.8132 30.8333L43.5423 38.5417L36.0423 40.2083L32.084 46.875L25.0007 43.8542L17.9173 46.875ZM19.6882 41.5625L25.0007 39.2708L30.4173 41.5625L33.334 36.5625L39.0631 35.2083L38.5423 29.375L42.3965 25L38.5423 20.5208L39.0631 14.6875L33.334 13.4375L30.3132 8.4375L25.0007 10.7292L19.584 8.4375L16.6673 13.4375L10.9382 14.6875L11.459 20.5208L7.60482 25L11.459 29.375L10.9382 35.3125L16.6673 36.5625L19.6882 41.5625ZM22.8132 32.3958L34.584 20.625L31.6673 17.6042L22.8132 26.4583L18.334 22.0833L15.4173 25L22.8132 32.3958Z" fill="#FF725E"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'CheckAward',
};
</script>
