<template>
  <div class="sidebar-container--courses">
    <div class="sidebar-container--courses--items">
      <a class="link-back back-button-courses" @click.prevent="$router.push({ name: 'Courses'})"
        style="margin-bottom: 20px;">
        <span class="icon arrow" />
        <span class="text"> Voltar para os cursos </span>
      </a>
      <ProgressCard :time="courseTime" :level="$courseDifficulty(course)" bgColor="#5D4A99" :colors="['#FFFFFF']" />
      <CourseIntroduction :class="`${!current_lesson_id && 'current'}`" :completed="course.started" />
      <CourseModule v-for="(mod, i) in course.courseLessons" :key="i" :index="i" :module="mod"
        :current_lesson_id="current_lesson_id" />
      <FinalExameItem :disabled="!canExame" :course="course" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressCard from './ProgressCard/ProgressCard.vue';
import CourseIntroduction from './CourseIntroduction/CourseIntroduction.vue';
import CourseModule from './CourseModule/CourseModule.vue';
import FinalExameItem from './FinalExameItem/FinalExameItem.vue';
import './CourseSidebar.scss';

export default {
  props: {
    current_lesson_id: {
      type: Number,
      default: null,
    },
  },
  components: {
    ProgressCard,
    CourseIntroduction,
    CourseModule,
    FinalExameItem,
  },
  computed: {
    ...mapGetters('application', ['openLink']),
    ...mapGetters('courses', ['course', 'course_all_lessons']),
    canExame() {
      return this.course.completed;
    },
    courseTime() {
      const courseTimeFromBack = this.course?.courseTime;
      if (courseTimeFromBack && courseTimeFromBack !== '0') {
        const [hours, minutes] = this.course?.courseTime.split('.');
        const courseTime = this.course?.courseTime ? `${hours}:${minutes}` : '1';
        if (minutes === '0') return `${hours}h`;
        return `${courseTime}h`;
      } return '1h';
    },
  },
  methods: {
    backCourses() {
      this.$router.push({ name: 'Courses' });
    },
  },
};
</script>
