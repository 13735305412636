<template>
  <div class="sidebar-container--item" @click="clicked" :style="{ '--bg-color': this.tenantConfig.primary_color }">
    <slot />
  </div>
</template>
<script>
import './SidebarItem.scss';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('application', ['tenantConfig']),
  },
  methods: {
    clicked() {
      this.$emit('click');
    },
  },
};
</script>
