<template>
  <div class="tab-view">
    <div class="tab-titles">
      <button v-for="(title, index) in titles" :class="`${getTabClasses(index)} ${rounded && 'rounded'}`"
      :key="index" @click="() => changeTab(index)">
        {{ title }}
      </button>
    </div>

    <div id="tabs-collection" class="tabs">
      <slot> </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabView',
  data() {
    return {
      focusedTabIndex: 1,
    };
  },
  props: {
    titles: {
      type: Array,
    },
    tab: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    maxTabs() {
      const tabs = this.$slots.default ?? [];
      return tabs.length;
    },

    isFirstTab() {
      return this.focusedTabIndex === 0;
    },

    isLastTab() {
      return this.maxTabs - 1 === this.focusedTabIndex;
    },
  },
  methods: {
    getTabClasses(tabIndex) {
      const classes = ['tab-title'];

      if (tabIndex === this.focusedTabIndex) {
        classes.push('focused-tab-title');
      }

      return classes.join(' ');
    },

    handleSubmitEnd(event) {
      this.$emit('getFirstErrorFromValidate', event);
      this.$emit('submit', event);
    },

    changeTab(tabIndex) {
      this.focusedTabIndex = tabIndex;
      this.$emit('tabActive', tabIndex);
    },

    changeTabContent() {
      const tabs = document.querySelectorAll('.tabs > *');
      const focusedTabIndex = Number(this.focusedTabIndex);

      tabs.forEach((tab) => {
        if (tab.classList) {
          tab.classList.add('hide-tab');
        }
      });
      if (tabs.item(focusedTabIndex)) {
        tabs.item(focusedTabIndex).classList.remove('hide-tab');
      }
    },
  },

  mounted() {
    this.changeTab(0);
  },

  watch: {
    focusedTabIndex() {
      this.changeTabContent();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-view {
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: transparent;
  margin: 1rem 0;
}

.tab-titles {
  width: 100%;
  height: 3.3125rem;
  display: flex;
  border-radius: 0.75rem;
  border: 0.0625rem solid var(--primary-color);
  gap: 1.15625rem;
  z-index: 2;
}

.tab-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 2.7rem;
  max-width: 16rem;
  cursor: pointer;
  border-radius: 1.6rem;
  border: 1px solid #5D4A99;
  color: #5D4A99;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #fff;
  white-space: nowrap;
}

.focused-tab-title {
  transition: 0.1s;
  border-radius: 1.6rem;
  border: 1px solid #5D4A99;
  color: #FFF;
  background: linear-gradient(0deg, #5D4A99 0%, #5D4A99 100%), #FFF;
  box-shadow: 0px 20px 20px -5px rgba(0, 0, 0, 0.15);
  max-height: 2.7rem;
  max-width: 16rem;
}

::v-deep .hide-tab {
  display: none !important;
}

.tabs {
  margin: 2rem 0;
}

.active {
  background-color: #041c60;
  color: white;
}

button {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
  // background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.rounded{
    border-radius: 0.625rem !important;
  }
}

.control-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.previous {
  width: 6rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: 1px solid #3b59ad;
  background: transparent;
  color: #3b59ad;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;

  &:hover {
    background: #3b59ad;
    color: white;
  }
}

.next {
  width: 6rem;
  height: 2.5rem;
  border: 1px solid #fff;
  border-radius: 0.3rem;
  background: #3b59ad;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;

  &:hover {
    background: #2a417e;
  }
}

@media(max-width: 430px) {
  .tab-titles {
    // flex-direction: column;
    height: auto;
    overflow: auto;
    gap: 0.4rem;
  }

  .focused-tab-title {
    transition: 0.1s;
    border-radius: 1.6rem;
    border: 1px solid #5D4A99;
    color: #FFF;
    background: linear-gradient(0deg, #5D4A99 0%, #5D4A99 100%), #FFF;
    box-shadow: 0px 12px 12px -5px rgba(0, 0, 0, 0.15);
    max-height: 2.7rem;
    // max-width: 16rem;
}

  button.tab-title {
    font-size: 0.7rem;
    // max-width: 9rem;
    width: 100%;
    max-height: 1.4rem;
    padding: 1rem 1.3rem;
  }

  .tab-title {
    margin-bottom: 1rem;
  }
}

</style>
