<template>
  <div class="flex-col mt-48 sm-mt-0 mb-80">
    <div class="flex-row sm-flex-col align-items-center">
      <VueSkeletonLoader class="w-40 h-392px br-10px sm-hide" />
      <div class="flex-col w-60 md-pl-32 lg-pl-32 sm-w-100">
        <VueSkeletonLoader class="w-100 h-68px sm-mb-56" />
        <VueSkeletonLoader class="w-50 sm-w-100 h-24px mt-24" />
        <VueSkeletonLoader class="w-100 h-32px mt-24" />
        <VueSkeletonLoader class="w-60 h-32px mt-24" />
        <VueSkeletonLoader class="w-40 h-32px mt-24" />
        <VueSkeletonLoader class="w-70 h-32px mt-24" />
        <div class="w-100 flex-row mt-64">
          <VueSkeletonLoader class="w-100 h-56px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'CoursesSkeleton',
  components: { VueSkeletonLoader },
};
</script>
