<template>
  <div class="sidebar-container--progress--item" :style="{ '--bg-color': this.tenantConfig.primary_color }">
    <b class="sidebar-container--progress--item--title"> {{ finished }} de {{ total }} das aulas completas </b>
    <ProgressBar :percentage="progressPercentage" />
    <div class="sidebar-container--progress--item--infos">
      <div class="sidebar-container--progress--item--infos-item">
        <span class="sidebar-container--progress--item--infos-item-icon clock" />
        <span class="sidebar-container--progress--item--infos-item--text">{{ time }}</span>
      </div>
      <div class="sidebar-container--progress--item--infos-item">
        <span class="sidebar-container--progress--item--infos-item-icon hat" />
        <span class="sidebar-container--progress--item--infos-item--text">{{ level }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ProgressBar } from 'vue-pdc-ext-frontend';

import './ProgressCard.scss';

export default {
  props: {
    level: {
      type: String,
      default: 'Básico',
    },
    time: {
      type: String,
      default: '00h00',
    },
  },
  components: {
    ProgressBar,
  },
  name: 'ProgressCard',
  computed: {
    ...mapGetters('courses', ['course', 'course_all_lessons']),
    ...mapGetters('application', ['tenantConfig']),
    total() {
      return this.course_all_lessons.length || 0;
    },
    finished() {
      return (this.course_all_lessons || []).filter((lesson) => lesson.finished).length || 0;
    },
    progressPercentage() {
      if (!this.course.progress) {
        return 0;
      }
      return this.course.progress > 100 ? 100 : Math.ceil(this.course.progress);
    },
  },
};
</script>
