<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9989 13.5C15.9989 16.8137 13.3126 19.4999 9.99895 19.4999C6.68528 19.4999 3.99902 16.8137 3.99902 13.5"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M12.4123 3.75879C15.2357 4.71143 17.2791 7.28945 17.3616 10.343C17.365 10.4694 17.3918 10.5945 17.4452 10.7091V10.7091C17.4786 10.7808 17.5248 10.8458 17.5815 10.9009L18.7385 12.0247C19.274 12.5448 18.9313 13.4998 18.2091 13.4998H1.79062C1.0685 13.4998 0.725771 12.5448 1.26125 12.0247L2.41828 10.9009C2.47501 10.8458 2.5212 10.7808 2.55459 10.7091V10.7091C2.60794 10.5945 2.63477 10.4694 2.63818 10.343C2.7207 7.28945 4.76411 4.71143 7.58744 3.75879M12.367 8.27638V2.6294C12.367 2.00565 11.896 1.5 11.3149 1.5H8.68483C8.1038 1.5 7.63279 2.00565 7.63279 2.6294V8.27638"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'Working',
};
</script>
