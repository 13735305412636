<template>
  <div class="collection__card">
    <div class="card__header">
      <div class="card__img">
        <img :src="collection.cover || image" alt="">
      </div>
    </div>

    <div class="card__content">
      <div class="card__headerContent">
        <div class="card__headerContent-rating">
          <span class="trails-pill">Trilhas</span>
          <RatingDisplay :rating="Number(collection.score ? collection.score : 0)" :ratingAmount="collection.qtyScores" />
        </div>
        <p class="card__title">{{ collection.name }}</p>
        <p class="card__description">{{ collection.description }}</p>
      </div>

      <div class="card__footer">
        <CollectionCardSpecs :collection="collection" />
        <CollectionCardBtn :collection="collection" />
      </div>
    </div>
  </div>
</template>

<script>
import './CollectionCard.scss';
import { mapGetters } from 'vuex';
import CollectionCardBtn from './CollectionCardBtn.vue';
import CollectionCardSpecs from './CollectionCardSpecs.vue';
import RatingDisplay from '../../RatingDisplay/RatingDisplay.vue';

export default {
  name: 'CourseCard',
  data() {
    return {
      image: 'https://courses-stg.s3.us-east-2.amazonaws.com/default-course-image.svg',
    };
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
  },
  props: {
    collection: Object,
  },
  components: {
    CollectionCardBtn,
    CollectionCardSpecs,
    RatingDisplay,
  },
};
</script>
