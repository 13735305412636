<template>
  <div class="favorites-section">
    <CoursesSkeleton type="recent" v-if="recent_loading" />
    <Carousel v-else title="Cursos marcados como favorito" :items="favorite_courses">
      <template v-slot:item="{ item }">
        <CourseCard :course="item" :finished="checkFinishedCourse(item)" section="favorite_courses" />
      </template>
    </Carousel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CourseCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import Carousel from '@/components/atoms/Carousel/Carousel.vue';

export default {
  name: 'FavoriteSection',
  components: {
    CourseCard,
    Carousel,
    CoursesSkeleton,
  },
  computed: {
    ...mapGetters('courses', ['recent', 'finished', 'recent_loading', 'favorite_courses']),
  },
  methods: {
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },
  },
};
</script>
