<template>
  <div class="course-exame-topbar">
    <a class="course-exame-topbar--link-back" @click.prevent="handleBackClick">
      <span class="course-exame-topbar--icon-arrow" />
      <span class="course-exame-topbar--text sm-hide">
        {{ backBtnText }}
      </span>
    </a>
    <span class="course-exame-topbar--text mx-auto sm-hide" v-if="show_title">{{ course.title }}</span>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import './CourseExameTopbar.scss';
import LostProgressAlert from '@/components/organisms/Course/CourseExameTopbar/LostProgressAlert.vue';
import { getCookie } from '@/utils/helpers';

export default {
  name: 'CourseExameTopbar',
  props: {
    show_title: {
      type: Boolean,
      default: false,
    },
    back_text: {
      type: String,
      default: 'Abandonar teste',
    },
    custom_back: {
      type: Function,
      require: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('exame', ['current_step', 'is_in_progress', 'is_correcting_exame', 'time_exceeded']),
    showBackToDashboardBtn() {
      return !this.is_in_progress || this.is_correcting_exame || this.time_exceeded;
    },
    backBtnText() {
      if (this.back_text) return this.back_text;
      return this.showBackToDashboardBtn ? 'Voltar ao início' : 'Abandonar teste';
    },
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    ...mapActions('exame', ['abortAttempt']),
    goToCoursePage() {
      this.abortAttempt(this.course.id).then(() => {
        this.appRouterPush({ name: 'CourseDetail', params: { course: this.course.path } });
      });
    },
    handleBackClick() {
      if (this.custom_back) {
        this.custom_back();
      }
      if (this.showBackToDashboardBtn) {
        return this.goToDashboard();
      }
      return this.showAlertDialog('LostProgressAlert');
    },
    goToDashboard() {
      // Cookie utilizado no Teste A/B para informar que o usuario esta na versao de testes
      const newHomeAB = getCookie('pdc_feed_page');
      this.appRouterPush(newHomeAB ? { name: 'Feed' } : { name: 'Courses' });
      this.appRouterPush({ name: 'Courses' });
    },
    getConfirmView(option) {
      const options = {
        LostProgressAlert: {
          message: <LostProgressAlert />,
          cancelButtonText: 'Continuar exame',
          confirmButtonText: 'Abandonar exame',
          confirmAction: () => this.goToCoursePage(),
        },
      };
      return options[option];
    },
    showAlertDialog(alertOption) {
      const confirmView = this.getConfirmView(alertOption);
      this.$confirm(confirmView.message, {
        showClose: false,
        customClass: 'custom-exame-dialog-alert',
        cancelButtonText: confirmView.cancelButtonText,
        confirmButtonText: confirmView.confirmButtonText,
      }).then(() => {
        confirmView.confirmAction();
      });
    },
  },
};
</script>
