<template>
  <TemplateAdmin :loading="loading_app">
    <template slot="topbar-title">
      <a class="link-back" @click.prevent="$router.push({name: 'CourseCollections'})">
        <span class="icon arrow" />
        <span class="text"> Voltar para trilhas </span>
      </a>
    </template>
    <template slot="container">
      <div class="content-page">
        <div class="top-cards">
          <TrailsSection />
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import TrailsSection from '@/components/organisms/Home/TrailsSection.vue';

export default {
  name: 'CourseCollection',
  components: {
    TemplateAdmin,
    TrailsSection,
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  created() {
    this.getCoursesOngoing();
    this.getCoursesFinished();
    this.getCourseCollections();
    this.getTrailsDetails(this.$route.params.collectionId || 1);
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner']),
    ...mapGetters('application', ['loading_app']),
  },
  methods: {
    ...mapActions('courses', ['getCoursesOngoing', 'getCoursesFinished', 'getCourseCollections', 'getTrailsDetails']),
  },
};
</script>
