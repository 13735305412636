const professions = [
  'Pedreiro',
  'Engenheiro Civil',
  'Construtor',
  'Eletricista',
  'Estudante',
  'Azulejista',
  'Pintor',
  'Arquiteto',
  'Empreiteiro',
  'Consumidor',
  'Vendedor - Lojas Mat Construção',
  'Instalador de DryWall',
  'Gesseiro',
  'Automação Residencial',
  'Marido de aluguel',
  'Design de Interiores',
  'Encanador/Instalador Hidráulico',
  'Consultor de Vendas',
  'Dono/Gerente de loja',
  'Marketing',
  'Decorador',
  'Energia Solar',
  'Marceneiro',
  'Impermeabilizador',
  'Representante Comercial',
  'Serralheiro e Solda',
  'Climatização',
  'Concretagem',
  'Limpeza Pós Obra',
  'Jardineiro',
  'RH',
  'Instalador de Piso',
  'Vidraceiro',
  'Telhadista',
  'Chaveiro',
  'Instalador Light Steel Frame',
  'Mestre de obras',
  'Outros',
];

const uniqueProfessions = [...new Set(professions)];

const PROFESSIONALS_OPTIONS = uniqueProfessions.map((profession) => ({
  value: profession,
  label: profession,
}));

export default PROFESSIONALS_OPTIONS;
