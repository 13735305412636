/* eslint-disable no-trailing-spaces */
import axios from 'axios';
import * as accountService from '@/services/account.service';
import { getCookie, setCookie, cleanLoginDataFromLocalStorage } from '@/utils/helpers';
import { dailyPoints } from '@/services/account.service';
import courseHttp from '../../../utils/courses.http';
import { setTokenDefaultInstance } from '../../../utils/default.http';
import { getTokenByCode, getUserInfo, createLogoutUrl } from '../../../utils/authsso';
import { userLogged } from '../../../services/dataLayer.service';
import legacyHttp from '../../../utils/legacy.http';

const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  resume: (state) => state.resume,
  account: (state) => state.account,
  user: (state) => state.user,
  points: (state) => state.points,
  isAdmin: (state) => {
    const accountComplete = state;
    const result = accountComplete && accountComplete.role && accountComplete.role.length > 0 && accountComplete.role[0].name === 'admin';
    return result;
  },
  accountComplete: (state) => state.accountComplete,
  isClub: (st) => st.accountComplete && st.accountComplete.clubAccount && st.accountComplete.clubAccount.length > 0,
  campaign: (st) => st.campaign,
  isSgUser: (st) => st.accountComplete
    && st.accountComplete.role
    && st.accountComplete.role.length > 0
    && [5, 9, 11, 16, 17, 18, 19, 31, 32].includes(st.accountComplete.role[0].id),
  update_account_name: (state) => state.name,
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_error(state) {
    state.status = 'error';
  },
  setPoints(state, points) {
    state.points = points;
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.account = null;
  },
  setToken(state, token) {
    state.token = token;
  },
  setAccount(state, account) {
    state.account = account;
  },
  setAccountComplete(state, account) {
    state.accountComplete = account;
  },
  setCampaign(state, campaign) {
    state.campaign = campaign;
  },
  setAccountCampaign(state, campaign) {
    state.accountCampaign = campaign;
  },
  SET_UPDATE_ACCOUNT_NAME(state, payload) {
    state.accountFull.name = payload;
  },
  SET_EXTERNAL_IDS(state, payload) {
    if (state.account) {
      state.account.externalId = payload.externalId;
      state.account.eventExternalId = payload.eventExternalId;
    }
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
};

const actions = {
  async getSlug() {
    return accountService.getSlug();
  },
  async logout({ commit }) {
    await createLogoutUrl({ redirectUri: window.location.origin });
    commit('logout');
    cleanLoginDataFromLocalStorage();
    delete axios.defaults.headers.common.Authorization;
  },

  async handleToken({ commit }, tokens) {
    localStorage.setItem('loopback-token', JSON.stringify(tokens));
    await setTokenDefaultInstance(tokens, legacyHttp);
    await setTokenDefaultInstance(tokens, courseHttp);
    commit('setToken', tokens.id);
  },

  async loadAccount({ commit, dispatch }, account) {
    dispatch('interests/getUserJobs', null, { root: true });
    try {
      const accountFull = await accountService.getMe(account.id);
      commit('setAccountComplete', {
        ...accountFull,
      });
    } catch (err) {
      // eslint-disable-next-line
      console.log('erro ao carregar conta');
    } finally {
      commit('setAccount', { ...account, name: account.given_name });
    }
  },

  async getPoints({ commit, rootState }) {
    try {
      const { id } = rootState.auth.account;
      await dailyPoints(id).then((item) => commit('setPoints', item));
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  },

  async checkSso({ dispatch, rootState, commit }, options) {
    const { to, redirectOnError } = options;

    // Função para construir o URL de redirecionamento
    const buildRedirectUrl = () => {
      let redirectUrl = window.location.origin + window.location.pathname;
      const externalId = Object.prototype.hasOwnProperty.call(to.query, 'externalId');
      const eventExternalId = Object.prototype.hasOwnProperty.call(to.query, 'eventExternalId');

      if (to.query && externalId && eventExternalId) {
        redirectUrl += `?externalId=${to.query.externalId}&eventExternalId=${to.query.eventExternalId}`;
      }
      return redirectUrl;
    };

    if (to.query && to.query.code && !window.localStorage.getItem('loopback-token')) {
      const { code } = to.query;
      const token = await getTokenByCode({ code, redirectUrl: buildRedirectUrl() });

      if (token) {
        setCookie('PDC_TOKEN', '', 1);
        await dispatch('handleToken', token);
      }
    } else {
      const token = await getCookie('token');
      if (to && token && !window.localStorage.getItem('loopback-token')) {
        if (token) {
          setCookie('PDC_TOKEN', '', 1);
          await dispatch('handleToken', { access_token: token });
        }
      }
    }

    if (!rootState.auth.user) {
      const userInfo = await getUserInfo({ redirectOnError });
      commit('auth/SET_USER', userInfo, { root: true });
    }

    if (!rootState.auth.user) {
      return null;
    }
    if (rootState.auth.user.data.id) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ userId: rootState.auth.user.data.id });

      if (!rootState.application.tenantConfig.id) {
        await dispatch('application/getTenantConfig', { tenant: rootState.auth.user.data.tenant }, { root: true });
      }

      // await dispatch('application/getTenantConfig', { tenant: rootState.auth.user.data.tenant }, { root: true });

      userLogged(window.dataLayer, rootState.auth.user.data);
      try {
        await accountService.dailyPoints(rootState.auth.user.data.id);
      } catch (err) {
        // eslint-disable-next-line
        console.log('Não foi possível carregar os pontos de login');
      }

      if (!rootState.auth.account) {
        await dispatch('loadAccount', rootState.auth.user.data);
      }
    } else {
      const verify = await accountService.getAccountByEmailAndUpdateSso(rootState.auth.user.data.email);
      // verifico se encontrou a conta no banco de dados
      if (verify && !verify.finded) {
        // se não existe redireciona para o registro
        // (obs: sem parametros neste caso pois a url é externa)
        window.location.href = '/registro';
        return null;
      }
      // caso encontre significa que o
      // usuario foi atualizado e agora tem o accountId no perfil
      window.location.reload();
    }
    return rootState.auth.user;
  },

  syncToken({ dispatch }) {
    return new Promise((resolve) => {
      const token = getCookie('PDC_TOKEN') || window.localStorage.getItem('loopback-token');

      if (token) {
        dispatch('handleToken', JSON.parse(decodeURIComponent(token.replace(/[\\]/g, ''))));
      }

      resolve(token);
    });
  },

  syncRouter({ dispatch, commit }, myRouter) {
    // eslint-disable-next-line no-unused-vars
    commit('application/SET_LOADING_APP', true, { root: true });
    myRouter.beforeEach(async (to, from, next) => {
      await dispatch('syncToken');
      await dispatch('checkSso', {
        from,
        to,
        redirectOnError: true,
        next,
      });
      await dispatch('verifyIfFromEvent');
      // dispatch('application/setRouteApp', to.name, { root: true });
      commit('application/SET_LOADING_APP', false, { root: true });
      next();
    });
  },

  async onLoadCampaign({ commit }, campaignSlug) {
    const campaign = await accountService.getCampaign(campaignSlug);
    commit('setCampaign', campaign);
    return campaign;
  },

  updateAccountName({ commit }, payload) {
    commit('SET_UPDATE_ACCOUNT_NAME', payload);
  },

  async verifyIfFromEvent({ commit, state, dispatch }) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('externalId')) {
      const externalId = urlParams.get('externalId');
      const eventExternalId = urlParams.get('eventExternalId');
      try {
        if (!state.account) {
          await dispatch('loadAccount', await getUserInfo());
        }
        await accountService.relateEventTracking({
          externalId,
          eventExternalId,
          accountId: state.account.id,
          email: state.account.email,
          is_email: 1,
        });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
      commit('SET_EXTERNAL_IDS', { externalId, eventExternalId });
    }
  },
};

const state = {
  status: '',
  token: localStorage.getItem('token') || '',
  account: null,
  user: null,
  accountComplete: null,
  campaign: null,
  updateAccountName: null,
  points: 0,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
