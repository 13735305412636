<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">

    <div class="modal-header">
      <h1>Editar informações gerais</h1>
    </div>

    <form>

      <CustomTextArea name="name" title="Descrição*" required v-model="differentialOfYourWork" />

      <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
        <PdcButton class="button" variant="outlined" @click="handleClose">Fechar
        </PdcButton>
        <PdcButton class="button" variant="primary" @click.native="handleSubmit" :loading="loading_save">Salvar
        </PdcButton>
      </span>
    </form>
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import CustomTextArea from '@/components/atoms/CustomTextArea/CustomTextArea.vue';

import './InfoGeneralDialog.scss';

export default {
  name: 'InfoGeneralDialog',
  props: {
    differentialOfYourWork: {
      type: String,
      required: true,
    },
  },
  components: {
    PdcButton,
    CustomTextArea,
  },
  data: () => ({
    dialog: true,
    close: false,
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'toastMessage']),
  },
  methods: {
    ...mapActions('profile', ['updateUserProfile']),

    handleClose() {
      this.$emit('handleClose');
    },
    async handleSubmit(e) {
      e.preventDefault();
      const { differentialOfYourWork } = this;

      await this.updateUserProfile({
        differentialOfYourWork,
      });

      this.handleClose();

      if (this.toastMessage) {
        this.$swal.fire(
          this.toastMessage.title,
          this.toastMessage.description,
          this.toastMessage.type,
        );
      }
    },
  },

};
</script>
