import Router from '@/router';
import * as accountService from '@/services/account.service';

const getInitialState = () => ({
  loading_app: false,
  route: '',
  tenantConfig: {},
  loadingTenantConfig: true,
  preStore: {},
  hasTenant: false,
  URLWhitelabel: '',
});

const getters = {
  loading_app: (state) => state.loading_app,
  route: (state) => state.route,
  tenantConfig: (state) => state.tenantConfig,
  preStore: (state) => state.preStore,
  loadingTenantConfig: (state) => state.loadingTenantConfig,
  hasTenant: (state) => state.hasTenant,
  URLWhitelabel: (state) => state.URLWhitelabel,
};

const mutations = {
  SET_LOADING_APP(state, payload) {
    state.loading_app = payload;
  },
  SET_ROUTE(state, payload) {
    state.route = payload;
  },
  SET_TENANT_CONFIG(state, payload) {
    state.tenantConfig = payload;
  },
  SET_PRE_STORE(state, payload) {
    state.preStore = payload;
  },
  SET_LOADING_TENANT_CONFIG(state, payload) {
    state.loadingTenantConfig = payload;
  },
  SET_HAS_TENANT(state, payload) {
    state.hasTenant = payload;
  },
  SET_URL_WHITELABEL(state, payload) {
    state.URLWhitelabel = payload;
  },
};

const actions = {
  async appRouterPush({ commit }, payload) {
    commit('SET_LOADING_APP', true);
    await Router.push(payload);
  },
  setLoadingApp({ commit }, payload) {
    commit('SET_LOADING_APP', payload);
  },
  setRouteApp({ commit }, payload) {
    commit('SET_ROUTE', payload);
  },
  openLink(_, payload) {
    window.open(`${payload}`, '_self');
  },
  openLinkNewTab(_, payload) {
    window.open(`${payload}`, '_blank');
  },
  async getTenantConfig({ commit }, param) {
    try {
      const tenantConfig = await accountService.getTenantConfig();

      commit('SET_PRE_STORE', { preRegister: false });

      commit('SET_TENANT_CONFIG', tenantConfig);
      localStorage.setItem('URL_WHITELABEL', tenantConfig.url_whitelabel);
      commit('SET_LOADING_TENANT_CONFIG', false);
      if (Object.keys(tenantConfig).length > 1 && tenantConfig?.id !== 1) {
        commit('SET_HAS_TENANT', true);
      }
    } catch (err) {
      commit('SET_LOADING_TENANT_CONFIG', false);
    }
  },
  async preRegister({ commit }) {
    await accountService.getPreStore();
    commit('SET_PRE_STORE', { preRegister: false });
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
