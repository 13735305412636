<template>
  <div class="card">
    <div class="card__header">
      <div class="card__img">
        <div :class="`card__fav-icon ${isFav ? 'isFav' : ''}`" @click="putFavorite">
        </div>
        <img :src="course.primaryImage || image" alt="">
      </div>
    </div>

    <div :class="`card__content ${progress}`">
      <div class="card__headerContent">
        <div class="card__content-type">
          <p class="card__content-type-value">
            {{ course_started }}
          </p>

          <div class="card__content__type__time">
            <img src="@/assets/icons/card/clock-purple.svg" alt="" />
            {{ course_time }}
          </div>

          <div class="card__content__type__rating">
            <img src="@/assets/icons/card/star-purple.svg" alt="" />
            {{ course_rating }}
          </div>

        </div>

      </div>

      <div class="card__content__progress">
        <div class="card__content__progress__bar">
          <div class="card__content__progress__value" :style="`width:${course.progressBar}%`"></div>
        </div>
        <div class="card__content__progress__percent">{{ roundedProgressBar }}%</div>
      </div>

      <p class="card__title">{{ course.title }}</p>

      <div class="card__footer">
        <CourseCardSpecs :course="course" />
        <CourseCardBtn :course="course" />
      </div>
    </div>

  </div>
</template>

<script>
import './CourseCard.scss';
import { mapActions } from 'vuex';
import CourseCardBtn from './CourseCardBtn.vue';
import CourseCardSpecs from './CourseCardSpecs.vue';

export default {
  name: 'CourseCard',
  data() {
    return {
      image: 'https://courses-stg.s3.us-east-2.amazonaws.com/default-course-image.svg',
      isFav: this.course.isFavorite && this.course.isFavorite.id,
    };
  },
  props: {
    course: Object,
  },
  components: {
    CourseCardBtn,
    CourseCardSpecs,
  },
  computed: {
    colorPoint() {
      if (this.course.points >= 200 && this.course.points < 300) {
        return '#6c6c6c';
      }
      return '#FFF';
    },
    backgroundPoint() {
      if (this.course.points >= 500) return '#845EF7';
      if (this.course.points >= 400) return '#FF345D';
      if (this.course.points >= 300) return '#087F5B';
      if (this.course.points >= 200) return '#FFF53D';
      if (this.course.points >= 100) return '#FD7E14';
      if (this.course.points >= 50) return '#F741B6';
      return '#087F5B';
    },
    course_time() {
      const courseTimeFromBack = this.course?.courseTime;
      if (courseTimeFromBack && courseTimeFromBack !== '0') {
        const [hours, minutes] = this.course?.courseTime.split('.');
        const courseTime = this.course?.courseTime ? `${hours}:${minutes}` : '1';
        if (minutes === '0') return `${hours}h`;
        return `${courseTime}h`;
      } return '1h';
    },
    course_rating() {
      return this.course.score ? Math.round(this.course.score)
        : 0;
    },
    progress() {
      switch (true) {
        case this.course.started && this.course.progressBar <= 50:
          return 'card__content--danger';
        case (this.course.progressBar >= 51 && this.course.progressBar <= 99)
        || (this.course.progressBar === 100 && !this.course.approved && !this.course.completed)
        || (this.course.progressBar === 100 && !this.course.approved && this.course.completed):
          return 'card__content--warning';
        case this.course.progressBar === 100 && this.course.approved:
          return 'card__content--success';
        default:
          return '';
      }
    },
    course_started() {
      if (this.course.started && this.course.completed && this.course.approved) {
        return 'Curso finalizado';
      } else if ((this.course.started && !this.course.completed) || (!this.course.approved && this.course.started && this.course.completed)) {
        return 'Curso em andamento';
      }
      return 'Curso não iniciado';
    },
    roundedProgressBar() {
      const { progressBar } = this.course;
      return !progressBar ? 0 : Math.floor(progressBar);
    },
  },
  methods: {
    ...mapActions('courses', ['toggleFavorite']),
    putFavorite() {
      this.toggleFavorite({ entity: 'course', entityId: this.course.id });
      this.isFav = !this.isFav;
    },
  },
};
</script>
