<template>
  <div class="rating-display-container">
    <StarRating :read-only="true" :rating="parsedRating" inactiveColor="transparent" activeColor="#FFE100"
      activeBorderColor="#E5CA00" :border-width="1" border-color="#ADB5BD" :star-size="12" :show-rating="false"
      :increment="0.5" :star-points="[
        23, 2,
        15.85, 16.39,
        0, 19,
        11.57, 29.82,
        8.7, 45.41,
        23, 38.09,
        37.3, 45.41,
        34.43, 29.82,
        46, 19,
        30.15, 16.39
      ]" />
    <div class="rating-display-meta-container">
      <span>{{ rating? parsedLocalizedRating: '' }}</span>
      <!-- <span>({{ ratingAmount }})</span> -->
    </div>
  </div>
</template>

<script>
import './RatingDisplay.scss';
import StarRating from 'vue-star-rating';

export default {
  name: 'RatingDisplay',
  components: {
    StarRating,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    ratingAmount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ratingValue() {
      return Number(this.rating ? this.rating : 0);
    },
    parsedLocalizedRating() {
      return this.rating ? Number(this.rating.toFixed(1)).toLocaleString() : '';
    },
    parsedRating() {
      return this.rating ? Number(this.rating.toFixed(1)) : 0;
    },
  },
};
</script>
