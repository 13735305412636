<template>
  <TemplateAdmin :loading="loading_app">
    <template slot="container">
      <CoursesSkeleton type="recommended" v-if="loading_app" />
      <div v-else class="results-container">
        <button @click="backToFeed" class="back"> <img src="../../assets/icons/card/chevron-left.svg" alt=""></button>
        <loading class="loading" v-if="search_loading" :height="'4rem'" :width="'4rem'" />
        <div v-if="courses_result.length < 1 && !search_loading" class="no-results-img">
          <h3>{{ 'Sem resultados' }}</h3>
          <img src="@/assets/illustrations/no-results.svg" alt="">
          <p>Não encontramos curso para a categoria selecionada</p>
        </div>
        <h3 v-else-if="courses_result.title">{{ courses_result.title }}</h3>
        <div class="result-cards">
          <div v-for="(course, index) in courses_result.courses" :key="index">
            <CoursesCard v-if="course.title" :course="course"
              :finished="checkFinishedCourse(course)" />
            <CollectionCard v-if="course.name" :collection="course" />
          </div>
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CollectionCard from '@/components/organisms/Course/CollectionCard/CollectionCard.vue';
import CoursesCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import Loading from '@/components/atoms/Loading/Loading.vue';
import { getCategoryById } from '@/services/course.service';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CoursesByCategorie',
  components: {
    CoursesSkeleton,
    TemplateAdmin,
    CoursesCard,
    CollectionCard,
    Loading,
  },
  data() {
    return {
      searchTerm: '',
      courses_result: [],
      search_loading: true,
    };
  },
  computed: {
    ...mapGetters('courses', ['search_result',
      'categories', 'coursesByCategorie', 'finished',
    ]),
    ...mapGetters('application', ['loading_app', 'hasTenant', 'loadingTenantConfig']),
    coursesByCategorieToWatch() {
      return this.courses_result;
    },
  },
  methods: {
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },
    backToFeed() {
      this.$router.push({ name: 'Feed' });
    },
    // eslint-disable-next-line consistent-return
    async getCategory(id) {
      const data = await getCategoryById(id);
      if (data) return data;
    },
  },
  async mounted() {
    const { categoryId } = this.$route.params;
    const data = await this.getCategory(categoryId);
    [this.courses_result] = data;
    this.search_loading = false;
  },
};
</script>

<style lang="scss">
.results-container {

  .loading {
    margin: 2rem auto;
  }
  h3 {
    background: #5D4A99;
    border-radius: 30px;
    padding: 0.3rem 0.9rem;
    color: white;
    width: max-content;
  }

  button.back {
    // width: 10%;
    margin-bottom: 2rem;
    border-radius: 30px;
    padding: 0.4rem 0.9rem;
    // border: none;
    background: transparent;
    border:none;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.4rem;
    cursor: pointer;

    img {
      height: 2rem;
    }
    p {
      margin: 0;
      font-family: sans-serif;
      font-weight: 500;
      color: #212559;
    }
    &:hover {
      // border: 1px solid #5D4A99;
      background: #5D4A99;
      p {
        color: white;
      }
      transition: 0.3s ease-in-out;
    }

  }
  .search-specs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #adb5bd;

    span {
      h2 {
        color: #212559;
      }
      p {
        font-size: 1.3rem;
      }
      display: flex;
      align-items: center;
      gap: 0.6rem;
    }

    p {
      color: #495057;
    }
  }

  .result-cards {
    max-width: 820px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .no-results-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    h3 {
      color: #495057;
    }

    p {
      color: #495057;
      text-align: center;
      margin: 0;
    }

    @media screen and (max-width: 500px) {
      img {
        width: 180px;
      }

      h3 {
        font-size: 16px;
      }

      P {
        font-size: 14px;
      }
    }
  }
}

</style>
