const getInitialState = () => ({
  player_current_lesson: {},
  player_next_lesson: {},
  player_countdown: false,
});

const getters = {
  player_current_lesson: (state) => state.player_current_lesson,
  player_next_lesson: (state) => state.player_next_lesson,
  player_countdown: (state) => state.player_countdown,
};

const mutations = {
  SET_PLAYER_CURRENT_LESSON(state, payload) {
    state.player_current_lesson = payload;
  },
  SET_PLAYER_NEXT_LESSON(state, payload) {
    state.player_next_lesson = payload;
  },
  SET_PLAYER_COUNTDOWN(state, payload) {
    state.player_countdown = payload;
  },
};

const actions = {
  setPlayerCurrentLesson({ commit }, payload) {
    commit('SET_PLAYER_CURRENT_LESSON', payload);
  },
  setPlayerNextLesson({ commit }, payload) {
    commit('SET_PLAYER_NEXT_LESSON', payload);
  },
  setPlayerCountdown({ commit }, payload) {
    commit('SET_PLAYER_COUNTDOWN', payload);
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
