<template>
  <div class="card">
    <div class="card__header">
      <div class="card__img">
        <img :src="categoriePicture || image" alt="teste">
      </div>
    </div>

    <div class="card__content">
      <div class="card__headerContent">
        <div class="card__content-type">
          <p class="card-type-value">
            Categoria
          </p>
        </div>
        <!-- <RatingDisplay :rating="Number(categorie.score ? categorie.score : 0)" :ratingAmount="categorie.qtyScores" /> -->
      </div>
      <div class="course-title">
        <p class="card__title">{{ categorie.title }}</p>
      </div>
      <div class="card__footer">
        <!-- <CourseCardSpecs :course="categorie" />/ -->
        <button @click="sendCategorie()" class="matchmaking-btn">Ver mais</button>
      </div>
    </div>
  </div>
</template>

<script>
import '../Course/CoursesCard/CourseCard.scss';
import { mapActions } from 'vuex';

export default {
  name: 'CategorieCard',
  data() {
    return {
      image: 'https://courses-stg.s3.us-east-2.amazonaws.com/default-course-image.svg',
      // isFav: this.categorie.isFavorite !== null,
    };
  },
  computed: {
    categoriePicture() {
      const picture = this.categorie.courses.find((course) => !!course.primaryImage);
      return picture && picture.primaryImage;
    },
  },
  props: {
    categorie: Object,
  },
  components: {
    // CourseCardSpecs,
    // RatingDisplay,
  },
  methods: {
    ...mapActions('courses', ['toggleFavorite', 'setCoursesByCategorie']),
    putFavorite() {
      this.toggleFavorite({ entity: 'course', entityId: this.categorie.id });
      this.isFav = !this.isFav;
    },
    sendCategorie() {
      this.$emit('sendCategorie', this.categorie);
    },
  },
};
</script>
<style scoped lang="scss">
.card__footer  {
  display: flex;
  flex-direction: column;
}

.matchmaking-btn {
  /* height: 21.973px; */
  padding: 5px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #5D4A99;
  margin: 0 auto;
  color: #FFF;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: none;
  cursor: pointer;

  &:hover {
    background: #4A3A7F;
  }
}
.swiper-slide {
    height: auto !important;
}

button {
    display: inline-flex;
    padding: 7px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: #E6213C;
}

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    cursor: revert;
    overflow: hidden;
    height: 100%;
    justify-content: space-between;
    border: 1px solid #ADB5BD66;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);

    .card__header {

        .card__img {
            position: relative;

            img {
                width: 100%;
                max-height: 140px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }

    .card__content {
        padding: 0 16px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .course-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 0;
            margin: 0;
            padding: 0;

            .card__value {
                color: #5D4A99;
                font-family: Poppins;
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }

        .card__headerContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: fax-content;
            }
        }

        .card__title {
            color: #000;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 550;
            line-height: normal;
            margin: 0;
        }

        .card__footer {
            padding-bottom: 16px;
            .card__specs {
                margin-top: 5px;
                justify-content: space-between;
                padding-bottom: 24px;
                width: 100%;

                .card__specs-item {
                    display: flex;
                    align-items: center;
                    font-family: Montserrat;
                    justify-content: space-evenly;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 144%;
                    color: #ea00ff;
                }

                @media screen and (max-width: 500px) {
                    .card__specs-item {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

.flex-row {
    align-items: center;
    justify-content: center;
    button {
        display: inline-flex;
        padding: 10px 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: #E6213C;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
}

.card .card__content .card__headerContent .card__content-type p {
  font-size: 0.8rem;
  color: #ACACAC;
  background: white;
  font-family: Poppins;
  font-size: 13px;
  padding: 0;
  font-style: normal;
  margin: 0;
  font-weight: 600;
  line-height: normal;
}

@media(max-width: 500px) {
    .card {
        max-width: 320px;
    }
}
</style>
