<!-- eslint-disable max-len -->
<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6H13M13 6L8 1M13 6L8 11" stroke="#949494" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight',
};
</script>
