import * as addressService from '@/services/address.service';

const getInitialState = () => ({
  loadingAddress: true,
  userAddress: [],
  useAddressOn: '',
});

const getters = {
  loadingAddress: (state) => state.loadingAddress,
  userAddress: (state) => state.userAddress,
  useAddressOn: (state) => state.useAddressOn,
};

const mutations = {
  SET_LOADING_ADDRESS(state, payload) {
    state.loadingAddress = payload;
  },
  SET_ADDRESS(state, payload) {
    state.userAddress = payload;
  },
  SET_USE_ADDRESS_ON(state, payload) {
    state.useAddressOn = payload;
  },
};

const actions = {
  async getAccountAddress({ commit, rootState }, typeAddress = 'accountId') {
    try {
      const { accountId } = rootState.auth.account;
      const { profileId } = rootState.profile.userProfile;

      const loadAddressFrom = typeAddress === 'accountId' ? accountId : profileId;

      const response = await addressService.getAddress(loadAddressFrom, typeAddress);

      commit('SET_ADDRESS', response);
      commit('SET_LOADING_ADDRESS', false);
    } finally {
      commit('SET_LOADING_ADDRESS', false);
    }
  },
  async saveAddressOnProfile({ commit, rootState }, payload) {
    commit('SET_LOADING_ADDRESS', true);
    const accountId = rootState.auth.account.id;
    const professionalProfileId = rootState.profile.userProfile?.id;
    const { body, typeAddress } = payload;
    const createOrUpdateAddressByParam = {
      onlyProfessional: async () => addressService.createAddress({ ...body, professionalProfileId }),
      onlyPersonal: async () => addressService.createAddress({ ...body, accountId }),
      professional: async () => addressService.updateAddress({ ...body, accountId, professionalProfileId }),
      both: async () => addressService.createAddress({ ...body, accountId, professionalProfileId }),
    };
    const response = await createOrUpdateAddressByParam[typeAddress]();
    commit('SET_ADDRESS', response);
    commit('SET_LOADING_ADDRESS', false);
  },
  saveAddressOnState({ commit }, payload) {
    commit('SET_ADDRESS', payload);
  },
  saveUseAddressOn({ commit }, payload) {
    commit('SET_USE_ADDRESS_ON', payload);
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
