import Vue from 'vue';
import VueRouter from 'vue-router';
import { NotFoundPage, MaintenancePage } from 'vue-pdc-ext-frontend';
import Courses from '@/views/Courses/Courses.vue';
import store from '@/store';
import Feed from '@/views/Feed/Feed.vue';
import CourseDetails from '@/views/CoursesDetails/CourseDetails.vue';
import CourseExame from '@/views/CourseExame/CourseExame.vue';
import CourseExameReview from '@/views/CourseExameReview/CourseExameReview.vue';
import ExameResult from '@/views/ExameResult/ExameResult.vue';
import Certificate from '@/views/Certificate/Certificate.vue';
import CollectionsDetails from '@/views/CourseCollections/CourseCollection.vue';
import SearchResult from '@/views/Search/SearchResult.vue';
import CollectionList from '@/views/CourseCollections/CollectionList/CollectionList.vue';
import { getCookie } from '@/utils/helpers';
import { pageView } from '@/services/dataLayer.service';
import UserAccountData from '@/views/UserAccountData/UserAccountData.vue';
import Contact from '@/views/Contact/Contact.vue';
import UserProfile from '@/views/UserProfile/UserProfile.vue';
import MyLearning from '@/views/MyLearning/MyLearning.vue';
import CoursesByCategorie from '@/views/CoursesByCategorie/CoursesByCategorie.vue';
import Ranking from '@/views/Ranking/Ranking.vue';
import AwardCampaing from '@/views/AwardCampaing/AwardCampaing.vue';
import AwardRankingCampaing from '@/views/AwardRankingCampaing/AwardRankingCampaing.vue';

Vue.use(VueRouter);
// Cookie utilizado no Teste A/B para informar que o usuario esta na versao de testes
const newHomeAB = getCookie('pdc_feed_page');
const URL_WHITELABEL = localStorage.getItem('URL_WHITELABEL') ?? 'pdc';

const routes = [
  {
    path: '/',
    name: 'login',
    redirect: { path: newHomeAB ? '/feed' : `${URL_WHITELABEL}/dashboard/evoluir/cursos` },
  },
  {
    path: '/:whitelabelURL/',
    name: 'Feed',
    component: Feed, // Substitua por seu componente real
    props: (route) => ({ code: route.query.code }),
  },
  {
    path: '/:whitelabelURL/feed',
    name: 'Feed',
    component: Feed,
    meta: { title: 'Parceiro da Construção', requiresSearch: true },
  },
  {
    path: '/:whitelabelURL/dashboard/evoluir/cursos',
    name: 'Courses',
    component: Courses,
    meta: { title: 'Parceiro da Construção - Cursos', requiresSearch: true },
  },
  {
    path: '/:whitelabelURL/parceiro/meus-dados',
    name: 'AccountData',
    component: UserAccountData,
    meta: { title: 'Meus Dados', requiresSearch: true },
  },
  {
    path: '/:whitelabelURL/parceiro/contato',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Fale conosco', requiresSearch: false },
  },
  {
    path: '/:whitelabelURL/dashboard/evoluir/trilhas-de-conhecimento',
    name: 'CourseCollections',
    component: CollectionList,
    meta: { title: 'Parceiro da Construção - Trilhas', requiresSearch: true },
  },
  {
    name: 'UserProfile',
    path: '/:whitelabelURL/user-profile/:accountId?',
    component: UserProfile,
    meta: { title: 'Perfil Profissional', requiresSearch: false },
  },
  {
    name: 'CourseCollection',
    path: '/:whitelabelURL/dashboard/evoluir/trilhas-de-conhecimento/:collectionId',
    component: CollectionsDetails,
    meta: {
      requiresAuth: true,
      title: 'Parceiro da Construção - Trilhas',
      requiresSearch: false,
    },
  },
  {
    name: 'SearchResult',
    path: '/:whitelabelURL/search/:searchTerm',
    component: SearchResult,
    meta: {
      requiresAuth: true,
      title: 'Parceiro da Construção - Resultado da Pesquisa',
      requiresSearch: true,
    },
  },
  {
    name: 'Ranking',
    path: '/:whitelabelURL/ranking',
    component: Ranking,
    meta: {
      requiresAuth: true,
      title: 'Parceiro da Construção - Ranking',
      requiresSearch: true,
    },
  },
  {
    name: 'CoursesByCategorie',
    path: '/:whitelabelURL/courses-by-categorie/:categoryId',
    component: CoursesByCategorie,
    meta: { requiresAuth: true },
  },
  {
    name: 'CourseDetail',
    path: '/:whitelabelURL/dashboard/evoluir/cursos/:course',
    component: CourseDetails,
    meta: { requiresAuth: true, requiresSearch: false },
    children: [
      {
        name: 'CourseDetailLesson',
        path: '/:whitelabelURL/dashboard/evoluir/cursos/:course/licao/:module/topico/:lesson',
        component: CourseDetails,
        meta: { requiresAuth: true, requiresSearch: false },
      },
    ],
  },
  {
    name: 'CourseExame',
    path: '/:whitelabelURL/dashboard/evoluir/cursos/:course/exame',
    component: CourseExame,
    meta: { requiresAuth: true, requiresSearch: false },
  },
  {
    name: 'CourseExameReview',
    path: '/:whitelabelURL/dashboard/evoluir/cursos/:course/exame/correcao',
    component: CourseExameReview,
    meta: { requiresAuth: true, requiresSearch: false },
  },
  {
    name: 'ExameResult',
    path: '/:whitelabelURL/dashboard/evoluir/cursos/:course/exame/resultado',
    component: ExameResult,
    meta: { requiresAuth: true, requiresSearch: false },
  },
  {
    name: 'Certificate',
    path: '/:whitelabelURL/dashboard/evoluir/cursos/:course/exame/resultado/certificado',
    component: Certificate,
    meta: { requiresAuth: true, requiresSearch: false },
  },
  {
    path: '/:whitelabelURL/meu-aprendizado',
    name: 'MyLearning',
    component: MyLearning,
    meta: { title: 'Parceiro da Construção', requiresSearch: true },
  },
  {
    path: '/:whitelabelURL/campanha',
    name: 'AwardCampaing',
    component: AwardCampaing,
    meta: { title: 'Parceiro da Construção', requiresSearch: true },
  },
  {
    path: '/:whitelabelURL/ranking-campanha',
    name: 'AwardRankingCampaing',
    component: AwardRankingCampaing,
    meta: { title: 'Parceiro da Construção', requiresSearch: true },
  },
  { path: '/:whitelabelURL/manutencao', name: 'MaintenancePage', component: MaintenancePage },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage },
];

// eslint-disable-next-line
const maintenanceRoutes = routes.map((route) => {
  return route.name === 'MaintenancePage' ? { ...route } : { ...route, redirect: { path: '/manutencao' } };
});

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

window.DEFAULT_TITLE = 'Parceiro da Construção';

// router.beforeEach((to, _, next) => {
//   if (!to.params.whitelabelURL) {
//     setTimeout(() => next({ name: 'Courses', params: { whitelabelURL: URL_WHITELABEL } }), 2000);
//   }
//   return next();
// });

function sendToCommunityProfile(fullPath) {
  if (!fullPath.includes('/user-profile')) return;
  const [, env, route, param] = fullPath.split('/');
  const { origin } = window.location;
  const path = `${origin}/${env}/?redirect=${route}/${param}`;
  window.open(path, '_self');
}

router.beforeEach((to, _, next) => {
  const communityURLs = ['community', 'community-hml'];
  const isValidURL = communityURLs.some((url) => to.path.includes(`${url}/user-profile`));
  if (isValidURL) {
    return sendToCommunityProfile(to.path);
  }
  const isCommunityPath = to.path.split('/')[1] === 'community';
  if (!to.params.whitelabelURL && !isCommunityPath) {
    const { origin } = window.location;
    const param = to.path.split('/').find((item) => item === URL_WHITELABEL);
    const path = `${origin}${to.fullPath.includes(param) ? param : `/${URL_WHITELABEL}`}${to.fullPath}`;
    window.open(path, '_self');
    next();
  }
  return next();
});

router.afterEach((to) => {
  const whitelabelURLState = store.state.auth.account.tenant.url_whitelabel ?? 'pdc';
  const whitelabelURL = to.params.whitelabelURL === 'null' ? 'pdc' : to.params.whitelabelURL;

  if (whitelabelURL !== whitelabelURLState) {
    router.push({ name: 'Courses', params: { whitelabelURL: whitelabelURLState } });
  }

  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title : window.DEFAULT_TITLE;
    if (window.dataLayer) {
      pageView(window.dataLayer, document.title, to.name, to.path);
    }
  });
});

store.dispatch('auth/syncRouter', router);

export default router;
