<template>
  <div class="certificate-card-container">
    <div class="certificate-info">
      <p class="certificate-name">{{ index + 1 }} Certificado - {{ certificate.title }}.pdf</p>
    </div>
    <div class="certificate-actions">

      <div v-if="loadingPdf" class="loading-box">
        <Loading />
      </div>
      <a v-else @click="downloadPdf(certificate.urlPdf, certificate.title)">
        <img src="@/assets/icons/download.svg" />
        Baixar
      </a>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/atoms/Loading/Loading.vue';
import { download } from '@/utils/helpers';

export default {
  name: 'CertificateCard',
  components: {
    Loading,
  },
  data() {
    return {
      loadingPdf: false,
    };
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    certificate: {
      type: Object,
    },
    onShow: {
      type: Function,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    async downloadPdf(urlPdf, title) {
      this.loadingPdf = true;
      await download(urlPdf, title);
      this.loadingPdf = false;
    },
    onShowCertificate(urlImage) {
      this.onShow(urlImage);
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #DFE1E4;
  background: #FBFBFB;
  border-radius: 18px;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

}

.certificate-info {
  width: 100%;
  max-width: 40rem;

  .certificate-name {
    font-weight: 600;
    color: #5D4A99;
    font-size: 1.125rem;
    margin: 0;
  }
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  min-width: 110px;
}

.certificate-actions {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  button,
  a {
    border: 0;
    background: none;
    font-weight: 500;
    color: #949494;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      height: 24px;
      margin-right: 0.25rem;
    }
  }

  button+a {
    margin-left: 2rem;
  }
}

@media(max-width: 768px) {
  .certificate-card-container {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .certificate-actions {
    margin-left: auto;
    margin-top: 0.5rem;

    button+a {
      margin-left: 1rem;
    }
  }

  .certificate-info .certificate-name {
    font-size: 1rem;
  }

}
</style>
