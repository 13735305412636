<template>
  <div class="ranking">
    <div class="ranking__header">
      <h3 class="ranking__header-title">Ranking geral</h3>
      <p class="ranking__header-description">
        Quanto mais você interage com a plataforma, finalizando
        cursos e trilhas, mais chances tem de ganhar prêmios
      </p>

      <div class="ranking__header-filters">
        <button type="button"
          :class="`ranking__header-filters-button ${periodActive === 'CURRENT_MONTH' && 'ranking__header-filters-button--active'}`"
          @click="handleGetRanking('CURRENT_MONTH')">Mês atual</button>
        <button type="button"
          :class="`ranking__header-filters-button ${periodActive === 'LAST_MONTH' && 'ranking__header-filters-button--active'}`"
          @click="handleGetRanking('LAST_MONTH')">Último mês</button>
        <button type="button"
          :class="`ranking__header-filters-button ${periodActive === 'CURRENT_YEAR' && 'ranking__header-filters-button--active'}`"
          @click="handleGetRanking('CURRENT_YEAR')">Este ano</button>
        <button type="button"
          :class="`ranking__header-filters-button ${periodActive === '' && 'ranking__header-filters-button--active'}`"
          @click="handleGetRanking('')">Histórico geral</button>
      </div>
    </div>

    <div class="ranking__table">
      <div class="ranking__table-header">
        <div class="ranking__table-header-column">
          <span>Nome</span>
        </div>

        <div class="ranking__table-header-column">
          <Icon :name="'Ranking'" />
          <span>Posição</span>
        </div>

        <div class="ranking__table-header-column">
          <Icon :name="'Book'" />
          <span>Certificados</span>
        </div>
      </div>

      <template v-if="rankingList.length < 1">
      <Notfound :title="'Nenhum ranking encontrado!'"
        :subtitle="'Não existem dados disponíveis para exibição no momento'" />
    </template>

    <template v-else>
      <div v-if="loadingFilterRanking">
        <Loading :width="'4rem'" :height="'4rem'" style="margin: auto" />
      </div>

      <template v-else>
        <div class="ranking__table-row" v-for="(rank, index) in rankingList" :key="index">
          <div class="ranking__table-row-column">
            <div class="ranking__profile">
              <div class="ranking__profile-image">
                <img :src="rank.avatar || imageDefault" />
              </div>
              <Icon :name="'HatConstruction'" />
              <span class="ranking__profile-name">
                {{ rank.name }}
              </span>
            </div>
          </div>

          <div class="ranking__table-row-column">
            <div class="ranking__position">
              {{ rank.position }}º Lugar
            </div>
          </div>

          <div class="ranking__table-row-column">
            <div class="ranking__certificates">
              <span class="ranking__certificates-total">{{ rank.certificates }}</span>
              <span class="ranking__certificates-label">Certificações</span>
            </div>
          </div>
        </div>
      </template>
    </template>
    </div>
  </div>
</template>
<script>
import './RankingInitial.scss';

import { mapActions, mapGetters } from 'vuex';

import Icon from '@/components/atoms/Icon/Icon.vue';
import Notfound from '@/components/atoms/Notfound/Notfound.vue';
import Loading from '@/components/atoms/Loading/Loading.vue';

export default {
  name: 'RankingInitial',
  components: {
    Icon,
    Notfound,
    Loading,
  },
  computed: {
    ...mapGetters('ranking', ['rankingList', 'loadingFilterRanking', 'periodActive']),
    imageDefault() {
      return 'https://res.cloudinary.com/dzhbkamn0/image/upload/v1687226975/MicrosoftTeams-image_4_rtkgqe.jpg';
    },
  },
  methods: {
    ...mapActions('ranking', ['getRanking']),
    async handleGetRanking(date) {
      await this.getRanking(date);
    },
    goToCampaignRanking() {
      const url = 'https://dados.parceirodaconstrucao.com.br/public/dashboard/8ddb2c04-6371-4928-b2ab-5be729a08191';
      window.open(url, '_blank');
    },
  },

};
</script>
