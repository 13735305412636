<template>
  <TemplateAdmin :loading="isLoading" :show_help_btn="false" class="CourseExame">
    <CourseExameTopbar slot="topbar-container" />
    <CourseExameTimer slot="topbar-dropdown" v-if="showTimer" />
    <template slot="container">
      <transition name="fade-only-enter">
        <div>
          <CourseExameSkeleton v-if="isLoading" />
          <template v-else>
            <ProgressBar :percentage="percentageProgress" bgColor="#F1F3F5" :colors="['#3E297F']" :height="8"
              v-if="showProgressBar" />
            <ExameTimeExceeded v-if="time_exceeded" />
            <SliderSteps v-else @on-step-changed="setCurrentStep" ref="step_component" :initial_step="current_step">
              <template v-slot:step-0="{ nextStep }">
                <ExameIntroduction :handleNextStep="nextStep" v-if="!isApprovedThreshold" />
                <PdcRating :handleNextStep="nextStep" v-if="isApprovedThreshold" />
              </template>
              <template v-for="(question, index) in questions" v-slot:[makeStepName(index)]="{ nextStep, previousStep }">
                <CourseExameStepQuestion :handleNextStep="nextStep" :handlePreviousStep="previousStep"
                  :question="question" :course="course" :key="index" :index="index" />
              </template>
              <template v-slot:[makeStepName(qtyQuestions)]="{ previousStep }">
                <AnswerRevision :handlePreviousStep="previousStep" v-if="is_revising_exame" />
                <ExameCorrectingDialog v-else-if="is_correcting_exame" />
              </template>
            </SliderSteps>
          </template>
        </div>
      </transition>
    </template>
  </TemplateAdmin>
</template>
<script>
import './CourseExame.scss';
import { mapActions, mapGetters } from 'vuex';
import { SliderSteps, ProgressBar } from 'vue-pdc-ext-frontend';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CourseExameTopbar from '@/components/organisms/Course/CourseExameTopbar/CourseExameTopbar.vue';
import CourseExameSkeleton from '@/components/organisms/Skeletons/CourseExameSkeleton.vue';
import ExameIntroduction from '@/components/organisms/Course/CourseExameSteps/ExameIntroduction/ExameIntroduction.vue';
import CourseExameStepQuestion from '@/components/organisms/Course/CourseExameSteps/CourseExameStepQuestion/CourseExameStepQuestion.vue';
import CourseExameTimer from '@/components/organisms/Course/CourseExameTimer/CourseExameTimer.vue';
import AnswerRevision from '@/components/organisms/Course/AnswerRevision/AnswerRevision.vue';
import ExameTimeExceeded from '@/components/organisms/Course/ExameTimeExceeded/ExameTimeExceeded.vue';
import ExameCorrectingDialog from '@/components/organisms/Course/ExameCorrectingDialog/ExameCorrectingDialog.vue';
import PdcRating from '@/components/organisms/Course/PdcRating/PdcRating.vue';

export default {
  name: 'CourseExame',
  components: {
    TemplateAdmin,
    SliderSteps,
    CourseExameTopbar,
    CourseExameSkeleton,
    ExameIntroduction,
    CourseExameStepQuestion,
    CourseExameTimer,
    AnswerRevision,
    ProgressBar,
    ExameTimeExceeded,
    ExameCorrectingDialog,
    PdcRating,
  },
  created() {
    this.initPage();
  },
  computed: {
    ...mapGetters('application', ['loading_app']),
    ...mapGetters('courses', ['course', 'course_loading']),
    ...mapGetters('exame', [
      'current_step',
      'is_in_progress',
      'questions',
      'is_correcting_exame',
      'is_revising_exame',
      'time_exceeded',
      'exame_loading',
      'last_step_page',
    ]),
    isLoading() {
      return this.course_loading || this.loading_app || this.exame_loading;
    },
    qtyQuestions() {
      return this.questions.length;
    },
    percentageProgress() {
      return (this.current_step * 100) / this.qtyQuestions;
    },
    isLastStep() {
      return this.current_step === this.qtyQuestions + 1;
    },
    showProgressBar() {
      return this.current_step && !this.isLastStep && !this.time_exceeded && !this.is_correcting_exame;
    },
    showTimer() {
      return !this.is_correcting_exame && this.is_in_progress;
    },
    isApprovedThreshold() {
      return this.course.quizApprovalThreshold === 0;
    },
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    ...mapActions('exame', ['setCurrentStep', 'fetchExameStarter', 'startAttempt', 'setExameLoading', 'setLastStepPage']),
    makeStepName(index) {
      return `step-${index + 1}`;
    },
    async startPage(step = 0, course, startAttempt = true) {
      this.setCurrentStep(step);
      if (step > 0) {
        if (startAttempt) {
          await this.startAttempt(course.id);
        }
        this.setExameLoading(false);
      }
      this.setExameLoading(false);
    },
    initPage() {
      // eslint-disable-next-line no-unused-vars
      this.fetchExameStarter(this.$router.currentRoute.params.course).then(async ({ course, questions }) => {
        document.title = `${course.title} | EXAME - ${window.DEFAULT_TITLE}`;

        const answeredQuestions = questions.filter((question) => question.answered);
        const hasAnsweredQuestions = answeredQuestions.length > 0;

        if (!hasAnsweredQuestions) {
          return this.startPage(0, course);
        }

        const qtyAnsweredQuestions = answeredQuestions.length;
        if (qtyAnsweredQuestions === questions.length && course.progress === 100 && course.approved) {
          return this.$router.push({
            name: 'ExameResult',
            params: {
              course: course.path,
            },
          });
        }

        const notAnsweredQuestion = questions.findIndex((question) => !question.answered);
        return this.startPage(notAnsweredQuestion + 1, course);
      });
    },
  },
};
</script>
