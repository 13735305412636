import Vue from 'vue';
import * as AdminService from '@/services/admin.service';
import * as CourseService from '@/services/course.service';
import * as FavoritesService from '@/services/favorites.service';
import {
  initiateCourse, finishLessons,
} from '@/services/dataLayer.service';
// eslint-disable-next-line import/no-cycle
import Router from '@/router';

const getInitialState = () => ({
  ads: [],
  course: {},
  course_current_topic: null,
  course_loading: true,
  ongoing_loading: true,
  finished_loading: true,
  categories_loading: true,
  collections_loading: true,
  recent_loading: true,
  recommended_loading: true,
  recommended_by_course_loading: true,
  ranking_loading: true,
  semantic_search_loading: true,
  courses: [],
  categories: [],
  pdc_talks: [],
  collections: [],
  recommended_collections: [],
  collections_on_going: [],
  trail_details: [],
  ongoing: [],
  recommended: [],
  recommended_by_course: [],
  recommended_by_course_error: null,
  recent: [],
  ranking_courses: [],
  finished: [],
  enable_finished: false,
  search_result: [],
  semantic_search_result: [],
  favorite_courses: [],
  favorite_collections: [],
  coursesByCategorie: [],
  filterSearch: {
    collections: true,
    courses: true,
  },
  course_evaluated: null,
});

const getters = {
  ads: (state) => state.ads,
  recommended_loading: (state) => state.recommended_loading,
  recommended_by_course_loading: (state) => state.recommended_by_course_loading,
  categories_loading: (state) => state.categories_loading,
  ranking_loading: (state) => state.ranking_loading,
  collections_loading: (state) => state.collections_loading,
  courses: (state) => state.courses,
  recent: (state) => state.recent,
  authStatus: (state) => state.status,
  resume: (state) => state.resume,
  categories: (state) => state.categories,
  trail_details: (state) => state.trail_details,
  pdc_talks: (state) => state.pdc_talks,
  collections: (state) => state.collections,
  recommended_collections: (state) => state.recommended_collections,
  ranking_courses: (state) => state.ranking_courses,
  collections_on_going: (state) => state.collections_on_going,
  ongoing: (state) => state.ongoing,
  ongoing_loading: (state) => state.ongoing_loading,
  coursesByCategorie: (state) => state.coursesByCategorie,
  finished_loading: (state) => state.finished_loading,
  recommended: (state) => state.recommended,
  recommended_by_course: (state) => state.recommended_by_course,
  recommended_by_course_error: (state) => state.recommended_by_course_error,
  loading: (state) => state.loading,
  finished: (state) => state.finished,
  course: (state) => state.course,
  course_current_topic: (state) => state.course_current_topic,
  recent_loading: (state) => state.recent_loading,
  course_loading: (state) => state.course_loading,
  course_all_lessons: (state) => {
    if (!state.course) {
      return [];
    }
    const lessons = [];
    (state.course?.courseLessons || []).forEach((modules) => {
      modules.courseTopics.forEach((lesson) => lessons.push(lesson));
    });
    return lessons;
  },
  enable_finished: (state) => state.enable_finished,
  show_welcome_banner: (state) => {
    if (state.ongoing_loading || state.finished_loading) {
      return false;
    }
    const show = state.ongoing.length === 0 && state.finished.length === 0;
    return show;
  },
  search_result: (state) => state.search_result,
  semantic_search_result: (state) => state.semantic_search_result,
  semantic_search_loading: (state) => state.semantic_search_loading,
  favorite_courses: (state) => state.favorite_courses,
  favorite_collections: (state) => state.favorite_collections,
  filterSearch: (state) => state.filterSearch,
  course_evaluated: (state) => state.course_evaluated,
};

const mutations = {
  SET_ADS(state, payload) {
    state.ads = payload;
  },
  SET_RECOMMENDED_LOADING(state, loading) {
    state.recommended_loading = loading;
  },
  SET_RECOMMENDED_BY_COURSE_LOADING(state, loading) {
    state.recommended_by_course_loading = loading;
  },
  SET_CATEGORIES_LOADING(state, loading) {
    state.categories_loading = loading;
  },
  SET_RECENT(state, payload) {
    state.recent = payload;
  },
  SET_COURSES_BY_CATEGORIES(state, payload) {
    state.coursesByCategorie = payload;
  },
  SET_COLLECTIONS_LOADING(state, loading) {
    state.collections_loading = loading;
  },
  SET_RANKING_LOADING(state, loading) {
    state.ranking_loading = loading;
  },
  SET_COURSES(state, payload) {
    state.courses = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_PDC_TALKS(state, payload) {
    state.pdc_talks = payload;
  },
  SET_COLLECTIONS(state, payload) {
    state.collections = payload;
  },
  SET_RECOMMENDED_COLLECTIONS(state, payload) {
    state.recommended_collections = payload;
  },
  SET_COLLECTIONS_ONGOING(state, payload) {
    state.collections_on_going = payload;
  },
  SET_TRAIL_DETAILS(state, payload) {
    state.trail_details = payload;
  },
  SET_ONGOING_LOADING(state, payload) {
    state.ongoing_loading = payload;
  },
  SET_ONGOING(state, payload) {
    state.ongoing = payload;
  },
  SET_RECOMMENDED(state, payload) {
    state.recommended = payload;
  },
  SET_RECOMMENDED_BY_COURSE(state, payload) {
    state.recommended_by_course = payload;
  },
  SET_RECOMMENDED_BY_COURSE_ERROR(state, error) {
    state.recommended_by_course_error = error;
  },
  SET_RECENT_LOADING(state, payload) {
    state.recent_loading = payload;
  },
  SET_FINISHED_LOADING(state, payload) {
    state.finished_loading = payload;
  },
  SET_FINISHED(state, payload) {
    state.finished = payload;
  },
  SET_COURSE_LOADING(state, payload) {
    state.course_loading = payload;
  },
  SET_COURSE(state, payload) {
    state.course = payload;
  },
  SET_COURSE_CURRENT_TOPIC(state, lessons) {
    let hasCurrentTopics;
    lessons.forEach((lesson) => lesson.courseTopics.some((topic) => {
      if (topic.current_topic) hasCurrentTopics = true;
      return null;
    }));

    if (hasCurrentTopics) {
      const currentLessonIdx = lessons.findIndex((lesson) => lesson.courseTopics.some((topic) => topic.current_topic));
      const isLastLessonTopic = lessons.slice(-1)[0].courseTopics.slice(-1)[0].current_topic === true;

      // eslint-disable-next-line
      state.course_current_topic = lessons.slice(-1)[0].courseTopics.slice(-1)[0];

      if (currentLessonIdx >= 0 && !isLastLessonTopic) {
        const currentLessonCurrentTopicIdx = lessons[currentLessonIdx].courseTopics.findIndex((topic) => topic.current_topic);
        const currentLessonNextTopic = lessons[currentLessonIdx].courseTopics[currentLessonCurrentTopicIdx + 1];

        if (currentLessonNextTopic) {
          state.course_current_topic = currentLessonNextTopic;
        } else {
          const nextLessonNextTopicIdx = lessons[currentLessonIdx + 1].courseTopics.findIndex((topic) => topic.current_topic);
          const nextLessonNextTopic = lessons[currentLessonIdx + 1].courseTopics[nextLessonNextTopicIdx + 1];
          state.course_current_topic = nextLessonNextTopic;
        }
      }
    } else {
      // eslint-disable-next-line
      state.course_current_topic = lessons[0].courseTopics[0];
    }
  },
  SET_ENABLE_FINISHED(state, payload) {
    state.enable_finished = payload;
  },
  SET_STATE_VALUE(state, payload) {
    Vue.set(state, payload.key, payload.value);
  },
  SET_SEARCH_RESULT(state, payload) {
    state.search_result = payload;
  },
  SET_SEMANTIC_SEARCH_RESULT(state, payload) {
    state.semantic_search_result = payload;
  },
  SET_SEMANTIC_SEARCH_LOADING(state, payload) {
    state.semantic_search_loading = payload;
  },
  SET_RANKING_COURSES(state, payload) {
    state.ranking_courses = payload;
  },
  SET_FAVORITE_COURSES(state, payload) {
    state.favorite_courses = payload;
  },
  SET_FAVORITE_COLLECTIONS(state, payload) {
    state.favorite_collections = payload;
  },
  SET_FILTER_SEARCH(state, payload) {
    state.filterSearch = payload;
  },
  SET_COURSE_EVALUATED(state, payload) {
    state.course_evaluated = payload;
  },
};

const actions = {
  setCourseLoading({ commit }, payload) {
    commit('SET_COURSE_LOADING', payload);
  },
  async fetchOnlyCourse({ commit }, slug) {
    await commit('SET_COURSE_LOADING', true);
    const course = await CourseService.findCourse(slug);
    commit('SET_COURSE', course);
    commit('SET_COURSE_LOADING', false);
    return course;
  },
  async fetchCourseWithoutLessons({ commit }, slug) {
    await commit('SET_COURSE_LOADING', true);
    const course = await CourseService.findCourse(slug);
    commit('SET_COURSE', course);
    return course;
  },
  async fetchCourse({ commit }, slug) {
    await commit('SET_COURSE_LOADING', true);

    const course = await CourseService.findCourse(slug);
    course.courseLessons = await CourseService.fetchLessons(course.id);
    const ads = await AdminService.fetchCourseAds(course.id);

    commit('SET_ADS', ads);
    commit('SET_COURSE', course);
    commit('SET_COURSE_CURRENT_TOPIC', course.courseLessons);
    commit('SET_COURSE_LOADING', false);

    return course;
  },
  async fetchCoursePath({ commit, rootState }, slug) {
    const { id } = rootState.auth.account;
    await commit('SET_COURSE_LOADING', true);

    const [course] = await CourseService.findCoursePath(slug, id);
    course.courseLessons = await CourseService.fetchLessons(course.id);
    const ads = await AdminService.fetchCourseAds(course.id);

    commit('SET_ADS', ads);
    commit('SET_COURSE', course);
    commit('SET_COURSE_CURRENT_TOPIC', course.courseLessons);
    commit('SET_COURSE_LOADING', false);

    return course;
  },
  async fetchCourseWithoutLoading({ commit }, slug) {
    const course = await CourseService.findCourse(slug);
    course.courseLessons = await CourseService.fetchLessons(course.id);
    commit('SET_COURSE', course);
    commit('SET_COURSE_CURRENT_TOPIC', course.courseLessons);
    return course;
  },
  setCoursesByCategories({ commit }, payload) {
    commit('SET_COURSES_BY_CATEGORIES', payload);
  },
  async getAllCourses({ commit }) {
    const courses = await CourseService.getAllCourses();
    commit('SET_COURSES', courses);
    return courses;
  },
  async trailsFilterDetails({ commit }, payload) {
    const { status, collectionsId } = payload;

    let details = await CourseService.getTrailsDetails(collectionsId);
    let filterDetails;
    if (status === 'started') {
      filterDetails = details.items.filter((course) => course.started === true && course.completed === false);
    } else if (status === 'completed') {
      filterDetails = details.items.filter((course) => course.completed === true);
    } else if (status === 'incompleted') {
      filterDetails = details.items.filter((course) => course.completed === false && course.started === false);
    } else {
      filterDetails = details.items;
    }
    details = [{ ...details, items: filterDetails }];
    commit('SET_TRAIL_DETAILS', details);
  },

  async getTrailsDetails({ commit }, collectionsId) {
    const details = await CourseService.getTrailsDetails(collectionsId);
    commit('SET_TRAIL_DETAILS', [details]);

    return details;
  },
  async getRankingCourses({ commit }) {
    commit('SET_RANKING_LOADING', true);
    const courses = await CourseService.getRankingCourses();
    commit('SET_RANKING_COURSES', courses);
    commit('SET_RANKING_LOADING', false);
    return courses;
  },
  async getRecentCourses({ commit }) {
    commit('SET_RECENT_LOADING', true);
    let useLastCourses = true;
    // Variable used to A/B testing with Optmize
    if (window && window.pdc_use_last_courses) useLastCourses = Boolean(window.pdc_use_last_courses);
    const courses = await CourseService.getRecentCourse(useLastCourses);
    commit('SET_RECENT', courses);
    commit('SET_RECENT_LOADING', false);
    return courses;
  },
  async getRecommendedCourses({ commit }) {
    commit('SET_RECOMMENDED_LOADING', true);
    let useLastCourses = true;
    // Variable used to A/B testing with Optmize
    if (window && window.pdc_use_last_courses) useLastCourses = Boolean(window.pdc_use_last_courses);
    const courses = await CourseService.getRecommendedCourses(useLastCourses);
    commit('SET_RECOMMENDED', courses);
    commit('SET_RECOMMENDED_LOADING', false);
    return courses;
  },
  async getRecommendedCoursesByCourse({ commit }) {
    try {
      commit('SET_RECOMMENDED_BY_COURSE_LOADING', true);
      const courses = await CourseService.getRecommendedCoursesByCourse();
      commit('SET_RECOMMENDED_BY_COURSE', courses);
    } catch (err) {
      commit('SET_RECOMMENDED_BY_COURSE_ERROR', err);
    } finally {
      commit('SET_RECOMMENDED_BY_COURSE_LOADING', false);
    }
  },
  async getCourseCategories({ commit }) {
    commit('SET_CATEGORIES_LOADING', true);
    const courses = await CourseService.getCourseCategories();
    commit('SET_CATEGORIES', courses);
    commit('SET_CATEGORIES_LOADING', false);
  },
  async getCourseCollections({ commit }) {
    commit('SET_COLLECTIONS_LOADING', true);
    const collections = await CourseService.getCourseCollections();
    commit('SET_COLLECTIONS', collections);

    const collectionsOnGoing = collections.filter((collection) => collection.progress.started === true);
    commit('SET_COLLECTIONS_ONGOING', collectionsOnGoing);
    commit('SET_COLLECTIONS_LOADING', false);
  },
  async getRecommendedCollections({ commit }) {
    commit('SET_COLLECTIONS_LOADING', true);
    const recommendedCollections = await CourseService.getRecommendedCollections();

    commit('SET_RECOMMENDED_COLLECTIONS', recommendedCollections);
    commit('SET_COLLECTIONS_LOADING', false);
  },
  async getCourseCollectionsInProgress({ commit }) {
    commit('SET_COLLECTIONS_LOADING', true);
    const collectionsOnGoing = await CourseService.getCourseCollectionsInProgress();

    commit('SET_COLLECTIONS_ONGOING', collectionsOnGoing);
    commit('SET_COLLECTIONS_LOADING', false);
  },

  async getPdcTalksCategories({ commit }) {
    commit('SET_CATEGORIES_LOADING', true);
    const pdc = await CourseService.getPdcTalksCategories();
    const categoriesCourses = pdc.map((obj) => obj.courses);
    const coursesArray = categoriesCourses.flat();

    commit('SET_PDC_TALKS', coursesArray);

    commit('SET_CATEGORIES_LOADING', false);
  },

  async getCoursesOngoing({ commit }) {
    commit('SET_ONGOING_LOADING', true);
    const courses = await CourseService.getCoursesOngoing();
    commit('SET_ONGOING', courses);
    commit('SET_ONGOING_LOADING', false);
  },
  async getCoursesFinished({ commit }) {
    commit('SET_FINISHED_LOADING', true);
    const courses = await CourseService.getCoursesFinished();
    commit('SET_FINISHED', courses);
    commit('SET_FINISHED_LOADING', false);
  },

  async searchCourses({ commit }, search) {
    const courses = await CourseService.searchCourses(search);
    commit('SET_COURSES', courses);
  },

  async search({ commit }, params) {
    commit('SET_COLLECTIONS_LOADING', true);
    const { filters } = params;
    const courses = filters.courses ? await CourseService.searchCourses(params.search, params.accountId) : [];

    const collections = filters.collections ? await CourseService.searchCollections(params.search) : [];

    const searchResult = [...courses?.data?.searchResult, ...collections];
    commit('SET_SEARCH_RESULT', searchResult);
    commit('SET_COLLECTIONS_LOADING', false);
  },

  async semanticSearch({ commit }, params) {
    const { find, entities } = params;
    const data = await CourseService.semanticSearch(find, entities);
    commit('SET_SEMANTIC_SEARCH_RESULT', data);
  },

  // eslint-disable-next-line no-shadow
  async filterCoursesByCategory({ commit, getters }, categoryId) {
    const category = getters.categories.find((cat) => cat.id === Number(categoryId));
    commit('SET_COURSES', category.courses);
  },
  // eslint-disable-next-line no-shadow
  async onTimeUpdate({ dispatch, getters }, payload) {
    const { seconds, duration } = payload;
    const enableFinished = seconds >= duration - 30;
    // if (enableFinished && !getters.enable_finished) {
    //   await dispatch('onTopicView', Router.currentRoute.params.lesson);
    // }
  },
  // eslint-disable-next-line no-shadow
  async onTopicView({ getters, dispatch, rootGetters }, topicId) {
    dispatch('setEnabledFinished', true);
    const isLastLesson = getters.course_all_lessons.filter((lesson) => lesson.finished).length + 1 === Number(getters.course.totalLessons);
    const account = rootGetters['auth/account'];
    if (getters.course?.progress === 0) {
      initiateCourse(window.dataLayer, getters.course, account);
    }
    if (!getters.course.completed && isLastLesson) {
      finishLessons(window.dataLayer, getters.course, account);
    }
    await CourseService.viewTopic(getters.course.id, topicId);

    await dispatch('fetchCourseWithoutLoading', getters.course.path);
  },
  // eslint-disable-next-line no-shadow
  async goToFirstTopic({ getters }) {
    await Router.push({
      name: 'CourseDetailLesson',
      params: {
        course: getters.course.path,
        module: getters.course?.currentTopic?.courseLesson?.id || getters.course?.courseLessons[0]?.id,
        lesson: getters.course.currentTopic?.id || getters.course?.courseLessons[0]?.courseTopics[0]?.id,
      },
    });
  },
  setEnabledFinished({ commit }, payload) {
    commit('SET_ENABLE_FINISHED', payload);
  },

  async getFavorites({ commit }, entity) {
    const favorites = await FavoritesService.getFavorites(entity);

    if (entity === 'course') {
      commit('SET_FAVORITE_COURSES', favorites);
    }
    if (entity === 'collection') {
      commit('SET_FAVORITE_COLLECTIONS', favorites);
    }
  },

  async toggleFavorite({ dispatch }, payload) {
    await FavoritesService.toggleFavorite(payload);
    await dispatch('getFavorites', payload.entity);
  },

  async setFilterSearch({ commit }, payload) {
    commit('SET_FILTER_SEARCH', payload);
  },

  // eslint-disable-next-line no-shadow
  async setEvaluateCourse({ getters, commit }, payload) {
    await CourseService.evaluateCourse(getters.course.id, payload);
    commit('SET_COURSE_EVALUATED', payload);
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
