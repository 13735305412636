<template>
  <ElDialog id="discovery-dialog" v-if="showDialog" :visible.sync="dialog" max-width="500"
    :before-close="handleCloseModal" :modal="false">
    <div class="content-card">
      <img class="image-step" src="@/assets/illustrations/trails_discovery.svg" alt="" />
      <h2 id="title-discovery">Olha a novidade!</h2>
      <p id="description-discovery">As Trilhas de Conhecimento mudaram, agora você vai ser desafiado no Teste de
        Habilidade.</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <PdcButton variant="primary" id="button-discovery" @click.native="handleClose">Acesse agora</PdcButton>
    </span>
  </ElDialog>
</template>
<script>
import { mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';

export default {
  components: {
    PdcButton,
  },
  data: () => ({
    dialog: true,
    showDialog: false,
  }),
  computed: {
    ...mapGetters('auth', ['campaign']),
    ...mapGetters('courses', ['loading']),
  },
  watch: {
    loading() {
      setTimeout(() => {
        if (!this.loading && this.dialog) {
          this.showDialog = true;
        }
      }, 500);
    },
  },
  methods: {
    handleCloseModal() {
      this.dialog = false;
      window.localStorage.setItem('discovery_modal', true);
    },
    handleClose() {
      // window.open('/dashboard/evoluir/trilhas', '_self');
      window.localStorage.setItem('discovery_modal', true);
    },
  },
};
</script>
<style lang="scss">
#discovery-dialog {
  display: none; // active this only in Google Optmize
}

.content-card {
  text-align: center;
  max-width: 80%;
  margin: 0 auto;

  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    color: $color-neutral-dark;
    margin: 24px 0;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 144%;
    margin: 0px;
    letter-spacing: -0.02em;
    color: $color-neutral-dark;
    word-break: normal;
  }
}

#discovery-dialog .el-dialog {
  border-radius: 24px;
  max-width: 597px;
}

.el-dialog {
  @media only screen and (max-width: 768px) {
    width: 90% !important;
  }
}</style>
