<template>
  <el-dialog
    id="add-new-experience-dialog"
    class="custom-modal-pdc"
    :visible.sync="dialog"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="modal-header">
      <h1 v-if="!isEdit">Adicionar experiência</h1>
      <h1 v-else>Editar experiência</h1>
      <Icon name="Add" />
    </div>

    <form>
      <CustomInput
        v-model="jobExperience.name"
        type="text"
        name="title"
        id="title"
        placeholder="Ex: Mestre de Obras?"
        title="Título*"
      />

      <CustomSelect
        v-model="jobExperience.jobTypeId"
        title="Tipo de emprego*"
        :options="renderTypeJobs"
      >
        <small slot="helperHTML"
          >Saiba mais sobre os
          <span class="type-jobs">tipos de emprego.</span></small
        >
      </CustomSelect>

      <CustomInput
        v-model="jobExperience.company"
        type="text"
        name="company_name"
        id="company_name"
        placeholder="Ex: PDC"
        title="Nome da empresa*"
      />

      <!-- <CustomInput type="text" v-model="jobExperience." name="locale" id="locale" placeholder="EX: São Paulo" title="Localidade" /> -->

      <CustomSelect
        v-model="jobExperience.locationTypeId"
        :options="renderLocales"
        title="Tipo de localidade"
        :helperText="'Selecionar tipo de localidade (ex: remota)'"
      />

      <fieldset class="check-image">
        <label for="like">
          <input
            v-model="jobExperience.isActualJob"
            type="checkbox"
            name="like"
            id="like"
          />
          <i></i>
          Trabalho aqui atualmente
        </label>
      </fieldset>

      <el-alert
        :title="error"
        v-if="!!error"
        type="error"
        style="margin-bottom: 1rem"
      />

      <div class="period">
        <CustomSelect
          v-model="startMonth"
          :options="renderMonths"
          title="Início"
          name="initial_month"
          defaultLabel="Mês"
        />

        <CustomSelect
          :options="renderYears"
          v-model="startYear"
          defaultLabel="Ano"
          name="initial_year"
        />

        <CustomSelect
          v-if="!jobExperience.isActualJob"
          v-model="endMonth"
          :options="renderMonths"
          title="Término"
          name="end_month"
          defaultLabel="Mês"
        />
        <CustomSelect
          v-if="!jobExperience.isActualJob"
          v-model="endYear"
          :options="renderYears"
          defaultLabel="Ano"
          name="end_year"
        />
      </div>

      <CustomInput
        type="text"
        name="sector"
        v-model="jobExperience.description"
        id="sector"
        placeholder="EX: Construção Civil"
        title="Setor / Ramo*"
      />

      <span
        slot="footer"
        class="accept-footer"
        style="display: flex; margin-top: 45px; justify-content: flex-end"
      >
        <PdcButton
          class="button"
          variant="outlined"
          id="button-discovery"
          @click="handleClose"
          >Fechar
        </PdcButton>
        <PdcButton
          class="button"
          variant="primary"
          id="button-discovery"
          @click.native="handleSubmit"
          :loading="loading_save"
          >Salvar</PdcButton
        >
      </span>
    </form>
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect.vue';

import CONSTANTS from '@/utils/constants';
import { isValidDate } from '@/utils/functions/date';

import './AddNewExperienceDialog.scss';

export default {
  name: 'AddNewExperienceDialog',
  components: {
    PdcButton,
    Icon,
    CustomInput,
    CustomSelect,
  },
  data: () => ({
    dialog: true,
    close: false,
    startYear: '',
    startMonth: '',
    endYear: '',
    endMonth: '',
    isEdit: false,
    error: '',
    jobExperience: {
      id: '',
      name: '',
      jobTypeId: '1',
      company: '',
      locationTypeId: '',
      isActualJob: false,
      jobStartDate: '',
      jobEndDate: '',
      description: '',
    },
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'jobExpirienceSelected', 'userProfile', 'toastMessage']),
    renderTypeJobs() {
      return CONSTANTS?.data?.TYPE_JOBS;
    },
    renderMonths() {
      return CONSTANTS?.data?.MONTHS;
    },
    renderYears() {
      return CONSTANTS?.data?.YEARS;
    },
    renderLocales() {
      return CONSTANTS?.data?.LOCALES;
    },
  },
  methods: {
    ...mapActions('profile', ['addJobExperience', 'updateJobExperience', 'clearObject']),
    handleClose() {
      this.$emit('handleClose');
      this.clearObject('jobExpirienceSelected');
    },
    // eslint-disable-next-line consistent-return
    combineValues(startDate, endDate) {
      if (startDate && endDate) {
        const combinedDate = `${endDate}-${startDate}-01`;
        return combinedDate;
      }
    },
    async handleSubmit() {
      const isActualUserJob = this.jobExperience.isActualJob ? 1 : 0;
      const body = {
        name: this.jobExperience.name,
        jobTypeId: this.jobExperience.jobTypeId,
        company: this.jobExperience.company,
        locationTypeId: this.jobExperience.locationTypeId,
        isActualJob: isActualUserJob,
        jobStartDate: this.combineValues(this.startMonth, this.startYear),
        description: this.jobExperience.description,
        jobEndDate: isActualUserJob === 0 ? this.combineValues(this.endMonth, this.endYear) : null,
      };

      if (!isValidDate(body.jobStartDate, body.jobEndDate)) {
        this.error = 'Data de término não pode ser menor ou igual a data de início';
      } else {
        const professionalProfileId = this.userProfile?.id;

        if (this.isEdit) {
          await this.updateJobExperience({ id: this.jobExperience.id, professionalProfileId, ...body });
        } else {
          await this.addJobExperience(body);
        }

        this.handleClose();

        if (this.toastMessage) {
          this.$swal.fire(
            this.toastMessage.title,
            this.toastMessage.description,
            this.toastMessage.type,
          );
        }
      }
    },
  },
  mounted() {
    if (Object.keys(this.jobExpirienceSelected).length > 0) {
      const [year, month] = this.jobExpirienceSelected.jobStartDate.split('-');
      const [yearEnd, monthEnd] = this.jobExpirienceSelected?.jobEndDate ? this.jobExpirienceSelected?.jobEndDate.split('-') : '2022-01'.split('-');
      this.isEdit = true;
      this.jobExperience = this.jobExpirienceSelected;
      JSON.stringify(this.jobExperience.jobTypeId);
      JSON.stringify(this.jobExperience.locationTypeId);
      this.startYear = year;
      this.startMonth = month;
      this.endYear = yearEnd;
      this.endMonth = monthEnd;
    }
  },
};
</script>
