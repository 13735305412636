/* eslint-disable import/prefer-default-export */
import socialHttp from '../utils/social.http';

const toggleEntityLike = (payload) => {
  socialHttp.put(`like/${payload.entity}/${payload.entity_id}`);
};

export {
  toggleEntityLike,
};
