<template>
  <div class="flex-col">
    <div class="flex-row sm-order-1 sm-flex-col">
      <VueSkeletonLoader class="h-64px w-288px sm-w-50" />
      <VueSkeletonLoader class="ml-auto h-64px w-248px br-5px sm-w-100 sm-mt-24" />
    </div>
    <VueSkeletonLoader class="w-100 mt-40 h-576px sm-order-0 sm-mb-32 sm-h-288px" />
    <div class="w-50 flex-col mt-40 sm-order-2 sm-w-100 mb-40">
      <VueSkeletonLoader class="h-24px w-172px" />
      <VueSkeletonLoader class="h-2px w-100 my-24" />
      <VueSkeletonLoader class="h-80px w-100" />
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'CoursesDetailSkeleton',
  components: { VueSkeletonLoader },
};
</script>
