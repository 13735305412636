<template>
  <div class="question-send question-send--feedback">
    <img class="image-step" src="@/assets/illustrations/check.svg" alt="" />
    <h2 class="mt-32 mb-16">Sugestão enviada!</h2>
    <p class="mb-32">
      Recebemos sua sugestão e estamos muito felizes com sua contribuição. Caso sua ideia seja
      aceita iremos te avisar ❤️
    </p>
    <Button variant="primary" type="button" @click="finishSteps"> Voltar ao início </Button>
  </div>
</template>
<script>
import Button from '../../atoms/Button/Button.vue';

export default {
  name: 'Step2',
  props: ['context'],
  components: {
    Button,
  },
  methods: {
    finishSteps() {
      this.$emit('onClose');
      this.context.previousStep();
    },
  },
};
</script>
<style lang="scss"></style>
