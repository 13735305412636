<template>
  <TemplateAdmin :loading="isLoading" :show_help_btn="false" class="CourseExame">
    <CourseExameTopbar slot="topbar-container" :custom_back="goToDashboard" back_text="Voltar ao início" />
    <template slot="container">
      <CourseExameResultSkeleton v-if="course_loading" />
      <template v-else>
        <div class="answer-result flex-col align-items-center">
          <img class="answer-result--image" :src="imageUrl" alt="answer-result" />
          <b class="text-align-center flex-row align-items-center mt-32 answer-result--text">
            <span>{{ title }}</span>
            <Emoji class="ml-8" :value="isApproved ? '😁' : '😕'" :size="35.56" v-show="!isApprovedThreshold" />
          </b>
          <div class="answer-result--description mt-32" v-html="description" v-show="!isApprovedThreshold" />
          <div class="answer-result--subdescription mt-24 mb-14" v-if="!isPDC">
            <template v-if="!isApproved">
              <PdcButton variant="primary" class="sm-w-100 md-w-100 lg-w-100 px-40" @click.native="goToCourse">
                Rever aulas
              </PdcButton>
            </template>
            <template v-else>
              <span>
                Baixe o seu certificado e aproveite para conhecer o Perfil Profissional e anunciar seus serviços gratuitamente.
              </span>
            </template>
          </div>

          <div class="answer-result--subdescription subdescription-opacity mt-24 mb-40" v-html="subDescription" v-if="!isPDC" />

          <div class="answer-result--btns flex-row sm-flex-col w-100 justify-content-center align-items-center">
            <PdcButton variant="primary" class="sm-w-100 h-56px md-w-50 lg-w-40 sm-ml-0 sm-mt-8 lg-mr-24"
              @click.native="goToCummunityUserProfile(account.id)">
              Anuncie seus serviços
            </PdcButton>

            <template v-if="isApproved">
              <PdcButton variant="primary" :outlined="true" class="sm-w-100 h-56px md-w-50 lg-w-40"
                @click.native="goToReview" style="display: none;">
                Visualizar correção 1
              </PdcButton>
              <PdcButton variant="outlined" class="sm-w-100 h-56px md-w-50 lg-w-40 sm-ml-0 sm-mt-8 ml-0"
                @click.native="goToCertificate" :disabled="loadingHighlightCertificate">
                Ver certificado
              </PdcButton>
            </template>
            <template v-else>
              <PdcButton variant="primary" :outlined="true" class="sm-w-100 h-56px md-w-50 lg-w-40"
                @click.native="goToExame" @loading="tryAgainLoading">
                Tentar novamente
              </PdcButton>
              <PdcButton variant="primary" style="display: none;" class="sm-w-100 md-w-50 h-56px lg-w-40"
                @click.native="goToReview">
                Visualizar correção
              </PdcButton>
            </template>
          </div>
        </div>

        <RecommendedCourses v-if="isApproved"/>
      </template>

      <!-- Dialog -->
       <!-- <el-dialog v-if="isApproved" id="dialog-professional-profile" :visible.sync="dialog" :close-on-click-modal="false" :show-close="true">
        <div class="accept-header">
          <img class="accept-image" src="@/assets/icons/dialog/pdc-icon.svg" alt="" />
          <h3 class="accept-tittle">Conquiste mais clientes</h3>
        </div>
        <p class="dialog-professional-text">
          Divulgue seu trabalho gratuitamente no Perfil Profissional e receba pedidos de orçamento
          de pessoas ou empresas que precisam de um prestador de serviço.
        </p>
        <div class="accept-content">

          <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">

            <PdcButton variant="primary" id="button-discovery" @click.native="goToCummunityUserProfile(account.id)">Anuncie seus serviços</PdcButton>
          </span>
        </div>
      </el-dialog> -->
    </template>
  </TemplateAdmin>
</template>
<script>
import './ExameResult.scss';
import { mapActions, mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import approvedImage from '@/assets/icons/course-exame/certificado.svg';
import repprovedImage from '@/assets/icons/course-exame/exame-repproved.png';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CourseExameTopbar from '@/components/organisms/Course/CourseExameTopbar/CourseExameTopbar.vue';
import CourseExameResultSkeleton from '@/components/organisms/Skeletons/CourseExameResultSkeleton.vue';
import Emoji from '@/components/atoms/Emoji.vue';
import { getCookie } from '@/utils/helpers';
import RecommendedCourses from '@/components/organisms/Course/RecommendedCourses/RecommendedCourses.vue';
import * as certificateService from '@/services/certificate.service';
import * as professionalProfile from '@/services/matchmaking/professionalProfile.service';

export default {
  name: 'ExameResult',
  data() {
    return {
      tryAgainLoading: false,
      dialog: true,
      close: false,
      loadingHighlightCertificate: false,
    };
  },
  components: {
    TemplateAdmin,
    PdcButton,
    CourseExameTopbar,
    CourseExameResultSkeleton,
    Emoji,
    RecommendedCourses,
  },
  created() {
    // TODO REVISAR ROTA
    this.fetchCourseWithoutLessons(this.$router.currentRoute.params.course).then((course) => {
      if (!course.completed) {
        return this.$router.push({ name: 'NotFound' });
      }
      return this.setCourseLoading(false);
    });
  },
  computed: {
    ...mapGetters('courses', ['course_loading', 'course']),
    ...mapGetters('application', ['loading_app']),
    ...mapGetters('auth', ['account']),
    ...mapGetters('profile', ['userProfile']),

    isLoading() {
      return this.course_loading || this.loading_app;
    },
    result() {
      return this.course.results;
    },
    passingScore() {
      return 70;
    },
    rightAnswers() {
      return this.result.correctResponses;
    },
    totalQuestions() {
      return this.result.questions;
    },
    isApproved() {
      return this.result.approved;
    },
    isApprovedThreshold() {
      return this.course.quizApprovalThreshold === 0;
    },
    score() {
      const score = Number((this.result.rating / 10).toFixed(2));
      return +score;
    },
    imageUrl() {
      if (this.isApproved) {
        return approvedImage;
      }
      return repprovedImage;
    },

    title() {
      if (this.isApproved && !this.isApprovedThreshold) {
        return `Parabéns! Você foi aprovado, sua nota no exame foi ${this.score} `;
      }
      if (this.isApproved && this.isApprovedThreshold) {
        return 'Obrigado por sua avaliação, clique no botão abaixo para receber seu certificado de conclusão';
      }
      return 'Tente outra vez para ser aprovado!';
    },
    description() {
      const defaultText = `Você acertou <b>${this.rightAnswers}</b> de <b>${this.totalQuestions}</b> questões do exame.`;
      if (this.isApproved && !this.isApprovedThreshold) {
        return `${defaultText} Visualize sua nova conquista, também é possivel compartilhar nas redes sociais. `;
      }

      return `${defaultText} Não desista agora, <a href="${this.getReviewUrl}">revise os conteúdos vistos<br />
        em aula</a> antes de realizar uma nova tentativa.`;
    },

    subDescription() {
      if (this.isApproved) {
        return 'Lembre-se: Seu certificado estará disponível para acessar a qualquer momento.';
      }
      return `Lembre-se: Você precisa acertar <b>${this.passingScore}%</b> das questões para ser aprovado.`;
    },
    getReviewUrl() {
      return `/dashboard/evoluir/cursos/${this.course.path}`;
    },
    isPDC() {
      return this.account.tenant.id === 1;
    },
  },
  methods: {
    ...mapActions('courses', ['fetchCourseWithoutLessons', 'setCourseLoading']),
    ...mapActions('exame', ['setLastStepPage', 'abortAttempt']),
    ...mapActions('application', ['appRouterPush', 'openLink']),
    ...mapActions('certificates', ['createHighlightCertificate']),
    goToDashboard() {
      // Cookie utilizado no Teste A/B para informar que o usuario esta na versao de testes
      const newHomeAB = getCookie('pdc_feed_page');
      this.appRouterPush(newHomeAB ? { name: 'Feed' } : { name: 'Courses' });
    },
    goToReview() {
      // TODO REVISAR ROTA
      this.$router.push({ name: 'CourseExameReview', params: { course: this.$router.currentRoute.params.course } });
    },
    goToCourse() {
      this.appRouterPush({ name: 'CourseDetail', params: { course: this.course.path } });
    },
    async goToCertificate() {
      this.$router.push({ name: 'Certificate', params: { course: this.course.path } });
      // window.open(this.course.certificate.urlPdf, '_blank');
    },
    goToCummunityUserProfile(id) {
      const { origin } = window.location;
      window.location.href = `${origin}/community/user-profile/${id}`;
    },
    goToExame() {
      this.tryAgainLoading = true;
      this.abortAttempt(this.course.id).then(() => {
        this.$router.push({ name: 'CourseExame', params: { course: this.course.path } });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#dialog-professional-profile{

  .accept-header{
    margin-left: 0;
    align-items: center;
    gap: 0;
  }

  .accept-header .accept-tittle{
    font-size: 1.25rem;
    margin: auto;
  }

  .el-dialog{
    height: auto;
  }

  .dialog-professional-text{
    font-size: 1rem;
    word-break: break-word;
  }
}

</style>
