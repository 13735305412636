<template>
  <SidebarItem :class="`mt-16 mb-8 exame ${disabled && 'disabled'}`" @click="goToExame">
    <div class="sidebar-container--exame--item" :style="{ '--bg-color': this.tenantConfig.primary_color }">
      <div class="sidebar-container--exame--item--icon" />
      <div class="sidebar-container--exame--item--text">Exame final</div>
    </div>
  </SidebarItem>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SidebarItem from '../SidebarItem/SidebarItem.vue';
import './FinalExameItem.scss';

export default {
  props: {
    disabled: {
      type: Boolean,
      return: false,
    },
    course: Object,
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
  },
  components: {
    SidebarItem,
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    goToExame() {
      if (!this.disabled) {
        this.appRouterPush({ name: 'CourseExame', params: { course: this.course.path } });
      }
    },
  },
};
</script>
