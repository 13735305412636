/* eslint-disable import/prefer-default-export */
import socialHttp from '../utils/social.http';

const getFavorites = async (entity) => (
  socialHttp.get(`/favorite/${entity}`)
);

const toggleFavorite = async ({ entity, entityId }) => (
  socialHttp.put(`/favorite/${entity}/${entityId}`)
);

export { getFavorites, toggleFavorite };
