import addressHttp from '@/utils/address.http';

export const createAddress = (body) => addressHttp.post('address', body);

export const updateAddress = (body) => addressHttp.put('address', body);

export const getAddress = (id, typeId = 'accountId') => {
  let typeAccount = '';

  if (typeId === 'professional') typeAccount = 'professionalProfileId';
  else typeAccount = 'accountId';

  const data = addressHttp.get(`address?${typeAccount}=${id}`);
  return data;
};
