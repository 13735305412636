<template>
  <div class="container">
    <h3 class="title">{{ title }}</h3>
    <ul class="steps">
      <li v-for="(step, index) in steps" :key="index">
        <Icon :name="'CheckAward'" />
        <span>
          {{ step }}
        </span>
      </li>
    </ul>

    <a :href="url" v-if="!hideLink" class="link" target="_blank">{{ textLink }}</a>
  </div>
</template>

<script>

import Icon from '@/components/atoms/Icon/Icon.vue';

export default {
  name: 'CardStepByStep',
  components: {
    Icon,
  },
  props: {
    title: String,
    steps: Array,
    textLink: String,
    url: String,
    hideLink: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .container{
    border-radius: 10px;
    border: 1px solid #C8C4E9;
    padding: 1.25rem;
    text-align: center;
    margin-bottom: 1.25rem;
    background-color: #5D4A99;
  }

  .title{
    color: #FFF;
    font-weight: 700;
    font-size: 1.6rem;
  }

  .steps{
    list-style: none;

    li{
      color: #FFF;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;
      display: grid;
      align-items: flex-start;
      grid-template-columns: 40px 1fr;
      gap: 0 20px;
    }

    li + li{
      margin-top: 3rem;
    }

    svg{
      margin-right: 10px;
    }
  }

  .link{
    color: #C3B0FF;
    font-size: 1rem;
    margin: 0.5rem 0;
    display: inline-block;
  }

  @media(max-width: 768px){
    .container{
      margin: 0 -1.5rem;
      border-radius: 0;
    }
  }
</style>
