import Vue from 'vue';
import Vuex from 'vuex';
import VueMixpanel from '@/plugins/vue-mixpanel';
import auth from './modules/auth/auth.module';
import courses from './modules/courses/courses.module';
import exame from './modules/courses/exame.module';
import questions from './modules/courses/questions.module';
import interests from './modules/auth/interests.module';
import application from './modules/application/application.module';
import likes from './modules/courses/likes.module';
import comment from './modules/courses/comment.module';
import ratings from './modules/courses/ratings.module';
import profile from './modules/matchmaking/profile.module';
import certificates from './modules/certificates/certificates.module';
import ranking from './modules/ranking/ranking.module';
import address from './modules/address/address.module';
import player from './modules/courses/player.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    courses,
    exame,
    interests,
    application,
    questions,
    likes,
    comment,
    ratings,
    profile,
    certificates,
    address,
    ranking,
    player,
  },
  plugins: [VueMixpanel],
});
