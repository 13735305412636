import axiosRetry from 'axios-retry';
import apiBase from './default.http';

export const { instance: apiBaseService, setToken } = apiBase(
  process.env.VUE_APP_COURSE_SERVICE_URL || 'https://course-service.prd.parceirodaconstrucao.com.br',
);

axiosRetry(apiBaseService, { retries: 2, retryDelay: (retryCount) => retryCount * 1000 });
const courseHttp = apiBaseService;

export default courseHttp;
