import axiosRetry from 'axios-retry';
import apiBase from './default.http';

export const { instance: apiBaseService, setToken } = apiBase(
  process.env.VUE_CLASSESS_INFO || 'https://api.stg.parceirodaconstrucao.com.br/internal',
);
axiosRetry(apiBaseService, { retries: 2, retryDelay: (retryCount) => retryCount * 1000 });
const courseHttpProd = apiBaseService;

export default courseHttpProd;
