<!-- eslint-disable max-len -->
<template>
  <TemplateAdmin :loading="loading">
    <template slot="container">
      <div class="content-page" :style="{ '--section-title-color': this.tenantConfig.primary_color }">
        <div class="banner-welcome">
          <div class="welcome-text">
            <h4>Olá, <strong>José da Silva</strong> sua posição atual no Ranking é:</h4>
            <span class="position mx-16">13° Lugar</span>
            <div class="certifications">2 Certificações</div>
          </div>

          <div class="welcome-obs">
            No momento você está  12 posições atrás do primeiro colocado.
            Continue estudando através da nossa plataforma e se certificando para aparecer primeiro nos resultados de pesquisa de potenciais clientes.
          </div>
        </div>

        <p class="description">
          Quanto mais você interage com a plataforma, finalizando cursos e trilhas, mais chances tem de ganhar prêmios exclusivos.
        </p>

        <span class="line" />

        <div :class="`ranking-card ${item <3 ? 'top-rank' : ''}`" v-for="(item) in Array.from(Array(10).keys())" :key="item">
          <span class="position">{{ item + 1 }}°</span>
          <div class="ranking-user">
            <div class="ranking-user-info">
              <img :src="imageDefault" alt="" />
              <span>José Rafael dos Santos Candido</span>
            </div>
            <div class="certifications">22 Certificações</div>
          </div>
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';

export default {
  name: 'AwardRankingCampaing',
  components: {
    TemplateAdmin,

  },
  created() {

  },
  data() {
    return {
      playerReady: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner', 'recommended_collections']),
    ...mapGetters('application', ['loading_app', 'tenantConfig', 'loadingTenantConfig']),
    imageDefault() {
      return 'https://res.cloudinary.com/dzhbkamn0/image/upload/v1687226975/MicrosoftTeams-image_4_rtkgqe.jpg';
    },

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import 'AwardRankingCampaing.scss';
</style>
