<template>
  <div class="container">
    <h2 :style="subtitle ? 'margin-bottom: 0.5rem' : ''">{{ title }}</h2>
    <p v-if="subtitle">{{ subtitle }}</p>
</div>
</template>

<script>

export default {
  name: 'Notfound',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 10px 5px;
  margin-top: 1rem;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5rem;
    color: $color-neutral-darkest;
  }

  p {
    margin-top: 0;
    font-size: 1rem;
  }
}
</style>
