<template>
  <label class="flex-row align-items-center pdc-input-radio">
    <input :disabled="disabled" type="radio" v-model="model" :name="option_name" :value="option_value" />
    <div :class="`pdc-input-radio--icon ${model === option_value ? 'checked' : 'unchecked'}`" />
    <slot />
    <template v-if="!$slots"> {{ option_value }} </template>
  </label>
</template>
<script>
export default {
  props: {
    option_name: String,
    option_value: String,
    disabled: Boolean,
  },
  data() {
    return {
      model: this.$attrs.value || null,
    };
  },
  watch: {
    model(val) {
      this.$emit('input', val);
    },
  },
};
</script>
<style lang="scss">
.pdc-input-radio {
  input[type='radio'] {
    display: none;
    align-items: center;

    +.pdc-input-radio--icon {
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;

      @media screen and (max-width: 800px) {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        margin-right: 8px;
      }

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/input/radio_unchecked.svg');
    }

    &:checked+.pdc-input-radio--icon {
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;

      @media screen and (max-width: 800px) {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        margin-right: 8px;
      }

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/input/radio_checked.svg');
    }
  }
}
</style>
