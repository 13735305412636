<template>
  <TemplateAdmin :loading="loadingTenantConfig && search_loading">
    <template slot="container">
      <CoursesSkeleton type="recommended" v-if="loadingTenantConfig && search_loading" />
      <div v-else class="results-container">
        <div class="search-specs">
          <span>
            <h2>Resultado da busca por:</h2>
            <p>"{{ this.searchTerm }}"</p>
          </span>
          <loading v-if="search_loading" />
          <p v-else class="search-count">{{ courses_result.length }} resultados encontrados</p>
        </div>
        <div v-if="courses_result.length < 1 && !search_loading" class="no-results-img">
          <img src="@/assets/illustrations/no-results.svg" alt="">
          <h3>Nenhum resultado encontrado</h3>
          <p>Não encontramos resultados para <br> "{{ this.$route.params.searchTerm.split('&')[0] }}”</p>
        </div>
        <div class="result-cards">
          <div v-for="(searchItem, index) in search_result" :key="index">
            <CoursesCard v-if="searchItem.title" :course="searchItem"
              :finished="checkFinishedCourse(searchItem)" />
            <CollectionCard v-if="searchItem.name" :collection="searchItem" />
          </div>
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CollectionCard from '@/components/organisms/Course/CollectionCard/CollectionCard.vue';
import CoursesCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import Loading from '@/components/atoms/Loading/Loading.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CoursesSkeleton,
    TemplateAdmin,
    CoursesCard,
    CollectionCard,
    Loading,
  },
  data() {
    return {
      searchTerm: '',
      courses_result: [],
      trails_result: [],
      collections_result: [],
      search_loading: false,
      search_error: null,
    };
  },
  async created() {
    this.searchTerm = this.$route.params.searchTerm;

    const index = this.searchTerm.indexOf('&');
    const queryString = this.searchTerm.slice(index, this.searchTerm.length);

    const params = new URLSearchParams(queryString);

    const filters = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of params.entries()) {
      filters[key] = value === 'true';
    }
    const searchKey = this.$route.params.searchTerm.split('&')[0];
    await this.improvingSearch(searchKey, filters);
  },
  computed: {
    ...mapGetters('courses', [
      'semantic_search_result',
      'recommended_collections', 'finished',
      'recommended',
      'courses',
      'recommended_collections',
      'courses',
      'categories',
      'pdc_talks',
      'collections',
      'recommended_collections',
      'collections_on_going',
      'trail_details',
      'ongoing',
      'recommended',
      'recent',
      'ranking_courses',
      'finished',
      'favorite_courses',
      'favorite_collections',
    ]),
    ...mapGetters('application', ['loading_app', 'hasTenant', 'loadingTenantConfig']),
    search_result() {
      return this.courses_result.concat(this.trails_result);
    },
  },
  methods: {
    ...mapActions('courses', ['semanticSearch']),
    async improvingSearch(key, filters) {
      this.searchTerm = key;
      this.search_loading = true;
      try {
        await this.semanticSearch({ find: key, entities: this.getFilterEntities(filters) });
        if (this.semantic_search_result.courses) {
          this.courses_result = this.semantic_search_result.courses;
        }
        if (this.semantic_search_result.trails) {
          this.trails_result = this.semantic_search_result.trails;
        }
      } catch (err) {
        this.search_error = err;
      } finally {
        this.search_loading = false;
      }
    },
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },
    getFilterEntities(filter) {
      const entities = [];
      if (filter.courses) entities.push('curso');
      if (filter.collections) entities.push('trilha');
      return entities.toString();
    },
  },
};
</script>

<style lang="scss">
@import './SearchResult.scss';
</style>
