<template>
  <div id="app" class="container">
    <div class="banners" v-if="!isDesktopScreen">
      <CarouselSlider :per-page="1" :loop="true" :autoplay="true" :adjustableHeight="false" :autoplayTimeout="10000"
        :paginationPadding="3">
        <Slide v-for="banner in getImagesBannerMobile" :key="banner.id">
          <a :href="banner.linkTarget" target="_blank">
            <img :src="banner.imageMobileUrl" class="full-image" :alt="banner.alt" />
          </a>
        </Slide>

      </CarouselSlider>
    </div>

    <div class="banners" v-else>
      <CarouselSlider :per-page="1" :loop="true" :autoplay="true" :adjustableHeight="false" :autoplayTimeout="10000"
        :paginationPadding="3">
        <Slide v-for="banner in getImagesBannerDesktop" :key="banner.id">
          <a :href="banner.linkTarget" target="_blank">
            <img :src="isDesktopScreen ? banner.imageUrl : banner.imageMobileUrl" class="full-image" :alt="banner.alt" />
          </a>
        </Slide>

      </CarouselSlider>
    </div>

    <RequestJobDialog :showDialog="showRequestJobDialog" @closeDialog="showRequestJobDialog = false" />

    <ContainerPdc :active="activeRoute($route.name)" :page-title="pageRoute($route.name)" :is-admin="isAdmin"
      :first-name="userName" @click-item="handleRouter" :show_clubs="isClub" :show_chiligum="true" :isSgUser="isSgUser"
      :breakpoints="breakpoints" :loading="loading"
      :show_sidebar="!['CourseExame', 'ExameResult', 'Certificate', 'CourseExameReview'].includes(route)"
      :show_footer="!['CourseExame', 'ExameResult', 'Certificate', 'CourseExameReview'].includes(route)"
      :show_help_btn="show_help_btn" :class="extra_class" :sidebarBgColor="this.tenantConfig.background_color"
      :logo="this.tenantConfig.logo?.url" :smallLogo="this.tenantConfig.small_logo?.url"
      :sidebarActiveBgColor="this.tenantConfig.primary_color" :sidebarTextColor="this.tenantConfig.text_color"
      :hamburgerMenuBgColor="this.tenantConfig.primary_color" :topbarHoverColor="this.tenantConfig.primary_color"
      :topbarTextColor="this.tenantConfig.text_color" :hasTenant="hasTenant" :tenant="this.tenantConfig" :accountId="this.account.id"
      :roleMappings="this.accountComplete.roleMappings">
      <template slot="topbar-dropdown">
        <slot name="topbar-dropdown" />
      </template>
      <template slot="topbar-container">
        <slot name="topbar-container" />
      </template>
      <template slot="topbar-title">
        <slot name="topbar-title" />
      </template>
      <template slot="container">
        <div class="is-search" v-if="this.isSearch">
          <div class="search-image-back" @click="toggleSearch">
            <img src="@/assets/icons/input/back.svg" alt="">
          </div>
          <form @submit="onSearch" class="searchInput-topbar">
            <Input type="search" placeholder="Pesquise por um curso, trilha..." @input="onChange" />
            <FilterSearch :color="this.tenantConfig.primary_color" />
          </form>
        </div>
        <portal to="topbar-portal" v-if="$route.meta.requiresSearch">
          <form @submit="onSearch" class="form-search">
            <FilterSearch :color="this.tenantConfig.primary_color" />
            <Input type="search" class="searchInput-topbar" placeholder="Pesquise por um curso, trilha..."
              @input="onChange" />
          </form>
          <div class="search-image" @click="toggleSearch">
            <img src="@/assets/icons/input/search.svg" alt="">
          </div>
        </portal>
        <slot name="container" />
      </template>
      <template slot="sidebar-container">
        <slot name="sidebar-container" />
      </template>
    </ContainerPdc>

    <CampaignsDialog />
    <QuestionDialog />
    <DiscoveryDialog v-if="showDiscoveryDialog" />
    <AcceptDialog v-if="showAcceptModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ContainerPdc } from 'vue-pdc-ext-frontend';
import Input from '@/components/atoms/Input/Input.vue';
import { ref } from 'vue';
import { Carousel as CarouselSlider, Slide } from 'vue-carousel';
import { getUserJob } from '@/services/account.service';
import FilterSearch from '@/components/atoms/FilterSearch/FilterSearch.vue';
import CampaignsDialog from '../CampaingsDialog/CampaignsDialog.vue';
import QuestionDialog from '../QuestionDialog/QuestionDialog.vue';
import DiscoveryDialog from '../DiscoveryDialog/DiscoveryDialog.vue';
import AcceptDialog from '../AcceptDialog/AcceptDialog.vue';
import RequestJobDialog from '../RequestJobDialog/RequesJobDialog.vue';
import './AdminTemplate.scss';

export default {

  props: {
    show_help_btn: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    breakpoints: {
      type: Object,
      default: () => ({
        small: 1200,
        medium: 1400,
      }),
    },
  },
  components: {
    ContainerPdc,
    CampaignsDialog,
    QuestionDialog,
    DiscoveryDialog,
    Input,
    AcceptDialog,
    CarouselSlider,
    Slide,
    FilterSearch,
    RequestJobDialog,
  },
  computed: {
    ...mapGetters('auth', ['account', 'isAdmin', 'isClub', 'isSgUser', 'accountComplete']),
    ...mapGetters('interests', ['userjobs', 'showed_assessment_dialog']),
    ...mapGetters('application', ['route', 'tenantConfig', 'preStore', 'tenantConfigMessage']),

    showAssessmentInterest() {
      return !this.foundation_loading && !this.loading && !this.userjobs.length && !this.showed_assessment_dialog && !this.preStore.preRegister;
    },
    showAcceptModal() {
      return this.preStore.preRegister && this.$route.name !== 'CourseDetail' && this.$route.name !== 'CourseDetailLesson';
    },
    showDiscoveryDialog() {
      const showDialog = !this.showAssessmentInterest && this.account;
      const discoveryModal = window.localStorage.getItem('discovery_modal');
      const checkDate = new Date() > new Date('2022-02-08 23:59:59');
      const preventCheck = (discoveryModal && discoveryModal === 'true') || checkDate;
      return preventCheck ? false : showDialog;
    },
    userName() {
      if (this.accountComplete) return this.accountComplete.name.split(' ')[0];
      return this.account?.name.split(' ')[0];
    },
    getImagesBannerMobile() {
      return !this.isDesktopScreen
        && Object.keys(this.tenantConfig).length > 0
        && this.tenantConfig?.banners?.length > 0 ? this.tenantConfig?.banners.filter((banner) => banner?.imageMobileUrl) : [];
    },
    getImagesBannerDesktop() {
      return this.isDesktopScreen
        && Object.keys(this.tenantConfig).length > 0
        && this.tenantConfig?.banners?.length > 0 ? this.tenantConfig?.banners.filter((banner) => banner?.imageUrl) : [];
    },
    hasTenant() {
      if (Object.keys(this.tenantConfig).length) {
        return this.tenantConfig.id !== 1;
      }
      return false;
    },
  },
  data() {
    return {
      isDesktopScreen: window.innerWidth >= 1200,
      extra_class: '',
      searchTerm: '',
      timeoutToSearch: null,
      isSearch: ref(false),
      dialog: true,
      dialogg: false,
      filters: {
        collections: true,
        courses: true,
      },
      showRequestJobDialog: false,
    };
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('application', ['getTenantConfig']),
    toggleSearch() {
      this.isSearch = !this.isSearch;
    },
    goTo(path) {
      this.$router.push(`/${this.tenantConfig?.url_whitelabel ?? 'pdc'}/${path}`);
    },
    openWindow(path) {
      window.open(`/${this.tenantConfig.url_whitelabel ?? 'pdc'}/${path}`, '_self');
    },
    openWindowWithoutWhitelabel(path) {
      window.open(path, '_self');
    },
    async handleRouter(route) {
      const dashboardRoute = 'dashboard/evoluir';
      const dashboardScheduleRoute = 'dashboard/parceria/agenda';

      const actions = {
        home: () => this.goTo('feed'),
        courses: () => this.goTo(`${dashboardRoute}/cursos`),
        collections: () => this.goTo(`${dashboardRoute}/trilhas-de-conhecimento`),
        events: () => this.openWindow(dashboardScheduleRoute),
        'clubs-install_master': () => this.openWindow('dashboard/clubes/mestres-da-instalacao'),
        'clubs-training': () => this.openWindow(dashboardScheduleRoute),
        'clubs-billets': () => this.openWindow(dashboardScheduleRoute),
        profile: () => this.goTo('parceiro/meus-dados'),
        contact: () => this.goTo('parceiro/contato'),
        my_profile: () => this.openWindowWithoutWhitelabel(`/community/user-profile/${this.account.id}`),
        campaign: () => window.open('https://pedreirotopbrasil.com.br/', '_blank'),
        profile_professional: () => this.openWindowWithoutWhitelabel('/community/search-profile'),
        groups: () => this.openWindowWithoutWhitelabel('/community'),
        my_learning: () => this.goTo('meu-aprendizado'),
        campaing: () => this.goTo('campanha'),
        ranking_campaing: () => this.goTo('ranking-campanha'),
        general_ranking: () => this.goTo('ranking'),
        campaign_ranking: () => window.open(
          'https://dados.parceirodaconstrucao.com.br/public/dashboard/8fd8dcd4-34c6-42d8-af6b-d88bd1afbb1d', '_blank',
        ),
        event: () => window.open(
          process.env.NODE_ENV === 'development'
            ? 'https://sgb-reality-stag-frontend-pedreiro.web.app/'
            : 'http://portal.pedreirotopbrasil.com.br/', '_blank',
        ),
        admin: () => {
          window.open('/admin', '_self');
          this.$router.push({ name: 'admin' });
        },
        exit: async () => {
          await this.logout();
        },
        downloads: () => {
          if (this.isSgUser) {
            // eslint-disable-next-line max-len
            window.open(
              'https://dados.parceirodaconstrucao.com.br/public/dashboard/8fd8dcd4-34c6-42d8-af6b-d88bd1afbb1d',
            );
          } else {
            window.open('/dashboard/downloads', '_self');
          }
        },
      };

      const fallback = () => this.goTo(`${dashboardRoute}/cursos`);

      return actions[route] ? actions[route]() : fallback();
    },
    activeRoute(route) {
      const options = {
        Courses: () => 'courses',
        CourseCollections: () => 'collections',
        Feed: () => 'home',
        Trails: () => 'trails',
      };
      return options[route] ? options[route]() : route;
    },
    pageRoute(route) {
      const options = {
        Courses: () => 'Cursos',
        CourseCollections: () => 'Trilhas de Conhecimento',
        SearchResult: () => 'Busca',
      };
      return options[route] ? options[route]() : 'Início';
    },
    onSearch(e) {
      e.preventDefault();
      const collectionsLocal = localStorage.getItem('@PARCEIROS_DA_CONSTRUCAO_FILTER_COLLECTTIONS');
      const coursesLocal = localStorage.getItem('@PARCEIROS_DA_CONSTRUCAO_FILTER_COURSES');
      const whiteLabel = localStorage.getItem('URL_WHITELABEL');
      window.open(
        `/${whiteLabel}/search/${this.searchTerm}&collections=${JSON.parse(collectionsLocal)}&courses=${JSON.parse(coursesLocal)}`,
        '_self',
      );
    },
    onChange(val) {
      this.searchTerm = val;
    },
  },
  async created() {
    const hasJob = await getUserJob(this.accountComplete?.id);
    if (hasJob.length === 0) {
      this.showRequestJobDialog = true;
    }
    this.$eventBus.$emit('userId', this.account?.AccountId || this.accountComplete?.id);
    this.$eventBus.$on('logout', async () => {
      await this.logout();
    });
  },
};
</script>

<style lang="scss" scoped>
.banners {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
  right: 0;
  margin-left: auto;

  .link {
    display: block;
  }

  @media(min-width: 1200px) and (max-width: 1400px) {
    width: calc(100% - var(--collapsed_sidebar_width));
  }

  @media(min-width: 1400px) {
    width: calc(100% - var(--default_sidebar_width));
  }
}

.full-image {
  width: 100%;
  height: auto;
}

video {
  width: 100%;
  z-index: 1 !important;

  &::-webkit-media-controls {
    display: none !important;
  }

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }

}

.form-search {
  display: none;

  @media(min-width: 690px) {
    display: flex;
    align-items: center;
  }

}
</style>
