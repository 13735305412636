<template>
  <TemplateAdmin :loading="loading">
    <template slot="container">
      <div class="content-page" :style="{ '--section-title-color': this.tenantConfig.primary_color }">
        <div class="top-cards flex-col">
          <home-course :courses="homeCourses" />
          <!-- <TopBanner type='feed' /> -->
          <tab-view @tabActive="onChangeTabValue" :titles="['Novidades', 'Categorias', 'Top 10', 'Favoritos']">
            <recent-section v-if="isTabActive === 0" />
            <!-- <recommended-section v-if="isTabActive === 1" /> -->
            <category-section :categoriesTab="true" v-if="isTabActive === 1" />
            <ranking-section v-if="isTabActive === 2" />
            <favorite-section v-if="isTabActive === 3" />
          </tab-view>
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import RecentSection from '@/components/organisms/Home/RecentSection.vue';
import RankingSection from '@/components/organisms/Home/RankingSection.vue';
import TabView from '@/components/organisms/TabView/TabView.vue';
import HomeCourse from '@/components/organisms/Course/HomeCourse/HomeCourse.vue';
import CategorySection from '@/components/organisms/Home/CategorySection.vue';
import FavoriteSection from '@/components/organisms/Home/FavoriteSection.vue';

export default {
  name: 'Course',
  components: {
    TemplateAdmin,
    RankingSection,
    RecentSection,
    TabView,
    HomeCourse,
    CategorySection,
    FavoriteSection,
  },
  async created() {
    this.getRecommendedCollections();
    this.getRankingCourses();
    this.getRecommendedCourses();
    this.getRecentCourses();
    this.getCoursesOngoing();
    this.getCoursesFinished();
    this.getCourseCategories();
    this.getPdcTalksCategories();
    this.getFavorites('course');
  },
  data() {
    return {
      loading: true,
      homeCourses: [],
      isTabActive: 0,
    };
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner', 'recommended_collections', 'favorite_courses']),
    ...mapGetters('application', ['loading_app', 'tenantConfig', 'loadingTenantConfig', 'hasTenant']),
    ...mapGetters('interests', ['userjobs']),
    userJobs() {
      const jobs = this.userjobs.map((job) => job.job);
      return jobs;
    },
  },
  methods: {
    ...mapActions('courses', ['getRankingCourses', 'getRecentCourses', 'getRecommendedCourses', 'fetchCourseWithoutLessons',
      'getCoursesOngoing', 'getCoursesFinished', 'getCourseCategories', 'getPdcTalksCategories', 'getRecommendedCollections', 'getFavorites']),
    ...mapActions('application', ['getTenantConfig']),
    ...mapActions('interests', ['getUserJobs']),

    onChangeTabValue(value) {
      this.isTabActive = value;
    },
  },
  async mounted() {
    try {
      const coursesToShowOnHome = ['como-otimizar-seu-aprendizado-com-o-parceiro-da-construcao'];
      coursesToShowOnHome.forEach((course) => {
        this.fetchCourseWithoutLessons(course).then((res) => {
          this.homeCourses.push(res);
        });
      });
      this.getTenantConfig().then(() => {
        if (this.hasTenant) {
          this.$router.push({ name: 'Courses' });
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import 'Feed.scss';
</style>
