/* eslint-disable max-len */
const TYPE_JOBS = [
  { label: 'Tempo integral', value: '1' },
  { label: 'Meio período', value: '2' },
  { label: 'Autônomo', value: '3' },
  { label: 'Freelancer', value: '4' },
  { label: 'Temporário', value: '5' },
  { label: 'Estágio', value: '6' },
  { label: 'Aprendiz', value: '7' },
  { label: 'Terceirizado', value: '8' },
  { label: 'Trainee', value: '9' },
];

const MONTHS = [
  { label: 'Janeiro', value: '01' },
  { label: 'Fevereiro', value: '02' },
  { label: 'Março', value: '03' },
  { label: 'Abril', value: '04' },
  { label: 'Maio', value: '05' },
  { label: 'Junho', value: '06' },
  { label: 'Julho', value: '07' },
  { label: 'Agosto', value: '08' },
  { label: 'Setembro', value: '09' },
  { label: 'Outubro', value: '10' },
  { label: 'Novembro', value: '11' },
  { label: 'Dezembro', value: '12' },
];

const LOCALES = [
  { label: 'Presencial', value: '1' },
  { label: 'Híbrido', value: '2' },
  { label: 'Remoto', value: '3' },
];

const ACADEMIC_LEVELS = [
  { label: 'Técnico', value: 'Técnico' },
  { label: 'Tecnólogo', value: 'Tecnólogo' },
  { label: 'Bacharelado', value: 'Bacharelado' },
  { label: 'Licenciatura', value: 'Licenciatura' },
  { label: 'Pós-graduação', value: 'Pós-graduação' },
  { label: 'Mestrado', value: 'Mestrado' },
  { label: 'Doutorado', value: 'Doutorado' },
  { label: 'Pós-doutorado', value: 'Pós-doutorado' },
];

const STATES = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'PR', label: 'Paraná' },
  { value: 'RJ', label: 'Rio de} Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'TO', label: 'Tocantins' },
];

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

const YEARS = Array.from({ length: 31 }, (_, index) => {
  const ano = anoAtual - index + 1;
  return { label: ano.toString(), value: ano.toString() };
}).slice(1);

const LEVELS = ['Iniciante', 'Básico', 'Intermediário', 'Avançado', 'Especialista'];

const HEADERS = {
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'OPTIONS,POST,GET, PUT, DELETE',
  'Access-Control-Request-Headers': 'Content-Type',
};

const COURSES_BY_CATEGORIE = [
  {
    id: 18,
    title: 'Intermediário em coberturas Brasilit',
    path: 'treinamento-intermediario-em-coberturas-brasilit',
    difficulty: 3,
    categoryId: 2,
    description: 'Seja bem-vindo ao curso intermediário sobre telhas de fibrocimento do Parceiro da Construção! Ao longo de 3 treinamentos, iniciante ao avançado, você irá aprender desde os conceitos básicos até o processo de escolha da melhor solução de fibrocimento para diferentes tipologias de projeto. Além deste conteúdo desenvolvido especialmente para você, aproveite para checar nosso site e fazer o download de manuais e catálogos, para aprofundar ainda mais seus conhecimentos nas diversas soluções de cobertura de fibrocimento oferecidas pela Brasilit. Esperamos que sua jornada seja excelente, bons estudos!',
    introVideo: null,
    courseTime: null,
    introTime: null,
    points: 300,
    quizApprovalThreshold: 70,
    quizTimeToFinish: 30,
    workload: '0.40',
    position: 5,
    smallDescription: 'Avance sobre instalações de coberturas e saiba escolher a melhor solução de fibrocimento para diferentes projetos.',
    smallTitle: 'Conheça as vantagens e diferenciais das coberturas Brasilit',
    company: null,
    secondDescription: '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">No curso, você vai conhecer as categorias de telhas de fibrocimento, garantindo máxima robustez e durabilidade na estrutura, com conforto térmico para o ambiente. </span></p>',
    shortDescription: 'Aprenda conceitos sobre instalações de coberturas e saiba escolher  a melhor solução de fibrocimento para diferentes projetos.',
    primaryImage: 'https://diwe-pdc.s3.sa-east-1.amazonaws.com/files/1616195149411.jpg',
    primaryThumb: null,
    secondaryImage: 'https://diwe-pdc.s3.sa-east-1.amazonaws.com/files/1616195156569.jpg',
    showPortal: true,
    releaseDate: null,
    createdAt: '2020-08-28T00:00:00.000Z',
    updatedAt: '2021-03-19T23:47:03.000Z',
    attachmentId: null,
    qtyLikes: 1,
    score: 3,
    qtyScores: 1,
    statusPublication: '3',
    progress: 0,
    totalTimeLessons: 44.34,
    totalLessons: '9',
    totalResources: '0',
    category: {
      id: 2,
      title: 'Telhas e Coberturas',
      shortTitle: '',
      position: 8,
      private: 0,
      color: '#FB0035',
    },
    nps: [],
    roles: [
      {
        id: 1,
        name: '',
        description: null,
        type: 'role',
        created: '2019-03-18T15:06:41.000Z',
        modified: '2019-03-18T15:06:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 3,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 5,
        name: '',
        description: null,
        type: null,
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: null,
        content: null,
      },
      {
        id: 6,
        name: '',
        description: null,
        type: null,
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: null,
        content: null,
      },
      {
        id: 7,
        name: 'Gesseiro',
        description: null,
        type: 'job',
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 8,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 9,
        name: '',
        description: null,
        type: 'acl',
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 10,
        name: '',
        description: null,
        type: null,
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: null,
        content: null,
      },
      {
        id: 11,
        name: '',
        description: null,
        type: 'role',
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 12,
        name: 'Outra profissa',
        description: null,
        type: 'job',
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 13,
        name: '',
        description: null,
        type: null,
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: null,
        content: null,
      },
      {
        id: 14,
        name: '',
        description: null,
        type: null,
        created: '2020-03-20T00:00:00.000Z',
        modified: '2020-03-20T00:00:00.000Z',
        active: null,
        content: null,
      },
      {
        id: 15,
        name: '',
        description: null,
        type: null,
        created: '2020-03-20T00:00:00.000Z',
        modified: '2020-03-20T00:00:00.000Z',
        active: null,
        content: null,
      },
      {
        id: 16,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 18,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 20,
        name: '',
        description: null,
        type: 'acl',
        created: null,
        modified: null,
        active: true,
        content: null,
      },
      {
        id: 21,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 23,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 24,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 25,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 26,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 27,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 28,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 29,
        name: '',
        description: null,
        type: null,
        created: null,
        modified: null,
        active: null,
        content: null,
      },
      {
        id: 117,
        name: '',
        description: null,
        type: 'acl',
        created: null,
        modified: null,
        active: true,
        content: null,
      },
    ],
    courseProfessor: [],
    isLike: {
      id: 305,
      accountId: 61631,
      entity: 'course',
      entityId: 18,
      isLike: true,
      createdAt: '2023-04-20T12:55:38.000Z',
      updatedAt: '2023-04-20T12:55:38.000Z',
    },
    isFavorite: null,
    approved: false,
    completed: false,
    started: false,
    difficultyText: 'Básico',
    currentTopic: {
      id: 137,
      title: 'Conheça mais sobre a telha Fibrotex',
      time: '4.52',
      description: 'Nosso convidado Marcos Barragam - gerente de produtos do canal varejo da Saint-Gobain, responsável pelas linhas de coberturas Brasilit, apresenta conceitos um pouco mais aprofundados sobre uma telha da linha Essencial, chamada Fibrotex. Nesta aula, Barragam aborda desde os principais usos da telha e seu público alvo, dimensões presentes no portfólio Brasilit, particularidades de espaçamento máximo entre vãos, inclinações adequadas de uso e seu procedimento de fixação. Por fim, menciona as peças complementares à linha, garantindo perfeito acabamento do telhado.',
      video: '452197380',
      createdAt: null,
      updatedAt: null,
      courseLesson: {
        id: 41,
        title: 'Treinamento intermediário em coberturas Brasilit',
        createdAt: null,
        updatedAt: null,
      },
      courseTopicResource: [],
      finished: false,
    },
  },
  {
    id: 163,
    title: 'PDC Talks 2022 #6',
    path: 'pdc-talks-2022-6',
    difficulty: 1,
    categoryId: 80,
    description: '',
    introVideo: '78110021',
    courseTime: null,
    introTime: '00.01',
    points: 100,
    quizApprovalThreshold: 0,
    quizTimeToFinish: 2,
    workload: '00.00',
    position: 47,
    smallDescription: '',
    smallTitle: null,
    company: null,
    secondDescription: null,
    shortDescription: null,
    primaryImage: null,
    primaryThumb: null,
    secondaryImage: null,
    showPortal: null,
    releaseDate: '2022-11-09T00:00:00.000Z',
    createdAt: '2022-11-09T18:32:14.000Z',
    updatedAt: '2022-11-09T18:34:59.000Z',
    attachmentId: null,
    qtyLikes: 4,
    score: 5,
    qtyScores: 23,
    statusPublication: '3',
    progress: 0,
    totalTimeLessons: 0.01,
    totalLessons: '1',
    totalResources: '0',
    category: {
      id: 80,
      title: 'Mão na Massa',
      shortTitle: 'Mão na Massa',
      position: 1,
      private: 0,
      color: '#5d4a99',
    },
    nps: [],
    roles: [
      {
        id: 126,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-10-13T20:49:13.000Z',
        modified: '2022-10-13T20:49:13.000Z',
        active: true,
        content: null,
      },
      {
        id: 125,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-10-13T20:40:58.000Z',
        modified: '2022-10-13T20:40:58.000Z',
        active: true,
        content: null,
      },
      {
        id: 124,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-10-13T20:36:45.000Z',
        modified: '2022-10-13T20:36:45.000Z',
        active: true,
        content: null,
      },
      {
        id: 123,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-09-02T15:13:34.000Z',
        modified: '2022-09-02T15:13:34.000Z',
        active: true,
        content: null,
      },
      {
        id: 122,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-09-01T14:53:37.000Z',
        modified: '2022-09-01T14:53:37.000Z',
        active: true,
        content: null,
      },
      {
        id: 121,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-09-01T12:11:25.000Z',
        modified: '2022-09-01T12:11:25.000Z',
        active: true,
        content: null,
      },
      {
        id: 120,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-09-01T12:03:26.000Z',
        modified: '2022-09-01T12:03:26.000Z',
        active: true,
        content: null,
      },
      {
        id: 118,
        name: '',
        description: null,
        type: 'acl',
        created: '2022-03-18T15:06:41.000Z',
        modified: '2022-03-18T15:06:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 117,
        name: '',
        description: null,
        type: 'acl',
        created: null,
        modified: null,
        active: true,
        content: null,
      },
      {
        id: 20,
        name: '',
        description: null,
        type: 'acl',
        created: null,
        modified: null,
        active: true,
        content: null,
      },
      {
        id: 11,
        name: '',
        description: null,
        type: 'role',
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 9,
        name: '',
        description: null,
        type: 'acl',
        created: '2020-03-13T14:32:41.000Z',
        modified: '2020-03-13T14:32:41.000Z',
        active: true,
        content: null,
      },
      {
        id: 1,
        name: '',
        description: null,
        type: 'role',
        created: '2019-03-18T15:06:41.000Z',
        modified: '2019-03-18T15:06:41.000Z',
        active: true,
        content: null,
      },
    ],
    courseProfessor: [],
    isLike: {
      id: 293,
      accountId: 61338,
      entity: 'course',
      entityId: 163,
      isLike: true,
      createdAt: '2023-02-15T21:33:16.000Z',
      updatedAt: '2023-02-15T21:33:16.000Z',
    },
    isFavorite: {
      id: '63',
      accountId: '185313',
      entityType: 'course',
      entityId: '163',
      createdAt: '2023-07-24T19:15:20.000Z',
      updatedAt: '2023-07-24T19:15:20.000Z',
    },
    approved: false,
    completed: false,
    started: false,
    difficultyText: 'Básico',
    currentTopic: {
      id: 544,
      title: 'Aula 1',
      time: '00.01',
      description: 'Aula 1',
      video: '78110021',
      createdAt: '2022-11-09T18:32:53.000Z',
      updatedAt: '2022-11-28T10:04:30.000Z',
      courseLesson: {
        id: 245,
        title: 'Módulo 1',
        createdAt: '2022-11-09T18:32:28.000Z',
        updatedAt: '2022-11-09T18:32:28.000Z',
      },
      courseTopicResource: [],
      finished: false,
    },
  },
  {
    id: 23,
    title: 'O trade pós-pandemia',
    path: 'o-trade-pos-pandemia',
    difficulty: 2,
    categoryId: 31,
    description: 'O ano de 2020 veio acompanhado de muitos desafios. Pensando em oferecer um conteúdo para ajudar o desenvolvimento de sua equipe de trade ou aprimoramento desse setor crucial para o sucesso do seu negócio, convidamos o grande nome do trade marketing Rubens Sant’Anna, que além dos 15 anos de experiência no segmento, escreveu vários livros, como por exemplo, o “Planejamento do Trade Marketing”.',
    introVideo: '480925415',
    courseTime: null,
    introTime: '5.24',
    points: 70,
    quizApprovalThreshold: 60,
    quizTimeToFinish: 20,
    workload: '0.55',
    position: 18,
    smallDescription: 'Conheça técnicas, boas práticas e cases de trade marketing que podem ajudá-lo a superar vários desafios.',
    smallTitle: 'Inovação e digitalização em foco',
    company: null,
    secondDescription: '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">No curso, você vai conhecer as principais tendências e boas práticas de marketing e inovação que podem orientar o projeto de digitalização do seu negócio. </span></p>',
    shortDescription: 'Conheça técnicas, boas práticas e cases de trade marketing que podem ajudá-lo a superar vários desafios.',
    primaryImage: 'https://diwe-pdc.s3.sa-east-1.amazonaws.com/files/1623164622197.jpg',
    primaryThumb: null,
    secondaryImage: 'https://diwe-pdc.s3.sa-east-1.amazonaws.com/files/1623164627772.jpg',
    showPortal: true,
    releaseDate: null,
    createdAt: '2020-11-24T00:00:00.000Z',
    updatedAt: '2021-06-08T15:03:53.000Z',
    attachmentId: null,
    qtyLikes: 1,
    score: 5,
    qtyScores: 5,
    statusPublication: '3',
    progress: 0,
    totalTimeLessons: 31.01,
    totalLessons: '5',
    totalResources: '0',
    category: {
      id: 31,
      title: 'PDC Talks 2020',
      shortTitle: '',
      position: 0,
      private: 0,
      color: '#808080',
    },
    nps: [],
    roles: [
      {
        id: 1,
        name: '',
        description: null,
        type: 'role',
        created: '2019-03-18T15:06:41.000Z',
        modified: '2019-03-18T15:06:41.000Z',
        active: true,
        content: null,
      },
    ],
    courseProfessor: [],
    isLike: {
      id: 372,
      accountId: 185313,
      entity: 'course',
      entityId: 23,
      isLike: true,
      createdAt: '2023-07-31T17:03:31.000Z',
      updatedAt: '2023-07-31T17:03:31.000Z',
    },
    isFavorite: null,
    approved: false,
    completed: false,
    started: false,
    difficultyText: 'Básico',
    currentTopic: {
      id: 165,
      title: 'Lições que podemos tirar sobre um case de sucesso',
      time: '5.44',
      description: 'Começaremos esse conteúdo falando sobre algumas lições que podemos retirar de um case de sucesso, nada mais nada menos, do que os ensinamentos que podemos tirar do momento mais conturbado da gigante da tecnologia Apple.',
      video: '480927233',
      createdAt: null,
      updatedAt: null,
      courseLesson: {
        id: 46,
        title: 'O trade pós-pandemia',
        createdAt: null,
        updatedAt: null,
      },
      courseTopicResource: [],
      finished: false,
    },
  },
];

export default {
  data: {
    TYPE_JOBS,
    MONTHS,
    YEARS,
    LOCALES,
    LEVELS,
    HEADERS,
    STATES,
    COURSES_BY_CATEGORIE,
    ACADEMIC_LEVELS,
  },
};
