<template>
  <div>
    <PdcButton variant="like" :class="`btn-link h-100 w-100 ${likedClass}`" v-if="mode == 'button'"
      @click.native="postLike">
      <div class="like-icon" />
    </PdcButton>
    <div class="card__specs-item pb-0 mb-0" v-else-if="mode == 'counter'" @click="postLike">
      <Transition name="fade">
        <div class="card__specs-item-text likes-section flex-row f-12">
          <div class="like-avatars">
            <div :class="`like-icon ${likedClass}`" />
          </div>
          <span class="like-text" v-if="qty > 1">{{ qty }} Curtidas</span>
          <span class="like-text" v-else>{{ qty }} Curtida</span>
        </div>
      </Transition>
    </div>
  </div>
</template>
<script>
import './likeComponent.scss';
import PdcButton from '@/components/atoms/Button/Button.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    entity: String,
    entity_id: Number,
    mode: String,
    qty_likes: {
      type: Number,
      default: 0,
    },
    is_liked: {
      type: Boolean,
      default: false,
    },
    always_visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PdcButton,
  },
  computed: {
    ...mapGetters('likes', ['entity_likes', 'entity_user_liked']),
    qty() {
      return this.entity_likes[this.makeIndex('likes')]?.qty ?? 0;
    },
    liked() {
      return this.entity_user_liked[this.makeIndex('liked')] ?? false;
    },
    likedClass() {
      return `${this.liked ? 'liked' : ''}`;
    },
  },
  created() {
    this.setUserLiked({
      index: this.makeIndex('liked'),
      value: this.is_liked,
    });
    if (this.mode === 'counter') {
      this.setLikes({
        qty: this.qty_likes,
        is_liked: this.is_liked,
        entity: this.entity,
        entity_id: this.entity_id,
        index: this.makeIndex('likes'),
      });
    }
  },
  methods: {
    ...mapActions('likes', ['setLikes', 'toggleLike', 'setUserLiked']),
    makeIndex(prefix) {
      return `${prefix}_${this.entity}_${this.entity_id}`;
    },
    likeComment(qtyLikes) {
      this.$emit('likeComment', qtyLikes);
    },
    postLike() {
      this.toggleLike({
        entity: this.entity,
        entity_id: this.entity_id,
        index: this.makeIndex('likes'),
        liked: !this.liked,
      });
      if (this.liked) {
        this.likeComment(this.qty_likes);
      }
    },
  },
};
</script>
