<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5.5H17L16 19H4L3 5.5Z" stroke="#585858" stroke-width="1.5" stroke-linejoin="round" />
    <path d="M13.5 7.5L13 17" stroke="#585858" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 7.5V17" stroke="#585858" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.5 7.5L7 17" stroke="#585858" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 3H17C18.1046 3 19 3.89543 19 5V5.5H1V5C1 3.89543 1.89543 3 3 3H6M14 3L13 1H7L6 3M14 3H6" stroke="#585858"
      stroke-width="1.5" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'Delete',
};
</script>
