<template>
  <div class="recommended-collections-section">
    <CoursesSkeleton type="recommended" v-if="recommended_loading" />
    <Carousel title="Trilhas recomendadas para você" :items="recommendedCollections"
      v-else-if="recommendedCollections.length > 0" :options="swiperOptions">
      <template v-slot:item="{ item }">
        <CollectionCard :collection="item" section="recommended_collections" />
      </template>
    </Carousel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import CollectionCard from '@/components/organisms/Course/CollectionCard/CollectionCard.vue';
import Carousel from '@/components/atoms/Carousel/Carousel.vue';

export default {
  name: 'RecommendedSection',
  components: {
    CollectionCard,
    CoursesSkeleton,
    Carousel,
  },
  computed: {
    ...mapGetters('courses', ['recommended_collections', 'collections', 'finished', 'recommended_loading']),
    recommendedCollections() {
      return this.recommended_collections.slice(0).reverse();
    },
    swiperOptions() {
      return {
        slidesPerView: 1.04,
        breakpoints: {
          1200: {
            slidesPerView: 2.9,
            spaceBetween: 24,
          },
          980: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          700: {
            slidesPerView: 1.8,
            spaceBetween: 24,
          },
        },
      };
    },
  },
};
</script>
