export function userLogged(dataLayer, user) {
  dataLayer.push({
    event: 'UserLogged',
    ...user,
  });
}

export function initiateCourse(dataLayer, course, user) {
  dataLayer.push({
    event: 'InitiateCourse',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    contentType: 'COURSE',
  });
}

export function initiateLesson(dataLayer, course, lesson, user) {
  dataLayer.push({
    event: 'InitiateLesson',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    lessonName: lesson.title,
    contentType: 'COURSE',
  });
}

export function finishLesson(dataLayer, course, lesson, user) {
  dataLayer.push({
    event: 'FinishLesson',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    lessonName: lesson.title,
    contentType: 'COURSE',
  });
}

export function showAd(dataLayer, course, lesson, ad, user) {
  dataLayer.push({
    event: 'ShowAd',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    lessonName: lesson.title,
    adTime: ad.advertising_insert_position,
    adUrl: ad.advertising_insert_url,
    timeToSkip: ad.advertising_insert_time_to_skip,
    lessonTime: lesson.time,
    contentType: 'COURSE',
  });
}

export function clickAd(dataLayer, course, lesson, ad, user) {
  dataLayer.push({
    event: 'ClickAd',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    lessonName: lesson.title,
    adTime: ad.advertising_insert_position,
    timeToSkip: ad.advertising_insert_time_to_skip,
    adUrl: ad.advertising_insert_url,
    lessonTime: lesson.time,
    contentType: 'COURSE',
  });
}

export function skipAd(dataLayer, course, lesson, ad, user) {
  dataLayer.push({
    event: 'SkipAd',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    lessonName: lesson.title,
    adTime: ad.advertising_insert_position,
    timeToSkip: ad.advertising_insert_time_to_skip,
    adUrl: ad.advertising_insert_url,
    lessonTime: lesson.time,
    contentType: 'COURSE',
  });
}

export function finishLessons(dataLayer, course, user) {
  dataLayer.push({
    event: 'FinishLessons',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    contentType: 'COURSE',
  });
}

export function iniateExam(dataLayer, course, user, questionsQuantity) {
  dataLayer.push({
    event: 'InitiateExam',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    contentType: 'COURSE',
    questionsQuantity,
  });
}

export function finishExam(dataLayer, course, user, results) {
  dataLayer.push({
    event: 'FinishExam',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    contentType: 'COURSE',
    // questionsQuantity: course.results.questions,
    // correctQuestions: course.results.correctResponses,
    isApproved: results.approved,
  });
}

export function finishCourse(dataLayer, course, user) {
  dataLayer.push({
    event: 'FinishCourse',
    courseName: course.title,
    courseId: course.id,
    accountId: user.id,
    courseCategory: course.category.title,
    contentType: 'COURSE',
    // resultsPoints: course.result.rating / 10,
    points: course.points,
    firstCourse: false,
  });
}

export function clickedCourse(dataLayer, course, section, origin) {
  dataLayer.push({
    event: 'ClickedCourse',
    courseName: course.title,
    courseId: course.id,
    contentType: 'COURSE',
    section,
    origin,
  });
}

export function searchCourse(dataLayer, searchTerm, searchResultsCount) {
  dataLayer.push({
    event: 'SearchCourse',
    searchTerm,
    searchResultsCount,
  });
}

export function pageView(dataLayer, pageTitle, pageName, pagePath) {
  dataLayer.push({
    event: 'PageView',
    pageTitle,
    pageName,
    pagePath,
  });
}

export function clickedTrail(dataLayer, trail) {
  dataLayer.push({
    event: 'ClickedTrail',
    trailName: trail.name,
    trailId: trail.id,
    trailProgress: trail.progress,
  });
}

export function insertComment(dataLayer, course, lessonName, comment, isReply) {
  dataLayer.push({
    event: 'InsertComment',
    courseName: course.title,
    courseId: course.id,
    courseCategory: course.category.title,
    lessonName,
    commentLength: comment.commentLength,
    commentStatus: comment.status,
    isReply,
  });
}

export function likeComment(dataLayer, course, lessonName, comment, commentLength) {
  dataLayer.push({
    event: 'LikeComment',
    courseName: course.title,
    courseId: course.id,
    courseCategory: course.category.title,
    lessonName,
    commentLength,
    commentStatus: comment.status,
    commentLikes: comment.qtyLikes,
  });
}
