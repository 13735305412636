<template>
  <div style="display:flex; flex-direction:column; justify-content: center; align-items: center; margin: 0 auto;">
    <ElDialog id="rating-dialog" :visible.sync="showDialog" max-width="500" :modal="false" :close-on-click-modal="false"
      :show-close="true">
      <div v-if="!loading">
        <template v-if="entity === 'course_topic'">
          <h3 class="rating-tittle">
            Como você avalia o curso <br> que acabou de assistir?
          </h3>
        </template>

        <template v-if="entity === 'collection'">
          <div style="text-align:center">
            <img src="@/assets/illustrations/finish.svg" alt="" style="margin-bottom: 30px;" />
          </div>

          <h3 class="rating-tittle">Parabéns! Você chegou ao final da <br>Trilha de Conhecimento!</h3>
          <p class="modal-description"> Gostaríamos de entender como foi sua experiência <br>de aprendizado nessa
            trilha?
          </p>
        </template>

        <div slot="footer" class="dialog-footer">
          <div class="star-rating-container">
            <StarRating @click.native="submitEntityRating" v-model="score" inactiveColor="transparent" activeColor="#FFE100"
              activeBorderColor="#E5CA00" :border-width="2" border-color="#ADB5BD" :star-size="32" :show-rating="false"
              :star-points="[
                23, 2,
                15.85, 16.39,
                0, 19,
                11.57, 29.82,
                8.7, 45.41,
                23, 38.09,
                37.3, 45.41,
                34.43, 29.82,
                46, 19,
                30.15, 16.39
              ]" />
            <div class="rating-instruction">
              <span class="label-1">Não gostei</span>
              <span class="label-2">Gostei muito</span>
            </div>
          </div>

          <div class="comment-rating-container">
            <CustomTextArea name="name" title="Comentário (opcional)" v-model="feedback" :maxlength="400" />
            <Button @click="submitCourseRating" variant="primary" class="rating-button">Salvar</Button>
          </div>
        </div>
      </div>

      <div v-if="loading" class="el-loading-spinner">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
      </div>
    </ElDialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import StarRating from 'vue-star-rating';
import 'vue-toast-notification/dist/theme-default.css';
import './RatingDialog.scss';
import '../../atoms/Toast/Toast.scss';

import CustomTextArea from '../../atoms/CustomTextArea/CustomTextArea.vue';
import Button from '../../atoms/Button/Button.vue';

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
      default: '',
    },
    entityId: {
      type: Number,
      default: null,
    },

    goToNextLesson: {
      type: Function,
      default: null,
    },
  },
  components: {
    StarRating,
    CustomTextArea,
    Button,
  },
  data: () => ({
    score: null,
    loading: false,
    feedback: '',

  }),
  methods: {
    ...mapActions('ratings', ['clearRating', 'setUserEntityRating']),
    ...mapActions('courses', ['setEvaluateCourse']),
    submitEntityRating() {
      if (this.entity === 'collection') {
        setTimeout(() => {
          this.loading = true;

          setTimeout(async () => {
            try {
              await this.setUserEntityRating(
                { entity: this.entity, entityId: this.entityId, score: this.score },
              );

              this.$toast.open({
                message: 'Aula avaliada com sucesso!', type: 'success', position: 'bottom-left', duration: 3000,
              });
            } catch (error) {
              this.clearRating();

              this.loading = false;

              this.$toast.open({
                message: 'Algo deu errado! Tente novamente.', type: 'error', position: 'bottom-left', duration: 5000,
              });
            }
          }, 500);
        }, 400);
      }
    },
    async submitCourseRating() {
      this.loading = true;

      try {
        await this.setEvaluateCourse({
          stars: this.score,
          feedback: this.feedback,
        });

        this.$toast.open({
          message: 'Curso avaliado com sucesso!', type: 'success', position: 'bottom-left', duration: 3000,
        });

        setTimeout(() => {
          this.goToNextLesson();
        }, 2000);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.$toast.open({
          message: 'Algo deu errado! Tente novamente.', type: 'error', position: 'bottom-left', duration: 5000,
        });
      }
    },
  },
};
</script>
