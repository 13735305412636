<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">

    <div class="modal-header">
      <h1 v-if="!isEdit">Adicionar endereço profissional</h1>
      <h1 v-else>Editar endereço</h1>
    </div>

    <form>
      <div class="import-address">
        <fieldset v-if="!isEdit && hasAddress" class="check-image">
          <label for="like">
            <input v-model="useSameAddress" type="checkbox" name="like" id="like">
            <i></i>
            Usar endereço pessoal:
          </label>
          <div class="my-address">
            <p>{{ userAddress?.[0]?.address }}, {{ userAddress?.[0]?.number }} - {{ userAddress?.[0]?.neighborhood }} - {{
              userAddress?.[0]?.city
            }}
              -
              {{ userAddress?.[0]?.state }}</p>
          </div>
        </fieldset>
        <fieldset v-else-if="!isEdit && !!userAddress" class="check-image">
          <label for="like">
            <input v-model="useSameAddressOnBoth" type="checkbox" name="like" id="like">
            <i></i>
            Usar este endereço também para o perfil pessoal
          </label>
        </fieldset>
      </div>
      <div v-if="!useSameAddress" class="address-form">
        <CustomInput type="text" name="cep" @input="getAddress($event)" id="cep" placeholder="00000-000" title="CEP"
          v-model="localAddress.cep" />
        <CustomInput disabled type="text" :value="'Brasil'" placeholder="Ex: Brasil" title="País/Região" name="country" />

        <CustomSelect :options="states" v-model="localAddress.uf" title="Estado*" name="state"
          :defaultLabel="localAddress.uf || 'Escolha seu estado'" />

        <CustomInput type="text" v-model="localAddress.localidade" placeholder="Ex: Natal" title="Cidade" name="city"
          defaultLabel="Cidade" />
        <CustomInput type="text" v-model="localAddress.bairro" placeholder="Bairro" title="Bairro*" name="neighborhood"
          defaultLabel="Bairro" />

        <CustomInput type="text" v-model="localAddress.logradouro" placeholder="Rua / Avenida" title="Rua*" name="street"
          defaultLabel="Rua" />

        <CustomInput type="text" v-model="localAddress.numero" placeholder="Ex: 123" title="Número" name="number"
          defaultLabel="Número" />

        <CustomInput type="text" v-model="localAddress.complemento" placeholder="Se houver, digite seu complemento"
          title="Complemento" name="complement" />
      </div>
      <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
        <PdcButton class="button" variant="outlined" id="button-discovery" @click="handleClose">Fechar
        </PdcButton>
        <PdcButton :loading="loading_save" class="button" variant="primary" id="button-discovery"
          @click.native="saveAddress">Salvar</PdcButton>
      </span>
    </form>
  </el-dialog>
</template>
<script>

import PdcButton from '@/components/atoms/Button/Button.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect.vue';
import CONSTANTS from '@/utils/constants';
import { findAddressByCEP } from '@/services/via-cep.service';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AddressDialog',
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading_save: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasSomeAddress: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    PdcButton,
    CustomInput,
    CustomSelect,
  },
  data: () => ({
    dialog: true,
    close: false,
    useSameAddress: false,
    states: [],
    localAddress: {},
    useSameAddressOnBoth: false,
  }),
  methods: {
    ...mapActions('address', ['saveAddressOnProfile', 'saveUseAddressOn', 'saveAddressOnState', 'getAccountAddress']),
    saveAddress() {
      if (this.useSameAddress) this.saveUseAddressOn('professional');
      else if (this.useSameAddressOnBoth) this.saveUseAddressOn('both');
      else this.saveUseAddressOn('onlyProfessional');
      const addressToSend = Object.keys(this.localAddress).length > 0 ? this.parseAddressToRequest : this.userAddress[0];
      this.saveAddressOnState(addressToSend);
      this.$emit('saveAddress');
    },
    handleClose() {
      this.$emit('close');
    },
    async getAddress(cep) {
      if (cep && cep.length === 8) {
        const { status, data } = await findAddressByCEP(cep);
        if (status === 200) this.localAddress = data;
      }
    },
    async getAddressData() {
      await this.getAccountAddress(this.isEdit ? 'professional' : 'accountId');
    },
    parseAddressToShow(addressToShow) {
      const {
        state, city, neighborhood, address, cep, number, complement,
      } = addressToShow;
      return {
        uf: state,
        localidade: city,
        bairro: neighborhood,
        logradouro: address,
        cep,
        numero: number,
        complemento: complement,
      };
    },
  },
  computed: {
    ...mapGetters('address', ['loadingAddress', 'userAddress', 'useAddressOn']),
    statesToShow() {
      const states = CONSTANTS?.data?.STATES;
      const statesArray = Object.entries(states).map(([sigla, nome]) => ({
        label: nome,
        value: sigla,
      }));
      return [...statesArray];
    },
    parseAddressToRequest() {
      const {
        localidade, bairro, logradouro, cep, uf, numero, complemento, id,
      } = this.localAddress;
      return {
        state: uf,
        city: localidade,
        neighborhood: bairro,
        address: logradouro,
        cep,
        number: numero,
        complement: complemento,
        id,
      };
    },
    hasAddress() {
      if (this.userAddress) return Object.keys(this.userAddress).length > 0;
      return false;
    },
  },
  async mounted() {
    this.states = CONSTANTS?.data?.STATES;
    await this.getAddressData();
    if (this.userAddress[0]) this.localAddress = this.parseAddressToShow(this.userAddress[0]);
  },
};
</script>
<style lang="scss">
.address-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 1rem;
  padding: 1rem;
}

fieldset {
  label {
    font-size: 1rem;
  }
}

form {
  padding: 1rem;

  .my-address {
    border: 1px solid #ccc;
    padding: 1rem;
    margin: 0.4rem 0.7rem;
    width: 90%;
  }

  hr {
    max-width: 95%;
  }
}
</style>
