<template>
  <div>
    <SidebarItem class="mb-16" @click="opened = !opened">
      <div class="sidebar-container--module--item">
        <div class="sidebar-container--module--item--text">
          <div class="sidebar-container--module--item--text--title">Módulo {{ index + 1 }}</div>
          <div class="sidebar-container--module--item--text--counter">
            {{ counter }}
          </div>
          <div :class="`sidebar-container--module--item--text--arrow ${opened && 'opened'}`" />
        </div>
      </div>
    </SidebarItem>
    <div class="mb-24 mt-8" v-if="opened">
      <SidebarItem
        v-for="(lesson, i) in module.courseTopics"
        :key="i"
        :class="`${current_lesson_id == lesson.id && 'current'} hoverable sub mb-8`"
        @click="clickedLesson(lesson)"
      >
        <div class="sidebar-container--module--item-subitem">
          <div
            :class="`sidebar-container--module--item-subitem--icon ${lesson.finished && 'checked'}`"
          />
          <div class="sidebar-container--module--item-subitem--text">
            Aula {{ (i + 1).toString().padStart(2, '0') }}
          </div>
        </div>
      </SidebarItem>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SidebarItem from '../SidebarItem/SidebarItem.vue';

export default {
  props: {
    current_lesson_id: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    module: {
      type: Object,
      default: () => ({
        title: '',
        lessons: [],
      }),
    },
  },
  components: {
    SidebarItem,
  },
  watch: {
    current_lesson_id(val) {
      this.opened = this.checkModuleHasLessonId(val);
    },
  },
  data() {
    return {
      opened: this.checkModuleHasLessonId(this.current_lesson_id),
    };
  },
  computed: {
    ...mapGetters('courses', ['course']),
    total() {
      return this.module.courseTopics.length;
    },
    counter() {
      const completedLessons = this.module.courseTopics.filter((lesson) => lesson.finished);
      return `${completedLessons.length}/${this.total}`;
    },
  },
  methods: {
    ...mapActions('application', ['openLink']),
    checkModuleHasLessonId(id) {
      return this.module.courseTopics.filter((lesson) => lesson.id === id).length > 0;
    },
    clickedLesson(lesson) {
      if (lesson.id !== this.current_lesson_id) {
        this.$router.push({
          name: 'CourseDetailLesson',
          params: { course: this.course.path, module: this.module.id, lesson: lesson.id },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import './CourseModule.scss';
</style>
