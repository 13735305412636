<template>
  <div class="loading-spinner" :style="{ width, height }"></div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    width: {
      type: String,
      default: '1rem',
    },
    height: {
      type: String,
      default: '1rem',
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  border: 0.25em solid transparent;
  border-top-color: #7983ff;
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
