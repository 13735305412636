<template>
  <div class="categories-section">
    <CoursesSkeleton type="categories" v-if="categories_loading" />
    <template v-else-if="!categoriesTab">
      <div class="recommended" v-for="(category, idx) in sortedCategories" :key="idx">
        <Carousel :title="category.title" :items="category.courses">
          <template v-slot:item="{ item }">
            <CourseCard :course="item" />
          </template>
        </Carousel>
      </div>
    </template>
    <template v-else>
     <Carousel :title="'Categorias'" :items="sortedCategories">
        <template v-slot:item="{ item }">
          <CategorieCard @sendCategorie="sendCategorie" :categorie="item" />
        </template>
      </Carousel>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import CourseCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import Carousel from '@/components/atoms/Carousel/Carousel.vue';
import _ from 'lodash';
import CourseCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CategorieCard from '../Categories/CategorieCard.vue';

export default {
  name: 'CategorySection',
  components: {
    CourseCard,
    CoursesSkeleton,
    Carousel,
    CategorieCard,
  },
  props: {
    categoriesTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('courses', ['categories', 'finished', 'categories_loading', 'coursesByCategorie']),
    sortedCategories() {
      return _.sortBy(this.categories ?? [], ['position', 'id']);
    },
  },
  data() {
    return {
      repeaterId: '',
    };
  },
  methods: {
    ...mapActions('courses', ['setCoursesByCategories']),
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },
    sendCategorie(categorie) {
      this.setCoursesByCategories(categorie);
      this.$router.push({ name: 'CoursesByCategorie', params: { categoryId: categorie.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.recommended {
  margin: 0;
}
</style>
