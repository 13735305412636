<!-- eslint-disable max-len -->
<template>
  <TemplateAdmin :loading="loading">
    <template slot="container">
      <div class="content-page" :style="{ '--section-title-color': this.tenantConfig.primary_color }">
        <BannerAward />

        <h2 class="section-title">Ao todo, teremos 3 premiações, sendo elas:</h2>

        <CardVoucher
          v-for="card in CardVoucherArray"
          :key="card.id"
          :title="card.title"
          :description="card.description"
        />

        <CardStepByStep :steps="cardStepByStepArray" :title="'Confira o passo a passo para  participar:'"
          :textLink="'Confira o regulamento aqui'" :url="'https://pdc-construcao.com.br/termos-uso'"
        />

        <h2 class="section-title">Lembre-se, para participar será necessário dar o aceite!</h2>

        <CardAward style="padding: 2rem;">
          <input type="text" class="field" placeholder="Digite seu CPF aqui..." name="cpf" />
            <div class="terms">
              <div>
                <input type="checkbox" name="terms" id="terms" />
              </div>
              Eu concordo com os termos da campanha e em receber comunicações do Parceiro da Construção através do e-mail e telefone cadastrados.*
            </div>
          <button>Quero ganhar prêmios</button>
        </CardAward>

          <div class="personal-content">

          <div class="slider-header">
            <h3 class="slider-section-title">Confira os depoimentos de profissionais já premiados</h3>
            <div class="slider-controls">
              <img :class="swiperPreviousBtn" src="@/assets/icons/card/chevron-right.svg" alt="" />
              <img src="@/assets/icons/card/chevron-right.svg" :class="swiperNextBtn"  alt="" />
            </div>
          </div>

            <Swiper :ref="`mySwiperFinished2`" :options="swiperOptionCollection">
                <SwiperSlide v-for="(video) in this.videosArray" :key="`comments-${video.id}`">
                  <div class="video-container">
                    <VimeoPlayer @loadComponent="playerReady = false" @beforeNextAd="playerReady = false"
                    @loaded="playerReady = true" @destroy="playerReady = false" @created="playerReady = true"
                    v-show="playerReady" :id="Number(video.introVideo)" class="playerIntro sm-order-0"
                    :options="{ autoplay: false }" />
                  </div>
                </SwiperSlide>

            </Swiper>
          </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CardVoucher from '@/components/organisms/CardVoucher/CardVoucher.vue';
import VimeoPlayer from '@/components/atoms/VimeoPlayer/VimeoPlayer.vue';
import BannerAward from '@/components/atoms/BannerAward/BannerAward.vue';
import CardStepByStep from '@/components/atoms/CardStepByStep/CardStepByStep.vue';
import CardAward from '@/components/atoms/CardAward/CardAward.vue';

export default {
  name: 'AwardCampaing',
  components: {
    TemplateAdmin,
    BannerAward,
    CardVoucher,
    CardStepByStep,
    CardAward,
    VimeoPlayer,

  },
  created() {
    this.getRankingCourses();
    this.getRecentCourses();
    this.getCoursesOngoing();
    this.getCoursesFinished();
    this.getCourseCategories();
    this.getPdcTalksCategories();
    this.getMyCertificates();
  },
  data() {
    return {
      playerReady: false,
      loading: false,
      isTabActive: 0,
      videosArray: [
        {
          id: 1,
          introVideo: '814958062',
        },
        {
          id: 2,
          introVideo: '814949434',
        },
        {
          id: 3,
          introVideo: '814950296',
        },
        {
          id: 4,
          introVideo: '814951171',
        },

      ],
      CardVoucherArray: [
        {
          id: 1,
          title: 'Voucher de R$ 650,00 em vale-presente a sua escolha',
          // eslint-disable-next-line max-len
          description: 'Para a pessoa que mais concluir cursos no mês de abril (mínimo 20 cursos),portanto em primeira posição, e preencher o Perfil Profissional.',
        },
        {
          id: 2,
          title: 'Voucher de R$ 650,00 em vale-presente a sua escolha',
          // eslint-disable-next-line max-len
          description: 'Para a pessoa que mais concluir cursos no mês de abril (mínimo 20 cursos),portanto em primeira posição, e preencher o Perfil Profissional.',
        },
        {
          id: 3,
          title: 'Voucher de R$ 650,00 em vale-presente a sua escolha',
          // eslint-disable-next-line max-len
          description: 'Para a pessoa que mais concluir cursos no mês de abril (mínimo 20 cursos),portanto em primeira posição, e preencher o Perfil Profissional.',
        },
      ],

      cardStepByStepArray: [
        'Quantos mais cursos fizer (no mínimo 20 cursos), entre 10 de abril de 2024 a 30 de abril de 2024, mais chances tem de ganhar;',
        // eslint-disable-next-line max-len
        'É importante que, além de concluir cursos e emitir os certificados em nossa plataforma, você preencha todos os campos da área de Perfil Profissional até 30 de abril;',
        // eslint-disable-next-line max-len
        'Você será avisado(a) sobre o resultado final até dia 5 de maio. Fique de olho na área de Ranking da Campanha, na plataforma, para conferir sua posição',
      ],
    };
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner', 'recommended_collections']),
    ...mapGetters('application', ['loading_app', 'tenantConfig', 'loadingTenantConfig']),
    swiperNextBtn() {
      // eslint-disable-next-line no-underscore-dangle
      return `swiper-button-next-${this._uid}`;
    },
    swiperPreviousBtn() {
      // eslint-disable-next-line no-underscore-dangle
      return `swiper-button-prev-${this._uid}`;
    },
    swiperOptionCollection() {
      return {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: false,
        loop: false,
        grabCursor: false,
        transparent: true,
        navigation: {
          prevEl: `.${this.swiperPreviousBtn}`,
          nextEl: `.${this.swiperNextBtn}`,
        },
        breakpoints: {
          767: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      };
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import 'AwardCampaing.scss';
</style>
