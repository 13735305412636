<template>
  <div class="cardDetails">
    <div class="container_card">
      <img :src="(course.primaryImage || 'https://courses-stg.s3.us-east-2.amazonaws.com/default-course-image.svg')"
        class="card_image" />
      <div class="card__header">
        <div class="card__img">

        </div>
      </div>
      <div class="card__content">

        <div class="card__points" :style="`background-color: ${backgroundPoint};`">
          <p class="card__points-value" :style="`color: ${colorPoint}`">
            +{{ course.points ? course.points : 0 }} pontos
          </p>
        </div>
        <RatingDisplay :rating="Number(course.score ? course.score : 0)" :ratingAmount="course.qtyScores"
          class="rating-display" />

        <div class="card__headerContent">
          <p class="card__title">{{ course.title }}</p>
          <p class="card__description" v-html="course.smallDescription" />
        </div>
        <div class="card__footer">
          <div class="card__specs">
            <div class="full_spec_container">
              <div class="spec__container">
                <RatingDisplay :rating="Number(course.score ? course.score : 0)" :ratingAmount="course.qtyScores"
                  id="card-rating" />
                <div class="card__specs-item">
                  <div class="card__specs-item-icon">
                    <img src="@/assets/icons/card/graduation-red.svg" alt="" />
                  </div>
                  <div class="card__specs-item-text dark">Nível {{ $courseDifficulty(course) }}</div>
                </div>
                <div class="card__specs-item">
                  <div class="card__specs-item-icon">
                    <img src="@/assets/icons/card/clock-red.svg" alt="" />
                  </div>
                  <div class="card__specs-item-text dark">
                    {{ course_time }}
                  </div>
                </div>
              </div>
              <LikeComponent entity="course" :entity_id="course.id" mode="counter"
                :is_liked="course.isLike ? true : false" :qty_likes="course.qtyLikes ? course.qtyLikes : 0" />
            </div>

          </div>
          <div class="button_container">
            <PdcButton @click.native="handleClickItem" class="pdc-details-button" variant="primary"
              v-if="!course.approved && !course.started" :buttonColor="this.tenantConfig.primary_color">
              <span>
                Iniciar curso
              </span>
            </PdcButton>
            <PdcButton @click.native="handleClickItem" class="pdc-details-button" variant="primary" outlined
              v-if="!course.approved && course.started && !course.completed">
              <span>Continuar curso</span>
            </PdcButton>
            <PdcButton variant="quaternary" class="pdc-details-button" @click.native="handleClickExam"
              v-if="!course.approved && course.started && course.completed"
              :buttonColor="this.tenantConfig.tertiary_color">
              Fazer exame
            </PdcButton>
            <PdcButton
              @click.native="$router.push({name: 'Certificate' , params: {course: course.path}})"
              variant="primary" class="pdc-details-button-certificate" v-if="course.approved">
              Certificado
            </PdcButton>

            <PdcButton @click.native="handleClickItem" class="pdc-details-button-certificate" variant="outlined"
              v-if="course.approved">
              Refazer
            </PdcButton>
            <LikeComponent id="like-button" entity="course" :entity_id="course.id" mode="button"
              :is_liked="course.isLike ? true : false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './CourseCardDetails.scss';
import PdcButton from '@/components/atoms/Button/Button.vue';
import { clickedCourse } from '@/services/dataLayer.service';
import LikeComponent from '@/components/atoms/Likes/LikeComponent.vue';
import { mapGetters } from 'vuex';
import RatingDisplay from '../../RatingDisplay/RatingDisplay.vue';

export default {
  props: {
    course: Object,
    section: {
      type: String,
      default: () => 'normal',
      required: false,
    },
  },
  components: {
    PdcButton,
    LikeComponent,
    RatingDisplay,
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
    course_time() {
      const courseTimeFromBack = this.course?.courseTime;
      if (courseTimeFromBack && courseTimeFromBack !== '0') {
        const [hours, minutes] = this.course?.courseTime.split('.');
        const courseTime = this.course?.courseTime ? `${hours}:${minutes}` : '1';
        if (minutes === '0') return `${hours}h`;
        return `${courseTime}h`;
      } return '1h';
    },
    colorPoint() {
      if (this.course.points >= 200 && this.course.points < 300) {
        return '#6c6c6c';
      }
      return '#FFF';
    },
    backgroundPoint() {
      if (this.course.points >= 500) return '#845EF7';
      if (this.course.points >= 400) return '#FF345D';
      if (this.course.points >= 300) return '#087F5B';
      if (this.course.points >= 200) return '#FFF53D';
      if (this.course.points >= 100) return '#FD7E14';
      if (this.course.points >= 50) return '#F741B6';
      return '#087F5B';
    },
  },
  methods: {
    handleClickItem() {
      if (window.dataLayer) {
        clickedCourse(window.dataLayer, this.course, this.section, 'trail');
      }
      this.$router.push({ name: 'CourseDetail', params: { course: this.course.path } });
    },
    handleClickExam() {
      if (window.dataLayer) {
        clickedCourse(window.dataLayer, this.course, this.section, 'trail');
      }
      this.$router.push({ name: 'CourseExame', params: { course: this.course.path } });
    },
  },
};
</script>
