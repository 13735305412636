import Vue from 'vue';
import VueGtm from 'vue-gtm';
import router from '../router';

const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'homolog';

Vue.use(VueGtm, {
  id: 'GTM-5S3C24S',
  enabled: !isDev,
  vueRouter: router,
});
