<template>
  <el-button :class="`btn btn-default btn-${variant} ${outlined && 'btn-outlined'}`" :loading="loading" @click="clicked"
    :disabled="disabled" :style="{ '--btn-color': buttonColor }">
    <slot />
  </el-button>
</template>

<script>
import { Button } from 'element-ui';
import './Button.scss';

export default {
  props: {
    variant: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonColor: {
      type: String,
      default: '#5D4A99',
    },
  },
  components: {
    'el-button': Button,
  },
  methods: {
    clicked() {
      this.$emit('click');
    },
  },
};
</script>
