import Vue from 'vue';

import * as CommentService from '@/services/comment.service';
import { concat } from 'lodash';
// eslint-disable-next-line import/no-cycle

const setAllCommentsType = (state, payload) => {
  // eslint-disable-next-line
  const { waiting, approved, neutral, question } = payload;

  Vue.set(state, 'all', concat([], waiting, approved, neutral, question));
  Vue.set(state, 'approved', approved);
  Vue.set(state, 'neutral', neutral);
  Vue.set(state, 'question', question);
  Vue.set(state, 'waiting', waiting);
};

const getInitialState = () => ({
  comments: {},
  reply_comments: {},
  comments_by_user: {},
  reply_comments_by_user: {},
  comments_loading: true,
  reply_comments_loading: false,
  comments_with_open_replies: null,

});

const getters = {

  comments: (state) => state.comments,
  comments_by_user: (state) => state.comments_by_user,
  reply_comments: (state) => state.reply_comments,
  reply_comments_by_user: (state) => state.reply_comments_by_user,
  comments_loading: (state) => state.comments_loading,
  reply_comments_loading: (state) => state.reply_comments_loading,
  comments_with_open_replies: (state) => state.comments_with_open_replies,

};

const mutations = {

  SET_COMMENTS(state, payload) {
    setAllCommentsType(state.comments, payload);
  },
  SET_COMMENTS_BY_USER(state, payload) {
    setAllCommentsType(state.comments_by_user, payload);
  },
  SET_REPLY_COMMENTS(state, payload) {
    setAllCommentsType(state.reply_comments, payload);
  },
  SET_REPLY_COMMENTS_BY_USER(state, payload) {
    setAllCommentsType(state.reply_comments_by_user, payload);
  },
  SET_WAITING_COMMENTS(state, payload) {
    Vue.set(state.comments, 'waiting', payload);
  },
  SET_COMMENTS_LOADING(state, loading) {
    state.comments_loading = loading;
  },
  SET_REPLY_COMMENTS_LOADING(state, loading) {
    state.reply_comments_loading = loading;
  },
  SET_INITIAL_COMMENTS(state, payload) {
    state.inital_comments = payload;
  },
  SET_INITIAL_COMMENTS_BY_USER(state, payload) {
    state.inital_comments_by_user = payload;
  },
  SET_COMMENT_WITH_OPEN_REPLIES(state, payload) {
    state.comments_with_open_replies = payload;
  },

};

const actions = {

  async fetchComments({ commit }, payload) {
    await commit('SET_COMMENTS_LOADING', true);
    const comments = await CommentService.getCommentByEntityandId(payload.entity, payload.entityId);
    commit('SET_COMMENTS', comments);
    commit('SET_COMMENTS_LOADING', false);
  },

  async fetchReplyComments({ commit }, payload) {
    await commit('SET_REPLY_COMMENTS_LOADING', true);
    const comments = await CommentService.getCommentByEntityandId(payload.entity, payload.entityId);
    comments.approved.sort((a, b) => a.id - b.id);
    commit('SET_REPLY_COMMENTS', comments);

    await commit('SET_REPLY_COMMENTS_LOADING', false);
  },

  async fetchReplyCommentsByUser({ commit }, payload) {
    await commit('SET_REPLY_COMMENTS_LOADING', true);
    const comments = await CommentService.getCommentByEntityIdandUser(payload.entity, payload.entityId);
    comments.waiting.sort((a, b) => a.id - b.id);
    commit('SET_REPLY_COMMENTS_BY_USER', comments);
    commit('SET_REPLY_COMMENTS_LOADING', false);
  },

  async fetchCommentsByUser({ commit }, payload) {
    await commit('SET_COMMENTS_LOADING', true);
    const commentsByUser = await CommentService.getCommentByEntityIdandUser(payload.entity, payload.entityId);
    commit('SET_COMMENTS_BY_USER', commentsByUser);
    commit('SET_COMMENTS_LOADING', false);
  },

  async createComments({ commit, dispatch }, payload) {
    await commit('SET_COMMENTS_LOADING', true);
    CommentService.createComment(payload);
    const comments = await CommentService.getCommentByEntityandId(payload.entity, payload.entityId);
    commit('SET_COMMENTS', comments);
    commit('SET_COMMENTS_LOADING', false);
    await dispatch('fetchCommentsByUser', payload);
    await dispatch('fetchComments', payload);
  },

  async createReplyComments({ commit, dispatch }, payload) {
    await commit('SET_COMMENTS_LOADING', true);
    CommentService.createComment(payload);
    await CommentService.getCommentByEntityandId(payload.entity, payload.entityId);
    commit('SET_COMMENTS_LOADING', false);
    await dispatch('fetchReplyCommentsByUser', payload);
    await dispatch('fetchReplyComments', payload);
  },

  async deleteComment({ dispatch }, payload) {
    await CommentService.deleteComment(payload.entity, payload.entityId, payload.commentId);
    await dispatch('fetchCommentsByUser', payload);
    await dispatch('fetchComments', payload);
  },

  async deleteReplyComment({ dispatch }, payload) {
    await CommentService.deleteComment(payload.entity, payload.entityId, payload.commentId);
    await dispatch('fetchReplyCommentsByUser', payload);
    await dispatch('fetchReplyComments', payload);
  },

  toggleCommentsWithOpenReplies({ commit, state }, payload) {
    if (state.comments_with_open_replies === payload) {
      commit('SET_COMMENT_WITH_OPEN_REPLIES', null);
    } else {
      commit('SET_COMMENT_WITH_OPEN_REPLIES', payload);
    }
  },

  async pushComments({ commit, state }, payload) {
    const getComments = [...state.comments];

    getComments.push(payload);

    commit('SET_COMMENTS', getComments);
  },

};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
