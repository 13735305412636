/* eslint-disable import/prefer-default-export */
import socialHttp from '../utils/social.http';

const getRating = async ({ entity, entityId }) => (
  socialHttp.get(`/rating/${entity}/${entityId}/user`)
);

const setRating = async (payload) => (
  socialHttp.put('/rating', payload)
);

export { getRating, setRating };
