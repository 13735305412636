<template>
  <TemplateAdmin :breakpoints="{ small: 1200 }" :loading="isLoading" :key="$route.path">
    <template slot="topbar-title">
      <a class="link-back back-button-courses" @click.prevent="$router.push({name: 'Courses' })">
        <span class="icon arrow" />
        <span class="text"> {{ link_back_text }} </span>
      </a>
    </template>
    <template slot="sidebar-container">
      <CourseSideBar :current_lesson_id="current_lesson_id" />
    </template>
    <template slot="container">
      <CourseDetailSkeleton v-if="isLoading" />
      <template v-else>
        <template>
          <div class="sm-flex-col">
            <RatingDialog :showDialog="!!current_lesson && current_lesson.finished && !isLoadingRating && !has_rating && isLastLesson"
              entity="course_topic" :entityId="current_lesson_id" :goToNextLesson="goToNextLesson" />

            <CourseTitle :title="current_lesson ? `Aula ${current_lesson_index}` : 'Introdução'"
              :description="current_lesson ? current_lesson.title : course.title" class="sm-order-1 sm-mt-32 sm-flex-col">
              <div class="display-flex align-items-center">
                <CourseButton @click="backLessonOptions($event)" :text_button="'Voltar aula'" :next_dialog="'backclass'"
                  :loading="courseButtonLoading" :back_icon="true" class="mr-8" v-if="current_lesson_index > 0" :disabled="courseNextLessonLoading" />

                  <CourseButton @click="nextLessonOptions($event)" :text_button="text_button" :next_dialog="dialog_button"
                  :loading="courseButtonLoading" :disabled="courseNextLessonLoading" />

                  <CourseButton @click="handleNavigateToExame" :text_button="'Refazer exame'"
                  :loading="courseButtonLoading" :next_dialog="'exame'" class="ml-8" v-if="remakeExame" :disabled="courseNextLessonLoading" />
              </div>

            </CourseTitle>
            <div v-if="(course && course.introVideo) || (current_lesson && current_lesson.video)">
              <VueSkeletonLoader class="player-load sm-order-0" v-if="!playerReady" />
              <transition name="expandVertical">
                <div class="lesson-finish" v-show="playerReady"
                  v-if="(current_lesson && current_lesson.finished) || (!current_lesson && introdutionCompleted)">
                  <i class="icon-checked" aria-hidden="true" />Aula concluída
                </div>
              </transition>
              <VimeoPlayer @loadComponent="playerReady = false" @beforeNextAd="playerReady = false"
                @loaded="playerReady = true" @destroy="playerReady = false" @created="createVideoPlayer"
                @timeupdate="onTimeUpdate" @onPlay="onPlay" @adShow="adShow" @clickAd="clickAd" @skipAd="skipAd"
                v-show="playerReady" :id="current_lesson ? current_lesson.video : course.introVideo"
                :topic_ads="topic_ads" class="playerIntro sm-order-0" />
            </div>
            <img v-else src="@/assets/illustrations/good-studies.svg" />
            <AboutSession class="sm-order-2" :routeIsIntro="!current_lesson"
              :description="current_lesson ? current_lesson.description : course.description" :topic="current_lesson" />
            <CommentSession class="sm-order-2" :isIntro="!!current_lesson" :currentTopic="current_lesson_id"
              @insertComment="insertComment" @likeComment="likeComment" :current_lesson_title="this.current_lesson?.title"
              :course="this.course" />
          </div>
        </template>
      </template>
    </template>
  </TemplateAdmin>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';
import { mapActions, mapGetters } from 'vuex';
import CourseSideBar from '@/components/organisms/Course/CourseSidebar/CourseSidebar.vue';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CourseDetailSkeleton from '@/components/organisms/Skeletons/CourseDetailSkeleton.vue';
import CourseTitle from '@/components/organisms/Course/CourseTitle/CourseTitle.vue';
import CourseButton from '@/components/organisms/Course/CourseTitle/CourseButton.vue';
import AboutSession from '@/components/organisms/Course/AboutSession/AboutSession.vue';
import RatingDialog from '@/components/organisms/RatingDialog/RatingDialog.vue';

import CommentSession from '@/components/organisms/Course/CommentSession/CommentSection.vue';
import VimeoPlayer from '@/components/atoms/VimeoPlayer/VimeoPlayer.vue';
import './CourseDetails.scss';

import {
  finishLesson, initiateLesson, showAd, clickAd, skipAd, insertComment, likeComment,
} from '@/services/dataLayer.service';

export default {
  name: 'CourseDetails',
  components: {
    TemplateAdmin,
    CourseSideBar,
    CourseDetailSkeleton,
    CourseTitle,
    CourseButton,
    VueSkeletonLoader,
    AboutSession,
    VimeoPlayer,
    CommentSession,
    RatingDialog,
  },
  data() {
    return {
      routeIsIntro: true,
      topic: null,
      playerReady: false,
      courseButtonLoading: false,
      courseNextLessonLoading: false,
      video_error: false,
      firstPlay: false,
      screen_width: window.innerWidth,
    };
  },
  created() {
    this.fetchCommentsByUser({ entity: 'course_topic', entityId: this.current_lesson_id });
    this.fetchComments({ entity: 'course_topic', entityId: this.current_lesson_id });
    this.getUserEntityRating({ entity: 'course_topic', entityId: this.current_lesson_id });

    document.title = `${this.course.title} - ${window.DEFAULT_TITLE}`;
    this.$nextTick(() => {
      if (this.$route.params.lesson) this.routeIsIntro = false;
      this.fetchCourse(this.$route.params.course).then(() => {
        if (this.course.currentTopic.id && this.course_all_lessons[0].finished) {
          this.$router.push({
            name: 'CourseDetailLesson',
            params: {
              course: this.course.path,
              module: this.course?.currentTopic?.courseLesson?.id,
              lesson: this.course.currentTopic?.id,
            },
          });
        }
        this.checkCurrentLessonAndFinish();
      });
    });

    window.addEventListener('resize', () => {
      this.screen_width = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters('application', ['loading_app']),
    ...mapGetters('comment', ['comments', 'comments_by_user']),
    ...mapGetters('courses', [
      'ads',
      'course_loading',
      'course',
      'course_all_lessons',
      'course_evaluated',
    ]),
    ...mapGetters('auth', ['account']),
    ...mapGetters('ratings', ['userEntityRating', 'isLoadingRating']),
    current_lesson_id() {
      return Number(this.$route.params.lesson) || null;
    },
    currentLessonIsIntroduction() {
      return this.course_all_lessons[0]?.id === this.course.currentTopic?.id;
    },
    isLoading() {
      return this.course_loading || this.loading_app;
    },
    introdutionCompleted() {
      return this.course_all_lessons.filter((lesson) => lesson.finished).length > 0;
    },
    current_lesson() {
      if (!this.current_lesson_id) {
        return null;
      }
      return this.course_all_lessons.find((lesson) => Number(lesson.id) === Number(this.current_lesson_id));
    },
    current_lesson_index() {
      if (!this.current_lesson_id) {
        return null;
      }
      return this.course_all_lessons.findIndex((lesson) => Number(lesson.id) === Number(this.current_lesson_id)) + 1;
    },
    text_button() {
      if (this.course.approved && this.isLastLesson) return 'Visualizar Certificado';
      if (this.toDoExame) return 'Fazer exame';
      if (this.course.results && this.isLastLesson) return 'Refazer exame';
      return 'Próxima aula';
    },
    toDoExame() {
      return this.course.completed && !this.course.results && this.isLastLesson;
    },
    remakeExame() {
      return this.course.completed && this.course.results && !this.course.results.approved && !this.isLastLesson;
    },
    dialog_button() {
      if (this.course.approved && this.isLastLesson) return 'certificated';
      if (this.course.completed && !this.course.results && this.isLastLesson) return 'exame';
      if (this.course.results && this.isLastLesson) return 'exame';
      if (!this.current_lesson_id) return 'introduction';
      if ((this.current_lesson && this.current_lesson.finished) || (!this.current_lesson && this.introdutionCompleted)) return 'next_only';
      return 'next';
    },
    topic_ads() {
      const topicAds = this.ads.filter((ad) => ad.topic_id === Number(this.$route.params.lesson));

      return topicAds
        .reduce(
          (adsByPosition, ad) => {
            const adInsertPosition = ad.advertising_insert_position;
            const adsAtPosition = adsByPosition[adInsertPosition] || [];

            adsAtPosition.push(ad);

            return { ...adsByPosition, [adInsertPosition]: adsAtPosition };
          },
          {},
        );
    },
    has_rating() {
      return this.course_evaluated !== null;
    },
    link_back_text() {
      return this.screen_width <= 600 ? 'Voltar' : 'Voltar para os cursos';
    },
    isLastLesson() {
      if (this.course_all_lessons.length && this.current_lesson) {
        return this.course_all_lessons[this.course.totalLessons - 1].id === this.current_lesson.id;
      }
      return false;
    },
  },
  watch: {
    current_lesson(prevValue, nextValue) {
      this.firstPlay = false;
      this.fetchComments({ entity: 'course_topic', entityId: this.current_lesson_id });
      this.fetchCommentsByUser({ entity: 'course_topic', entityId: this.current_lesson_id });

      this.getUserEntityRating({ entity: 'course_topic', entityId: this.current_lesson_id });

      if (prevValue !== nextValue) {
        this.courseNextLessonLoading = false;
      }
    },
    screen_width() {
      this.emitValue();
    },
  },
  methods: {
    ...mapActions('application', ['openLink', 'setLoadingApp', 'appRouterPush']),
    ...mapActions('comment', ['fetchComments', 'createComments', 'deleteComment', 'fetchCommentsByUser']),
    ...mapActions('courses', [
      'fetchCourse',
      'onTimeUpdate',
      'onTopicView',
      'goToFirstTopic',
    ]),
    ...mapActions('ratings', ['getUserEntityRating']),
    ...mapActions('player', ['setPlayerCurrentLesson', 'setPlayerNextLesson']),

    createVideoPlayer() {
      if (this.course.id) {
        this.playerReady = true;
      }

      if (this.current_lesson_index === null) {
        this.setPlayerCurrentLesson({});
        this.setPlayerNextLesson(this.course_all_lessons[0]);
      } else {
        this.setPlayerCurrentLesson(this.current_lesson);
        this.setPlayerNextLesson(this.course_all_lessons[this.current_lesson_index]);
      }
    },
    deleteComments() {
      this.deleteComment({ entity: 'course_topic', entityId: this.current_lesson_id });
    },
    checkCurrentLessonAndFinish() {
      if (this.currentLessonIsIntroduction && !this.routeIsIntro) {
        return this.appRouterPush({ name: 'CourseDetail', params: { course: this.course.path } });
      }
      return this.setLoadingApp(false);
    },
    // simula um processamento para transição de página ser mais sutíl
    goToFirstTopicHandle() {
      this.courseButtonLoading = true;
      setTimeout(() => {
        this.goToFirstTopic().then(() => {
          this.courseButtonLoading = false;
        });
      }, 500);
    },
    onTopicViewHandle(id) {
      this.courseButtonLoading = true;
      this.onTopicView(Number(id)).then(() => {
        this.courseButtonLoading = false;
        finishLesson(window.dataLayer, this.course, this.current_lesson, this.account);
        this.current_lesson.finished = true;
        this.goToNextLesson();
      });
    },
    onPlay() {
      if (!this.firstPlay && this.current_lesson) {
        initiateLesson(window.dataLayer, this.course, this.current_lesson, this.account);
        this.firstPlay = true;
      }
    },
    adShow(adToBeShown) {
      showAd(window.dataLayer, this.course, this.current_lesson, adToBeShown, this.account);
    },
    clickAd(ad) {
      clickAd(window.dataLayer, this.course, this.current_lesson, ad, this.account);
    },
    skipAd(ad) {
      skipAd(window.dataLayer, this.course, this.current_lesson, ad, this.account);
    },
    insertComment(comment, isReply) {
      insertComment(window.dataLayer, this.course, this.current_lesson.title, comment, isReply);
    },
    likeComment(comment, commentLength) {
      likeComment(window.dataLayer, this.course, this.current_lesson.title, comment, commentLength);
    },
    handleNavigateToExame() {
      this.$router.push({
        name: 'CourseExame',
        params: {
          course: this.$route.params.course,
        },
      });
    },
    async backLessonOptions() {
      return this.goToBackLesson();
    },
    async nextLessonOptions($event) {
      if (this.dialog_button === 'introduction') {
        return this.goToFirstTopicHandle();
      }
      if (this.dialog_button === 'next') {
        if (this.current_lesson) {
          this.onTopicViewHandle(this.current_lesson_id).then(() => this.goToNextLesson());
        }
        this.goToNextLesson();
      }
      if (this.dialog_button === 'dialog') {
        if ($event) {
          if (this.current_lesson) {
            await this.onTopicViewHandle(this.current_lesson_id);

            if (!this.current_lesson_id) {
              return this.goToNextLesson();
            }

            return undefined;
          }
          return this.goToFirstTopicHandle();
        }
        return this.goToNextLesson();
      }
      if (this.dialog_button === 'next_only') {
        if (!this.$route.params.lesson) {
          return this.goToFirstTopicHandle();
        }
        if (this.current_lesson) {
          this.onTopicViewHandle(this.current_lesson_id).then(() => this.goToNextLesson());
        } else {
          return this.goToNextLesson();
        }
      }
      if (this.dialog_button === 'exame') {
        this.handleNavigateToExame();
        return null;
      }
      if (this.dialog_button === 'certificated') {
        this.handleNavigateToExame();
        return null;
      }
      return null;
    },
    goToNextLesson() {
      const nextLesson = this.course_all_lessons[this.current_lesson_index];

      if (nextLesson) {
        this.courseNextLessonLoading = true;
        this.$router.push({
          name: 'CourseDetailLesson',
          params: {
            course: this.$route.params.course,
            module: nextLesson.courseLesson.id,
            lesson: nextLesson.id,
          },
        });
      }
    },
    goToBackLesson() {
      const coursePath = this.$route.params.course;
      const currentLesson = this.current_lesson_index - 1;
      const backLesson = this.course_all_lessons[currentLesson - 1];
      if (this.current_lesson_index < 2) {
        this.$router.push({ name: 'CourseDetail', params: { couse: coursePath } });
      } else if (backLesson) {
        this.$router.push({
          name: 'CourseDetailLesson',
          params: {
            course: coursePath,
            module: backLesson.courseLesson.id,
            lesson: backLesson.id,
          },
        });
      }
    },
  },
};
</script>
