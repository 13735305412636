<template>
  <div class="not-found-skeleton">
    <VueSkeletonLoader class="not-found-skeleton--item-image" />
    <VueSkeletonLoader class="not-found-skeleton--item-text" />
    <VueSkeletonLoader class="not-found-skeleton--item-description" />
    <VueSkeletonLoader class="not-found-skeleton--item-btn" />
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'NotFoundSkeleton',
  components: { VueSkeletonLoader },
};
</script>
<style lang="scss">
.not-found-skeleton {
  padding: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .not-found-skeleton--item-image {
    width: 50% !important;
    height: 450px !important;
    @media screen and (max-width: 900px) {
      width: 80% !important;
    }
  }
  .not-found-skeleton--item-text {
    margin-top: 16px;
    width: 20% !important;
    height: 30px !important;
  }
  .not-found-skeleton--item-description {
    margin-top: 16px;
    width: 30% !important;
    height: 50px !important;
  }
  .not-found-skeleton--item-btn {
    margin-top: 16px;
    width: 10% !important;
    height: 70px !important;
  }
}
</style>
