<template>
  <div class="flex-row">

    <PdcButton @click.native="goTo(course.path)" variant="primary"
      v-if="!course.approved && !course.started" :buttonColor="this.tenantConfig.primary_color" class="btn-course">
      <span>Iniciar curso</span>
    </PdcButton>
    <PdcButton @click.native="goTo(course.path)" variant="primary" outlined
      v-if="!course.approved && course.started && !course.completed" class="btn-course">
      <span>Continuar curso</span>
    </PdcButton>
    <PdcButton variant="quaternary"
      @click.native="$router.push({ name: 'CourseExame', params: { course: course.path } })"
      v-if="!course.approved && course.started && course.completed" :buttonColor="this.tenantConfig.tertiary_color" class="btn-course">
      Fazer exame
    </PdcButton>
    <PdcButton @click.native="goTo(course.path)" variant="tertiary"
      v-if="course.approved" class="btn-course">
      Curso Finalizado
    </PdcButton>
    <LikeComponent class="w-30 pl-8" entity="course" :entity_id="course.id" mode="button"
      :is_liked="course.isLike ? true : false" />
  </div>
</template>
<script>
import PdcButton from '@/components/atoms/Button/Button.vue';
import LikeComponent from '@/components/atoms/Likes/LikeComponent.vue';
import { mapGetters } from 'vuex';

export default {
  props: ['course'],
  components: {
    PdcButton,
    LikeComponent,
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
  },
  methods: {
    goTo(path) {
      this.$router.push({ name: 'CourseDetail', params: { course: path } });
    },
  },

};
</script>
