<template>
  <div class="step-content">
    <div class="flex-col course-exame-step-2 mb-80">
      <h1 class="course-exame-step-2--title mt-56 mb-0">Questão {{ index + 1 }}</h1>
      <h3 class="course-exame-step-2--description mt-24" v-html="question.title" />
      <div class="course-exame-step-2--subdescription mt-24 mb-8">Selecione apenas uma alternativa</div>
      <div class="flex-col course-exame-step-2--question">
        <template v-for="(op, i) in question.courseAlternatives">
          <Radio :class="`course-exame-step-2--input-section py-24 px-16 ${getClassItem(op)}`" :key="i" v-model="answer"
            :option_name="questionKey" :option_value="makeAlternativeId(op.id)" :disabled="isReview">
            <b class="sm-ml-8 md-ml-16 lg-ml-40 mr-8 course-exame-step-2--input-alternative"> {{ getAlternativeLetter(i)
            }}) </b>
            <span class="course-exame-step-2--input-alternative-text" v-html="sanitizeTags(op.label)" />
          </Radio>
        </template>
      </div>
      <div class="w-100 flex-row mt-64 justify-content-between mt-112 fixed-footer-exam">
        <div class="w-100 flex-row">
          <PdcButton class="md-w-40 lg-w-30 sm-mr-16" :variant="current_step === stepEnableBackButton ? 'dark' : 'light'"
            :disabled="current_step === stepEnableBackButton" @click.native="backQuestion">
            Voltar questão
          </PdcButton>
          <PdcButton class="ml-auto  md-w-40 lg-w-30" variant="primary" @click.native="nextStep" :disabled="!canNext"
            :loading="next_loading">
            <span v-if="isReview && index === totalQuestions - 1">Finalizar revisão <i
                class="icon-circle-check"></i></span>
            <span v-else>Próxima</span>
          </PdcButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './CourseExameStepQuestion.scss';
import { mapActions, mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import Radio from '@/components/atoms/Input/Radio.vue';

export default {
  name: 'CourseExameStepQuestion',
  props: {
    handleNextStep: Function,
    handlePreviousStep: Function,
    question: Object,
    course: Object,
    index: Number,
    isReview: Boolean,
    totalQuestions: Number,
  },
  components: {
    Radio,
    PdcButton,
  },
  data() {
    return {
      answer: null,
      alternative_prefix: 'alternative_id_',
      next_loading: false,
    };
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('exame', ['current_step', 'answers']),
    canNext() {
      return !!this.answer;
    },
    questionKey() {
      return this.makeQuestionId(this.question.id);
    },
    selectedAnswerId() {
      return this.answer ? Number(this.answer.replace(this.alternative_prefix, '')) : null;
    },
    showExameCorrectAnswer() {
      return process.env.VUE_APP_SHOW_EXAME_CORRECT_ANSWER === 'true';
    },
    stepEnableBackButton() {
      return !this.isReview ? 1 : 0;
    },
  },
  created() {
    if (this.question.answered) {
      const finded = this.question.courseAlternatives.find((x) => x.picked);
      this.answer = this.makeAlternativeId(finded.id);
    } else {
      this.answer = this.answers[this.questionKey] || null;
    }
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    ...mapActions('exame', ['setAnswer', 'sendAnswer']),
    getClassItem(option) {
      if (option.picked && this.isReview) {
        return `alternative ${option.isCorrect ? 'correctReview' : 'incorrectReview'}`;
      }
      if (!this.showExameCorrectAnswer) {
        return 'alternative';
      }
      return `alternative ${option.isCorrect ? 'correct' : ''}`;
    },
    makeQuestionId(id) {
      return `question_id_${id}`;
    },
    makeAlternativeId(id) {
      return `${this.alternative_prefix}${id}`;
    },
    getAlternativeLetter(index) {
      return String.fromCharCode(index + 65).toUpperCase();
    },
    backQuestion() {
      this.handlePreviousStep();
    },
    nextStep() {
      if (!this.answer) {
        return;
      }
      this.next_loading = true;
      if (!this.isReview) {
        this.sendAnswer({ courseQuestionId: this.question.id, courseAlternativeId: this.selectedAnswerId }).then(() => {
          this.setAnswer({ key: this.questionKey, answer: this.answer });
          this.handleNextStep();
          this.next_loading = false;
        });
      } else {
        this.setAnswer({ key: this.questionKey, answer: this.answer });
        this.handleNextStep();
        this.next_loading = false;
      }
    },
    sanitizeTags(string) {
      return string.replace(/<span.*?>|<\/span>/gi, '');
    },
  },
};
</script>
<style lang="scss" scoped>
.step-content {
  @media(max-width: 1200px) {
    padding-bottom: 125px;
  }
}
</style>
