import * as CourseService from '@/services/course.service';
import * as AccountService from '@/services/account.service';

const getters = {
  categories: (state) => state.categories,
  userjobs: (state) => state.userjobs,
  jobs: (state) => state.jobs,
  jobs_loading: (state) => state.jobs_loading,
  categories_loading: (state) => state.categories_loading,
  collections_loading: (state) => state.collections_loading,
  showed_assessment_dialog: (state) => state.showedAssessmentDialog,
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_COLLECTIONS(state, payload) {
    state.categories = payload;
  },
  SET_USERJOBS(state, payload) {
    state.userjobs = payload;
  },
  SET_JOBS(state, payload) {
    state.jobs = payload;
  },
  SET_JOBS_LOADING(state, payload) {
    state.jobs_loading = payload;
  },
  SET_CATEGORIES_LOADING(state, payload) {
    state.categories_loading = payload;
  },
  SET_COLLECTIONS_LOADING(state, payload) {
    state.collections_loading = payload;
  },
  SET_INTERESTS_LOADING(state, payload) {
    state.interests_loading = payload;
  },
  SET_SHOWED_ASSESSMENT_DIALOG(state, payload) {
    state.showedAssessmentDialog = payload;
  },
  SET_COURSE_LOADING(state, payload) {
    state.course_loading = payload;
  },
  SET_COURSE(state, payload) {
    state.course = payload;
  },
};

const actions = {
  setShowedAssessmentDialog({ commit }, payload) {
    commit('SET_SHOWED_ASSESSMENT_DIALOG', payload);
  },
  async getCourseCategories({ commit }) {
    commit('SET_CATEGORIES_LOADING', true);
    const courses = await CourseService.getCourseCategories();
    commit('SET_CATEGORIES', courses);
    commit('SET_CATEGORIES_LOADING', false);
  },
  async getCategories({ commit }) {
    commit('SET_CATEGORIES_LOADING', true);
    const courses = await CourseService.getCategories();
    commit('SET_CATEGORIES', courses);
    commit('SET_CATEGORIES_LOADING', false);
  },
  async getCourseCollections({ commit }) {
    commit('SET_COLLECTIONS_LOADING', true);
    const courses = await CourseService.getCourseCollections();
    commit('SET_COLLECTIONS', courses);
    commit('SET_COLLECTIONS_LOADING', false);
  },
  async getUserJobs({ commit }) {
    const userjobs = await AccountService.getUserJobs();
    commit('SET_USERJOBS', userjobs);
  },
  async setUserJobs(cx, payload) {
    await AccountService.setUserJobs(payload);
  },
  async getJobs({ commit }) {
    commit('SET_JOBS_LOADING', true);
    const jobs = await AccountService.getJobs();
    commit('SET_JOBS', jobs);
    commit('SET_JOBS_LOADING', false);
    commit('SET_INTERESTS_LOADING', false);
  },
  async setUserInterests(cx, payload) {
    await AccountService.setUserInterests(payload);
  },
};

const state = {
  categories: [],
  interests: [],
  jobs: [],
  userjobs: [],
  jobs_loading: true,
  interests_loading: true,
  showedAssessmentDialog: false,
  categories_loading: false,
  collections_loading: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
