<template>
  <div>
    <CoursesSkeleton v-if="route === 'Courses'" />
    <CourseDetailSkeleton v-if="['CourseDetail', 'CourseDetailLesson'].includes(route)" />
    <CourseExameSkeleton v-if="['CourseExame'].includes(route)" />
    <CourseExameResultSkeleton v-if="['ExameResult', 'Certificate'].includes(route)" />
    <NotFoundSkeleton v-if="!route" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CoursesSkeleton from './CoursesSkeleton.vue';
import CourseDetailSkeleton from './CourseDetailSkeleton.vue';
import NotFoundSkeleton from './NotFoundSkeleton.vue';
import CourseExameSkeleton from './CourseExameSkeleton.vue';
import CourseExameResultSkeleton from './CourseExameResultSkeleton.vue';

export default {
  name: 'DefaultSkeleton',
  components: {
    CoursesSkeleton,
    CourseDetailSkeleton,
    NotFoundSkeleton,
    CourseExameSkeleton,
    CourseExameResultSkeleton,
  },
  computed: {
    ...mapGetters('application', ['route']),
  },
};
</script>
<style lang="scss">
html {
  overflow: hidden;
}
</style>
