<template>
  <el-dialog
    id="add-new-experience-dialog"
    class="custom-modal-pdc"
    :visible.sync="dialog"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="modal-header">
      <h1>Criar perfil profissional</h1>
    </div>

    <form>
      <CustomInput
        type="text"
        name="name"
        id="name"
        placeholder="Carlos"
        title="Nome*"
        v-model="item.firstname"
      />

      <CustomInput
        type="text"
        name="lastname"
        id="lastname"
        placeholder="dos Santos"
        title="Sobrenome*"
        v-model="item.lastname"
      />

      <!-- <FilePreview /> -->

      <CustomSelect
        type="text"
        name="aditional_names"
        id="aditional_names"
        placeholder="Especialista em Empermeabilização"
        title="Profissão principal*"
        v-model="job.value"
        :options="professions"
      />

      <CustomInput
        type="date"
        name="birthday"
        id="birthday"
        placeholder="dd/mm/aaaa"
        title="Data de nascimento"
        v-model="item.birthday"
      />

      <CustomInput
        type="phone"
        name="aditional_names"
        id="aditional_names"
        placeholder="(11) 99999-9999"
        title="Whatsapp*"
        v-model="item.whatsapp"
      />

      <CustomInput
        type="text"
        name="name"
        title="Título*"
        placeholder=""
        v-model="item.title"
      />

      <div class="info">
        <Icon name="Info" />
        Adicione um título que melhor te identifique
      </div>

      <CustomTextArea
        name="name"
        title="Informações gerais"
        v-model="item.differentialOfYourWork"
      />

      <div class="edit-address">
        <h2>Endereço profissional</h2>
        <p>
          Configure seu endereço (residencial ou comercial) para que apareça nos
          resultados de pesquisa por profissionais.
        </p>
        <PdcButton
          class="button"
          variant="primary"
          id="button-discovery"
          @click="showAddressModal = true"
          >Adicionar
        </PdcButton>
        <p v-if="success">
          <Icon name="Approved" />
          Endereço atualizado com sucesso!
        </p>
      </div>

      <span
        slot="footer"
        class="accept-footer"
        style="display: flex; margin-top: 45px; justify-content: flex-end"
      >
        <PdcButton
          class="button"
          variant="outlined"
          id="button-discovery"
          @click="handleClose"
          >Fechar
        </PdcButton>
        <PdcButton
          class="button"
          variant="primary"
          id="button-discovery"
          @click.native="handleSubmit"
          :loading="loading_save"
          >Salvar</PdcButton
        >
      </span>
    </form>
    <AddressModal
      v-if="showAddressModal"
      @saveAddress="addressSaveParams"
      :loading_save="loadingAddress"
      :address="userAddress"
      @close="handleCloseAddress"
    />
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import CustomTextArea from '@/components/atoms/CustomTextArea/CustomTextArea.vue';
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect.vue';
// import { createAddress, updateAddress } from '@/services/address.service';
import AddressModal from '../AddressDialog/AddressModal.vue';

import './CreateProfessionalProfileDialog.scss';

export default {
  name: 'CreateProfessionalProfileDialog',
  components: {
    PdcButton,
    Icon,
    CustomInput,
    CustomTextArea,
    AddressModal,
    CustomSelect,
  },
  data: () => ({
    dialog: true,
    close: false,
    item: {
      firstname: '',
      lastname: '',
      title: '',
      extraInformation: '',
      differentialOfYourWork: '',
      whatsapp: '',
      birthday: '',
    },
    showAddressModal: false,
    success: false,
    loadingAddress: false,
    addressParams: {},
    professions: [],
    job: {
      value: '',
      label: '',
    },
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'userProfile']),
    ...mapGetters('address', ['userAddress', 'useAddressOn']),
  },
  methods: {
    ...mapActions('profile', ['createProfessionalProfile', 'saveProfessions']),
    ...mapActions('address', ['saveAddressOnProfile']),
    ...mapActions('profile', ['listProfessions']),

    handleClose() {
      this.$emit('handleClose');
    },
    handleCloseAddress() {
      this.showAddressModal = false;
    },

    addressSaveParams(event) {
      this.loadingAddress = true;
      this.addressParams = event;
      setTimeout(() => {
        this.loadingAddress = false;
        this.handleCloseAddress();
        this.success = true;
      }, 1000);
    },

    async save() {
      this.saveAddress('both');
    },
    async handleSubmit(e) {
      e.preventDefault();

      const {
        firstname,
        lastname,
        extraInformation,
        differentialOfYourWork,
        whatsapp,
        birthday,
      } = this.item;
      await this.createProfessionalProfile({
        firstname,
        lastname,
        title: this.job.value,
        extraInformation,
        differentialOfYourWork,
        whatsapp,
        birthday: birthday || null,
        accountId: Number(this.$route.params.accountId),
        docNumber: '11111111111',
        avatar: 'https://res.cloudinary.com/dzhbkamn0/image/upload/v1687226975/MicrosoftTeams-image_4_rtkgqe.jpg',
        coverImage: 'https://res.cloudinary.com/dzhbkamn0/image/upload/v1687281461/MicrosoftTeams-image_5_gjtxvr.jpg',
      }).then(async (res) => {
        this.handleClose();
        await this.saveProfessions(this.job.value);
        await this.saveAddressOnProfile({ body: this.userAddress, typeAddress: this.useAddressOn });
        await this.$nextTick();
        this.$swal.fire(
          res.title,
          res.description,
          res.type,
        );
      })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err);
        });
      this.handleClose();
    },
  },
  async mounted() {
    try {
      const data = await this.listProfessions();
      this.professions = data.list.map((item) => ({ value: item.name, label: item.name }));
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    this.success = false;
  },
};
</script>
