<template>
  <div class="question-send">
    <h1>Sugira um curso</h1>
    <p>
      Você tem algum tema de curso que não encontrou e que gostaria de ver por aqui? Preencha o
      campo abaixo com sua sugestão e nos conte o porquê ele é importante para você:
    </p>
    <form @submit.prevent="submit">
      <div>
        <label>Sua Recomendação</label>
        <TextArea
          :value="context.data_object.message"
          class="form-control"
          placeholder="Escreva quais cursos você gostaria de ver"
          :error="$v.context.data_object.message.$error"
          message="Campo obrigatório"
          @input="(e) => (context.data_object.message = e)"
        />
      </div>
      <Button type="submit" variant="primary" :loading="question_loading" :outlined="false">
        Sugerir o curso
      </Button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Button from '../../atoms/Button/Button.vue';
import TextArea from '../../atoms/TextArea/TextArea.vue';

export default {
  name: 'Step1-Question',
  props: ['context'],
  components: {
    Button,
    TextArea,
  },
  data() {
    return {
      form: null,
    };
  },
  computed: {
    ...mapGetters('questions', ['question_loading']),
  },
  validations: {
    context: {
      data_object: {
        message: { required },
      },
    },
  },
  methods: {
    ...mapActions('questions', ['onQuestionSendCourse']),
    async submit(e) {
      e.preventDefault();
      this.$v.context.data_object.$touch();
      if (this.$v.context.data_object.$error) return;
      await this.onQuestionSendCourse({ question: this.context.data_object.message });
      this.context.nextStep();
    },
  },
};
</script>
