<template>
  <TemplateAdmin :loading="loadingRanking">
    <template slot="container">
      <RankingSkeleton v-if="loadingRanking" />
      <ranking-initial v-else />
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import RankingInitial from '@/components/organisms/Ranking/RankingInitial/RankingInitial.vue';
import RankingSkeleton from '@/components/organisms/Skeletons/RankingSkeleton.vue';

export default {
  name: 'Ranking',
  components: {
    TemplateAdmin,
    RankingInitial,
    RankingSkeleton,
  },
  data() {
    return {
      homeCourses: [],
      isTabActive: 0,
    };
  },
  methods: {
    ...mapActions('ranking', ['getRanking']),

    onChangeTabValue(value) {
      this.isTabActive = value;
    },
  },
  computed: {
    ...mapGetters('ranking', ['loadingRanking']),
  },
  async created() {
    await this.getRanking('CURRENT_MONTH');
  },
};
</script>
