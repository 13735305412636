import * as professionalProfile from '@/services/matchmaking/professionalProfile.service';
import * as certificatesService from '@/services/matchmaking/certificate.service';

const getInitialState = () => ({
  userProfile: {},
  skillSelected: {},
  skills: [],
  jobExpirienceSelected: {},
  academicEducationSelected: {},
  loading_profile: true,
  loading_save: false,
  toastMessage: '',
  currentProfession: '',
});

const getters = {
  loading_profile: (state) => state.loading_profile,
  userProfile: (state) => state.userProfile,
  skills: (state) => state.skills,
  loading_save: (state) => state.loading_save,
  skillSelected: (state) => state.skillSelected,
  academicEducationSelected: (state) => state.academicEducationSelected,
  jobExpirienceSelected: (state) => state.jobExpirienceSelected,
  toastMessage: (state) => state.toastMessage,
  listProfessions: (state) => state.listProfessions,
  currentProfession: (state) => state.currentProfession,
};

const mutations = {
  SET_LOADING_PROFILE(state, payload) {
    state.loading_app = payload;
  },
  SET_PROFILE(state, payload) {
    state.userProfile = payload;
  },
  SET_PROFILE_DIFFERENCIAL_YOUR_WORK(state, payload) {
    state.userProfile.differentialOfYourWork = payload;
  },
  SET_LOADING_SAVE(state, payload) {
    state.loading_save = payload;
  },
  SET_SKILLS(state, payload) {
    state.skills = payload;
  },
  SET_PROFILE_SKILLS(state, payload) {
    state.userProfile.skills = payload;
  },
  SET_PROFILE_CERTIFICATES(state, payload) {
    state.userProfile = {
      ...state.userProfile,
      certificates: payload,
    };
  },
  SET_SKILL_SELECTED(state, payload) {
    state.skillSelected = payload;
  },
  SET_JOB_EXPIRIENCE_SELECTED(state, payload) {
    state.jobExpirienceSelected = payload;
  },
  SET_ACADEMIC_EDUCATION_SELECTED(state, payload) {
    state.academicEducationSelected = payload;
  },
  CLEAR_OBJECT(state, payload) {
    state[payload] = {};
  },
  SET_PROFILE_JOB_EXPERIENCE(state, payload) {
    state.userProfile.jobExperiences = payload;
  },
  SET_PROFILE_ACADEMIC_EDUCATION(state, payload) {
    state.userProfile = {
      ...state.userProfile,
      academicEducations: payload,
    };
  },
  SET_UPLOAD_FILE_AVATAR(state, payload) {
    state.userProfile.avatar = payload;
  },
  SET_UPLOAD_FILE_COVER(state, payload) {
    state.userProfile = {
      ...state.userProfile,
      coverImage: payload,
    };
  },
  SET_TOAST_MESSAGE(state, payload) {
    state.toastMessage = payload;
  },
  SET_CURRENT_PROFESSION(state, payload) {
    state.currentProfession = payload;
  },
};

const actions = {
  setLoadingPorfile({ commit }, payload) {
    commit('SET_LOADING_PROFILE', payload);
  },
  setProfile({ commit }, payload) {
    commit('SET_PROFILE', payload);
  },
  async listProfessions({ commit, rootState }, accountId) {
    const { AccountId } = rootState.auth.account;
    try {
      const response = await professionalProfile.getListProfessions(accountId || AccountId);
      return response.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async saveProfessions({ rootState, commit }, payload) {
    const professionalProfileId = rootState.profile.userProfile?.id;
    const accountId = rootState.profile.userProfile?.accountId;
    const params = {
      professionalProfileId,
      job: payload,
      accountId,
    };
    try {
      const response = await professionalProfile.saveUserProfessions(params);
      commit('SET_CURRENT_PROFESSION', response.job);
      return {
        type: 'success',
        title: 'Profissão salva',
        description: 'Sua profissão foi salva com sucesso!',
      };
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async getProfession({ commit, rootState }, professionalProfileId) {
    const accountId = rootState.profile.userProfile?.accountId;
    try {
      const response = await professionalProfile.getUserProfessions(accountId || professionalProfileId);
      const currentProfession = response[response.length - 1].job;
      commit('SET_CURRENT_PROFESSION', currentProfession);
      return currentProfession;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async loadProfessionalProfile({ commit, rootState }, accountId) {
    const { id } = rootState.auth.account;
    try {
      const response = await professionalProfile.getProfessionalProfile(accountId || id);
      commit('SET_PROFILE', response[0]);
      if (response[0]) {
        const skillsProfessionalProfile = await professionalProfile.getMySkills(response[0]?.id);
        commit('SET_PROFILE_SKILLS', skillsProfessionalProfile.skills);
      }
    } catch (err) {
      console.log({ err });
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar carregar o perfil profissional, tente novamente!',
      });
    }
  },
  async createProfessionalProfile({ commit, rootState }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      const response = await professionalProfile.createProfessionalProfile(payload);
      commit('SET_PROFILE', response.data[0]);
      return {
        type: 'success',
        title: 'Perfil criado',
        description: 'Seu perfil profissional foi criado com sucesso!',
      };
    } catch (err) {
      return {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar criar um novo perfil profissional, tente novamente!',
      };
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async createDefaultProfile({ rootState }) {
    const body = {
      firstname: 'Nome',
      lastname: 'Sobrenome',
      title: '(Título sobre você)',
      docNumber: '15521988041',
      whatsapp: '(84) 99999-9999',
      differentialOfYourWork: 'Informações gerais sobre você',
      extraInformation: 'Sua descrição',
    };
    const accountId = rootState.auth.account.id;
    await professionalProfile.createProfessionalProfile({ accountId, ...body });
  },
  async updateUserProfile({ commit, rootState }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      const profileData = rootState.profile.userProfile;
      const professionalProfileId = profileData.id;
      const response = await professionalProfile.updateProfessionalProfile({ professionalProfileId, ...payload });
      commit('SET_PROFILE', { ...profileData, ...response[0] });
      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Perfil atualizado',
        description: 'Os dados do seu perfil foram atualizados com sucesso!',
      });
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar atualizar as suas informações de perfil, tente novamente!',
      });
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async addJobExperience({ commit, rootState }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      const {
        name, jobTypeId, company,
        locationTypeId,
        isActualJob,
        jobStartDate,
        description,
      } = payload;
      const professionalProfileId = rootState.profile.userProfile.id;
      const { jobExperiences } = rootState.profile.userProfile;
      const response = await professionalProfile.addJobExperience({ professionalProfileId, ...payload });
      const newArrayJobExpirience = [...jobExperiences || [], {
        id: response.id,
        name,
        professionalProfileId,
        jobTypeId,
        company,
        locationTypeId,
        isActualJob,
        jobStartDate,
        description,
      }];
      commit('SET_PROFILE_JOB_EXPERIENCE', newArrayJobExpirience);

      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Experiência Adicionada',
        description: 'Você acabou de adicionar uma nova experiência em seu perfil profissional',
      });
    } catch (err) {
      console.log(err);
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Verifique se todos os campos foram preenchidos, ou se você já tem um emprego atual!',
      });
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async updateJobExperience({ commit, rootState }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      const { id } = payload;
      const profileData = rootState.profile.userProfile;
      const response = await professionalProfile.updateJobExperience(payload);
      const newArrayJobExpirience = profileData?.jobExperiences.map((job) => (job.id === id ? { ...response[0] } : job));
      commit('SET_PROFILE_JOB_EXPERIENCE', newArrayJobExpirience);
      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Experiência Atualizada',
        description: 'Você acabou de atualizar sua experiência em seu perfil profissional',
      });
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar atualizar uma experiência, tente novamente!',
      });
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async deleteExperience({ commit, rootState }, payload) {
    try {
      const jobExperienceId = payload;
      const { id } = rootState.profile.userProfile;
      await professionalProfile.deleteJobExperience(id, jobExperienceId);
      const newArrayJobExpirience = rootState.profile.userProfile.jobExperiences.filter((job) => job.id !== jobExperienceId);
      commit('SET_PROFILE_JOB_EXPERIENCE', newArrayJobExpirience);
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar deletar uma experiência, tente novamente!',
      });
    }
  },
  async addAcademicEducation({ commit, rootState }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      const {
        id,
        educationalInstitution,
        degree,
        startDate,
        endDate,
        studyArea,
        note,
      } = payload;
      const professionalProfileId = rootState.profile.userProfile.id;
      const { academicEducations } = rootState.profile.userProfile;
      const response = await professionalProfile.addAcademicEducation({ professionalProfileId, ...payload });
      const newAcademicEducationArray = [...academicEducations, {
        id: response.id,
        educationalInstitution,
        degree,
        startDate,
        endDate,
        studyArea,
        note,
      }];
      commit('SET_PROFILE_ACADEMIC_EDUCATION', newAcademicEducationArray);

      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Formação Acadêmica Adicionada',
        description: 'Você acabou de adicionar uma nova formação acadêmica em seu perfil profissional',
      });
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Verifique se todos os campos foram preenchidos!',
      });
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async updateAcademicEducation({ commit, rootState }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      const { id } = payload;
      const profileData = rootState.profile.userProfile;
      const response = await professionalProfile.updateAcademicEducation(payload);
      const newArrayAcademicEducation = profileData?.academicEducations.map((academic) => (academic.id === id ? { ...response[0] } : academic));
      commit('SET_PROFILE_ACADEMIC_EDUCATION', newArrayAcademicEducation);
      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Formação Acadêmica Atualizada',
        description: 'Você acabou de atualizar sua formação acadêmica em seu perfil profissional',
      });
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar atualizar sua formação acadêmica, tente novamente!',
      });
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async deleteAcademicEducation({ commit, rootState }, payload) {
    try {
      const academicEducationId = payload;
      const { id } = rootState.profile.userProfile;
      const { academicEducations } = rootState.profile.userProfile;
      await professionalProfile.deleteAcademicEducation(id, academicEducationId);
      const newArrayAcademicEducation = academicEducations.filter((academic) => academic.id !== academicEducationId);

      commit('SET_PROFILE_ACADEMIC_EDUCATION', newArrayAcademicEducation);
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar deletar sua formação acadêmica, tente novamente!',
      });
    }
  },
  async loadSkills({ commit }) {
    try {
      const response = await professionalProfile.getSkills();
      console.log('Skills', response);
      commit('SET_SKILLS', response);
      return response;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async loadMySkills({ rootState, commit }) {
    try {
      const { id } = rootState.profile.userProfile;
      const response = await professionalProfile.getMySkills(id);
      commit('SET_PROFILE_SKILLS', response.skills);
      return response;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async addNewSkill({ commit, dispatch }, payload) {
    commit('SET_LOADING_SAVE', true);
    try {
      await professionalProfile.addNewSkillProfessionalProfile(payload);

      dispatch('loadMySkills');
      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Habilidade Adicionada',
        description: 'Você acabou de adicionar uma nova habilidade em seu perfil profissional',
      });
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: 'Erro ao tentar atualizar uma habilidade, tente novamente!',
      });
    } finally {
      commit('SET_LOADING_SAVE', false);
    }
  },
  async uploadImage({ commit }, payload) {
    try {
      const response = await professionalProfile.changeProfilePicture(payload);
      if (payload.type === 'cover' && response) {
        commit('SET_UPLOAD_FILE_COVER', payload.imagePreview);
      } else {
        commit('SET_UPLOAD_FILE_AVATAR', payload.imagePreview);
      }
      commit('SET_TOAST_MESSAGE', {
        type: 'success',
        title: 'Upload realizado com sucesso',
        description: `Alteração da imagem de ${payload.type === 'cover' ? 'capa' : 'perfil'} feita com sucesso`,
      });
    } catch (err) {
      commit('SET_TOAST_MESSAGE', {
        type: 'error',
        title: 'Parece que algo deu errado!',
        description: `Erro ao tentar fazer o upload da imagem de ${payload.type === 'cover' ? 'capa' : 'perfil'}`,
      });
    }
  },
  async addCertificatesToProfessionalProfile({ commit, rootState }, certificate) {
    const { certificates } = rootState.profile.userProfile;
    try {
      const {
        id,
        title,
        description,
        createdAt,
        updatedAt,
        url,
        professionalProfileId,
      } = certificate;
      const newCertificatesArray = [...certificates, {
        id,
        title,
        description,
        createdAt,
        updatedAt,
        url,
        professionalProfileId,
      }];
      commit('SET_PROFILE_CERTIFICATES', newCertificatesArray);
      commit('SET_TEST', 'ADICIONOU');
    } catch (err) {
      console.log(err);
    }
  },
  async deleteCertificates({ commit, rootState }, payload) {
    try {
      const certificateId = payload;
      const { id } = rootState.profile.userProfile;
      await certificatesService.deleteCertificatesFromAccount(certificateId, id);
      const newArrayCertificates = rootState.profile.userProfile.certificates.filter((certificate) => certificate.id !== certificateId);
      commit('SET_PROFILE_CERTIFICATES', newArrayCertificates);
    } catch (err) {
      console.error('🚀 ~ file: profile.module.js:435 ~ deleteCertificates ~ err:', err);
    }
  },
  editSkillSelected({ commit }, payload) {
    commit('SET_SKILL_SELECTED', payload);
  },
  editJobExpirienceSelected({ commit }, payload) {
    commit('SET_JOB_EXPIRIENCE_SELECTED', payload);
  },
  editAcademicEducation({ commit }, payload) {
    commit('SET_ACADEMIC_EDUCATION_SELECTED', payload);
  },
  clearObject({ commit }, payload) {
    commit('CLEAR_OBJECT', payload);
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
