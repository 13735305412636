import axiosRetry from 'axios-retry';
import apiBase from './default.http';

export const { instance: apiBaseService, setToken } = apiBase(
  'https://tt01bf5f82.execute-api.us-east-2.amazonaws.com/Prod/trail',
  true,
);

axiosRetry(apiBaseService, { retries: 2, retryDelay: axiosRetry.exponentialDelay });
const trailHttp = apiBaseService;

export default trailHttp;
