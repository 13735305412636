<template>
  <div class="w-100 br-10px courses-skeleton">
    <template v-if="['top-banner', 'all'].includes(type)">
      <VueSkeletonLoader class="w-100 br-10px h-368px" />
    </template>
    <template v-if="['recommended', 'all'].includes(type)">
      <VueSkeletonLoader class="w-100 h-200px br-10px mb-18 mt-40" />
      <div class="flex-row w-100 justify-content-between mb-48">
        <VueSkeletonLoader class="w-100 br-10px h-150px mb-24 mt-48" />

      </div>
    </template>
    <template v-if="['search', 'all'].includes(type)">
      <VueSkeletonLoader class="w-100 br-10px h-200px mb-18 mt-40" />
      <div class="flex-row w-100 br-10px justify-content-between mb-48">

      </div>
    </template>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  props: {
    type: {
      type: String,
      default: 'all',
    },
  },
  name: 'ProfessionalProfileSkeleton',
  components: { VueSkeletonLoader },
};
</script>
