<template>
  <div class="cardTrailsDetails">

    <div class="trail-details" v-for="(trail_details, idx) in trail_details" :key="idx">

      <div class="trails_details_container">

        <div>
          <p class="trail_tittle">Trilha de Conhecimento</p>
          <p class="trail_name">{{ trail_details.name }}</p>
          <p class="trail_description">{{ trail_details.description }}</p>

          <div class="trail_courses_length_container">
            <img src="@/assets/icons/card/graduation-red.svg" style="margin-right: 10px;" />
            <p>{{ `${trail_details.items.length} Cursos` }}</p>
          </div>
          <div class="button-container">
            <PdcButton @click.native="trailInit()" class="trail_button" variant="primary">
              <span>
                Iniciar trilha
              </span>
            </PdcButton>
            <LikeComponent id="like-button" entity="collection" :entity_id="trail_details.id" mode="button"
              :is_liked="trail_details.isLiked?.isLike ? true : false" />
          </div>

        </div>
        <div class="trail-image-container">
          <div class="trail-image trail-image-responsive">
            <img :src="(trail_details.cover || 'https://courses-stg.s3.us-east-2.amazonaws.com/default-trail-cover.png')" />
          </div>
        </div>
      </div>
    </div>
    <div class="recommended__line" />
    <div class="recommended__slider" style="width: 100%">

    </div>

  </div>
</template>

<script>

import PdcButton from '@/components/atoms/Button/Button.vue';
import './CardTrailDetails.scss';
import LikeComponent from '@/components/atoms/Likes/LikeComponent.vue';

import { mapGetters } from 'vuex';
import { startTrail } from '@/services/course.service';

export default {
  props: {

    section: {
      type: String,
      default: () => 'normal',
      required: false,
    },
  },
  components: {
    PdcButton,
    LikeComponent,

  },
  computed: {
    ...mapGetters('courses', ['trail_details']),
    ...mapGetters('auth', ['account']),

  },
  methods: {
    trailInit() {
      this.$router.push({ name: 'CourseDetail', params: { course: this.trail_details[0].items[0].path } });
      const { collectionId } = this.$route.params;
      if (this.account) {
        const trailBody = {
          accountId: this.account.id,
          trailId: collectionId,
          startDate: new Date().toISOString().slice(0, 10).replace(/-/g, '/'),
        };
        const response = startTrail(trailBody);
        if (response.data);
      }
    },
  },

};
</script>
