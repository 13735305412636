<template>
  <div>
    <Button class="custom-button-course sm-flex-row sm-justify-content-center" variant="primary" :outlined="true"
      :loading="loading" @click="openDialog()" :buttonColor="this.tenantConfig.primary_color" :disabled="disabled">
      <i v-if="back_icon" class="button-icon button-back-class rotate" />
      {{ text_button }}
      <i v-if="next_dialog == 'dialog' || next_dialog == 'next'" :class="`button-icon`" />
    </Button>

    <ElDialog :visible.sync="showDialog" center custom-class="confirm-next-lesson-dialog-alert">
      <span>
        <div class="next-lesson-message">
          <img src="@/assets/icons/course-exame/warning-alert.png" class="next-lesson-message--icon"
            alt="warning-alert" />
          <h1 class="next-lesson-message--title">A aula não foi concluida!</h1>
          <div class="next-lesson-message--text">Essa aula não foi concluida, antes de avançar, deseja marcar como
            concluída?</div>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <Button class="custom-button-course sm-flex-row sm-justify-content-center" variant="primary"
          @click="nextLessonAndFinish" :buttonColor="this.tenantConfig.primary_color">
          Marcar como concluída
        </Button>
        <Button class="custom-button-course sm-flex-row sm-justify-content-center" variant="primary" @click="nextLesson"
          :outlined="true" :buttonColor="this.tenantConfig.primary_color">
          Avançar sem concluir
        </Button>
      </span>
    </ElDialog>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button/Button.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CourseButton',
  components: { Button },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
    ...mapGetters('courses', [
      'course_all_lessons',
    ]),
    current_lesson_index() {
      if (!this.current_lesson_id) {
        return null;
      }
      return this.course_all_lessons.findIndex((lesson) => Number(lesson.id) === Number(this.current_lesson_id)) + 1;
    },

  },
  props: {
    text_button: {
      type: String,
      required: true,
      default: 'Próxima aula',
    },
    next_dialog: {
      type: String,
      required: true,
      default: 'dialog',
    },
    back_icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    ...mapActions('ratings', ['setUserEntityRating']),
    openDialog() {
      if (this.next_dialog === 'dialog') {
        this.showDialog = true;
      } else {
        this.$emit('click');
      }
    },
    closeDialog() {
      this.showDialog = false;
    },
    nextLesson() {
      this.$emit('click', false);
      this.closeDialog();
    },
    nextLessonAndFinish() {
      this.$emit('click', true);
      this.setUserEntityRating(
        { entity: 'course_topic', entityId: this.$route.params.lesson, score: 5 },
      );
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss">
.custom-button-course {
  padding: 16px 24px !important;

  span {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .button-icon {
    width: 24px;
    height: 24px;
    background: url(../../../../assets/icons/course-details/arrow-right.svg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 8px;
    transition: background 0.35s;
  }

  .button-back-class {
    margin-right: 8px;
  }

  &:hover .button-icon {
    background: url(../../../../assets/icons/course-details/arrow-right-white.svg);
  }
}

.el-dialog {
  &.confirm-next-lesson-dialog-alert {
    width: 538px;
    padding: 40px 65px 24px;
    box-sizing: border-box;
    border-radius: 8px;

    .el-dialog__header {
      height: 0px;
      padding: 0;
      margin: 0;
    }

    .el-dialog__body {
      padding: 0;
      margin-bottom: 24px;

      .next-lesson-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .next-lesson-message--icon {
          width: 305.08px;
          height: 147.29px;
          object-fit: contain;
          margin-bottom: 32px;
        }

        .next-lesson-message--title {
          font-family: Poppins;
          font-weight: bold;
          font-size: 24px;
          line-height: 124%;
          color: $color-brand-primary-default;
          margin-top: 0;
          margin-bottom: 16px;
        }

        .next-lesson-message--text {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 16px;
          line-height: 148%;
          text-align: center;
          letter-spacing: -0.02em;
          color: $color-neutral-dark;
          opacity: 0.64;
        }
      }
    }

    .el-dialog__footer {
      padding: 0;

      .dialog-footer {
        flex-flow: column nowrap;
        gap: 16px;
        display: flex;
      }

      .el-button {
        line-height: 148%;
        letter-spacing: -0.02em;
        border-radius: 8px;
        margin-left: 0;
      }
    }
  }
}

.rotate {
  transform: rotate(180deg);
}
</style>
