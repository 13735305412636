<template>
  <TemplateAdmin :loading="loading_app">
    <template slot="container">

      <h2 class="page-title">Meus Dados</h2>

      <div class="user-page">

        <section class="user-data">
          <Card class="edit-data">

            <h3>Dados Pessoais</h3>

            <div class="personal-data">

              <form>

                <div class="email-label">
                  <label for="">Email:</label>
                  <span>{{ user.email }}</span>
                </div>

                <CustomInput :loading="loadingData" type="text" name="name" id="name" placeholder="CarlosdaSilva"
                  title="Nome*" v-model="user.name" />

                <div class="icon-label">

                  <CustomInput :loading="loadingData" type="text" name="phone" :hasIcon="true"
                    :icon="require('../../assets/icons/card/phone.svg')" id="email" placeholder="(84)99999-9999"
                    title="Telefone" v-model="user.phone" :max-length="'11'" @change="handleChangePhone($event)" @input="handleChangePhone($event)" />

                </div>

                <div class="icon-label">

                  <CustomInput :loading="loadingData" type="text" name="whatsapp" :hasIcon="true"
                    :icon="require('../../assets/icons/card/wpp.svg')" id="email" placeholder="(84)99999-9999"
                    title="Whatsapp" v-model="user.whatsapp" />

                </div>

              </form>

            </div>

          </Card>
          <Card>

            <h3>Conquistas</h3>

            <div class="points-label">
              <label for="">Pontos:</label>
              <span>{{ points ?? 'Não foi possível calcular seus pontos' }}</span>
            </div>

          </Card>
          <Card class="security">

            <h3>Segurança</h3>

            <div class="change-pass" @click="openDialog">
              <img src="../../assets/icons/card/lock.svg" alt="" />

              <p>Trocar Senha</p>
              <img src="../../assets/icons/card/right-vector.svg" alt="" />

            </div>

          </Card>

        </section>

        <section class="address">
          <Card>

            <h3>Endereço</h3>

            <div class="personal-data">
              <!-- <form> -->
              <CustomInput :loading="loadingData" type="text" name="cep" @input="getAddress($event)" id="cep"
                placeholder="00000-000" title="CEP" v-model="address.cep" />
              <CustomInput :loading="loadingData" type="text" v-model="address.pais" :value="address.pais || 'Brasil'"
                placeholder="Ex: Brasil" title="País/Região" name="country" />

              <CustomSelect :options="states" v-model="address.uf" title="Estado*" name="state"
                :defaultLabel="address.uf || 'Escolha seu estado'" />

              <CustomInput :loading="loadingData" type="text" v-model="address.localidade" placeholder="Ex: Natal"
                title="Cidade" name="city" defaultLabel="Cidade" />

              <CustomInput :loading="loadingData" type="text" v-model="address.bairro" placeholder="Bairro"
                title="Bairro*" name="neighborhood" defaultLabel="Bairro" />

              <CustomInput :loading="loadingData" type="text" v-model="address.logradouro" placeholder="Rua / Avenida"
                title="Rua*" name="street" defaultLabel="Rua" />

              <CustomInput :loading="loadingData" type="text" v-model="address.numero" placeholder="Ex: 123"
                title="Número" name="number" defaultLabel="Número" />

              <CustomInput :loading="loadingData" type="text" v-model="address.complemento"
                placeholder="Se houver, digite seu complemento" title="Complemento" name="complement" />

            </div>

          </Card>

        </section>

      </div>

      <div class="save-data">
        <PdcButton class="button" variant="primary" id="button-discovery" @click.native="updateAccount"
          :loading="loadingSave">Salvar</PdcButton>
      </div>
      <!-- <Card class="save-data"> -->
      <!-- </Card> -->
      <ChangePasswordDialog @handleClose="closeDialog" v-if="showChangePassDialog" />

    </template>

  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import Card from '@/components/atoms/Card/Card.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect.vue';
import PdcButton from '@/components/atoms/Button/Button.vue';
import { findAddressByCEP } from '@/services/via-cep.service';
import CONSTANTS from '@/utils/constants';
import { updatePhone, updateUserAccount } from '@/services/account.service';
import ChangePasswordDialog from '@/components/organisms/ChangePasswordDialog/ChangePasswordDialog.vue';
import { updateDataErrors } from '@/handlers/errors/user-errors';
import {
  createAddress,
  getAddress,
  updateAddress,
} from '@/services/address.service';

export default {
  name: 'UserAccountData',
  components: {
    TemplateAdmin,
    Card,
    CustomInput,
    CustomSelect,
    ChangePasswordDialog,
    PdcButton,
    // CustomTextArea,
  },
  data() {
    return {
      user: {},
      address: {},
      countries: [],
      states: [],
      cities: [],
      phone: '/phone.svg',
      showChangePassDialog: '',
      loadingData: false,
      loadingSave: false,
      changedPhone: false,
    };
  },
  computed: {
    ...mapGetters('application', ['loading_app', 'route', 'tenantConfig']),
    ...mapGetters('auth', ['accountComplete', 'points', 'account']),
    statesToShow() {
      const states = CONSTANTS?.data?.STATES;
      const statesArray = Object.entries(states).map(([sigla, nome]) => ({
        label: nome,
        value: sigla,
      }));
      return [...statesArray];
    },
  },
  methods: {
    ...mapActions('auth', ['getPoints']),
    ...mapActions('auth', ['updatePhoneSSO']),
    handleChangePhone(event) {
      const phone = event;

      if (phone === this.accountComplete.phone) {
        this.changedPhone = false;
      }
      this.changedPhone = true;
    },
    async getAddress(cep) {
      if (cep && cep.length === 8) {
        const { status, data } = await findAddressByCEP(cep);
        if (status === 200) this.address = data;
      }
    },
    removeMask(value) {
      return value.replace(/\D/g, '');
    },
    // eslint-disable-next-line consistent-return
    async updateAccount() {
      this.loadingSave = true;
      const accountFromBack = this.accountComplete;
      const body = {
        name: this.user.name || accountFromBack.name,
        email: this.user.email || accountFromBack.email,
        phone: this.user.phone || accountFromBack.phone,
        whatsapp: this.user.whatsapp || accountFromBack.whatsapp,
      };
      try {
        if (this.changedPhone) {
          await updatePhone({
            phone: this.removeMask(this.user.phone || accountFromBack.phone),
          });
          this.changedPhone = false;
        }
      } catch (err) {
        if (err.response.status === 422 && err.response.data.errors.includes('Phone must not have repeated characters')) {
          this.$swal.fire(
            'Erro ao alterar telefone de contato repetido, seu telefone possui caracteres repetidos',
            'Tente novamente mais tarde',
            'error',
          );
        } else if (err.response.status === 422 && err.response.data.message === 'Phone already registered') {
          this.$swal.fire(
            'Erro ao alterar telefone de contato, este telefone ja foi registrado',
            'Tente novamente mais tarde',
            'error',
          );
        }
        this.loadingSave = false;
        return;
      }
      if (!this.address.logradouro || !this.address.bairro) {
        this.$swal.fire(
          'Erro ao alterar dados',
          'Por favor, preencha todos os campos',
          'error',
        );
        this.loadingSave = false;
        return;
      }
      await Promise.all([
        updateUserAccount(body),
        this.saveAddress(this.address),
      ]).then(([account, address]) => {
        if (account && address) {
          this.$swal.fire(
            'Dados alterados',
            'Seus dados foram alterados com sucesso',
            'success',
          );
        }
      })
        .catch(({ data }) => {
          if (data) {
            const { message } = updateDataErrors(data?.statusCode);
            this.$swal.fire('Erro ao alterar dados', message, 'warn');
          }
        })
        .finally(() => {
          this.loadingSave = false;
        });
      this.closeDialog();
    },
    // eslint-disable-next-lineconsistent-return
    async saveAddress() {
      const addressId = this.address?.id;
      const {
        cep, logradouro, localidade, uf, bairro, numero, complemento,
      } = this.address;
      const addressBody = {
        cep,
        address: logradouro,
        city: localidade,
        state: uf,
        accountId: this.account.id,
        groupId: 1,
        maxTravelDistance: 1,
        complement: complemento,
        number: Number(numero),
        neighborhood: bairro,
      };
      if (addressId) {
        const data = await updateAddress({ ...addressBody, id: addressId });
        return data;
      }
      const data = await createAddress(addressBody);
      return data;
    },
    async loadAddress(userId) {
      const data = await getAddress(userId);
      const {
        address, cep, city, complement, id, state, accountId, number, neighborhood,
      } = data[data.length - 1];
      this.address = {
        localidade: city,
        bairro: neighborhood,
        logradouro: address,
        cep,
        uf: state,
        id,
        accountId,
        numero: number,
        complemento: complement,
      };
    },

    // eslint-disable-next-lineconsistent-return
    getUserPoints() {
      try {
        this.getPoints();
      } catch (err) {
        this.$swal.fire(
          'Erro ao carregar pontos do usuário',
          'Erro ao carregar seus pontos, tente novamente mais tarde!',
          'warn',
        );
      }
    },
    openDialog() {
      this.showChangePassDialog = true;
    },
    closeDialog() {
      this.showChangePassDialog = false;
    },
  },
  async mounted() {
    this.loadingData = true;
    this.states = CONSTANTS?.data?.STATES;
    this.user = this.accountComplete ?? this.account;
    try {
      await this.loadAddress(this.account.id);
    } catch (err) {
      this.$swal.fire(
        'Atenção!',
        'Seus dados não estão completos. Termine de preencher os campos que estão em branco.',
        'warn',
      );
    }
    this.getUserPoints();
    this.loadingData = false;
  },
};
</script>

<style lang="scss">
.page-title {
  padding: 0 1.3rem;
}

.user-page {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2.5rem;

  .user-data {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .edit-data {
      padding-bottom: 1rem;
    }

    h2 {
      width: 100%;
      color: #3E297F;
    }

  }

  .address {
    width: 50%;

    .card-container {
      padding-bottom: 30px;
    }
  }
}

h3 {
  margin: 0.5rem 0;
  color: #3E297F;
}

h2 {
  color: #3E297F;
}

.personal-data {
  display: flex;
  flex-direction: column;
}

Card {
  border-radius: 30px;

  .personal-data {
    .icon-label {
      display: flex;
    }
  }
}

.card-container .security {
  .card-body {
    padding: 1px 25px;
  }
}

.save-data {
  display: flex;
  padding: 0;
  margin: 0;
  //width:48%;
  height: 5rem;
  justify-content: flex-end;
  align-items: center;
  // margin-top: 3rem;

  button {
    padding: 10px 43px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid #3E297F;
    color: #3E297F;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    background: white;
    font-weight: 700;
    line-height: 144%;
    cursor: pointer;

    &:hover {
      background-color: #3E297F;
      color: #FFF;
      border-color: #3E297F;
      transition: ease 0.4s;
    }
  }
}

.change-pass {
  display: flex;
  gap: 0.7rem;

  img {
    cursor: pointer;
  }

  p {
    color: #000;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}

.email-label,
.points-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.8rem;

  label {
    margin: 0;
    color: #000;
    //font-family:Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  span {
    margin: 0;
    color: #000;
    //font-family:Poppins;
    font-size: 1rem;
    word-break: break-word;
  }
}

@media (max-width: 430px) {
  .user-page {
    display: grid;
    grid-template-columns: 100vw;
    grid-auto-rows: auto;
    //grid-auto-columns:auto;
  }

  .user-page .address,
  .user-page .user-data {
    width: 90%;
  }
}

</style>
