<template>
  <DialogWizard class="form-dialog" ref="wizard" :qty_steps="2" :data_object="data_object_values" :close_on_click="true"
    @onClose="handleClose">
    <template v-slot:step-0="context">
      <Step1 :context="context" />
    </template>
    <template v-slot:step-1="context">
      <Step2 :context="context" @onClose="handleClose" />
    </template>
  </DialogWizard>
</template>
<script>
import { DialogWizard } from 'vue-pdc-ext-frontend';
import { mapActions, mapGetters } from 'vuex';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import './QuestionDialog.scss';

export default {
  components: {
    DialogWizard,
    Step1,
    Step2,
  },
  computed: {
    ...mapGetters('questions', ['show_dialog_question']),
  },
  watch: {
    show_dialog_question(newValue) {
      if (newValue) {
        this.$refs.wizard.open();
      } else {
        this.$refs.wizard.close();
      }
    },
  },
  data() {
    return {
      current_step: 0,
      data_object_values: {
        message: '',
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.current_step = this.$refs.wizard.step;
      this.$refs.wizard.close();
    });
  },
  methods: {
    ...mapActions('questions', ['setDialogQuestion']),
    handleClose() {
      this.setDialogQuestion(false);
    },
  },
};
</script>
