import axios from 'axios';
import coursesHttp from '../utils/courses.http';
import coursesHttpProd from '../utils/coursesProd.http';
import collectionHttp from '../utils/collection.http';
import trailHttp from '../utils/trail.http';

// eslint-disable-next-line no-undef
export const findCourse = async (slug) => {
  const courseSlug = slug.replaceAll('&', '?');
  const response = await coursesHttp.get(`/courses/${courseSlug}`);
  return response;
};

export const findCoursePath = (slug, accountId) => coursesHttpProd.get(
  `/classes-infos?path=${slug}&accountId=${accountId}`,
);

export const fetchLessons = (courseId) => coursesHttp.get(`/courses/${courseId}/lessons`);

export const getAllCourses = () => coursesHttp.get('/courses');

export const getRecommendedCourses = (useLast = true) => coursesHttp.get(`/courses/recommended?last=${useLast}`);

export const getRecommendedCoursesByCourse = () => coursesHttp.get('/courses/recommended/course');

export const getRecommendedCoursesByJob = (body) => axios.post('https://api.stg.parceirodaconstrucao.com.br/internal/course-recommended', {
  accountId: body.accountId,
  jobs: body.jobs,
});

export const getTrailsDetails = (collectionId) => collectionHttp.get(`/collections/collections/${collectionId}`);

export const startTrail = (body) => trailHttp.post('/trailstart', body);

export const updateTrail = (body) => trailHttp.post('/trailstart/update', body);

export const getCourseCategories = () => coursesHttp.get('/courses/categories');

export const getPdcTalksCategories = () => coursesHttp.get('/courses/categories?categoryIds=79,80,81');

export const getCategoryById = (categoryId) => coursesHttp.get(`/courses/categories?categoryIds=${categoryId}`);

export const getCourseCollections = () => collectionHttp.get('/collections/collections');

export const getRecommendedCollections = () => collectionHttp.get('/collections/collections/recommended');

export const getCourseCollectionsInProgress = () => collectionHttp.get('/collections/collections?inProgress=true');

export const getCategories = () => coursesHttp.get('/courses/categories/public');

export const getCoursesOngoing = () => coursesHttp.get('/courses/ongoing');

// eslint-disable-next-line max-len
export const searchCourses = (key, accountId) => axios.get(`https://api.stg.parceirodaconstrucao.com.br/internal/search?key=${key}&accountId=${accountId}`);

export const searchCollections = (q) => collectionHttp.get(`/collections/collections?q=${q}`);

export const getCoursesFinished = () => coursesHttp.get('/courses/finished');

export const viewTopic = (courseId, topicId) => coursesHttp.post(`/courses/${courseId}/topics/${topicId}/view`);

export const fetchQuestions = (courseId) => coursesHttp.get(`/courses/${courseId}/questions`);

export const startExame = (courseId) => coursesHttp.post(`/courses/${courseId}/exam`);

export const sendAnswer = (courseId, payload) => coursesHttp.post(`/courses/${courseId}/questions/respond?proccess=false`, payload);

export const proccessAnswers = (courseId, payload) => coursesHttp.post(`/courses/${courseId}/questions/respond?proccess=true`, payload);

export const abortAttempt = (courseId) => coursesHttp.post(`/courses/${courseId}/resetExam`);

export const getRankingCourses = () => coursesHttp.get('/courses/rank-courses');

export const getRecentCourse = () => coursesHttp.get('/courses/recent-courses');

export const semanticSearch = (find, entities) => coursesHttp.get(`/courses/semantic/search?find=${find}&entities=${entities}`);
export const evaluateCourse = (courseId, payload) => coursesHttp.post(`/courses/${courseId}/evaluate`, payload);
