import commentHttp from '../utils/collection.http';

export const getCommentByEntityandId = async (entity, entityId) => (
  commentHttp.get(`social/commentary/${entity}/${entityId}`)
);

export const getCommentByEntityIdandUser = async (entity, entityId) => (
  commentHttp.get(`social/commentary/${entity}/${entityId}/user`)
);

export const createComment = async (data) => {
  commentHttp.post('/social/commentary', data);
};

export const changeCommentStatus = (entity, entityId, commentId, status) => {
  commentHttp.patch(`social/commentary/${entity}/${entityId}/${commentId}/status`, { status });
};

export const deleteComment = async (entity, entityId, commentId) => commentHttp.delete(`social/commentary/${entity}/${entityId}/${commentId}`);
