<template>
  <div class="lost-progress-message">
    <img src="@/assets/icons/course-exame/error-alert.png" class="lost-progress-message--icon" alt="red-alert" />
    <h1 class="lost-progress-message--title">Você vai perder seu progresso!</h1>
    <div class="lost-progress-message--text">
      Se você sair agora, você terá que iniciar novamente o exame para poder desbloquear seu certificado. Deseja sair?
    </div>
  </div>
</template>
<script>
import './CustomExameDialogAlert.scss';

export default {
  name: 'LostProgressAlert',
};
</script>
