<template>
  <div class="container" v-bind="$attrs">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'CardAward',

};
</script>

<style lang="scss" scoped>
  .container{
    border-radius: 10px;
    border: 1px solid #C8C4E9;
    padding: 1.25rem;
    text-align: center;
    margin-bottom: 1.25rem;
    background-color: #F7F9FD;
  }
</style>
