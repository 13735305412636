<template>
  <el-dialog id="accept-dialog" :visible.sync="dialog" :close-on-click-modal="false" :show-close="false">
    <div class="accept-header">
      <img class="accept-image" src="@/assets/icons/dialog/pdc-icon.svg" alt="" />
      <h3 class="accept-tittle"> Bem-vindo ao Parceiro da Construção!</h3>
    </div>
    <div class="accept-content">
      <div class="accept-text">
        <Termos />
      </div>
      <p class="accept-description">Se recusar os Termos e Condições de Uso do Parceiro da Construção, você não poderá
        assistir cursos da
        plataforma.</p>
      <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
        <PdcButton class="button" variant="outlined" id="button-discovery"
          @click.native="$router.back()">Recusar e voltar
        </PdcButton>
        <PdcButton class="button" variant="primary" id="button-discovery" @click.native="handleClose">Aceitar Termos
        </PdcButton>
      </span>
    </div>
  </el-dialog>
</template>
<script>

import PdcButton from '@/components/atoms/Button/Button.vue';
import { mapActions } from 'vuex';
import Termos from './Termos.vue';
import './AcceptDialog.scss';

export default {
  components: {
    PdcButton,
    Termos,
  },
  data: () => ({
    dialog: true,
    close: false,

  }),
  methods: {
    ...mapActions('application', ['getTenantConfig', 'preRegister']),

    handleClose() {
      this.dialog = false;
      this.preRegister();
    },

  },

};
</script>
