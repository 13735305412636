<template>
  <div>
    <CoursesSkeleton type="comments" v-if="reply_comments_loading" />
    <div class="comment_card_container">
      <div class="container_comment_geral">
        <div class="tittle_text_container">
          <div class="comment_container">
            <img v-if="!comment.account.avatar" src="@/assets/icons/comment-card/avatar.svg" alt="" />
            <div v-else class="avatar">
              <img :src="comment.account.avatar" alt="" />
            </div>

            <p class="comment_name">{{ comment.account.name || "Parceiro(a)" }}</p>
            <p class="comment_name_user" v-if="accountId === comment.account.id">
              (Você)
            </p>
            <p class="comment_date">{{ isoToLocaleDate(comment.createdAt) }}</p>
          </div>

          <div>
            <p class="comment">{{ comment.text }}</p>
            <p class="comment_date_mobile">{{ isoToLocaleDate(comment.createdAt) }}</p>
          </div>

          <div class="status-container">
            <template v-if="type === 'waiting' || type === 'waitingReply'">
              <img class="waiting_icon" src="@/assets/icons/comment-card/waiting.svg" alt="" />
              <p class="waiting">
                {{ status[comment.status] }}
              </p>
            </template>

            <template v-if="type === 'approved' || type === 'neutral' || type === 'question' || type === 'approvedReply'
              || type === 'neutralReply' || type === 'questionReply'">
              <div class="footer" style="margin-top: 10px">
                <LikeComponent id="like-button" entity="comment" :entity_id="comment.id" mode="counter"
                  :is_liked="!!comment.isLiked?.isLike" always_visible class="like-comment"
                  :qty_likes="comment?.qtyLikes" @likeComment="likeComment" />

                <span class="reply-input-buttom " @click="openTextArea"> Responder </span>
                <div class="status-container" v-if="accountId === comment.account.id" alt="">
                  <img style="width: 15px;" class="approved-icon" src="@/assets/icons/comment-card/approved.svg" />
                  <p class="approved" style="color: #37b24d">
                    {{ status[comment.status] }}
                  </p>
                </div>
              </div>

            </template>

          </div>

          <div v-if="type === 'approved'" style="margin-left:45px; margin-top: 10px;">
            <img class="icon" v-if="!showReplyIcon" src="@/assets/icons/comment-card/show-reply.svg" alt="" />
            <img class="icon" v-if="showReplyIcon" src="@/assets/icons/comment-card/hide-reply.svg" alt="" />
            <span class="reply-buttom" @click="openReply"> {{ comment.qtyResponses }} respostas
            </span>
          </div>

        </div>

        <div v-if="type === 'approved' || type === 'waiting'" class="delete_icon" @click="openDeleteModal()">
          <img src="@/assets/icons/comment-card/delete.svg" alt="" v-if="accountId === comment.account.id" />
        </div>

        <div v-if="type === 'waitingReply'" class=" delete_icon" @click="openDeleteModalResponse()">
          <img src="@/assets/icons/comment-card/delete.svg" alt="" v-if="accountId === comment.account.id" />
        </div>
      </div>
    </div>

    <!-- Form para criar resposta do comentário -->

    <form v-if="showTextArea" style="margin-left: 40px;">
      <div class="input_container">
        <img class="icon" v-if="!accountComplete.avatar" src="@/assets/icons/comment-card/avatar.svg" alt="" />
        <div class="avatar" v-else>
          <img class="icon" :src="accountComplete.avatar" alt="" />
        </div>

        <textarea style="overflow: hidden" ref="insertComment" type="text" v-model="text" class="input_comment"
          placeholder="Adicionar comentário" :maxlength="limit" @keydown="resizeTextArea"> </textarea>

        <div class="input_count" ref="inputCount">{{ text.length }}/{{ limit }}</div>
      </div>

      <div class="comment-button-container">
        <PdcButton @click.native="closeTextArea" class="comment_button" variant="outlines">Cancelar
        </PdcButton>
        <PdcButton v-if="type === 'approved' || type === 'neutral' || type === 'question'"
          @click.native="replySubmit" class="comment_button" variant="primary">
          Comentar</PdcButton>
        <PdcButton v-if="type === 'approvedReply' || type === 'neutralReply' || type === 'questionReply'"
          @click.native="replyResponseSubmit" class="comment_button"
          variant="primary">
          Comentar </PdcButton>
      </div>
    </form>

  </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import LikeComponent from '@/components/atoms/Likes/LikeComponent.vue';
import PdcButton from '@/components/atoms/Button/Button.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CommentsSkeleton.vue';
import { insertComment } from '@/services/dataLayer.service';

export default {
  props: {
    comment: {
      type: Object,
    },
    accountId: {
      type: Number,
    },
    entity: {
      type: String,
    },
    entityId: {
      type: Number,
    },
    type: {
      type: String,
    },
    current_lesson_title: {
      type: String,
      default: '',
    },
    course: {
      type: Object,
    },
  },
  components: {
    LikeComponent,
    PdcButton,
    CoursesSkeleton,
  },

  computed: {
    ...mapGetters('auth', ['account', 'accountComplete']),
    ...mapGetters('comment', ['comments', 'comments_loading',
      'comments_by_user', 'reply_comments', 'reply_comments_by_user', 'reply_comments_loading']),
  },

  data() {
    return {
      showingMoreComments: false,
      text: '',
      limit: 255,
      showTextArea: false,
      showReply: false,
      showReplyIcon: false,
      status: {
        waiting: 'Aguardando aprovação',
        approved: 'Publicado',
      },
    };
  },
  methods: {
    ...mapActions('comment', [
      'deleteComment',
      'createReplyComments',
      'fetchReplyComments',
      'fetchReplyCommentsByUser',
      'toggleCommentsWithOpenReplies',
      'deleteReplyComment',

    ]),
    openDeleteModal() {
      this.$confirm('Deseja excluir seu comentário definitivamente?', 'Excluir Comentário', {
        customClass: 'modalComment',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        showClose: false,
      }).then(() => {
        this.deleteComment({ entity: this.entity, entityId: this.entityId, commentId: this.comment.id });
      });
    },
    openDeleteModalResponse() {
      this.$confirm('Deseja excluir seu comentário definitivamente?', 'Excluir Comentário', {
        customClass: 'modalComment',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        showClose: false,
      }).then(() => {
        this.deleteReplyComment({ entity: this.entity, entityId: this.entityId, commentId: this.comment.id });
      });
    },
    isoToLocaleDate(isoDate, config = { day: 'numeric', month: 'long', year: 'numeric' }) {
      const date = new Date(isoDate);
      return date.toLocaleDateString('pt-BR', config);
    },
    openTextArea() {
      if (this.comment.entity === 'comment') {
        const username = this.comment.account.name.replace(/@([\w\d.-]+)?/g)
          .replace(/ +/g, '_');
        this.text = `@${username}  `;
      }
      this.showTextArea = true;
    },
    closeTextArea() {
      this.showTextArea = false;
    },
    resizeTextArea() {
      const el = this.$refs.insertComment;
      const counter = this.$refs.inputCount;
      setTimeout(() => {
        el.style.cssText = 'height:auto; padding:0';
        el.style.cssText = `height:${el.scrollHeight - 38}px`;
        if (this.text.length >= this.limit) counter.classList.add('input_error');
        else counter.classList.remove('input_error');
      }, 0);
    },
    async openReply() {
      await this.fetchReplyComments({ entity: 'comment', entityId: this.comment.id });

      await this.fetchReplyCommentsByUser({ entity: 'comment', entityId: this.comment.id });

      this.showReplyIcon = !this.showReplyIcon;
      this.toggleCommentsWithOpenReplies(this.comment.id);
      this.showReply = true;
    },
    likeComment(qtyLikes) {
      this.$emit('likeComment', qtyLikes, this.comment.text.length, this.comment.status);
    },
    async replySubmit() {
      const data = {
        text: this.text,
        entity: 'comment',
        entityId: this.comment.id,
      };
      await this.createReplyComments(data);
      if (window.dataLayer) {
        insertComment(window.dataLayer, this.course, this.current_lesson_title, { commentLength: this.text.length, status: 'waiting' }, true);
      }
      this.text = '';
      this.openReply();
    },
    async replyResponseSubmit() {
      const data = {
        text: this.text,
        entity: 'comment',
        entityId: this.comment.entityId,
        tenant_id: this.account.id,
      };
      await this.createReplyComments(data);
      if (window.dataLayer) {
        insertComment(window.dataLayer, this.course, this.current_lesson_title, { commentLength: this.text.length, status: 'waiting' }, true);
      }
      this.text = '';
    },
  },
};
</script>
