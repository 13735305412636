import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import PortalVue from 'portal-vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { sync } from 'vuex-router-sync';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import VueMeta from 'vue-meta';
import VueSocialSharing from 'vue-social-sharing';
import VueToast from 'vue-toast-notification';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vue-gtm';
import './plugins/helpers';
import './plugins/element-ui';
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css';

Vue.use(VueCarousel);
Vue.use(PortalVue);
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueSocialSharing);
Vue.use(VueToast);
Vue.use(VueSweetalert2);
sync(store, router);

Vue.prototype.$eventBus = new Vue();

mixpanel.init('99be2bbb789d4373062d6348a93c4bab', { persistence: 'localStorage' });

Vue.prototype.$moment = moment;

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
