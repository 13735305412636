<template>
  <div>
    <VueSkeletonLoader v-if="ongoing_loading && finished_loading" class="w-100 br-8px h-40px mb-24 mt-48" />
    <div class="section-title" v-else>{{ showMessage }}</div>
    <div class="ongoingSection">
        <CoursesSkeleton type="recommended" v-if="ongoing_loading" :options="swiperOptions" />
        <template v-else>
        <div class="notfound" v-if="ongoing.length < 1">Você ainda não possui curso em andamento.</div>
        <Carousel v-else :title="'Cursos em andamento'" :items="ongoing" :hiddenArrows="ongoing.length <= 2">
          <template v-slot:item="{ item }">
            <CourseCard :course="item" :finished="checkFinishedCourse(item)" />
          </template>
        </Carousel>
      </template>
    </div>
    <hr class="line" />
    <div class="finishedSection">
      <CoursesSkeleton type="recommended" v-if="finished_loading" :options="swiperOptions" />
      <div class="notfound" v-if="finished.length < 1">
        Você ainda não possui curso finalizado. Conclua um curso para que ele seja exibido aqui.
      </div>
      <Carousel v-else :title="'Finalizados'" :items="finished" :hiddenArrows="finished.length <= 2">
        <template v-slot:item="{ item }">
          <CourseCard :course="item" :finished="checkFinishedCourse(item)" />
        </template>
      </Carousel>
    </div>
    <hr class="line" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VueSkeletonLoader from 'skeleton-loader-vue';
import CourseCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import Carousel from '@/components/atoms/Carousel/Carousel.vue';

export default {
  name: 'CoursesOngoing',
  components: {
    CourseCard,
    CoursesSkeleton,
    Carousel,
    VueSkeletonLoader,
  },
  computed: {
    ...mapGetters('auth', ['accountComplete']),
    ...mapGetters('courses', ['ongoing', 'finished', 'ongoing_loading', 'finished_loading']),
    swiperOptions() {
      return {
        slidesPerView: 1.04,
        breakpoints: {
          1200: {
            slidesPerView: 2.9,
            spaceBetween: 24,
          },
          980: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          700: {
            slidesPerView: 1.8,
            spaceBetween: 24,
          },
        },
      };
    },
    showMessage() {
      // eslint-disable-next-line max-len
      const courseOrCollectionDosentStarted = `Bem-vindo (a), ${this.accountComplete.name} Você ainda não tem cursos ou trilhas iniciadas. Para acompanhar a evolução do seu aprendizado nessa área, comece nossos cursos 100% gratuitos e com certificado. Estamos te esperando!`;
      // eslint-disable-next-line max-len
      const courseOrCollectionStarted = 'Na seção de “Meu Aprendizado” você conseguirá acompanhar a evolução do seu aprendizado e cursos que iniciou, mas ainda não conseguiu finalizar. Ficou mais fácil organizar sua rotina de estudos por aqui!';
      return this.ongoing.length < 1 && this.finished.length < 1 ? courseOrCollectionDosentStarted : courseOrCollectionStarted;
    },
  },
  data() {
    return {
      repeaterId: '',
    };
  },
  methods: {
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },
  },
};
</script>

<style scoped lang="scss">

.section-title{
  font-size: 0.875rem;
  text-align: justify;
  line-height: 1.25rem;
  color: #4B4A49;
}
.notfound {
  border: 1px dashed #ccc;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  color: #A9A9A9;
}

.line{
  background-color: #DFDFDF;
  height: 1px;
  margin: 1rem 0;
  border: 0;
}
</style>
