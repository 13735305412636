<template>
  <TemplateAdmin :loading="isLoading" class="CourseExame">
    <CourseExameTopbar slot="topbar-container" :show_title="false" />
    <template slot="container">
      <CourseExameResultSkeleton v-if="course_loading" />
      <template v-else>
        <div class="exame-certificate">
          <div class="certificate-content flex-col align-items-center">
            <b class="text-align-center mt-48 exame-certificate--text flex-row align-items-center">
              Seu certificado está pronto! <Emoji class="ml-8" value="😁" :size="40" />
            </b>
            <div class="exame-certificate--description my-32">
              Você pode compartilhar essa conquista nas redes sociais. Lembre-se que você pode<br />
              acessar o seu certificado a qualquer momento.
            </div>
            <div class="exame-certificate--btns flex-row sm-flex-col w-100 justify-content-center align-items-center mb-80">
              <PdcButton variant="primary" class="sm-w-100 py-0 h-58px" @click.native="downloadCertificate"> Baixar certificado </PdcButton>
              <div class="flex-row ml-8 sm-ml-0 sm-mt-8 sm-w-100" v-if="showShareAndPrint">

                <Popover placement="top" width="400" trigger="click" class="w-20 sm-w-20 py-0 h-58px sm-w-100">
                  <div>
                    <div class="share-btn-content">
                      <div class="share-btn-content--title">Compartilhe nas redes sociais</div>
                      <div class="share-btn-content--btn-row">
                        <div class="share-btn-content--btn" v-for="(s, i) in socialNetworks" :key="i">
                          <ShareNetwork
                            :network="s.name"
                            :url="imageUrl"
                            title="Meu certificado de conclusão no Parceiro da Construção"
                            description="Confira minha conquista no Parceiro da Construção"
                            quote="Meu certificado de conclusão no Parceiro da Construção"
                            hashtags="parceirodaconstrucao,pdc,construcaocivil"
                          >
                            <img :src="s.icon" class="h-32px" />
                          </ShareNetwork>
                        </div>
                      </div>
                      <div class="share-btn-content--copy-row">
                        <div class="share-btn-content--copy-title">Copie o link para compartilhar</div>
                        <div class="flex-row">
                          <input disabled :value="imageUrl" class="share-btn-content--copy-input" />
                          <Tooltip
                            class="item"
                            v-bind:class="{
                              'tooltip-success': copyClicked,
                            }"
                            effect="dark"
                            :content="`${!copyClicked ? 'Clique para copiar' : '🗸  Copiado para área de transferência'}`"
                            placement="bottom-end"
                            @input="copyClicked = false"
                            :enterable="true"
                          >
                            <button class="share-btn-content--copy-btn" @click.prevent="handleCopyCertificate">
                              <img src="@/assets/icons/copy.svg" class="h-24px" />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template slot="reference">
                    <PdcButton variant="primary" :outlined="true" class="w-20 sm-w-10 py-0 h-58px sm-p-0 sm-w-100">
                      <img class="share-icon" src="@/assets/icons/course-exame/share.svg" />
                    </PdcButton>
                  </template>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </TemplateAdmin>
</template>
<script>
import printJS from 'print-js';
import './Certificate.scss';
import { mapActions, mapGetters } from 'vuex';
import { Popover, Tooltip } from 'element-ui';
import PdcButton from '@/components/atoms/Button/Button.vue';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CourseExameTopbar from '@/components/organisms/Course/CourseExameTopbar/CourseExameTopbar.vue';
import CourseExameResultSkeleton from '@/components/organisms/Skeletons/CourseExameResultSkeleton.vue';
import FacebookIcon from '@/assets/logos/facebook.svg';
import WhatsappIcon from '@/assets/logos/whatsapp.svg';
import LinkedinIcon from '@/assets/logos/linkedin.svg';
import Emoji from '@/components/atoms/Emoji.vue';
import { getCookie } from '@/utils/helpers';

export default {
  name: 'Certificate',
  components: {
    TemplateAdmin,
    PdcButton,
    CourseExameTopbar,
    CourseExameResultSkeleton,
    Popover,
    // Tooltip,
    Emoji,
  },
  data() {
    return {
      copyClicked: false,
      showShareAndPrint: true,
      socialNetworks: [
        {
          name: 'whatsapp',
          icon: WhatsappIcon,
        },
        {
          name: 'facebook',
          icon: FacebookIcon,
        },
        {
          name: 'linkedin',
          icon: LinkedinIcon,
        },
      ],
    };
  },
  created() {
    // TODO REVISAR ROTA
    this.fetchCourseWithoutLessons(this.$router.currentRoute.params.course).then((course) => {
      if (!course.completed || !course.approved) {
        return this.$router.push({ name: 'NotFound' });
      }
      return this.setCourseLoading(false);
    });
  },
  computed: {
    ...mapGetters('courses', ['course_loading', 'course']),
    ...mapGetters('application', ['loading_app']),
    isLoading() {
      return this.course_loading || this.loading_app;
    },
    certificate() {
      // Remove encoded from URL - Course Service return encode now
      const { certificate } = this.course;
      return certificate;
    },
    imageUrl() {
      return this.certificate.urlPdf;
    },
  },
  methods: {
    ...mapActions('courses', ['fetchCourseWithoutLessons', 'setCourseLoading']),
    ...mapActions('exame', ['setLastStepPage']),
    ...mapActions('application', ['appRouterPush']),
    goToDashboard() {
      // Cookie utilizado no Teste A/B para informar que o usuario esta na versao de testes
      const newHomeAB = getCookie('pdc_feed_page');
      this.appRouterPush(newHomeAB ? { name: 'Feed' } : { name: 'Courses' });
    },
    printCertificate() {
      printJS(decodeURIComponent(this.certificate.urlPdf));
    },
    handleCopyCertificate() {
      this.$copy(this.certificate.urlPdf).then(() => {
        this.copyClicked = true;
      });
    },
    downloadCertificate() {
      window.open(this.certificate.urlPdf, '_blank');
    },
  },
};
</script>
<style lang="scss">
.share-icon {
  max-width: 10rem;
  max-height: 10rem;
}
</style>
