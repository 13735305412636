<template>
  <div class="card-container">
    <div class="card-body">
      <slot></slot>
    </div>

    <div class="card-footer" v-if="showFooter">
      <a :href="footerLink">
        {{ footerTitle }}
        <Icon name="ArrowRight" class="icon-arrow" />
      </a>
    </div>

  </div>
</template>

<script>

import Icon from '@/components/atoms/Icon/Icon.vue';

export default {
  name: 'Card',
  components: {
    Icon,
  },
  props: {
    children: {
      type: String,
      required: false,
    },
    footerTitle: {
      type: String,
      default: '',
    },
    footerLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    showFooter() {
      return this.footerTitle !== '' && this.footerLink !== '';
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  background: #FFFFFF;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .card-body {
    padding: 15px 25px;
  }

  .card-footer {
    border-top: 1px solid #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;

    a {
      color: #949494;
      text-decoration: none;
    }

    .icon-arrow {
      margin-left: 20px;
    }
  }
}
</style>
