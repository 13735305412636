import * as RatingsService from '@/services/ratings.service';

const getters = {
  userEntityRating: (state) => state.userEntityRating,
  isLoadingRating: (state) => state.isLoadingRating,
};

const mutations = {
  SET_USER_ENTITY_RATING(state, payload) {
    state.userEntityRating = payload;
  },
  SET_IS_LOADING_RATING(state, payload) {
    state.isLoadingRating = payload;
  },
};

const actions = {
  clearRating({ commit }) {
    commit(
      'SET_USER_ENTITY_RATING',
      null,
    );
  },
  async getUserEntityRating({ commit }, payload) {
    commit('SET_IS_LOADING_RATING', true);

    const { score } = await RatingsService
      .getRating(payload);

    commit('SET_IS_LOADING_RATING', false);

    if (!score) {
      commit(
        'SET_USER_ENTITY_RATING',
        null,
      );

      return;
    }

    commit(
      'SET_USER_ENTITY_RATING',
      score,
    );
  },
  async setUserEntityRating({ commit }, { entity, entityId, score }) {
    await RatingsService
      .setRating({ entity, entityId, score });

    commit(
      'SET_USER_ENTITY_RATING',
      score,
    );
  },
};

const state = {
  userEntityRating: null,
  isLoadingRating: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
