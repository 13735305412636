<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">

    <div class="modal-header">
      <h1>
        Trocar senha
      </h1>
    </div>

    <form>
      <div>
        <CustomInput :error="$v.form_data.currentPassword.$error" :type="passwordVisibility.currentPassword ? 'text' : 'password'"
          name="currentPassword" id="currentPassword" placeholder="Digite sua senha atual"
          title="Senha atual*" v-model="$v.form_data.currentPassword.$model">
          <template v-slot:iconEnd>
            <PdcButton class="btn-password" @click="() => handlePasswordVisibility('currentPassword')">
              <Icon v-if="passwordVisibility.currentPassword" name="PasswordEye" />
              <Icon v-else name="PasswordEyeSlash" />
            </PdcButton>
          </template>
        </CustomInput>
        <div v-if="$v.form_data.currentPassword.$error">
          <p v-if="!$v.form_data.currentPassword.required" class="error">
            A senha atual é obrigatória
          </p>
        </div>
      </div>
      <div>
        <CustomInput :error="$v.form_data.newPassword.$error" :type="passwordVisibility.newPassword ? 'text' : 'password'"
          name="newPassword" id="newPassword" placeholder="Digite sua nova senha na plataforma"
          title="Nova senha*" v-model="$v.form_data.newPassword.$model">
          <template v-slot:iconEnd>
            <PdcButton class="btn-password" @click="() => handlePasswordVisibility('newPassword')">
              <Icon v-if="passwordVisibility.newPassword" name="PasswordEye" />
              <Icon v-else name="PasswordEyeSlash" />
            </PdcButton>
          </template>
        </CustomInput>
        <div v-if="$v.form_data.newPassword.$error">
          <p v-if="!$v.form_data.newPassword.required" class="error">
            A nova senha é obrigatória
          </p>
        </div>
      </div>
      <div>
        <CustomInput :error="$v.form_data.confirmPassword.$error" :type="passwordVisibility.confirmPassword ? 'text' : 'password'"
          name="confirmPassword" id="confirmPassword" placeholder="Confirme sua nova senha na plataforma"
          title="Confirmar nova senha*" v-model="$v.form_data.confirmPassword.$model">
          <template v-slot:iconEnd>
            <PdcButton class="btn-password" @click="() => handlePasswordVisibility('confirmPassword')">
              <Icon v-if="passwordVisibility.confirmPassword" name="PasswordEye" />
              <Icon v-else name="PasswordEyeSlash" />
            </PdcButton>
          </template>
        </CustomInput>
        <div v-if="$v.form_data.confirmPassword.$error">
          <p v-if="!$v.form_data.confirmPassword.required" class="error">
            A comfirmação da nova senha é obrigatória
          </p>
          <p v-if="!$v.form_data.confirmPassword.comfirmation" class="error">
            A nova senha não confere
          </p>
        </div>
      </div>
    </form>
    <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
      <PdcButton class="button" variant="outlined" id="button-discovery" @click="handleClose">Fechar
      </PdcButton>
      <PdcButton class="button" variant="primary" id="button-discovery" @click.native="handleSubmit"
        :loading="loading_save">Salvar</PdcButton>
    </span>
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';

import PdcButton from '@/components/atoms/Button/Button.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';
import { changePassword } from '@/services/account.service';

export default {
  name: 'ChangePasswordDialog',
  components: {
    CustomInput,
    PdcButton,
    Icon,
  },
  data: () => ({
    dialog: true,
    close: false,
    form_data: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    passwordVisibility: {
      currentPassword: false,
      newPassword: false,
      confirmPassword: false,
    },
  }),
  validations: {
    form_data: {
      currentPassword: { required },
      newPassword: { required },
      confirmPassword: { required, comfirmation: sameAs('newPassword') },
    },
  },
  computed: {
    ...mapGetters('profile', ['loading_save', 'toastMessage']),
    ...mapActions('auth', ['updateAccountName']),
    passwordIsDiff() {
      return this.newPassword !== this.confirmPassword;
    },
  },
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
    // eslint-disable-next-line consistent-return
    async handleSubmit(e) {
      e.preventDefault();
      this.$v.form_data.$touch();
      if (!this.$v.form_data.$invalid) {
        try {
          const { currentPassword, newPassword } = this.form_data;
          const data = await changePassword({ currentPassword, newPassword });
          if (data) {
            this.$swal.fire(
              'Senha alterada',
              'Senha alterada com sucesso!',
              'success',
            );
            this.handleClose();
          }
        } catch (err) {
          this.$swal.fire(
            'Não foi possivel alterar sua senha',
            'Ocorreu um erro ao executar essa ação, por favor tente novamente mais tarde!',
            'warn',
          );
        }
      }
    },
    handlePasswordVisibility(field) {
      this.passwordVisibility[field] = !this.passwordVisibility[field];
    },
  },

};
</script>

<style lang="scss" scoped>
.custom-modal-pdc {
  .el-dialog {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .el-dialog__header {
    display: none;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h1 {
      margin: 0;
      font-weight: 500;
      font-size: 1.4rem;
      color: $color-neutral-high;
    }

    p {
      color: #5a4a8d;
    }
  }

  .info {
    padding-left: 10px;
    margin-bottom: 1rem;
    color: #97999D;
    font-size: 1rem;
    display: flex;
    align-items: center;

    span {
      line-height: 1;
      margin-right: 5px;
    }
  }

  .btn-password {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
  }
}
</style>
