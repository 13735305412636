<template>
  <div class="container">
    <div class="wrapper">
      <div class="box">
        <h1 class="title">No Mês da Educação ganhe até R$650 em prêmios! Aqui o seu esforço é reconhecido!</h1>
        <div class="box-subtitle">
          <p>
            <strong>Impulsione sua carreira</strong> com o Parceiro número 1 dos profissionais da Construção Civil!
          </p>
          <p>
            Esteja entre os 3 profissionais que concluem mais cursos em Abril e ganhe <strong>até R$650 para gastar em vale-presente
            a sua escolha.</strong>
          </p>
        </div>
      </div>
      <img src="@/assets/illustrations/award.svg" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: 'BannerAward',

};
</script>

<style lang="scss" scoped>
  .container{
    border-radius: 10px;
    background: linear-gradient(180deg, #665DA6 0%, #272440 100%);
    padding: 1rem 2.5rem;
  }

  .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;

    img{
      margin-left: 45px;
      height: 17rem;
    }
  }

  .title{
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #FFFFFF;
    margin-bottom: 2rem;
    max-width: 30rem;
  }

  .box-subtitle{
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 1.5rem;
  }

@media (max-width: 768px) {

  .container{
    padding: 0.5rem 1rem;
    margin: 0 -1.5rem;
    border-radius: 0;
  }

  .title{
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .box-subtitle{
    padding: 0.5rem 0.75rem;

    p{
      margin-top: 0;
      font-size: 0.875rem;
    }
  }
}

@media (max-width: 960px) {
   .wrapper {
    flex-direction: column;

    img {
      display: none;
    }
  }
}
</style>
