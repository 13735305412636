<template>
  <div>
    <template v-if="!route">
      <DefaultSkeleton v-if="loading_app" />
      <router-view v-else />
    </template>
    <template v-else>
      <TemplateAdmin v-if="loading_app" :class="route">
        <template slot="container">
          <DefaultSkeleton />
        </template>
      </TemplateAdmin>
      <router-view v-else />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import DefaultSkeleton from '@/components/organisms/Skeletons/DefaultSkeleton.vue';
import './scss/app.scss';

export default {
  name: 'App',
  components: {
    TemplateAdmin,
    DefaultSkeleton,
  },
  computed: {
    ...mapGetters('application', ['loading_app', 'route']),
  },
  mounted() {
    window.addEventListener('keydown', this.blockPrint);

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('keydown', this.blockPrint);
    });
  },
  methods: {
    blockPrint(e) {
      if (e.ctrlKey && e.key === 'p') {
        e.preventDefault();
      }
    },
  },
};
</script>
