import adminHttp from '@/utils/admin.http';
import neoHttp from '@/utils/neo.http';
import professionalProfileHttp from '@/utils/matchmaking/professionalProfile.http';
import { authsso } from '@/utils/authsso';
import legacyHttp from '../utils/legacy.http';
import coursesHttp from '../utils/courses.http';
import eventHttp from '../utils/event.http';

const getMe = () => neoHttp.get('/profile');

const getTenantId = () => neoHttp.get('/profile');

const getPreStore = () => neoHttp.patch('/pre-store/promote', {});

const updateUserAccount = (body) => neoHttp.put('/profile/update', {
  name: body.name,
  phone: body.phone,
  whatsapp: body.whatsapp,
  birthdate: body.birthdate,
  email: body.email,
});

const changePassword = (data) => neoHttp.post('/profile/change-password', {
  currentPassword: data.currentPassword,
  newPassword: data.newPassword,
});

const getTenantConfig = () => professionalProfileHttp.get('/get-tenant-config');

const getAccountByEmailAndUpdateSso = (email) => legacyHttp.get(`/Accounts/verify-update/${email}`);

const getCampaign = (campaign) => legacyHttp.get(`/Campaigns/slug/${campaign}`);

const getSlug = () => legacyHttp.get('/slugs/getcode');

const getUserJobs = () => coursesHttp.get('/user-jobs/registered');

const setUserJobs = (jobs) => coursesHttp.post('/user-jobs', jobs);

const setUserInterests = (interests) => coursesHttp.post('/user-interests', interests);

const getJobs = () => coursesHttp.get('/user-jobs');

const dailyPoints = (accountId) => legacyHttp.get(`/Points/calculatePoints?accountId=${accountId}`);
const updatePhone = (body) => authsso.put('/update-phone', body);

const updateAccountData = (name) => neoHttp.put('/profile/update', name);

const submitQuestion = ({
  subject, question, name, email,
}) => legacyHttp.post('/Points/send-question', {
  subject,
  question,
  name,
  email,
});

const createJob = (data) => professionalProfileHttp.post('/jobs', data);

const getUserJob = (id) => professionalProfileHttp.get(`/jobs?accountId=${id}`);

const relateEventTracking = (data) => eventHttp.post('/event-tracking', data);

export {
  getMe,
  getTenantId,
  getTenantConfig,
  getAccountByEmailAndUpdateSso,
  getCampaign,
  getSlug,
  getUserJobs,
  setUserJobs,
  setUserInterests,
  getJobs,
  submitQuestion,
  getPreStore,
  dailyPoints,
  updateUserAccount,
  updateAccountData,
  changePassword,
  relateEventTracking,
  updatePhone,
  createJob,
  getUserJob,
};
