<template>
  <div class="ad-overlay-container">
    <form
      class="ad-actions-container"
      :action="currentAd ? currentAd.advertising_insert_url : ''"
      method="get"
      target="_blank"
      @submit="$emit('clickAd', currentAd.advertising_insert_url)"
    >
      <custom-button ref="adCtaBtnText" variant="primary" type="submit" />
      <custom-button
        ref="skipAdBtnText"
        variant="default"
        :disabled="!canSkipAd"
        @click="onClickedSkipAdBtn"
        class="skip-ad"
      />
    </form>
  </div>
</template>

<script>
import Button from '@/components/atoms/Button/Button.vue';
import './AdOverlay.scss';

export default {
  components: {
    'custom-button': Button,
  },
  data() {
    return {
      timeToSkip: null,
      currentSetIntervalId: null,
    };
  },
  props: {
    adCtaBtnText: {
      type: String,
      default: 'Visitar anunciante',
    },
    canSkipAd: {
      type: Boolean,
      default: false,
    },
    skipAdBtnText: {
      type: String,
      default: 'Pular anúncio',
    },
    currentAd: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    canSkipAd() { },
    currentAd(val) {
      if (val) {
        this.timeToSkip = this.currentAd.advertising_insert_time_to_skip;
        this.$refs.skipAdBtnText.$el.innerHTML = `${this.skipAdBtnText} (${this.timeToSkip}s)`;

        this.currentSetIntervalId = setInterval(() => {
          if (this.timeToSkip === 1) {
            clearInterval(this.currentSetIntervalId);
            this.$refs.skipAdBtnText.$el.innerHTML = `${this.skipAdBtnText}`;

            return;
          }

          this.timeToSkip -= 1;

          this.$refs.skipAdBtnText.$el.innerHTML = `${this.skipAdBtnText} (${this.timeToSkip}s)`;
        }, 1000);
      }
    },
  },
  mounted() {
    this.setupRefs();
  },
  methods: {
    setupRefs() {
      this.$refs.adCtaBtnText.$el.innerHTML = this.adCtaBtnText;
      this.$refs.skipAdBtnText.$el.innerHTML = this.skipAdBtnText;
    },
    onClickedSkipAdBtn() {
      if (this.currentSetIntervalId) {
        clearInterval(this.currentSetIntervalId);
      }

      this.$emit('clickedSkipAdBtn');
    },
  },
};
</script>
