<!-- eslint-disable max-len -->
<template>
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6805 3.5098C16.8176 4.5683 19.0881 7.4328 19.1798 10.8257C19.1836 10.9661 19.2134 11.1052 19.2727 11.2325V11.2325C19.3098 11.3121 19.3611 11.3843 19.4241 11.4455L20.7097 12.6942C21.3047 13.2721 20.9239 14.3333 20.1215 14.3333H1.87848C1.07611 14.3333 0.695297 13.2721 1.29028 12.6942L2.57589 11.4455C2.63892 11.3843 2.69024 11.3121 2.72734 11.2325V11.2325C2.78663 11.1052 2.81643 10.9661 2.82023 10.8257C2.91191 7.4328 5.1824 4.5683 8.31947 3.5098M13.6301 8.52941V2.2549C13.6301 1.56184 13.1068 1 12.4612 1H9.53881C8.89322 1 8.36986 1.56184 8.36986 2.2549V8.52941"
      stroke="#DCDCDC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'HatConstruction',
};
</script>
