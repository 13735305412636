// eslint-disable-next-line import/prefer-default-export
export function updateDataErrors(statusCode) {
  const errors = {
    409: {
      message: 'Telefone já existe na base de dados',
      statusCode: 409,
    },
    404: {
      message: 'Id da conta não encontrado na base',
      statusCode: 404,
    },
    400: {
      message: 'Erro ao finalizar operação, verifique se os dados estão corretos e tente novamente',
      statusCode: 400,
    },
  };
  if (!errors[statusCode]) return errors[400];
  return errors[statusCode];
}
