import * as certificateService from '@/services/certificate.service';

const getInitialState = () => ({
  loadingCertificates: true,
  myCertificates: [],
});

const getters = {
  loadingCertificates: (state) => state.loadingCertificates,
  myCertificates: (state) => state.myCertificates,
};

const mutations = {
  SET_LOADING_CERTIFICATES(state, payload) {
    state.loadingCertificates = payload;
  },
  SET_MY_CERTIFICATES(state, payload) {
    state.myCertificates = payload;
  },
};

const actions = {
  async getMyCertificates({ commit, rootState }) {
    try {
      const { id } = rootState.auth.account;
      const response = await certificateService.loadMyCertificates(id);

      commit('SET_MY_CERTIFICATES', response);
      commit('SET_LOADING_CERTIFICATES', false);
    } catch (err) {
      console.log('🚀 ~ file: certificates.module.js:35 ~ getMyCertificates ~ err:', err);
    } finally {
      commit('SET_LOADING_CERTIFICATES', false);
    }
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
