<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">
    <div class="modal-header">
      <h2 class="modal-title">Meus Certificados</h2>
      <p>Selecione qual certificado você quer incluir no seu perfil</p>
    </div>

    <div v-if="loading" class="display-flex align-items-center justify-content-center">
      <Loading :width="'2rem'" :height="'2rem'" />
    </div>
    <div class="all-certificates" v-else-if="certificateList.length > 0">

      <div class="list-certificates">
        <div v-for="(certificate) in certificateList" :key="certificate.id" @click="getSelectedRows(certificate, $event)"
          class="certificate">
          <div class="certificate-img">
            <img src="../../../assets/icons/matchmaking/pdf-icon.svg" class="pdf-icon" alt="">
          </div>
          <div class="certificate-text">
            <h2 class="title">{{ certificate.title }}</h2>
            <p>{{ certificate.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="no-have-yet" v-else>
      <h2>Parece que você não tem certificados na plataforma</h2>
    </div>
    <div class="btns">
      <PdcButton class="button" id="button-discovery" @click="handleClose">Fechar
      </PdcButton>
      <PdcButton v-if="certificateList.length > 0" class="button" variant="primary" id="button-discovery"
        @click.native="setCertificatesOnProfile">
        Salvar
      </PdcButton>
    </div>
  </el-dialog>
</template>

<script>
import PdcButton from '@/components/atoms/Button/Button.vue';
import { loadMyCertificates } from '@/services/certificate.service';
import { mapActions, mapGetters } from 'vuex';
import { addCertificatesByProfile } from '@/services/matchmaking/certificate.service';
import Loading from '@/components/atoms/Loading/Loading.vue';

export default {
  name: 'AddCertificatesDialog',
  data() {
    return {
      dialog: true,
      close: false,
      modalView: false,
      loading: true,
      selectedCertificates: [],
      selectedCertificate: '',
      searchFilter: '',
      iconsPath: '',
      certificateList: [],
    };
  },
  components: {
    PdcButton,
    Loading,
  },
  computed: {
    ...mapGetters('profile', ['userProfile']),
    ...mapGetters('auth', ['account']),
    accessorie() {
      return this.$store.state.acessorieForm.acessorie;
    },

    certificateName() {
      return this.certificateList[0].name;
    },

    boatId() {
      return Number(this.$route.params.id);
    },

    filteredAccessories() {
      return this.acessoriesListData;
    },

  },
  methods: {
    ...mapActions('profile', ['addCertificatesToProfessionalProfile']),
    deleteCertificate(certificate) {
      this.$emit('deleteCertificate', certificate);
    },

    setCertificatesOnProfile() {
      try {
        this.selectedCertificates.forEach(async (certificate) => {
          const data = await addCertificatesByProfile({
            title: certificate.title,
            professionalProfileId: this.userProfile.id,
            url: certificate.urlPdf,
            description: 'Curso top',
          });
          if (data) {
            this.addCertificatesToProfessionalProfile(data);
          }
        });
        this.$swal.fire(
          'Sucesso!',
          'Certificado adicionado ao seu perfil',
          'success',
        );
        this.handleClose();
      } catch (err) {
        this.$swal.fire(
          'Erro!',
          'Erro ao adicionar certificado ao seu perfil',
          'warn',
        );
      }
    },

    handleClose() {
      this.$emit('handleClose');
    },

    getSelectedRows(certificate, event) {
      if (this.selectedCertificates.includes(certificate)) {
        event.target.classList.remove('selected');
        this.selectedCertificates = this.selectedCertificates.filter((item) => item !== certificate);
        return;
      }
      event.target.classList.add('selected');
      this.selectedCertificates.push(certificate);
    },
  },
  async mounted() {
    const response = await loadMyCertificates(this.account.id);
    this.certificateList = response;
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.check-image {
  border: none;
  padding: 0.2rem 0;

  label {
    input {
      visibility: hidden;
    }
  }
}

.check-image label>input:checked+i {
  background: url(../../../assets/img/check.svg) no-repeat;
  width: 1.54rem;
  // margin-left: -0.4rem;
  height: 1.5rem;
}

.check-image label>input+i {
  display: inline-block;
  cursor: pointer;
  max-width: 2rem;
  width: 100%;
  height: 1rem;
  background: url(../../../assets/icons/matchmaking/Fechado.svg) no-repeat;
  transition: 0.3s;
  padding: 0 0.5rem;
}

.selected {
  border: 2px solid #3E297F;
}

.custom-modal-pdc {
  .el-dialog {
    border-radius: 20px;
    width: max-content;
  }

  .el-dialog__header {
    display: none;
  }

  .modal-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2.modal-title {
      padding: 0 1rem;
      border-radius: 1rem;
      font-size: 1.6rem;
      font-weight: 500;
      font-family: Poppins;
    }

    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
      font-family: Poppins;
    }
  }

  .section-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    img {
      margin-right: 10px;
    }
  }

  .period {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    gap: 0px 40px;
  }

  .type-jobs {
    font-weight: bold;
    color: $color-brand-primary-medium;
  }
}

.list-certificates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  .certificate-img {
    display: flex;
  }

  .certificate {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: rgba(255, 251, 251, 0.5);
    border-radius: 1rem;
    box-shadow: rgba(68, 70, 72, 0.2) 0px 8px 24px;
    gap: 0.5rem;

    h2 {
      font-family: Poppins;
      // width: 100%;
      display: flex;
      text-align: left;
      font-weight: 500;
      font-size: 1rem;
      pointer-events: none;
    }

    .certificate-text {
      pointer-events: none;
    }

    .certificate-img {
      max-width: 6rem;
      pointer-events: none;

      .pdf-icon {
        margin-right: 0.5rem;
        max-width: 4rem;
        max-height: 6rem;
      }
    }

    padding: 1rem;
  }
}

.btns {
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  margin-top: 1rem;
  background: transparent;
  border-radius: 1rem;
  justify-content: flex-end;
}

.no-have-yet {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(max-width: 728px) {
  .list-certificates {
    grid-template-columns: 1fr;
    overflow: scroll;
    max-height: 60vh;
  }

  .el-dialog {
    margin-top: 30vh;
  }

  .el-dialog__header {
    display: block;
  }
}
</style>
