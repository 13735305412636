<template>
  <div class="flex-row">
    <PdcButton @click.native="initTrail()"
      variant="primary" v-if="!collection.progress.started" :buttonColor="tenantConfig.secondary_color">
      <span>Iniciar trilha</span>
    </PdcButton>
    <PdcButton @click.native="handleClick()" variant="primary"
      v-if="collection.progress.started && !(collection.progress.stepsCompleted === collection.progress.stepsTotal)"
      :buttonColor="tenantConfig.secondary_color">
      <span>Continuar trilha</span>
    </PdcButton>
    <PdcButton @click.native="handleClick" variant="primary"
      v-if="collection.progress.started && (collection.progress.stepsCompleted === collection.progress.stepsTotal)"
      :buttonColor="tenantConfig.secondary_color">
      <span>Refazer trilha</span>
    </PdcButton>
    <LikeComponent class="w-30 pl-8" entity="collection" :entity_id="this.collection.id" mode="button"
      :is_liked="this.collection.isLiked?.isLike ? true : false" />
  </div>
</template>
<script>
import PdcButton from '@/components/atoms/Button/Button.vue';
import LikeComponent from '@/components/atoms/Likes/LikeComponent.vue';
import { mapGetters } from 'vuex';
import { clickedTrail } from '@/services/dataLayer.service';
import { updateTrail } from '@/services/course.service';

export default {
  props: ['collection'],
  computed: {
    ...mapGetters('application', ['tenantConfig']),
    ...mapGetters('auth', ['account']),
    whiteLabel() {
      return localStorage.getItem('URL_WHITELABEL');
    },
  },
  methods: {
    handleClick() {
      if (window.dataLayer) {
        clickedTrail(window.dataLayer, this.collection);
      }
      this.$router.push({ name: 'CourseCollection', params: { collectionId: this.collection.id } });
      if (this.account) {
        const trailBody = {
          trailId: this.collection.id,
          accountId: this.account.id,
          accessDate: new Date().toISOString().slice(0, 10).replace(/-/g, '/'),
        };
        const response = updateTrail(trailBody);
        if (response.data);
      }
    },
    initTrail() {
      this.$router.push({ name: 'CourseCollection', params: { whiteLabel: this.whiteLabel, collectionId: this.collection.id } });
    },
  },
  components: {
    PdcButton,
    LikeComponent,
  },
};
</script>
