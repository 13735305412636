<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="true"
    :show-close-button="true">
    <div class="close-modal">
    </div>
    <div class="qrcode-header">
      <h2 class="qrcode-title">Compartilhe seu perfil profissional</h2>
      <div @click="closeModal">
        <Icon name="Close" />
      </div>
    </div>
    <div class="qrcode-body">
      <qrcode-vue :value="url" :size="150" level="H" />
    </div>
  </el-dialog>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';

export default {
  name: 'QrCodeDialog',
  data() {
    return {
      dialog: true,
      close: false,
    };
  },
  components: {
    QrcodeVue,
    Icon,
  },
  props: {
    url: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('handleClose');
    },
  },
};
</script>
<style scoepd lang="scss">
.close-modal {
  display: flex;
  justify-content: flex-end;
  // margin: 1rem;
}

.qrcode-header {
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  div {
    cursor: pointer;
  }

  h2.qrcode-title {
    color: #585858;
    font-weight: 500;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 23px;
    display: flex;

  }
}

.qrcode-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

@media(max-width: 1050px) {
  .user-profile-card {
    .user-profile-infos {
      .profile-description {
        width: 75%;
      }

    }
  }
}

@media(max-width: 750px) {
  .user-profile-card {
    .user-profile-infos {
      .profile-description {
        width: 65%;
      }

    }
  }
}

@media(max-width: 550px) {
  .user-profile-card {
    .user-profile-infos {
      .profile-description {
        width: 50%;
      }

    }
  }
}</style>
