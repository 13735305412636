<!-- eslint-disable max-len -->
<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M7.50036 1.36364C6.69448 1.36364 5.8965 1.52236 5.15196 1.83074C4.40743 2.13912 3.73093 2.59112 3.16109 3.16094C2.59125 3.73075 2.13922 4.40722 1.83083 5.15172C1.52243 5.89621 1.3637 6.69416 1.3637 7.5C1.3637 8.30584 1.52243 9.10379 1.83083 9.84828C2.13922 10.5928 2.59125 11.2692 3.16109 11.8391C3.73093 12.4089 4.40743 12.8609 5.15196 13.1693C5.8965 13.4776 6.69448 13.6364 7.50036 13.6364C9.12791 13.6364 10.6888 12.9899 11.8396 11.8391C12.9905 10.6883 13.637 9.12747 13.637 7.5C13.637 5.87254 12.9905 4.31173 11.8396 3.16094C10.6888 2.01014 9.12791 1.36364 7.50036 1.36364ZM0 7.5C0 3.35795 3.35812 0 7.50036 0C11.6426 0 15.0007 3.35795 15.0007 7.5C15.0007 11.642 11.6426 15 7.50036 15C3.35812 15 0 11.642 0 7.5ZM8.35268 4.26136C8.35268 4.4874 8.26288 4.70418 8.10304 4.86401C7.9432 5.02384 7.72641 5.11364 7.50036 5.11364C7.27431 5.11364 7.05753 5.02384 6.89769 4.86401C6.73785 4.70418 6.64805 4.4874 6.64805 4.26136C6.64805 4.03533 6.73785 3.81855 6.89769 3.65872C7.05753 3.49888 7.27431 3.40909 7.50036 3.40909C7.72641 3.40909 7.9432 3.49888 8.10304 3.65872C8.26288 3.81855 8.35268 4.03533 8.35268 4.26136ZM6.81851 6.13636C6.63767 6.13636 6.46424 6.2082 6.33637 6.33606C6.2085 6.46393 6.13666 6.63735 6.13666 6.81818C6.13666 6.99901 6.2085 7.17243 6.33637 7.3003C6.46424 7.42817 6.63767 7.5 6.81851 7.5V10.9091C6.81851 11.0899 6.89035 11.2633 7.01822 11.3912C7.14609 11.5191 7.31952 11.5909 7.50036 11.5909C7.6812 11.5909 7.85463 11.5191 7.9825 11.3912C8.11038 11.2633 8.18221 11.0899 8.18221 10.9091V6.81818C8.18221 6.63735 8.11038 6.46393 7.9825 6.33606C7.85463 6.2082 7.6812 6.13636 7.50036 6.13636H6.81851Z"
      fill="#5D4A99" />
  </svg>
</template>

<script>
export default {
  name: 'Info',
};
</script>
