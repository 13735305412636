import Vue from 'vue';
import * as CourseService from '@/services/course.service';
import { finishCourse, finishExam } from '@/services/dataLayer.service';

const getInitialState = () => ({
  current_step: 0,
  answers: {},
  time_exceeded: false,
  exame_loading: true,
  questions: [],
  exame_minutes: 0,
  attempt: {},
  last_step_page: 'revising',
});

const getters = {
  current_step: (state) => state.current_step,
  is_in_progress: (state) => state.current_step >= 1,
  answers: (state) => state.answers,
  time_exceeded: (state) => state.time_exceeded,
  exame_is_finished(state) {
    const answerArray = Object.values(state.answers);
    return state.questions.length === answerArray.length;
  },
  questions: (state) => state.questions,
  is_correcting_exame: (state) => state.last_step_page === 'correcting',
  is_revising_exame: (state) => state.last_step_page === 'revising',
  exame_loading: (state) => state.exame_loading,
  exame_minutes: (state) => state.exame_minutes,
  attempt: (state) => state.attempt,
  last_step_page: (state) => state.last_step_page,
};

const mutations = {
  SET_CURRENT_STEP(state, payload) {
    state.current_step = payload;
  },
  SET_STATE_VALUE(state, payload) {
    Vue.set(state, payload.key, payload.value);
  },
  SET_ANSWER(state, payload) {
    Vue.set(state.answers, payload.key, payload.value);
  },
  SET_TIMEOUT_EXCEEDED(state, payload) {
    state.time_exceeded = payload;
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_EXAME_LOADING(state, payload) {
    state.exame_loading = payload;
  },
  SET_EXAME_MINUTES(state, payload) {
    state.exame_minutes = payload;
  },
  SET_ATTEMPT(state, payload) {
    state.attempt = payload;
  },
  SET_LAST_STEP_PAGE(state, payload) {
    state.last_step_page = payload;
  },
};

const actions = {
  setCurrentStep({ commit }, payload) {
    commit('SET_CURRENT_STEP', payload);
  },
  setAnswer({ commit }, payload) {
    commit('SET_ANSWER', { key: payload.key, value: payload.answer });
  },
  setTimeoutExceeded({ commit }, payload) {
    commit('SET_TIMEOUT_EXCEEDED', payload);
  },
  async fetchQuestions({ commit }, payload) {
    const questions = await CourseService.fetchQuestions(payload);
    commit('SET_QUESTIONS', questions);
    return questions;
  },
  async fetchExameStarter({ commit, dispatch }, slug) {
    dispatch('resetState');
    commit('SET_EXAME_LOADING', true);
    const course = await dispatch('courses/fetchOnlyCourse', slug, { root: true });
    if (!course.completed) {
      return dispatch('application/appRouterPush', { name: 'CourseDetail', params: { course: course.path } }, { root: true });
    }
    const questions = await CourseService.fetchQuestions(course.id);
    commit('SET_EXAME_MINUTES', course.quizTimeToFinish);
    commit('SET_QUESTIONS', questions);
    return { course, questions };
  },
  setExameLoading({ commit }, payload) {
    commit('SET_EXAME_LOADING', payload);
  },
  async startAttempt({ commit }, courseId) {
    const attempt = await CourseService.startExame(courseId);
    commit('SET_ATTEMPT', attempt);
    return attempt;
  },
  async sendAnswer({ commit, state }, payload) {
    const questions = Object.assign([], state.questions);
    const questionsIndex = questions.findIndex((x) => x.id === payload.courseQuestionId);
    const alternativeIndex = questions[questionsIndex].courseAlternatives.findIndex((x) => x.id === payload.courseAlternativeId);
    questions[questionsIndex].courseAlternatives[alternativeIndex].picked = true;
    commit('SET_QUESTIONS', questions);
    const response = await CourseService.sendAnswer(state.attempt.course.id, payload);
    return response;
  },
  async proccessAnswers({ state, rootGetters }) {
    const lastQuestion = state.questions[state.questions.length - 1];
    const alternative = lastQuestion.courseAlternatives.find((x) => x.picked);
    const payload = { courseQuestionId: lastQuestion.id, courseAlternativeId: alternative.id };
    const response = await CourseService.proccessAnswers(state.attempt.course.id, payload);
    const account = rootGetters['auth/account'];
    const course = rootGetters['courses/course'];

    finishExam(window.dataLayer, course, account, response.results);
    if (response.results.approved) finishCourse(window.dataLayer, course, account);

    return response;
  },
  resetState({ commit }) {
    const initialState = getInitialState();
    Object.keys(initialState).forEach((key) => {
      commit('SET_STATE_VALUE', { key, value: initialState[key] });
    });
  },
  setLastStepPage({ commit }, payload) {
    commit('SET_LAST_STEP_PAGE', payload);
  },
  async abortAttempt({ commit }, payload) {
    commit('SET_EXAME_LOADING', true);
    await CourseService.abortAttempt(payload);
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
