<template>
  <div class="form-group">
    <label :for="id" v-if="title">{{ title }}</label>
    <textarea
      :type="type"
      :id="id"
      :class="{ hasError: error }"
      :placeholder="placeholder"
      :name="name"
      :value="value"
      :maxlength="maxlength"
      @input="onInput"
      @change="onChange"
    ></textarea>
    <div>{{ charCount }}/{{ maxlength }}</div>
    <small v-if="helperText">{{ helperText }}</small>
  </div>
</template>

<script>
export default {
  name: 'CustomTextArea',
  data() {
    return {
      charCount: this.value ? this.value.length : 0,
    };
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    helperText: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    value: {
      type: String,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    maxlength: {
      type: Number,
      default: 200,
    },
  },
  methods: {
    onInput(event) {
      const newValue = event.target.value;
      if (newValue.length <= this.maxlength) {
        this.charCount = newValue.length;
        this.$emit('input', newValue);
      }
    },
    onChange(event) {
      this.$emit('change', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

label {
  color: $color-neutral-secondary;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 0.5rem;
  white-space: nowrap;
}

textarea {
  box-sizing: border-box;
  border: 0.5px solid #979797;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 18px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: $color-neutral-secondary;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: 120px;

  &::placeholder {
    color: $color-neutral-secondary;
    text-align: justify;
  }

  &:focus {
    border-color: #979797;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(151, 151, 151, 0.25);
  }
}

small {
  color: $color-neutral-secondary;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  font-weight: 400;
}
</style>
