/* eslint-disable indent */
/* eslint-disable camelcase */
import axios from 'axios';
import { getCookie, checkTokenExpiration, cleanLoginDataFromLocalStorage } from './helpers';

const initOptions = {
  url: `${process.env.VUE_APP_AUTHSSO_URL}` || 'https://authsso.parceirodaconstrucao.com.br/',
  clientId: 'front',
};

const frontUrl = process.env.VUE_APP_FRONT_AUTHSSO_URL || 'https://frontauthsso.parceirodaconstrucao.com.br/';

const client = axios.create({
  baseURL: `${initOptions.url}`,
});

function exportTokenToLocalStorage(token) {
  window.localStorage.setItem('loopback-token', JSON.stringify(token));
}

client.setToken = (token, save = true) => {
  client.token = token;
  client.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  if (save) exportTokenToLocalStorage(token);
};

const addTokenFromLocalStorage = () => {
  let token = window.localStorage.getItem('loopback-token');
  if (token) {
    token = token.replace('__q_strn|', '');
    client.setToken(JSON.parse(token), false);
  }
};

const returnOriginFromLocalStorage = () => {
  let origin = window.localStorage && window.localStorage.getItem('pdc-origin');
  if (origin) {
    origin = origin.replace('__q_strn|', '');
    return JSON.parse(origin);
  }
  return null;
};

const returnCampaignOnLocalStorage = () => {
  let origin = window.localStorage && window.localStorage.getItem('utm_campaign');
  if (origin) {
    origin = origin.replace('__q_strn|', '');
    return origin;
  }
  return null;
};

addTokenFromLocalStorage();

const getParameterOnUrl = (field) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(field);
};

// eslint-disable-next-line no-unused-vars
const createLoginUrl = ({ redirectUri }) => {
  cleanLoginDataFromLocalStorage();
  const origin = returnOriginFromLocalStorage();
  const campaign = returnCampaignOnLocalStorage();

  const baseUrl = process.env.VUE_APP_FRONT_AUTHSSO_URL;

  const scope = 'openid';
  const state = Date.now().toString(36) + Math.random().toString(36).substring(2);
  const responseType = 'code';
  const responseMode = 'query';
  const url = `${baseUrl}?client_id=${encodeURIComponent(initOptions.clientId)}&redirect_uri=${encodeURIComponent(
    redirectUri,
  )}&state=${encodeURIComponent(state)}&response_mode=${encodeURIComponent(responseMode)}&response_type=${encodeURIComponent(
    responseType,
  )
    }&utm_source=${getParameterOnUrl('utm_source') || origin || ''
    }&utm_campaign=${getParameterOnUrl('utm_campaign') || campaign || ''
    }&utm_medium=${getParameterOnUrl('utm_medium') || ''
    }&utm_term=${getParameterOnUrl('utm_term') || ''
    }&utm_content=${getParameterOnUrl('utm_content') || ''
    }&utm_id=${getParameterOnUrl('utm_id') || ''
    }&scope=${encodeURIComponent(scope)}`;
  window.location.replace(url);
  return url;
};

// eslint-disable-next-line camelcase
const getTokenByCode = async ({ code }) => {
  try {
    const data = {
      code,
    };
    const token = await client.put('/code', data).then((res) => res.data.token);
    return token;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('problema na obtencao do token', e);
    createLoginUrl({ redirectUri: window.location.origin + window.location.pathname });
    return null;
  }
};

const refreshToken = async () => new Promise((resolve, reject) => {
  const token = getCookie('PDC_TOKEN') || localStorage.getItem('loopback-token');
  const { refresh_token } = JSON.parse(token);

  client
    .post('/refresh-token', { refresh_token })
    .then((res) => {
      window.localStorage.setItem('loopback-token', JSON.stringify(res.data));
      client.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
      client.setToken(res.data, false);
      resolve(res);
    })
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        cleanLoginDataFromLocalStorage();
      }
      const url = window.location.origin + window.location.pathname;
      reject(createLoginUrl({ redirectUri: url }));
    });
});

const createLogoutUrl = async () => {
  const { pathname } = window.location;
  // eslint-disable-next-line no-useless-escape
  const redirectUrl = window.location.origin + pathname.replace(/\/[^\/]*/, '');
  const url = `${frontUrl}/?redirect_uri=${redirectUrl}`;
  window.location.replace(url);
};

const getLoggedUser = async (token = '') => {
  try {
    const tokenExpired = checkTokenExpiration(JSON.parse(localStorage.getItem('loopback-token')));
    if (tokenExpired) {
      const refresh = await refreshToken();
      const response = await client.get('/logged', { headers: { Authorization: `Bearer ${refresh.data.access_token}` } });
      return response;
    }
    const response = await client.get('/logged', { headers: { Authorization: `Bearer ${token}` } });
    return response;
  } catch ({ response }) {
    if (response.status === 401) {
      cleanLoginDataFromLocalStorage();
    }
    return null;
  }
};

const getUserInfo = async ({
  redirectOnError, countRequests = 0, authorization = null,
}) => new Promise((resolve, reject) => {
  let token = getCookie('PDC_TOKEN') || localStorage.getItem('loopback-token');
  if (token && !authorization) {
    token = token.replace(/[\\]/g, '');
    token = JSON.parse(decodeURIComponent(token));
    client.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
    client.token = token;
    client.setToken(token, true);
  }

  if (redirectOnError && !client.token) {
    const url = window.location.origin + window.location.pathname;
    reject(createLoginUrl({ redirectUri: url }));
  }
  const clientVariable = getLoggedUser(token.access_token || authorization);
  clientVariable
    .then((res) => resolve(res))
    .catch(async (e) => {
      try {
        if (countRequests >= 2) throw new Error('Max retrys');
        if (token && token.refresh_token) {
          const refresh = await refreshToken();
          await getUserInfo({
            token, redirectOnError, countRequests: countRequests + 1, authorization: refresh.data.access_token,
          });
        } else {
          const url = window.location.origin + window.location.pathname;
          reject(createLoginUrl({ redirectUri: url }));
        }
      } catch (error) {
        if (e && e.response && e.response.status === 401) {
          cleanLoginDataFromLocalStorage();
        }
        if (redirectOnError) {
          const url = window.location.origin + window.location.pathname;
          reject(createLoginUrl({ redirectUri: url }));
        }
      }
      resolve();
    });
});

// eslint-disable-next-line import/prefer-default-export
export {
  getTokenByCode, getUserInfo, createLogoutUrl, refreshToken, client as authsso,
};
