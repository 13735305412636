// eslint-disable-next-line import/prefer-default-export
export function getCookie(name, cbIfNotExists) {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  let value = null;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);

    if (cookie.indexOf(nameEQ) === 0) {
      value = cookie.substring(nameEQ.length, cookie.length);
      return value;
    }
  }

  // no cookie for you

  if (typeof cbIfNotExists === 'function') {
    return cbIfNotExists();
  }

  return null;
}

export function setCookie(name, value, seconds) {
  let cookie = '';
  let expires = '';
  const path = '/';
  const domain = 'parceirodaconstrucao.com.br';

  if (seconds) {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  cookie = `${name}=${value}${expires}; domain=${domain}; path=${path}`;

  document.cookie = cookie;

  return value;
}

export function removeDuplicatedResults(results) {
  const uniqueCoursesId = [];
  const uniqueCourseArray = results.filter((element) => {
    if (!element) return '';
    const isDuplicate = uniqueCoursesId.includes(element.id);

    if (!isDuplicate) {
      uniqueCoursesId.push(element.id);

      return true;
    }

    return false;
  });
  return uniqueCourseArray;
}

export function buildQueryString(values) {
  const queryString = Object.entries(values)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryString;
}

export async function download(link, filename) {
  try {
    const response = await fetch(link, { method: 'GET' });
    if (!response.ok) {
      throw new Error('Erro ao fazer o download');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundo
    window.URL.revokeObjectURL(url);
    a.remove();
  } catch (error) {
    console.error('Erro: ', error);
  }
}

export const countKeyOccurrences = (array, keyToCount) => {
  const count = array.reduce((accumulator, currentValue) => {
    if (currentValue[keyToCount] !== undefined) {
      return accumulator + currentValue[keyToCount];
    }
    return accumulator;
  }, 0);

  return count;
};

export const checkTokenExpiration = (token) => {
  // Verificar se a expiracao atual do token - Valor sera retornado em segundos (Timestamp)
  const { exp } = JSON.parse(Buffer.from(token.access_token.split('.')[1], 'base64'));
  return token.expires_in >= 0 && new Date().getTime() / 1000 > exp;
};

export function cleanLoginDataFromLocalStorage() {
  localStorage.removeItem('loopback-token');
  localStorage.removeItem('URL_WHITELABEL');
  setCookie('PDC_TOKEN', '', 1);
  setCookie('token', '', 1);
}
