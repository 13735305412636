<template>
  <TemplateAdmin :loading="loading_app">
    <template slot="container">
      <div class="content-page" :style="{ '--section-title-color': this.tenantConfig.primary_color }">
        <div class="top-cards flex-col">
          <!-- <TopBanner type="home" /> -->
          <RecommendedSection :categories-tab="false" />
          <CategorySection />
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
// import TopBanner from '@/components/organisms/Home/TopBanner.vue';
import RecommendedSection from '@/components/organisms/Home/RecomendedSection.vue';
import CategorySection from '@/components/organisms/Home/CategorySection.vue';

export default {
  name: 'Course',
  components: {
    TemplateAdmin,
    // TopBanner,
    RecommendedSection,
    CategorySection,
  },
  created() {
    this.getRecommendedCourses();
    this.getCourseCategories();
    this.getPdcTalksCategories();
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner']),
    ...mapGetters('application', ['loading_app', 'tenantConfig']),
  },
  methods: {
    ...mapActions('courses', ['getRecommendedCourses', 'getCourseCategories',
      'getPdcTalksCategories',
    ]),
  },
};
</script>

<style lang="scss">
@import 'Courses.scss';
</style>
