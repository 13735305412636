<template>
  <div :class="[ 'carousel', this.hiddenArrows ? 'swiper-custom-hidden' : '' ]">
    <div class="title-section">
      <p class="carousel__title" v-if="title">{{ title }}</p>
      <div class="carousel__slider-controls" v-if="items.length > 1">
        <img :class="swiperPreviousBtn" slot="button-prev" src="@/assets/icons/card/chevron-left.svg" alt="" />
        <img src="@/assets/icons/card/chevron-right.svg" :class="swiperNextBtn" slot="button-next" alt="" />
      </div>
    </div>
    <div class="carousel__line" v-if="title" />
    <div class="carousel__slider">
      <Swiper ref="swiper" :options="swiperOptions">
        <SwiperSlide v-for="(item, index) in items" :key="index">
          <slot name="item" :item="item" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => { },
    },
    hiddenArrows: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    swiperNextBtn() {
      // eslint-disable-next-line no-underscore-dangle
      return `swiper-button-next-${this._uid}`;
    },
    swiperPreviousBtn() {
      // eslint-disable-next-line no-underscore-dangle
      return `swiper-button-prev-${this._uid}`;
    },
    swiperOptions() {
      return {
        slidesPerView: 1.04,
        centeredSlides: false,
        spaceBetween: 10,
        grabCursor: false,
        loop: false,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          // clickable: true,
        },
        navigation: {
          prevEl: `.${this.swiperPreviousBtn}`,
          nextEl: `.${this.swiperNextBtn}`,
        },
        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          980: {
            slidesPerView: 2.5,
            spaceBetween: 24,
          },
          700: {
            slidesPerView: 1.8,
            spaceBetween: 24,
          },
        },
        ...this.options,
      };
    },
  },
};
</script>
<style lang="scss">
.carousel {
  display: flex;
  position: relative;
  flex-direction: column;

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: inherit !important;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  // .swiper-slide {
  //   padding: 3px;
  //   height: auto !important;
  // }

  .swiper-slide {
    max-width: 378px;
    margin-right: 24px;
  }

  .carousel__line {
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #f2f2f2;

    @media only screen and (max-width: 767px) {
      border: none;
    }
  }

  .carousel__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1.25rem;
    line-height: 124%;
    display: flex;
    align-items: center;
    color: #212529 !important;
  }

  .title-section {
    display: flex;
    justify-content: space-between;

    .carousel__slider-controls {
      cursor: pointer;
      width: 60px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      -webkit-tap-highlight-color: transparent;

      .swiper-button-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

}

.carousel .title-section .carousel__slider-controls {
  display: flex;
  gap: 1rem;
  align-items: center;

  img {
    height: 2rem;
  }
}

@media(max-width: 430px) {
  .carousel {
    .carousel__title {
      max-width: 250px;
      font-size: 1rem;
    }
    .title-section {
      display: flex;
      justify-content: space-between;

      .carousel__slider-controls {
        cursor: pointer;
        width: 56px;
        // display: flex;
        gap: 10px;

        .swiper-button-disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

  }

  .carousel .title-section .carousel__slider-controls {
    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      height: 2rem;
    }
  }
}

@media(max-width: 375px) {

  .carousel {
    .carousel__title {
      max-width: 150px
    }
    .title-section {
      display: flex;
      flex-direction: row;

      .carousel__slider-controls {
        position: absolute;
        right: 10px;
        z-index: 9;
        cursor: pointer;
        top: 10px;
        width: 56px;
        // display: flex;
        gap: 10px;

        .swiper-button-disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

  }

  .carousel .title-section .carousel__slider-controls {
    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      height: 2rem;
    }
  }
}

.swiper-custom-hidden .carousel__slider-controls{
  display: none !important;
}
</style>
