<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">

    <div class="modal-header">
      <h1>Habilidades</h1>
    </div>

    <div class="tip">
      <span>Selecione até 10 habilidades para destacar em seu perfil.</span>
      <span>{{skillsName.length}}/10</span>
    </div>
    <span class="error-msg" v-if="skillsName.length >= 10">Você atingiu o limite máximo de habilidades</span>
    <form class="form-skills">
      <div class="skill" v-for="(item, index) in skills" :key="index">
        <label :class="{ 'skill--disabled': skillsName.length >= 10 && !skillsName.includes(item.name) }">
          <input
          type="checkbox"
          v-model="skillsName"
          :value="item.name"
          :disabled="skillsName.length >= 10 && !skillsName.includes(item.name)"
          />
          {{ item.name }}
        </label>
      </div>
    </form>
    <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
        <PdcButton class="button" variant="outlined" id="button-discovery" @click="handleClose">Fechar
        </PdcButton>
        <PdcButton class="button" variant="primary" id="button-discovery" @click.native="handleSubmit"
          :loading="loading_save">Salvar</PdcButton>
      </span>
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';

import CONSTANTS from '@/utils/constants';

import './AddNewSkillsDialog.scss';

export default {
  name: 'AddNewSkillsDialog',
  components: {
    PdcButton,
  },
  data: () => ({
    dialog: true,
    close: false,
    skillsName: [],
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'skillSelected', 'toastMessage', 'skills', 'userProfile']),
    renderLevels() {
      return CONSTANTS.data.LEVELS.map((level, index) => ({ label: level, value: index + 1 }));
    },
  },
  methods: {
    ...mapActions('profile', ['addNewSkill', 'clearObject', 'loadSkills']),

    handleClose() {
      this.$emit('handleClose');
      this.clearObject('skillSelected');
    },
    async handleSubmit(e) {
      e.preventDefault();

      await this.addNewSkill({
        profileId: this.userProfile.id,
        skillNames: this.skillsName,
      });

      this.handleClose();

      if (this.toastMessage) {
        this.$swal.fire(
          this.toastMessage.title,
          this.toastMessage.description,
          this.toastMessage.type,
        );
      }
    },
  },
  async mounted() {
    await this.loadSkills();
    this.skillsName = this.userProfile.skills.map((skill) => skill.name);
  },
};
</script>
