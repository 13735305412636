<template>
  <div class="trails-section">

    <div class="recommended" v-for="(trail_details, idx) in trail_details" :key="idx">

      <CardTrailDetails />
      <div class="recommended__slider" style="width: 100%">

        <div class="trails_course_container">
          <div class="filter_container align-items-center">
            Filtrar:
            <PdcButton @click.native="toggleFilter('all')" class="filter_button px-8"
              :variant="current_filter === 'all' ? 'primary' : 'default'"> Todos
            </PdcButton>
            <PdcButton @click.native="toggleFilter('incompleted')" class="filter_button px-8"
              :variant="current_filter === 'incompleted' ? 'primary' : 'default'"> Não iniciado
            </PdcButton>
            <PdcButton @click.native="toggleFilter('started')" class="filter_button px-8"
              :variant="current_filter === 'started' ? 'primary' : 'default'"> Em
              andamento </PdcButton>
            <PdcButton @click.native="toggleFilter('completed')" class="filter_button px-8"
              :variant="current_filter === 'completed' ? 'primary' : 'default'"> Finalizado
            </PdcButton>
          </div>

          <div class="not_found_container" v-if='trail_details.items.length === 0'>
            <img class="not_found_image" src="https://courses-stg.s3.us-east-2.amazonaws.com/no-results-found.svg" />
            <p class="not_found_tittle">Nenhum resultado encontrado</p>
            <p class="not_found_description">Não há cursos para o filtro selecionado.</p>
          </div>
          <div v-for="(course, index) in trail_details.items" :key="index">
            <CourseCardDetails :course="course" :finished="checkFinishedCourse(course)" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import '../Course/CourseCardDetails/CourseCardDetails.scss';
import { mapGetters, mapActions } from 'vuex';
import CourseCardDetails from '@/components/organisms/Course/CourseCardDetails/CourseCardDetails.vue';
import CardTrailDetails from '@/components/organisms/CardTrailDetails/CardTrailDetails.vue';
import PdcButton from '@/components/atoms/Button/Button.vue';

export default {
  name: 'CategorySection',

  components: {
    CourseCardDetails,
    CardTrailDetails,
    PdcButton,
  },
  watch: {

    current_filter(val) {
      this.trailsFilterDetails({ status: val, collectionsId: this.$route.params.collectionId });
    },
  },
  computed: {
    ...mapGetters('courses', ['collections', 'finished', 'collections_loading', 'trail_details']),
    ...mapGetters('ratings', ['userEntityRating', 'isLoadingRating']),

    trailId() {
      return Number(this.$route.params.collectionId);
    },

    has_rating() {
      return this.userEntityRating !== null;
    },

  },
  created() {
    this.getUserEntityRating({ entity: 'collection', entityId: this.trailId });
    this.shouldShowRating();
  },
  data() {
    return {
      repeaterId: '',
      filters: [],
      current_filter: 'all',
      showRating: false,
    };
  },
  methods: {
    ...mapActions('courses', ['trailsFilterDetails']),
    ...mapActions('ratings', ['getUserEntityRating']),
    toggleFilter(index) {
      if (this.current_filter === index) {
        this.current_filter = null;
      } else {
        this.current_filter = index;
      }
    },
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },

    stepsCompleted() {
      return this.trail_details.progress.stepsCompleted;
    },
    stepsTotal() {
      return this.trail_details.progress.stepsTotal;
    },

    shouldShowRating() {
      if (this.trail_details.progress.stepsTotal === this.trail_details.progress.stepsCompleted) {
        this.showRating = true;
      }
    },

    removeCssProperty() {
      const refKey = `swiper${this.repeaterId}`;
      if (this.$refs[refKey]) {
        this.$refs[refKey][0].$el.children[0].classList.remove('myClass');
      }
    },
    addCssProperty() {
      const refKey = `swiper${this.repeaterId}`;
      if (this.$refs[refKey]) {
        this.$refs[refKey][0].$el.children[0].classList.add('myClass');
      }
    },
  },
};
</script>
