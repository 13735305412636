var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rating-display-container"},[_c('StarRating',{attrs:{"read-only":true,"rating":_vm.parsedRating,"inactiveColor":"transparent","activeColor":"#FFE100","activeBorderColor":"#E5CA00","border-width":1,"border-color":"#ADB5BD","star-size":12,"show-rating":false,"increment":0.5,"star-points":[
      23, 2,
      15.85, 16.39,
      0, 19,
      11.57, 29.82,
      8.7, 45.41,
      23, 38.09,
      37.3, 45.41,
      34.43, 29.82,
      46, 19,
      30.15, 16.39
    ]}}),_c('div',{staticClass:"rating-display-meta-container"},[_c('span',[_vm._v(_vm._s(_vm.rating? _vm.parsedLocalizedRating: ''))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }