<!-- eslint-disable max-len -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<mask id="mask0_11962_5594" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
<rect width="30" height="30" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_11962_5594)">
<path d="M12.3844 16.1625L13.3688 12.9563L10.7812 10.875H13.9875L15 7.725L16.0125 10.875H19.2188L16.6031 12.9563L17.5875 16.1625L15 14.1656L12.3844 16.1625ZM8.25 26.625V17.9344C7.5375 17.1469 6.98438 16.2469 6.59063 15.2344C6.19688 14.2219 6 13.1438 6 12C6 9.4875 6.87187 7.35938 8.61563 5.61563C10.3594 3.87187 12.4875 3 15 3C17.5125 3 19.6406 3.87187 21.3844 5.61563C23.1281 7.35938 24 9.4875 24 12C24 13.1438 23.8031 14.2219 23.4094 15.2344C23.0156 16.2469 22.4625 17.1469 21.75 17.9344V26.625L15 24.375L8.25 26.625ZM15 18.75C16.875 18.75 18.4688 18.0938 19.7812 16.7812C21.0938 15.4688 21.75 13.875 21.75 12C21.75 10.125 21.0938 8.53125 19.7812 7.21875C18.4688 5.90625 16.875 5.25 15 5.25C13.125 5.25 11.5312 5.90625 10.2188 7.21875C8.90625 8.53125 8.25 10.125 8.25 12C8.25 13.875 8.90625 15.4688 10.2188 16.7812C11.5312 18.0938 13.125 18.75 15 18.75ZM10.5 23.2781L15 22.125L19.5 23.2781V19.7906C18.8438 20.1656 18.1359 20.4609 17.3766 20.6766C16.6172 20.8922 15.825 21 15 21C14.175 21 13.3828 20.8922 12.6234 20.6766C11.8641 20.4609 11.1562 20.1656 10.5 19.7906V23.2781Z" fill="#665DA6"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'Medal',
};
</script>
