<template>
  <TemplateAdmin :loading="loading_app">
    <template slot="container">
      <div class="content-page" :style="{ '--section-title-color': this.tenantConfig.primary_color }">
        <div class="top-cards">
          <!-- <TopBanner type='feed' /> -->
          <RecommendedCollectionsSection />
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';

// import TopBanner from '@/components/organisms/Home/TopBanner.vue';
import RecommendedCollectionsSection from '@/components/organisms/Home/RecommendedCollectionsSection.vue';

export default {
  name: 'Course',
  components: {
    TemplateAdmin,
    // TopBanner,
    RecommendedCollectionsSection,
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  created() {
    this.getRecommendedCourses();
    this.getCoursesOngoing();
    this.getCoursesFinished();
    this.getCourseCategories();
    this.getPdcTalksCategories();
    this.getRecommendedCollections();
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner', 'recommended_collections']),
    ...mapGetters('application', ['loading_app', 'tenantConfig']),
  },
  methods: {
    ...mapActions('courses', ['getRecommendedCourses', 'getCoursesOngoing', 'getCoursesFinished', 'getCourseCategories',
      'getPdcTalksCategories', 'getRecommendedCollections']),
  },
};
</script>

<style lang="scss">
@import 'CollectionList.scss';
</style>
