export function formatDate(dateString) {
  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  const [year, month] = dateString.split('-');
  const newMonth = months[Number(month - 1)];

  return `${newMonth} ${year}`;
}

export function formatJobDuration(startDate, endDate) {
  const formattedStartDate = formatDate(startDate);
  if (!endDate) {
    return `${formattedStartDate} — Presente`;
  }
  const formattedEndDate = formatDate(endDate);
  return `${formattedStartDate} — ${formattedEndDate}`;
}

export function compareDates(startDate, endDate, hasMonths) {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : new Date();

  const diff = end - start;

  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));

  const formatYear = years > 0 ? `${years} ano${years !== 1 ? 's' : ''}` : '';

  if (!hasMonths) return `${formatYear}`;

  return `${formatYear} e ${months < 10 ? '0' : ''}${months} mes${months !== 1 ? 'es' : ''}`;
}

// eslint-disable-next-line consistent-return
export function isValidDate(startDate, endDate) {
  if (endDate) {
    return new Date(startDate) < new Date(endDate);
  } return true;
}
