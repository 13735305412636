<template>
  <div class="recommended-courses">
    <CoursesSkeleton v-if="recommended_by_course_loading" type="recommended" />
    <template v-else>
      <Carousel v-if="recommended.length > 0 && !finishAllRecommended" title="Cursos recomendados"
        :items="recommended">
        <template v-slot:item="{ item }">
          <CourseCard :course="item" :finished="true" section="recommended" />
        </template>
      </Carousel>
      <div v-else class="empty-courses">
        <p>Parabéns pela sua jornada até aqui, <b> {{ userName }}!</b></p>
        <p>{{ emptyCoursesText }}</p>
        <span>
          Te convidamos a visitar os cursos mais recentes da plataforma e ficar atento
          às nossas comunicações por e-mail, combinado?
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Carousel from '@/components/atoms/Carousel/Carousel.vue';
import CourseCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import './RecommendedCourses.scss';

export default {
  name: 'RecommendedCourses',
  components: {
    Carousel,
    CourseCard,
    CoursesSkeleton,
  },
  created() {
    this.getRecommendedCoursesByCourse();
  },
  computed: {
    ...mapGetters('auth', ['account', 'accountComplete']),
    ...mapGetters('courses', ['recommended_by_course_loading', 'recommended_by_course']),

    userName() {
      if (this.accountComplete) return this.accountComplete.name.split(' ')[0];
      return this.account?.name.split(' ')[0];
    },
    recommended() {
      // eslint-disable-next-line
      return this.recommended_by_course.map((course) => ({
        ...course,
        approved: course.completed,
      }));
    },
    finishAllRecommended() {
      return this.recommended.every((course) => course.completed && course.approved);
    },
    emptyCoursesText() {
      return this.finishAllRecommended
        ? 'Você concluiu todos os cursos da plataforma e, por isso, não temos recomendações no momento.'
        : 'Não temos recomendações de cursos no momento.';
    },
  },
  methods: {
    ...mapActions('courses', ['getRecommendedCoursesByCourse']),
  },
};
</script>
