<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">

    <div class="modal-header">
      <h1>
        Preencha o seu nome completo.
      </h1>
      <p>
        Esse será o seu nome no certificado, após a emissão não será possível mudar.
      </p>
    </div>

    <form>
      <CustomInput :error="changeNameError" type="text" name="name" id="name" placeholder="Seu nome no certificado"
        title="Nome*" v-model="name" />
      <p v-if="changeNameError" class="error">Ocorreu um erro ao executar essa ação, por favor tente novamente!</p>
    </form>
    <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
      <PdcButton class="button" variant="outlined" id="button-discovery" @click="handleClose">Fechar
      </PdcButton>
      <PdcButton class="button" variant="primary" id="button-discovery" @click.native="handleSubmit"
        :loading="loading_save">Salvar</PdcButton>
    </span>
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import { updateAccountData } from '@/services/account.service';

import './ChangeNameDialog.scss';

export default {
  name: 'ChangeNameDialogVue',
  components: {
    CustomInput,
    PdcButton,
  },
  data: () => ({
    dialog: true,
    close: false,
    name: '',
    changeNameError: false,
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'toastMessage']),
    ...mapActions('auth', ['updateAccountName']),
  },
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },
    async handleSubmit(e) {
      e.preventDefault();
      try {
        await updateAccountData({ name: this.name });
        this.$swal.fire(
          'Nome alterado',
          'Seu nome na plataforma foi alterado com sucesso!',
          'success',
        );
        this.handleClose();
        this.changeNameError = false;
      } catch (err) {
        this.changeNameError = true;
      }
    },
  },

};
</script>
