<template>
  <div class="ranking-section">
    <CoursesSkeleton type="ranking" v-if="ranking_loading" />
    <Carousel v-else title="Ranking de Cursos" :items="ranking_courses.slice(0, 10)">
      <template v-slot:item="{ item }">
        <CourseCard :course="item" :finished="checkFinishedCourse(item)" section="ranking" />
      </template>
    </Carousel>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CourseCard from '@/components/organisms/Course/CoursesCard/CourseCard.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CoursesSkeleton.vue';
import Carousel from '@/components/atoms/Carousel/Carousel.vue';

export default {
  name: 'RankingSection',
  components: {
    CourseCard,
    Carousel,
    CoursesSkeleton,
  },
  computed: {
    ...mapGetters('courses', ['ranking_courses', 'finished', 'ranking_loading']),
  },
  methods: {
    checkFinishedCourse(course) {
      return !!this.finished.find((c) => c.id === course.id);
    },
  },
};
</script>
