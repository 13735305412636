<template>
  <component :is="iconComponent" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconComponent() {
      const iconName = this.name.charAt(0).toUpperCase() + this.name.slice(1);

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`./${iconName}/${iconName}.vue`).default;
    },
  },
};
</script>
