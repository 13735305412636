<template>
  <div>
    <template>
      <div v-if="isIntro" :style="{ '--primary-color': this.tenantConfig.primary_color }">
        <div class="about">
          <div class="about__left">
            <p class="about__title">Comentários ({{ commentsLength }})</p>

            <!-- Form para criar comentário -->

            <form>
              <div class="input_container">
                <img class="icon" v-if="!accountComplete.avatar" src="@/assets/icons/comment-card/avatar.svg" alt="" />
                <div class="avatar" v-else>
                  <img class="icon" :src="accountComplete.avatar" alt="" />
                </div>

                <textarea @click="showButtonContainer" style="overflow: hidden" ref="insertComment" type="text"
                  v-model="text" class="input_comment" :placeholder="placeHolderComments" :maxlength="limit"
                  @keydown="resizeTextArea">
                </textarea>

                <div class="input_count" ref="inputCount">{{ text.length }}/{{ limit }}</div>
              </div>

              <div class="comment-button-container" v-show="showButton">
                <PdcButton @click.native="hideButtonContainer" class="comment_button" variant="outlines">Cancelar
                </PdcButton>
                <PdcButton @click.native="handleSubmit" :disabled="!text.length" class="comment_button" variant="primary">
                  Comentar</PdcButton>
              </div>
            </form>

            <CoursesSkeleton type="comments" v-if="comments_loading" />

            <!-- Mensagem quando não possui nenhum comentário -->

            <div class="comments-area" v-else>
              <div class="empty-field-container" v-if="!comments_by_user.waiting.length && !comments.all.length">
                <img class="empty-image" src="@/assets/illustrations/commentary.svg" alt="" />
                <p class="empty-tittle">Nenhum Comentário</p>
                <p class="empty-description">Seja o primeiro a fazer um comentário nessa aula</p>
              </div>

              <!-- Inicia sessão com todos os comentários -->
              <div v-for="(comment, index) in comments.all" :key="comment.id">
                <div :key="comment.id" v-if="(!showingMoreComments && index < commentsLimit) || showingMoreComments">
                  <!-- Mostra todos os comentários -->
                  <template v-if="comment.status !== 'waiting'">
                    <CommentCard :key="comment.id" :comment="comment" entity="course_topic" :entityId="courseTopicId"
                      :accountId="account.id" :type="comment.status" @likeComment="likeComment"
                      :current_lesson_title="current_lesson_title" :course="course" />
                  </template>

                  <!-- Mostra todos os comentários pendentes pelo o usuário -->
                  <template v-if="comment.status === 'waiting' && account.id === comment.account.id">
                    <CommentCard :key="comment.id" :comment="comment" entity="course_topic" :entityId="courseTopicId"
                      :accountId="account.id" :type="comment.status" @likeComment="likeComment"
                      :current_lesson_title="current_lesson_title" :course="course" />
                  </template>

                  <!-- Inicia sessão de resposta dos comentários  -->
                  <div v-if="comments_with_open_replies === comment.id">
                    <CommentCard style="margin-left: 35px;" v-for="comment in reply_comments_by_user.waiting"
                      v-bind:key="comment.id" :comment="comment" entity="comment" :entityId="comment.entityId"
                      :accountId="account.id" type="waitingReply" :current_lesson_title="current_lesson_title"
                      :course="course" />

                    <CommentCard style="margin-left: 35px;" v-for="comment in reply_comments.approved"
                      v-bind:key="comment.id" :comment="comment" entity="comment" :entityId="comment.entityId"
                      :accountId="account.id" type="approvedReply" @likeComment="likeComment"
                      :current_lesson_title="current_lesson_title" :course="course" />

                    <CommentCard style="margin-left: 35px;" v-for="comment in reply_comments.neutral"
                      v-bind:key="comment.id" :comment="comment" entity="comment" :entityId="comment.entityId"
                      :accountId="account.id" type="neutralReply" @likeComment="likeComment"
                      :current_lesson_title="current_lesson_title" :course="course" />

                    <CommentCard style="margin-left: 35px;" v-for="comment in reply_comments.question"
                      v-bind:key="comment.id" :comment="comment" entity="comment" :entityId="comment.entityId"
                      :accountId="account.id" type="questionReply" @likeComment="likeComment"
                      :current_lesson_title="current_lesson_title" :course="course" />
                  </div>
                </div>
              </div>

              <div class="show-comment-container" v-if="comments.all && comments.all.length > commentsLimit">
                <img src="@/assets/icons/comment-card/show-comments.svg" alt="" />
                <span class="show-comments" @click="toggleShowingMoreComments">
                  Mostrar {{ showingMoreComments ? "menos": "mais" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import './CommentSection.scss';
import { mapGetters, mapActions } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import CoursesSkeleton from '@/components/organisms/Skeletons/CommentsSkeleton.vue';
import CommentCard from '@/components/organisms/Course/CommentCard/CommentCard.vue';
import { ref } from 'vue';

export default {
  props: {
    isIntro: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    current_lesson_title: {
      type: String,
      default: '',
    },
    course: {
      type: Object,
    },
  },
  components: {
    PdcButton,
    CoursesSkeleton,
    CommentCard,
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
    ...mapGetters('auth', ['account', 'accountComplete']),
    ...mapGetters('comment', ['comments', 'comments_loading',
      'comments_by_user', 'reply_comments', 'reply_comments_by_user', 'comments_with_open_replies']),
    courseTopicId() {
      return Number(this.$route.params.lesson);
    },
    showClickedReplies(clickedCommentId) {
      return this.comments_with_open_replies.some((commentId) => commentId === clickedCommentId);
    },
    commentsLength() {
      const waitingByUser = this.comments.all.filter((comment) => comment.status === 'waiting' && comment.account.id === this.account.id);
      return this.comments.approved.length + this.comments.neutral.length + this.comments.question.length + waitingByUser.length;
    },
  },
  data() {
    return {
      commentsLimit: 2,
      limit: 255,
      text: '',
      entityId: Number(this.$route.params.lesson),
      commentId: 0,
      showButton: ref(false),
      showingMoreComments: false,
      commentWithOpenReplies: null,
      placeHolderComments:
      'Espaço reservado para troca de conhecimentos e experiências sobre o conteúdo das aulas. Vamos lá?!\n'
      + 'Dica: para utilizá-lo, lembre-se das condições do termo de uso da plataforma.',
    };
  },
  methods: {
    ...mapActions('comment', ['createComments']),

    resizeTextArea() {
      const el = this.$refs.insertComment;
      const counter = this.$refs.inputCount;
      setTimeout(() => {
        el.style.cssText = 'height:auto; padding:0';
        el.style.cssText = `height:${el.scrollHeight - 38}px`;
        if (this.text.length >= this.limit) counter.classList.add('input_error');
        else counter.classList.remove('input_error');
      }, 0);
    },
    toggleShowingMoreComments() {
      this.showingMoreComments = !this.showingMoreComments;
    },
    toggleReplyIcon() {
      this.showReplyIcon = !this.showingMoreComments;
    },
    current_lesson_id() {
      return Number(this.$route.params.lesson) || null;
    },
    accountId() {
      return this.account.id;
    },
    showButtonContainer() {
      this.showButton = true;
    },
    hideButtonContainer() {
      this.showButton = false;
    },

    insertComment() {
      this.$emit('insertComment', { commentLength: this.text.length, status: 'waiting' }, false);
    },

    likeComment(qtyLikes, commentLength, commentStatus) {
      this.$emit('likeComment', { status: commentStatus, qtyLikes: qtyLikes + 1 }, commentLength);
    },

    handleSubmit(e) {
      e.preventDefault();
      const data = {
        text: this.text,
        entity: 'course_topic',
        entityId: Number(this.$route.params.lesson),
        tenant_id: this.tenantConfig?.id,
      };
      this.createComments(data);
      if (window.dataLayer) {
        this.insertComment();
      }
      this.text = '';
    },
  },
};
</script>
