<template>
  <div class="form-group">

    <label v-if="title">{{ title }}</label>
    <select v-model="selectedValue" @change="handleChange">
      <option v-if="defaultLabel" value="" disabled>{{ defaultLabel }}</option>
      <option v-else value="" disabled>Selecione</option>
      <option v-for="option in options" :value="option.value" :key="option.value">{{ option.label }}</option>
    </select>
    <small v-if="helperText">{{ helperText }}</small>
    <slot name="helperHTML"></slot>
  </div>
</template>

<script>
// import './CustomInput.scss';

export default {
  name: 'CustomSelect',
  props: {
    title: {
      type: String,
      required: false,
    },
    defaultLabel: {
      type: String,
      required: false,
    },
    helperText: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String || Number,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  methods: {
    handleChange(event) {
      this.selectedValue = event.target.value;
      this.$emit('input', this.selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

label {
  color: $color-neutral-secondary;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 0.5rem;
}

select {
  box-sizing: border-box;
  border: 0.5px solid #979797;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  border-radius: 18px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: $color-neutral-secondary;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &::placeholder {
    color: $color-neutral-secondary;
    text-align: justify;
  }

  &:focus {
    border-color: #979797;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(151, 151, 151, .25);
  }
}

small {
  color: $color-neutral-secondary;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
  font-weight: 400;
}
</style>
