<template>
  <el-dialog :visible.sync="dialog" max-width="500" :before-close="handleClose">
    <div class="content-card" v-if="campaign">
      <h2>{{ campaign.dialogTitle }}</h2>
      <p>
        {{ campaign.dialogText }}
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <PdcButton @click.native="handleClose">Fechar</PdcButton>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';

export default {
  components: {
    PdcButton,
  },
  data: () => ({
    dialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters('auth', ['campaign']),
  },
  async mounted() {
    const getParameterOnUrl = (field) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(field);
    };
    const utmCampaign = getParameterOnUrl('utm_campaign');

    if (utmCampaign && utmCampaign === 'member-get-member') {
      this.loadCampaign(utmCampaign);
    } else if (window.localStorage.getItem('utm_campaign') && !window.localStorage.getItem(window.localStorage.getItem('utm_campaign'))) {
      this.loadCampaign(window.localStorage.getItem('utm_campaign'));
    }
  },
  methods: {
    ...mapActions('auth', ['onLoadCampaign', 'getSlug']),
    async loadCampaign(campaign) {
      await this.onLoadCampaign(campaign);
      setTimeout(async () => {
        if (this.campaign && !this.checkIsUrl(this.campaign.redirect)) {
          this.dialog = true;
        } else if (this.campaign && this.checkIsUrl(this.campaign.redirect)) {
          if (this.campaign.slug === 'member-get-member') {
            const slug = await this.getSlug();
            window.localStorage.setItem(this.campaign.slug, JSON.stringify(this.campaign));
            window.location.href = `${this.campaign.redirect}?slug=${slug.slug}`;
          } else {
            window.open(this.campaign.redirect, '_blank');
          }
        }
      }, 800);
    },
    setDialog() {
      this.dialog = false;
      window.localStorage.setItem(this.campaign.slug, JSON.stringify(this.campaign));
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    handleClose() {
      this.setDialog();
    },
    checkIsUrl(string) {
      return new RegExp('[a-zA-Z0-9]+://([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?').test(string);
    },
  },
};
</script>
<style lang="scss">
.content-card {
  text-align: center;
  max-width: 80%;
  margin: 0 auto;

  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 124%;
    color: #3E297F;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 144%;
    margin: 16px 0px;
    letter-spacing: -0.01em;
    color: #495057;
    min-height: 80px;
    word-break: normal;
    margin-top: 50px;
  }
}
</style>
