import Vue from 'vue';
import * as LikesService from '@/services/likes.service';

const getters = {
  entity_likes: (state) => state.entity_likes,
  entity_user_liked: (state) => state.entity_user_liked,
};

const mutations = {
  SET_ENTITY_LIKES(state, payload) {
    Vue.set(state.entity_likes, payload.index, payload);
  },
  SET_ENTITY_USER_LIKED(state, payload) {
    Vue.set(state.entity_user_liked, payload.index, payload.value);
  },
};

const makeIndex = (prefix, payload) => `${prefix}_${payload.entity}_${payload.entity_id}`;

const actions = {
  setUserLiked({ commit }, payload) {
    commit('SET_ENTITY_USER_LIKED', payload);
  },
  setLikes({ commit }, payload) {
    commit('SET_ENTITY_LIKES', payload);
  },
  toggleLike({ state, commit }, payload) {
    clearTimeout(state.timeout);
    state.timeout = setTimeout(() => {
      LikesService.toggleEntityLike(payload);
    }, 700);
    commit('SET_ENTITY_USER_LIKED', {
      index: makeIndex('liked', payload),
      value: payload.liked,
    });
    const oldState = state.entity_likes[makeIndex('likes', payload)];
    oldState.qty = payload.liked ? (oldState.qty + 1) : (oldState.qty - 1);
    commit('SET_ENTITY_LIKES', oldState);
  },
};

const state = {
  timeout: 0,
  entity_likes: {},
  entity_user_liked: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
