<template>
  <div class="time-exceeded flex-col align-items-center">
    <img class="sm-w-50 md-w-40 lg-w-40" src="@/assets/icons/course-exame/time-exceeded.png" alt="time-exceeded" />
    <b class="text-align-center flex-row align-items-center mt-32 time-exceeded--text">
      Seu tempo esgotou!
      <Emoji class="ml-8" value="⌛" :size="35.56" />
    </b>
    <div class="time-exceeded--description mt-32 mb-24">
      O tempo destinado para esse exame de <b>{{ exame_minutes }}</b> minutos acabou agora. Não se preocupe, revise os
      conteúdos das aulas e faça uma
      nova tentativa.
    </div>
    <div class="time-exceeded--description small mb-40">Lembre-se: Você pode assistir as aulas quantas vezes for
      necessário.</div>
    <div class="time-exceeded--btns flex-row w-100 justify-content-center align-items-center">
      <PdcButton variant="primary" outlined class="sm-w-100 md-w-50 lg-w-40" @click.native="goToCourse"> Rever aulas
      </PdcButton>
      <PdcButton variant="primary" class="sm-w-100 md-w-50 lg-w-40" @click.native="restartExame"
        :loading="tryAgainLoading">
        Tentar novamente
      </PdcButton>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import Emoji from '@/components/atoms/Emoji.vue';

export default {
  name: 'ExameTimeExceeded',
  data() {
    return {
      tryAgainLoading: false,
    };
  },
  props: {
    handlePreviousStep: Function,
  },
  components: {
    PdcButton,
    Emoji,
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('exame', ['exame_minutes']),
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    ...mapActions('exame', ['abortAttempt']),
    goToCourse() {
      return this.appRouterPush({ name: 'CourseDetail', params: { course: this.course.path } });
    },
    restartExame() {
      // TODO REVISAR ROTA
      this.tryAgainLoading = true;
      this.abortAttempt(this.course.id).then(() => {
        this.$router.go(this.$router.currentRoute);
      });
    },
  },
};
</script>
<style lang="scss">
.time-exceeded {
  .time-exceeded--text {
    font-family: Poppins;
    font-weight: bold;
    color: #3E297F;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }

  .time-exceeded--description {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;
    line-height: 148%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #495057;

    &.small {
      color: #495057;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.64;
    }
  }
}
</style>
