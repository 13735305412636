import * as rankingService from '@/services/ranking.service';

const getInitialState = () => ({
  loadingRanking: true,
  loadingFilterRanking: false,
  rankingList: [],
  periodActive: '',
});

const getters = {
  loadingRanking: (state) => state.loadingRanking,
  loadingFilterRanking: (state) => state.loadingFilterRanking,
  rankingList: (state) => state.rankingList,
  periodActive: (state) => state.periodActive,
};

const mutations = {
  SET_LOADING_RANKING(state, payload) {
    state.loadingRanking = payload;
  },
  SET_LOADING_FILTER_RANKING(state, payload) {
    state.loadingFilterRanking = payload;
  },
  SET_RANKING_LIST(state, payload) {
    state.rankingList = payload;
  },
  SET_PERIOD_ACTIVE(state, payload) {
    state.periodActive = payload;
  },
};

const actions = {
  async getRanking({ commit, rootState }, period) {
    try {
      commit('SET_LOADING_RANKING', true);
      const { id } = rootState.auth.account;
      const response = await rankingService.loadRanking(id, {
        period,
      });
      commit('SET_RANKING_LIST', response);
    } catch (err) {
      console.error('🚀 ~ file: certificates.module.js:35 ~ getMyCertificates ~ err:', err);
    } finally {
      commit('SET_LOADING_RANKING', false);
      commit('SET_PERIOD_ACTIVE', period);
    }
  },

  async filterRanking({ commit, rootState }, payload) {
    try {
      commit('SET_LOADING_FILTER_RANKING', true);
      const [startDate, endDate] = payload.map((date) => date.toISOString().slice(0, 10));
      const { id } = rootState.auth.account;
      const response = await rankingService.loadRanking(id, {
        startDate,
        endDate,
      });

      commit('SET_RANKING_LIST', response);
    } catch (err) {
      console.error('🚀 ~ file: certificates.module.js:35 ~ getMyCertificates ~ err:', err);
    } finally {
      commit('SET_LOADING_FILTER_RANKING', false);
    }
  },
};

const state = getInitialState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
