<template>
  <div class="player-container">
    <div class="vimeoPlayer" />
    <VideoOverlap v-if="showVideoCountdown" />
    <AdOverlay
      v-show="adIsPlaying && currentAd"
      :canSkipAd="canSkipAd"
      :currentAd="currentAd"
      @clickedSkipAdBtn="skipAd"
      @clickAd="clickAd"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Player from '@vimeo/player';

import AdOverlay from '@/components/atoms/AdOverlay/AdOverlay.vue';
import VideoOverlap from '@/components/atoms/VideoOverlap/VideoOverlap.vue';
import './VimeoPlayer.scss';

export default {
  name: 'Player',
  components: {
    AdOverlay,
    VideoOverlap,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    topic_ads: {
      type: [Object],
      default: () => ({}),
    },
    options: {
      type: [Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      player: null,
      adPlayerOptions: {
        transparent: false,
        responsive: true,
        controls: false,
        autoplay: true,
      },
      shownAds: [],
      timeBeforeAd: 0,
      adIsPlaying: false,
      canSkipAd: false,
      currentAd: null,
      firstAdAtPosition: null,
      isFullscreen: false,
    };
  },
  watch: {
    id(val) {
      this.player.loadVideo(val);
    },
    // eslint-disable-next-line no-unused-vars
    topic_ads(val) {
      // console.log('new topic_ads', val);
    },
  },
  mounted() {
    this.setPlayerCountdown(false);
    this.setFirstAdAtPosition();
    this.install();
  },
  methods: {
    ...mapActions('player', ['setPlayerCountdown']),

    clickAd() {
      this.$emit('clickAd', this.currentAd);
    },
    createPlayer(options) {
      if (this.player) {
        this.$emit('destroy');
        this.player.destroy();
      }

      this.player = new Player(this.$el, options);

      this.player.on('play', this.onPlay);
      this.player.on('ended', this.onEnded);
      this.player.on('timeupdate', this.onTimeUpdate);
      this.player.on('loaded', this.onLoaded);
      this.player.on('fullscreenchange', this.onFullScreenChange);

      this.player.ready()
        .then(() => this.$emit('created'))
        .catch((err) => {
          const errorText = `Vimeo Error: ${err.message}`;

          // eslint-disable-next-line no-console
          console.error(errorText);

          this.$emit('error');
        });
    },
    getLastShownAd() {
      const [lastShownAd] = this.shownAds.slice(-1);

      return lastShownAd;
    },
    isFirstAdAtPosition(ad) {
      const adPosition = ad.advertising_insert_position;
      const adId = ad.advertising_insert_id;

      const firstAdAtPosition = this.firstAdAtPosition[adPosition];
      const firstAdPosition = firstAdAtPosition.advertising_insert_position;
      const firstAdId = firstAdAtPosition.advertising_insert_id;

      return adId === firstAdId && adPosition === firstAdPosition;
    },
    stillHasAdsToShowAtCurrentPosition() {
      return !!this.topic_ads[this.currentAd.advertising_insert_position].length;
    },
    install() {
      this.$emit('loadComponent');

      this.createPlayer({ ...this.defaultPlayerOptions, id: this.id });
    },
    onPlay() {
      this.$emit('onPlay');
      if (!this.adIsPlaying) {
        this.showAd('start');
      } else {
        const lastShownAd = this.getLastShownAd();

        this.currentAd = lastShownAd;
        setTimeout(
          () => { this.canSkipAd = true; },
          lastShownAd.advertising_insert_time_to_skip * 1000,
        );
      }
    },
    onFullScreenChange() {
      this.player.getFullscreen().then((fullscreen) => {
        this.isFullscreen = fullscreen;
      });
    },
    async onEnded() {
      this.$emit('ended', true);

      if (this.isFullscreen) {
        this.player.exitFullscreen();
      }

      if (this.adIsPlaying) {
        if (!this.stillHasAdsToShowAtCurrentPosition()) {
          this.hideAd();
        }

        await this.showAd(this.currentAd.advertising_insert_position);
      } else {
        await this.showAd('end');
        this.setPlayerCountdown(true);
      }
    },
    async onTimeUpdate(data) {
      if (!this.adIsPlaying) {
        this.$emit('timeupdate', data);
      }

      const midVideoAdInsertPosition = this.getMidVideoAdsPosition(data.seconds);

      if (!this.adIsPlaying && midVideoAdInsertPosition) {
        await this.showAd(midVideoAdInsertPosition);
      }
    },
    onLoaded(data) {
      this.$emit('loaded', data);
    },
    async showAd(position) {
      this.canSkipAd = false;

      const ads = this.topic_ads[position];
      if (!ads) {
        return;
      }

      const adsNotShown = ads.filter(
        (ad) => !this.shownAds.some(
          (shownAd) => shownAd.advertising_insert_position === ad.advertising_insert_position
            && shownAd.advertising_insert_id === ad.advertising_insert_id,
        ),
      );
      if (!adsNotShown.length) {
        return;
      }

      const adToBeShown = this.topic_ads[position].shift();
      if (this.isFirstAdAtPosition(adToBeShown)) {
        this.timeBeforeAd = await this.player.getCurrentTime();
        this.createPlayer({
          ...this.adPlayerOptions,
          id: adToBeShown.advertising_insert_video_id,
        });
        this.adIsPlaying = true;
      } else {
        this.$emit('beforeNextAd');

        await this.player.loadVideo(adToBeShown.advertising_insert_video_id);

        this.player.play();
      }
      this.$emit('adShow', adToBeShown);
      this.shownAds.push(adToBeShown);
    },
    hideAd() {
      this.createPlayer({ ...this.defaultPlayerOptions, id: this.id });

      if (this.currentAd.advertising_insert_position !== 'end') {
        this.player.setCurrentTime(this.timeBeforeAd);
        this.player.play();
      }

      this.adIsPlaying = false;
    },
    async skipAd() {
      if (!this.stillHasAdsToShowAtCurrentPosition()) {
        this.hideAd();
        this.$emit('skipAd', this.currentAd);
      } else {
        await this.showAd(this.currentAd.advertising_insert_position);
      }
    },
    getMidVideoAdsPosition(currentTime) {
      const midVideoAdInsertPositions = Object.keys(this.topic_ads).filter(
        (position) => !Number.isNaN(Number(position)),
      );

      return midVideoAdInsertPositions.find((position, index) => {
        const parsedPosition = Number(position);
        const nextPosition = midVideoAdInsertPositions[index + 1];

        if (nextPosition) {
          return currentTime >= parsedPosition && currentTime < Number(nextPosition);
        }

        return currentTime >= parsedPosition;
      });
    },
    setFirstAdAtPosition() {
      const topicAdsEntries = Object.entries(this.topic_ads);

      this.firstAdAtPosition = topicAdsEntries.reduce(
        (firstAdAtPosition, [position, [firstAd]]) => ({
          ...firstAdAtPosition,
          [position]: firstAd,
        }),
        {},
      );
    },
  },
  computed: {
    ...mapGetters('player', ['player_next_lesson', 'player_countdown']),

    defaultPlayerOptions() {
      return {
        transparent: false,
        responsive: true,
        autoplay: true,
        ...this.options,
      };
    },
    currentRouteName() {
      return this.$route.name;
    },
    showVideoCountdown() {
      return this.player_next_lesson.id
        && this.player_countdown
        && this.currentRouteName !== 'Feed';
    },
  },
};
</script>
<style scoped>

</style>
