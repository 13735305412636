<template>
  <CardAward>
    <h3 class="title">
      <Icon :name="'Medal'" />
      {{ title }}
    </h3>
    <div class="description">
      <p>{{ description }}</p>
    </div>
  </CardAward>
</template>

<script>

import CardAward from '@/components/atoms/CardAward/CardAward.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';

export default {
  name: 'CardVoucher',
  components: {
    CardAward,
    Icon,
  },
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
  .title{
    color: #2C2C2C;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description{
    border: 1px solid #C8C4E966;
    border-radius: 5px;
    padding: 0.25rem 1.5rem;

    p{
      color: #4B4A49;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
  }

  svg{
    flex-basis: 60px;
  }

  @media(max-width: 768px){
    .title{
      align-items: baseline;
    }
  }
</style>
