<!-- eslint-disable max-len -->
<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="21" r="1" fill="#585858" />
    <circle cx="16" cy="21" r="1" fill="#585858" />
    <circle cx="19" cy="21" r="1" fill="#585858" />
    <path
      d="M17.1875 3.64526C17.6019 3.23085 18.1639 2.99805 18.75 2.99805C19.0402 2.99805 19.3275 3.0552 19.5956 3.16625C19.8637 3.2773 20.1073 3.44007 20.3125 3.64526C20.5177 3.85045 20.6805 4.09404 20.7915 4.36214C20.9026 4.63023 20.9597 4.91757 20.9597 5.20776C20.9597 5.49794 20.9026 5.78528 20.7915 6.05337C20.6805 6.32147 20.5177 6.56506 20.3125 6.77026L7.29167 19.7911L3.125 20.8328L4.16667 16.6661L17.1875 3.64526Z"
      stroke="#585858" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'Edit',
};
</script>
