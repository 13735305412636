<template>
  <el-dialog id="add-new-experience-dialog" class="custom-modal-pdc" :visible.sync="dialog" :close-on-click-modal="false"
    :show-close="false">

    <div class="modal-header">
      <h1 v-if="!isEdit">Adicionar formação acadêmica</h1>
      <h1 v-else>Editar formação acadêmica</h1>
    </div>

    <form>

      <CustomInput v-model="academicEducation.educationalInstitution" type="text"
        name="educationalInstitution" id="title" placeholder="Ex: FMU"
        title="Instituição de ensino*" />

      <CustomSelect v-model="academicEducation.degree" title="Tipo de graduação*" :options="renderAcademicLevels">
      </CustomSelect>

      <CustomInput v-model="academicEducation.studyArea" type="text"
        name="studyArea" id="studyArea" placeholder="Ex: Arquitetura e Urbanismo"
        title="Área de estudo (Ou nome do curso)" />

      <el-alert :title="error" v-if="!!error" type="error" style="margin-bottom: 1rem;" />

      <div class="period">

        <CustomSelect v-model="startMonth" :options="renderMonths" title="Início" name="initial_month"
          defaultLabel="Mês" />

        <CustomSelect :options="renderYears" v-model="startYear" defaultLabel="Ano" name="initial_year" />

        <CustomSelect v-model="endMonth" :options="renderMonths" title="Término (Ou previsão)"
          name="end_month" defaultLabel="Mês" />
        <CustomSelect v-model="endYear" :options="renderYears" defaultLabel="Ano"
          name="end_year" />
      </div>

      <CustomInput v-model="academicEducation.note" type="text"
        name="note" id="note" placeholder="Adicione alguma observação sobre sua formação acadêmica"
        title="Nota (Opcional)" />

      <span slot="footer" class="accept-footer" style="display: flex; margin-top: 45px; justify-content: flex-end;">
        <PdcButton class="button" variant="outlined" id="button-discovery" @click="handleClose">Fechar
        </PdcButton>
        <PdcButton class="button" variant="primary" id="button-discovery" @click.native="handleSubmit"
          :loading="loading_save">Salvar</PdcButton>
      </span>
    </form>
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect.vue';

import CONSTANTS from '@/utils/constants';
import { isValidDate } from '@/utils/functions/date';

export default {
  name: 'AddNewAcademicEducation',
  components: {
    PdcButton,
    CustomInput,
    CustomSelect,
  },
  data: () => ({
    dialog: true,
    close: false,
    startYear: '',
    startMonth: '',
    endYear: '',
    endMonth: '',
    isEdit: false,
    error: '',
    academicEducation: {
      id: '',
      educationalInstitution: '',
      degree: '',
      startDate: '',
      endDate: '',
      studyArea: '',
      note: '',
    },
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'academicEducationSelected', 'userProfile', 'toastMessage']),
    renderAcademicLevels() {
      return CONSTANTS?.data?.ACADEMIC_LEVELS;
    },
    renderMonths() {
      return CONSTANTS?.data?.MONTHS;
    },
    renderYears() {
      return CONSTANTS?.data?.YEARS;
    },
  },
  methods: {
    ...mapActions('profile', ['addAcademicEducation', 'updateAcademicEducation', 'clearObject']),
    handleClose() {
      this.$emit('handleClose');
      this.clearObject('academicEducationSelected');
    },
    // eslint-disable-next-line consistent-return
    combineValues(startDate, endDate) {
      if (startDate && endDate) {
        const combinedDate = `${endDate}-${startDate}-01`;
        return combinedDate;
      }
    },
    async handleSubmit() {
      const body = {
        educationalInstitution: this.academicEducation.educationalInstitution,
        degree: this.academicEducation.degree,
        startDate: this.combineValues(this.startMonth, this.startYear),
        endDate: this.combineValues(this.endMonth, this.endYear),
        studyArea: this.academicEducation?.studyArea,
        note: this.academicEducation?.note,
      };

      if (!isValidDate(body.startDate, body.endDate)) {
        this.error = 'Data de término não pode ser menor ou igual a data de início';
      } else {
        const professionalProfileId = this.userProfile.id;

        if (this.isEdit) {
          await this.updateAcademicEducation({ id: this.academicEducation.id, professionalProfileId, ...body });
        } else {
          await this.addAcademicEducation(body);
        }

        this.handleClose();

        if (this.toastMessage) {
          this.$swal.fire(
            this.toastMessage.title,
            this.toastMessage.description,
            this.toastMessage.type,
          );
        }
      }
    },
  },
  async mounted() {
    if (Object.keys(this.academicEducationSelected).length > 0) {
      this.isEdit = true;
      const [year, month] = this.academicEducationSelected.startDate.split('-');
      // eslint-disable-next-line max-len
      const [yearEnd, monthEnd] = this.academicEducationSelected.endDate ? this.academicEducationSelected.endDate.split('-') : '2022-01'.split('-');
      this.academicEducation = this.academicEducationSelected;
      this.startYear = year;
      this.startMonth = month;
      this.endYear = yearEnd;
      this.endMonth = monthEnd;
    }
  },
};
</script>
