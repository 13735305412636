<template>
  <div class="flex-col course-exame-step-1 mt-48 sm-mt-0 mb-80">
    <div class="flex-row sm-flex-col align-items-center">
      <div class="w-40 br-10px sm-hide">
        <img src="@/assets/icons/course-exame/exame-intro-image.png" alt="exame-intro" class="w-100" />
      </div>
      <div class="flex-col w-60 md-pl-32 lg-pl-48 sm-w-100">
        <h1 class="w-100 course-exame-step-1--title sm-text-align-center sm-mb-56">É hora de testar seu conhecimento no
          exame!</h1>
        <div v-bind:class="['flex-row', 'course-exame-step-1--icon-row mb-64', 'sm-justify-content-between']">
          <div class="flex-row align-items-center">
            <div class="course-exame-step-1--icon clock mr-8" />
            <span class="course-exame-step-1--text"> {{ formattedQuizTime }}</span>
          </div>
          <div class="flex-row ml-40 align-items-center">
            <div class="course-exame-step-1--icon layers mr-8" />
            <span class="course-exame-step-1--text"> {{ questions.length }} {{ questions.length > 1 ? 'Questões' :
                'Questão'
            }} </span>
          </div>
        </div>
        <div class="w-100 course-exame-step-1--text description">Antes de responder o exame, confira as orientações
          abaixo:</div>
        <div class="w-100 flex-row mt-16 align-items-center">
          <div class="course-exame-step-1--icon clock mr-16" />
          <span class="course-exame-step-1--text small">
            Você terá {{ formattedQuizTime }} para realizar o exame, então faça com muita calma;
          </span>
        </div>
        <div class="w-100 flex-row mt-16 align-items-center">
          <div class="course-exame-step-1--icon star mr-16" />
          <span class="course-exame-step-1--text small"> Cada pergunta terá apenas uma resposta correta, lembre-se
            disso; </span>
        </div>
        <div class="w-100 flex-row mt-16 align-items-center">
          <div class="course-exame-step-1--icon hat mr-16" />
          <span class="course-exame-step-1--text small">
            É necessário ter {{ course.quizApprovalThreshold }}% de acerto para passar no exame e emitir seu
            certificado.
          </span>
        </div>
        <div class="w-100 flex-row mt-64">
          <PdcButton variant="outlined" class="ml-auto w-100" @click.native="handleReviewCoursesClass" :loading="start_loading"
            >
            Rever aulas do curso</PdcButton>
        </div>
        <div class="w-100 flex-row mt-24">
          <PdcButton variant="primary" class="ml-auto w-100" @click.native="startExame" :loading="start_loading"
            :buttonColor="this.tenantConfig.primary_color">
            Iniciar exame </PdcButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './ExameIntroduction.scss';
import { mapActions, mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import { iniateExam } from '@/services/dataLayer.service';

export default {
  name: 'ExameIntroduction',
  components: {
    PdcButton,
  },
  props: {
    handleNextStep: Function,
  },
  data() {
    return {
      start_loading: false,
    };
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('exame', ['questions', 'exame_minutes', 'attempt']),
    ...mapGetters('auth', ['account']),
    ...mapGetters('application', ['tenantConfig']),
    formattedQuizTime() {
      const totalSeconds = this.course.quizTimeToFinish;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      if (hours > 0) {
        return `${hours}h e ${minutes}min`;
      } if (minutes > 0) {
        return `${minutes}min`;
      }
      return `${seconds}s`;
    },
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    ...mapActions('exame', ['startAttempt']),
    startExame() {
      this.start_loading = true;
      this.startAttempt(this.course.id).then(() => {
        if (!this.course.result) {
          iniateExam(window.dataLayer, this.course, this.account, this.questions.length);
        }
        this.handleNextStep();
        this.start_loading = false;
      });
    },
    handleReviewCoursesClass() {
      this.$router.push({ name: 'CourseDetail', params: { course: this.course.path } });
    },
  },
};
</script>
