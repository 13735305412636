<template>
  <div>
    <input :type="type" class="defaultInput" :class="{ hasError: error }" :placeholder="placeholder" :name="name"
      :value="value" @input="onInput" @change="onChange" />
    <span class="errorText" v-if="error">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: false,
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onChange(event) {
      this.$emit('change', event.target.value);
    },
  },
};
</script>

<style lang="scss">
@import './../../../scss/variables';

.defaultInput {
  width: 100%;
  align-items: center;
  position: relative;
  height: 56px;
  background-color: #f1f3f5;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 20px;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: -0.02em;
  color: #3E297F;
  border: 2px solid #f1f3f5;
  outline: none;
  transition: all 0.3s;

  @media (max-width: 600px) {
    width: 100%;
    min-width: 100%;
  }

  &[type='search'] {
    padding-left: 56px;
    background-image: url('../../../assets/icons/input/search-blue.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 16px 15px;
  }

  &:focus,
  &:active {
    border-color: #5C7CFA;
    color: #495057;
  }

  &:hover {
    border-color: #5C7CFA;

    &::placeholder {
      color: #adb5bd;
    }
  }

  &::placeholder {
    color: #adb5bd;
    transition: all 0.3s;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;
    height: 24px;
    width: 24px;
    background-size: 24px;
    margin-right: 15px;
  }

  &.hasError {
    border-color: $color-feedback-error-dark;
  }
}

.errorText {
  color: $color-feedback-error-dark;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
</style>
