<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M18.75 0H3.75C1.567 0 0 1.56284 0 3.59043V20.3457C0 22.3733 1.567 23.9362 3.75 23.9362H18.75C19.5523 23.9362 20 23.4896 20 22.7394V21.5415H4.94752C4.28654 21.5415 3.75071 21.0057 3.75071 20.3447C3.75071 19.6837 4.28654 19.1479 4.94752 19.1479H20V1.19681C20 0.446525 19.5523 0 18.75 0Z">
    </path>
  </svg>
</template>

<script>
export default {
  name: 'Book',
};
</script>
