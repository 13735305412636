<template>
  <div class="card__specs">

    <div class="card__specs-item">
      <div class="card__specs-item-icon">
        <img src="@/assets/icons/card/graduation.svg" alt="" />
        <span class="dark f-12">{{ collection.items.length }} Cursos</span>
      </div>

      <div class="card__specs-item-icon">
        <img src="@/assets/icons/card/clock-gray.svg" alt="" />
        {{ collection_time }}
      </div>

      <div class="card__specs-item-icon like">
        <LikeComponent entity="collection" :entity_id="collection.id" mode="counter"
          :is_liked="collection.isLiked?.isLike ? true : false"
          :qty_likes="collection.qtyLikes ? collection.qtyLikes : 0" />
      </div>
      <!-- <span v-else /> -->

    </div>
  </div>
</template>
<script>
import LikeComponent from '@/components/atoms/Likes/LikeComponent.vue';

export default {
  props: ['collection'],
  components: {
    LikeComponent,
  },
  computed: {
    collection_time() {
      const time = this.$moment().startOf('day').add(this.collection.workload, 'minutes');
      const hours = time.format('HH');
      const minutes = time.format('mm');
      return `${hours}h${minutes}`;
    },
  },
};
</script>
