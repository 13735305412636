<template>
  <div class="about" v-if="isIntro" :style="{ '--primary-color': this.tenantConfig.primary_color }">
    <div class="about__left">
      <p class="about__title">Sobre o curso</p>
      <div class="about__divider"></div>
      <div class="about__description_container">
        <p class="about__description">{{ descriptionFormated }}</p>
      </div>
    </div>
  </div>
  <div v-else :style="{ '--primary-color': this.tenantConfig.primary_color }">
    <div class="about">
      <div class="about__left">
        <p class="about__title">Sobre essa aula</p>
        <div class="about__description_container">
          <p class="about__description">{{ descriptionFormated }}</p>
        <div class="about__divider"></div>
        </div>
      </div>

      <div class="about__right">
        <p class="about__title">Materiais de apoio</p>
        <div class="about__divider"></div>
        <div v-if="topic?.courseTopicResource && topic?.courseTopicResource?.length">
          <div v-for="resource in topic.courseTopicResource" :key="resource.id" class="about__resource">
            <a :href="resource.link" target="_blank" class="innerCardTopic">
              <span>{{ resource.title }}</span>
              <img src="@/assets/icons/course-details/downloadIcon.svg" />
            </a>
          </div>
        </div>
        <div v-else>
          <p class="description">Nenhum disponível.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './AboutSession.scss';
import { mapGetters } from 'vuex';

export default {
  props: {
    isIntro: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    topic: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),

    descriptionFormated() {
      const regex = /(<([^>]+)>)/gi;
      const result = this.description.replace(regex, '');
      return result;
    },
  },
};
</script>
