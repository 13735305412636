<template>
  <div id="VideoOverlap" class="video-overlap">
    <div class="video-overlap__container">
      <p class="video-overlap__text">A seguir</p>
      <h4 class="video-overlap__title">{{ player_next_lesson.title }}</h4>
      <RadialProgressBar :diameter="screen_width >= 640 ? 100 : 65" :completed-steps="completedSteps"
        :total-steps="totalSteps" :innerStrokeColor="'#62676a'" :startColor="'#F1F3F5'" :stopColor="'#F1F3F5'"
        :strokeWidth="screen_width >= 640 ? 8 : 5" :innerStrokeWidth="screen_width >= 640 ? 6 : 4">
        <button class="video-overlap__play-button" @click="onTopicViewHandle" :disabled="stopCountDown" />
      </RadialProgressBar>
      <span class="video-overlap__cancel" @click="cancelCountdownTimer">
        Cancelar
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RadialProgressBar from 'vue-radial-progress';

import { finishLesson } from '@/services/dataLayer.service';
import './VideoOverlap.scss';

export default {
  name: 'VideoOverlap',
  components: {
    RadialProgressBar,
  },
  data() {
    return {
      countDown: 10,
      stopCountDown: false,
      completedSteps: 0,
      totalSteps: 10,
      screen_width: window.innerWidth,
    };
  },
  methods: {
    ...mapActions('player', ['setPlayerCountdown']),
    ...mapActions('courses', ['onTopicView']),

    startCountdownTimer() {
      if (this.countDown > 0 && !this.stopCountDown) {
        setTimeout(() => {
          this.countDown -= 1;
          this.completedSteps -= 1;
          this.startCountdownTimer();
        }, 1000);
      }
    },
    cancelCountdownTimer() {
      this.setPlayerCountdown(false);
    },
    goToNextLesson() {
      const nextLesson = this.player_next_lesson;

      this.$router.push({
        name: 'CourseDetailLesson',
        params: {
          course: this.$route.params.course,
          module: nextLesson.courseLesson.id,
          lesson: nextLesson.id,
        },
      });
    },
    onTopicViewHandle() {
      this.stopCountDown = true;

      if (this.isLessonFinished || !this.hasCurrentLessonId) {
        this.goToNextLesson();
      } else {
        this.onTopicView(Number(this.current_lesson_id)).then(() => {
          finishLesson(window.dataLayer, this.course, this.player_current_lesson, this.account);
          this.goToNextLesson();
        });
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['account']),
    ...mapGetters('courses', ['course']),
    ...mapGetters('player', ['player_current_lesson', 'player_next_lesson']),

    current_lesson_id() {
      return Number(this.$route.params.lesson) || this.player_current_lesson.id;
    },
    hasCurrentLessonId() {
      return this.player_current_lesson.id && this.$route.params.lesson;
    },
    isLessonFinished() {
      return this.player_current_lesson.finished;
    },
  },
  watch: {
    countDown(val) {
      if (val === 0) {
        this.onTopicViewHandle();
      }
    },
  },
  created() {
    this.startCountdownTimer();

    window.addEventListener('resize', () => {
      this.screen_width = window.innerWidth;
    });
  },
};
</script>
