<template>
  <TemplateAdmin :loading="isLoading" :show_help_btn="false" class="CourseExame">
    <CourseExameTopbar :show_title="true" :custom_back="onBack" back_text="Voltar" slot="topbar-container" />
    <template slot="container">
      <transition name="fade-only-enter">
        <div>
          <CourseExameSkeleton v-if="isLoading" />
          <template v-else>
            <div class="sm-flex-col flex-row">
              <div class="w-65 sm-w-100 mr-16 slider-steps-area">
                <SliderSteps @on-step-changed="setCurrentStep" ref="step_component" :initial_step="current_step">
                  <template v-for="(question, index) in questions"
                    v-slot:[makeStepName(index)]="{ nextStep, previousStep }">
                    <CourseExameStepQuestion :isReview="true" :handleNextStep="nextStep"
                      :handlePreviousStep="previousStep" :question="question" :key="index" :index="index"
                      :totalQuestions="questions.length" />
                  </template>
                </SliderSteps>
              </div>
              <div class="sm-w-100 flex-col exameReview">
                <div class="flex-col">
                  <h3>Correção do exame</h3>
                  <div class="question-legend" v-if="open">
                    <div class="question-legend-each">
                      <div class="correct"></div> <span>Questão certa</span>
                    </div>
                    <div class="question-legend-each">
                      <div class="incorrect"></div> <span>Questão errada</span>
                    </div>
                  </div>
                  <p class="mb-32">
                    Muito bem, Parceiro! Chegou o momento de revisitar seus
                    acertos e erros nesse exame. navegue entre as questões
                    respondidas ou clique no gabarito abaixo.
                  </p>
                  <div class="flex-questions" :class="open ? 'flex-questions-opened' : ''">
                    <!-- eslint-disable-next-line max-len -->
                    <button v-for="(question, index) in questions" :key="index" :class="`questions-btn ${checkIsCorrectQuestion(question) ? 'correct' : 'incorrect'
                      } ${current_step === index ? 'current' : ''}`" @click="forceChangeStep(index)">
                      {{ index + 1 }}
                    </button>
                  </div>
                  <PdcButton style="display: none;" v-if="open" class="only-mobile ml-auto w-100 sm-mt-32"
                    variant="primary" @click="onBack">
                    Finalizar revisão <i class="icon-circle-check"></i>
                  </PdcButton>
                  <div class="footer-mobile only-mobile" :class="open ? 'footer-mobile-opened' : ''">
                    <button @click="open = !open">Correção de exame</button>
                  </div>
                </div>
                <div>
                  <div class="question-legend only-desktop">
                    <div class="question-legend-each mr-16">
                      <div class="correct"></div> <span>Questão certa</span>
                    </div>
                    <div class="question-legend-each">
                      <div class="incorrect"></div> <span>Questão errada</span>
                    </div>
                  </div>
                  <div class="only-desktop" style="display: none;">
                    <PdcButton class="ml-auto w-100" style="display: none;" variant="primary" @click="onBack">
                      Finalizar revisão<i class="icon-circle-check"></i>
                    </PdcButton>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </transition>
    </template>
  </TemplateAdmin>
</template>
<script>
import '../CourseExame/CourseExame.scss';
import './CourseExameReview.scss';
import { mapActions, mapGetters } from 'vuex';
import { SliderSteps } from 'vue-pdc-ext-frontend';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import CourseExameTopbar from '@/components/organisms/Course/CourseExameTopbar/CourseExameTopbar.vue';
import CourseExameSkeleton from '@/components/organisms/Skeletons/CourseExameSkeleton.vue';
import CourseExameStepQuestion from '@/components/organisms/Course/CourseExameSteps/CourseExameStepQuestion/CourseExameStepQuestion.vue';
import PdcButton from '@/components/atoms/Button/Button.vue';

export default {
  name: 'CourseExameReview',
  components: {
    TemplateAdmin,
    SliderSteps,
    CourseExameTopbar,
    CourseExameSkeleton,
    CourseExameStepQuestion,
    PdcButton,
  },
  data() {
    return {
      open: false,
    };
  },
  created() {
    this.initPage();
  },
  watch: {
    current_step(current) {
      if (current === this.qtyQuestions) {
        this.onBack();
      }
    },
  },
  computed: {
    ...mapGetters('application', ['loading_app']),
    ...mapGetters('courses', ['course', 'course_loading']),
    ...mapGetters('exame', [
      'current_step',
      'questions',
      'exame_loading',
      'last_step_page',
    ]),
    isLoading() {
      return this.course_loading || this.loading_app || this.exame_loading;
    },
    qtyQuestions() {
      return this.questions.length;
    },
    isLastStep() {
      return this.current_step === this.qtyQuestions + 1;
    },
  },
  methods: {
    ...mapActions('application', ['appRouterPush', 'openLink']),
    ...mapActions('exame', [
      'setCurrentStep',
      'fetchExameStarter',
      'setExameLoading',
    ]),
    onBack() {
      // TODO REVISAR ROTA
      this.$router.push({ name: 'ExameResult', params: { course: this.$router.currentRoute.params.course } });
    },
    checkIsCorrectQuestion(question) {
      const picked = question.courseAlternatives.find((item) => item.picked);
      if (!picked) return false;
      return picked.isCorrect;
    },
    makeStepName(index) {
      return `step-${index}`;
    },
    async startPage(step = 0) {
      this.setCurrentStep(step);
      this.setExameLoading(false);
      this.$forceUpdate();
    },
    forceChangeStep(step) {
      this.$refs.step_component.step = step;
      this.$forceUpdate();
    },
    initPage() {
      // TODO REVISAR ROTA
      this.fetchExameStarter(this.$router.currentRoute.params.course).then(
        async ({ course }) => {
          document.title = `${course.title} | CORREÇÃO EXAME - ${window.DEFAULT_TITLE}`;

          return this.startPage(0, course);
        },
      );
    },
  },
};
</script>
