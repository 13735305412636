<template>
  <SidebarItem :class="`mt-32 mb-32 hoverable  intro ${isCurrentRoute && 'current'}`" @click="goToIntroducion">
    <div class="sidebar-container--introduction--item" :style="{ '--bg-color': this.tenantConfig.primary_color }">
      <div :class="`sidebar-container--introduction--item--icon ${completed && 'checked'}`" />
      <div class="sidebar-container--introduction--item--text ">Introdução</div>
    </div>
  </SidebarItem>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SidebarItem from '../SidebarItem/SidebarItem.vue';

export default {
  components: {
    SidebarItem,
  },
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked_value: this.checked,
      current_route: null,
    };
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('application', ['tenantConfig']),
    isCurrentRoute() {
      return this.current_route === 'CourseDetail';
    },
  },
  watch: {
    $route(to) {
      this.current_route = to.name;
    },
  },
  created() {
    this.current_route = this.$router.currentRoute.name;
  },
  methods: {
    ...mapActions('application', ['openLink']),
    goToIntroducion() {
      if (!this.isCurrentRoute) {
        this.$router.push({
          name: 'CourseDetail',
          params: { course: this.course.path },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import './CourseIntroduction.scss';
</style>
