<template>
  <TemplateAdmin :loading="loading">
    <template slot="container">
      <div class="content-page" :style="{ '--section-title-color': this.tenantConfig.primary_color }">
        <div class="top-cards flex-col">
          <TabView @tabActive="onChangeTabValue" :titles="['Cursos', 'Certificados']" rounded>
            <div class="card-wrapper">
              <div class="courses-wrapper">
                <CoursesOngoing v-if="isTabActive === 0" />
              </div>
            </div>
            <Certificates v-if="isTabActive === 1" />
          </TabView>
        </div>
      </div>
    </template>
  </TemplateAdmin>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import TabView from '@/components/organisms/TabView/TabView.vue';
import CoursesOngoing from '@/components/organisms/MyLearning/CoursesOngoing/CoursesOngoing.vue';
import Certificates from '@/components/organisms/MyLearning/Certificates/Certificates.vue';

export default {
  name: 'MyLearning',
  components: {
    TemplateAdmin,
    TabView,
    CoursesOngoing,
    Certificates,
  },
  created() {
    this.getRankingCourses();
    this.getRecentCourses();
    this.getCoursesOngoing();
    this.getCoursesFinished();
    this.getCourseCategories();
    this.getPdcTalksCategories();
    this.getMyCertificates();
  },
  data() {
    return {
      loading: false,
      isTabActive: 0,
    };
  },
  computed: {
    ...mapGetters('courses', ['show_welcome_banner', 'recommended_collections']),
    ...mapGetters('application', ['loading_app', 'tenantConfig', 'loadingTenantConfig']),
  },
  methods: {
    ...mapActions('courses', ['getRankingCourses', 'getRecentCourses', 'getCoursesOngoing',
      'getCoursesFinished', 'getCourseCategories', 'getPdcTalksCategories']),
    ...mapActions('application', ['getTenantConfig']),
    ...mapActions('certificates', ['getMyCertificates']),
    onChangeTabValue(value) {
      this.isTabActive = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'MyLearning.scss';
</style>
