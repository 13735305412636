<template>
  <el-dialog
    id="add-new-experience-dialog"
    class="custom-modal-pdc"
    :visible.sync="dialog"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="modal-header">
      <h1>Editar perfil</h1>
    </div>

    <form>
      <CustomInput
        type="text"
        name="text"
        id="name"
        placeholder="Carlos"
        title="Nome*"
        v-model="item.firstname"
      />

      <CustomInput
        type="text"
        name="text"
        id="lastname"
        placeholder="dos Santos"
        title="Sobrenome*"
        v-model="item.lastname"
      />

      <CustomSelect
        v-if="professions.length > 0"
        type="text"
        name="title"
        id="title"
        placeholder="Especialista em Empermeabilização"
        title="Profissão principal*"
        v-model="profession"
        :options="professions"
        :value="profession"
      />

      <CustomInput
        :placeholder="'01/01/1980'"
        type="date"
        name="birthday"
        id="birthday"
        title="Data de nascimento"
        v-model="item.birthday"
      />

      <CustomInput
        type="text"
        name="whatsapp"
        id="whatsapp"
        placeholder="(11) 99999-9999"
        title="Whatsapp*"
        v-model="item.whatsapp"
      />

      <CustomInput
        type="text"
        name="name"
        title="Título*"
        placeholder="Adicione um título que melhor te identifique"
        v-model="item.extraInformation"
      />

      <div class="info">
        <Icon name="Info" />
        Adicione uma descrição prévia sobre seu perfil profissional
      </div>

      <div class="edit-address">
        <h2>Endereço profissional</h2>
        <p>
          Configure seu endereço (residencial ou comercial) para que apareça nos
          resultados de pesquisa por profissionais.
        </p>
        <PdcButton
          class="button"
          variant="primary"
          id="button-discovery"
          @click="showAddressModal = true"
          >Editar endereço
        </PdcButton>
        <p v-if="success">
          <Icon name="Approved" />
          Endereço atualizado com sucesso!
        </p>
      </div>

      <span
        slot="footer"
        class="accept-footer"
        style="display: flex; margin-top: 45px; justify-content: flex-end"
      >
        <PdcButton
          class="button"
          variant="outlined"
          id="button-discovery"
          @click="handleClose"
          >Fechar
        </PdcButton>
        <PdcButton
          class="button"
          variant="primary"
          id="button-discovery"
          @click.native="handleSubmit"
          :loading="loading_save"
          >Salvar</PdcButton
        >
      </span>
    </form>
    <AddressModal
      v-if="showAddressModal"
      @saveAddress="editAddress"
      :loading_save="loadingAddress"
      :isEdit="true"
      :address="address"
      @close="handleCloseAddress"
    />
  </el-dialog>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

import PdcButton from '@/components/atoms/Button/Button.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';
import CustomInput from '@/components/atoms/CustomInput/CustomInput.vue';
import CustomSelect from '@/components/atoms/CustomSelect/CustomSelect.vue';
import { updateAddress } from '@/services/address.service';
import AddressModal from '../AddressDialog/AddressModal.vue';
import './EditUserProfileDialog.scss';

export default {
  name: 'EditUserProfileDialog',
  props: {
    item: {
      firstname: {
        type: String,
        required: true,
      },
      lastname: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      extraInformation: {
        type: String,
        required: true,
      },
      birthday: {
        type: String,
        required: true,
      },
      whatsapp: {
        type: String,
        required: true,
      },
    },
    address: {
      type: Object,
      required: false,
    },
  },
  components: {
    PdcButton,
    Icon,
    CustomInput,
    AddressModal,
    CustomSelect,
  },
  data: () => ({
    dialog: true,
    close: false,
    showAddressModal: false,
    loadingAddress: false,
    success: false,
    professions: [],
    profession: '',
    initialProfession: '',
  }),
  computed: {
    ...mapGetters('profile', ['loading_save', 'toastMessage', 'currentProfession']),
    ...mapGetters('address', ['userAddress']),
  },
  methods: {
    ...mapActions('profile', ['listProfessions', 'updateUserProfile', 'saveProfessions']),

    isProfessionChanged() {
      return this.profession !== this.initialProfession;
    },
    handleClose() {
      this.$emit('handleClose');
    },
    handleCloseAddress() {
      this.showAddressModal = false;
    },

    async editAddress() {
      this.loadingAddress = true;
      const { id, accountId, professionalProfileId } = this.address;
      const data = await updateAddress({
        ...this.userAddress,
        id,
        accountId,
        professionalProfileId,
      });
      if (data[0]) {
        this.loadingAddress = false;
        this.success = true;
      }
      this.loadingAddress = false;
      this.handleCloseAddress();
    },
    async handleSubmit(e) {
      e.preventDefault();

      const {
        firstname,
        lastname,
        title,
        extraInformation,
        birthday,
        whatsapp,
      } = this.item;

      const promisesToExecute = [
        this.updateUserProfile({
          firstname,
          lastname,
          title,
          extraInformation,
          birthday,
          whatsapp,
        }),
      ];

      if (this.isProfessionChanged()) {
        promisesToExecute.push(this.saveProfessions(this.profession));
      }

      await Promise.all(promisesToExecute);

      this.handleClose();

      if (this.toastMessage) {
        this.$swal.fire(
          this.toastMessage.title,
          this.toastMessage.description,
          this.toastMessage.type,
        );
      }
    },
  },
  async mounted() {
    try {
      this.profession = this.currentProfession;
      this.initialProfession = this.profession;
      const data = await this.listProfessions();
      this.professions = data.list.map((item) => ({ value: item.name, label: item.name }));
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    this.success = false;
  },
};
</script>
