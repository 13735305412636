import axiosRetry from 'axios-retry';
import apiBase from './default.http';

export const { instance: apiBaseService, setToken } = apiBase(
  process.env.VUE_APP_COLLECTION_SERVICE_URL || ' https://api.stg.parceirodaconstrucao.com.br',
);

axiosRetry(apiBaseService, { retries: 2, retryDelay: (retryCount) => retryCount * 1000 });
const mockHttp = apiBaseService;

export default mockHttp;
