<template>
  <div class="w-100 courses-skeleton mt-32">

    <template>
      <div class="mb-16">
        <VueSkeletonLoader class="w-20 h-24px mb-16" />
        <VueSkeletonLoader class="w-50 h-24px mb-8" />
        <VueSkeletonLoader class="w-25 h-24px" />
      </div>

      <VueSkeletonLoader class="w-50 h-24px my-32 mb-56" />

      <div class="flex-row w-100 justify-content-between mb-48">
        <VueSkeletonLoader class="w-20 h-24px mb-16" />
        <VueSkeletonLoader class="w-20 h-24px mb-16" />
        <VueSkeletonLoader class="w-20 h-24px mb-16" />
      </div>

      <div class="flex-row w-100 justify-content-between align-items-center mb-48" v-for="i in 4" :key="`ranking${i}`">
        <div class="flex-row align-items-center">

          <VueSkeletonLoader type="circle" :width="48" :height="48" class="w-36px h-36px mb-0 mt-0 mr-16"
            style="min-width: 48px;" />

          <VueSkeletonLoader class="h-24px" style="width: 20rem;" />
        </div>

        <VueSkeletonLoader class="w-28 h-24px mr-90" style="margin-right: 4rem;" />

        <div class="w-20 h-45px flex-col">
          <VueSkeletonLoader class="w-100 h-24px mb-8" />
          <VueSkeletonLoader class="w-40 h-24px" style="width: 5rem;" />
        </div>
      </div>

    </template>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'RankingSkeleton',
  components: { VueSkeletonLoader },
};
</script>

<style scoped lang="scss">
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 129px;
  align-items: center;
}
</style>
