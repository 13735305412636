<template>
  <div class="flex-col course-exame-step-1 mt-48 sm-mt-0 mb-80">
    <div class="exame-content">
      <div class="flex-row sm-flex-col align-items-center">
        <div class="w-40 br-10px sm-hide">
          <img src="@/assets/icons/course-exame/exame-intro-image.png" alt="exame-intro" class="w-100" />
        </div>
        <div class="flex-col w-60 md-pl-32 lg-pl-48 sm-w-100">
          <h1 class="w-100 course-exame-step-1--title sm-text-align-center sm-mb-56">Avalie o conteúdo</h1>
          <div v-bind:class="['flex-row', 'course-exame-step-1--icon-row mb-64', 'sm-justify-content-between']">

          </div>
          <div class="w-100 course-exame-step-1--text description"> Obrigada por assistir! Deixe sua avaliação e baixe seu
            certificado de participação:
          </div>

          <div class="w-100 flex-row mt-64">
            <PdcButton variant="primary" class="ml-auto w-100" @click.native="startExame" :loading="start_loading">
              Avaliar </PdcButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import { iniateExam } from '@/services/dataLayer.service';

export default {
  name: 'ExameIntroduction',
  components: {
    PdcButton,
  },
  props: {
    handleNextStep: Function,
  },
  data() {
    return {
      start_loading: false,
    };
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('exame', ['questions', 'exame_minutes', 'attempt']),
    ...mapGetters('auth', ['account']),
    isApprovedThreshold() {
      if (this.course.quizApprovalThreshold === 0) {
        return true;
      }
      return false;
    },
    title() {
      if (this.isApprovedThreshold) {
        return 'Deixe sua avaliação';
      }

      return 'É hora de testar seu conhecimento no exame!';
    },
  },
  methods: {
    ...mapActions('application', ['appRouterPush']),
    ...mapActions('exame', ['startAttempt']),
    startExame() {
      this.start_loading = true;
      this.startAttempt(this.course.id).then(() => {
        if (!this.course.result) {
          iniateExam(window.dataLayer, this.course, this.account, this.questions.length);
        }
        this.handleNextStep();
        this.start_loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 1200px) {
  .exame-content {
    padding-bottom: 60px;
  }

}
</style>
