<template>
  <div class="w-100 courses-skeleton">
    <template v-if="['top-banner', 'all'].includes(type)">
      <VueSkeletonLoader class="w-100 h-368px" />
    </template>
    <template v-if="['recommended', 'all'].includes(type)">
      <VueSkeletonLoader class="w-400px br-8px h-40px mb-24 mt-48" />
      <VueSkeletonLoader class="w-100 h-2px mb-16" />
      <div class="flex-row w-100 justify-content-between mb-48">
        <VueSkeletonLoader class="w-30 h-400px" />
        <VueSkeletonLoader class="w-30 h-400px" />
        <VueSkeletonLoader class="w-30 h-400px" />
      </div>
    </template>
    <template v-if="['search', 'all'].includes(type)">
      <div class="flex-row w-100 mb-48 justify-content-between">
        <VueSkeletonLoader class="w-45 br-8px h-56px" />
        <VueSkeletonLoader class="w-45 br-8px h-56px" />
      </div>
    </template>
    <template v-if="['categories', 'all'].includes(type)">
      <template>
        <div v-for="i in 5" :key="`category${i}`">
          <VueSkeletonLoader class=" w-400px br-8px h-40px mb-24 mt-48" />
          <VueSkeletonLoader class="w-100 h-2px mb-16" />
          <div class="flex-row w-100 justify-content-between">
            <VueSkeletonLoader class="w-30 h-400px" />
            <VueSkeletonLoader class="w-30 h-400px" />
            <VueSkeletonLoader class="w-30 h-400px" />
          </div>
        </div>
      </template>
    </template>
    <template v-if="['collections', 'all'].includes(type)">
      <template>
        <div v-for="i in 5" :key="`collection${i}`">
          <VueSkeletonLoader class="w-400px br-8px h-40px mb-24 mt-48" />
          <VueSkeletonLoader class="w-100 h-2px mb-16" />
          <div class="flex-row w-100 justify-content-between">
            <VueSkeletonLoader class="w-30 h-400px" />
            <VueSkeletonLoader class="w-30 h-400px" />
            <VueSkeletonLoader class="w-30 h-400px" />
          </div>
        </div>
      </template>
    </template>
    <template v-if="['pdc-talks', 'all'].includes(type)">
      <template>
        <div v-for="i in 5" :key="`pdc-talks${i}`">
          <VueSkeletonLoader class="w-400px br-8px h-40px mb-24 mt-48" />
          <VueSkeletonLoader class="w-100 h-2px mb-16" />
          <div class="flex-row w-100 justify-content-between">
            <VueSkeletonLoader class="w-30 h-400px" />
            <VueSkeletonLoader class="w-30 h-400px" />
            <VueSkeletonLoader class="w-30 h-400px" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  props: {
    type: {
      type: String,
      default: 'all',
    },
  },
  name: 'CoursesSkeleton',
  components: { VueSkeletonLoader },
};
</script>
