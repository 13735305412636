<template>
  <img :src="emoji" alt="emoji" :style="{ height }" />
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    height() {
      return `${this.size}px`;
    },
    options() {
      return {
        // eslint-disable-next-line global-require
        '😁': require('@/assets/emojis/beaming-face.png'),
        // eslint-disable-next-line global-require
        '😕': require('@/assets/emojis/confused-face.png'),
        // eslint-disable-next-line global-require
        '⌛': require('@/assets/emojis/hourglass-done.png'),
      };
    },
    emoji() {
      return this.options[this.value];
    },
  },
};
</script>
