<template>
  <div>
    <textarea class="defaultInput" :class="{ 'hasError': error }" :placeholder="placeholder" :name="name" :value="value"
      @input="onInput" @change="onChange" />
    <span class="errorText" v-if="error">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'CustomTextarea',
  props: {
    name: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: false,
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onChange(event) {
      this.$emit('change', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../scss/variables";

.defaultInput {
  resize: none;
  width: 100%;
  align-items: center;
  position: relative;
  height: 200px;
  background-color: #F1F3F5;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 20px;
  padding-top: 20px;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: -0.02em;
  color: #3E297F;
  border: 2px solid #F1F3F5;
  outline: none;
  transition: all .3s;

  &:focus,
  &:active {
    border-color: #5C7CFA;
    color: #3E297F;

    &::placeholder {
      color: #3E297F;
    }
  }

  &:hover {
    border-color: #ADB5BD;

    &::placeholder {
      color: #ADB5BD;
    }
  }

  &::placeholder {
    color: #ADB5BD;
    transition: all .3s;
  }

  &.hasError {
    border-color: $color-feedback-error-dark;
  }
}

.errorText {
  color: $color-feedback-error-dark;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
</style>
