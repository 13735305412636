<template>
  <TemplateAdmin name="container" slot="container" :loading="loading_app">
    <template slot="container">
      <ProfessionalProfileSkeleton v-if="loadingProfile" />
      <template v-if="userProfile && !loadingProfile">
        <div
          class="content-page"
          :style="{ '--section-title-color': this.tenantConfig.primary_color }"
        >
          <div class="top-cards flex-col">
            <section class="user-profile-card">
              <div class="user-profile-hero-image">
                <div class="profile-background-image">
                  <div
                    v-if="imgCoverLoading"
                    class="display-flex align-items-center justify-content-center"
                  >
                    <Loading :width="'2rem'" :height="'2rem'" />
                  </div>
                  <img v-else
                    :src="userProfile.coverImage ||
                                          'https://res.cloudinary.com/dzhbkamn0/image/upload/v1687281461/MicrosoftTeams-image_5_gjtxvr.jpg'" />
                </div>
                <label
                  v-if="isMyProfile"
                  for="fileInput"
                  class="btn-upload-profile-image"
                >
                  <Icon :name="userImageIcon" />
                </label>
                <input
                  v-if="isMyProfile"
                  id="fileInput"
                  ref="fileInput"
                  type="file"
                  class="hidden"
                  @change="uploadProfileImage($event, 'cover')"
                />
              </div>

              <div class="user-profile-infos">
                <div class="profile-main-image">
                  <div
                    v-if="imgAvatarLoading"
                    class="display-flex h-100 align-items-center justify-content-center"
                  >
                    <Loading :width="'2rem'" :height="'2rem'" />
                  </div>
                  <img v-else :src="userProfile.avatar" ref="imageProfileRef" />
                  <div class="user-status"></div>
                  <label
                    v-if="isMyProfile"
                    for="fileInputSelfie"
                    class="img-icon"
                  >
                    <Icon :name="userImageIcon" />
                  </label>
                  <input
                    v-if="isMyProfile"
                    id="fileInputSelfie"
                    type="file"
                    class="hidden"
                    @change="uploadProfileImage($event, 'selfie')"
                  />
                </div>

                <div class="profile-description">
                  <div class="profile-title">
                    <p>
                      {{ currentProfession }}
                    </p>
                    <div v-if="userProfile.title" class="profile-qrcode">
                      <qrcode-vue :value="profileUrl" :size="80" level="H" />
                      <button
                        @click="showQrCodeModal = !showQrCodeModal"
                        class="outlined"
                      >
                        Ver QRCode
                      </button>
                    </div>
                    <QrCodeDialog
                      :url="profileUrl"
                      @handleClose="handleCloseDialog('showQrCodeModal')"
                      v-if="showQrCodeModal"
                    >
                    </QrCodeDialog>
                  </div>
                  <div class="profile-actions"></div>
                </div>
              </div>
            </section>

            <Card>
              <div class="card-header">
                <h2 class="title" v-if="!!userProfile.firstname">
                  {{ userProfile.firstname }} {{ userProfile.lastname }}
                  <p>
                    <img
                      src="../../assets/icons/matchmaking/birthday.svg"
                      alt=""
                    />
                    {{ userBirthday }}
                  </p>
                  <p v-if="userAddress[0]?.professionalProfileId">
                    <img src="../../assets/icons/matchmaking/home.svg" alt="" />
                    {{ userAddress[0].city }} -
                    {{ userAddress[0].state }}
                  </p>
                </h2>
                <div>
                  <button
                    v-if="isMyProfile"
                    type="button"
                    @click="handleOpenDialog('showEditUserProfileDialog')"
                    class="ml-auto"
                  >
                    <Icon name="Edit" />
                  </button>
                  <button
                    v-if="isMyProfile"
                    type="button"
                    @click="handleOpenDialog('showAddressDialog')"
                    class="ml-auto"
                  >
                    <Icon name="Add" />
                  </button>
                </div>
              </div>

              <div class="user-info-data" v-if="!!userProfile.title">
                <p>{{ userProfile.title }}</p>
                <p v-if="!!userProfile.extraInformation">
                  {{ userProfile.extraInformation }}
                </p>
              </div>
              <div v-else>
                <Notfound
                  :title="'Nada para ver por enquanto'"
                  :subtitle="'Quando você adicionar novas informações, elas serão exibidos aqui.'"
                />
              </div>
            </Card>

            <h1 class="experience-professional">
              Informações gerais
              <button
                v-if="isMyProfile && !userProfile.differentialOfYourWork"
                type="button"
                @click="handleOpenDialog('showInfoGeneralDialog')"
              >
                <Icon name="Add" />
              </button>
            </h1>
            <p class="subtitle">
              (breve apresentação das suas principais entregas e diferenciais de
              trabalho):
            </p>
            <Card>
              <div
                v-if="!!userProfile.differentialOfYourWork"
                class="card-header geral-data"
              >
                <p>{{ userProfile.differentialOfYourWork }}</p>
                <button
                  v-if="isMyProfile"
                  type="button"
                  @click="handleOpenDialog('showInfoGeneralDialog')"
                >
                  <Icon name="Edit" />
                </button>
              </div>

              <Notfound
                :title="'Nada para ver por enquanto'"
                :subtitle="'Quando você adicionar novas informações, elas serão exibidas aqui.'"
                v-else
              />
            </Card>

            <Card class="card-ability">
              <div class="card-header">
                <h1 class="abilities">
                  Habilidades
                </h1>
                <button
                  v-if="isMyProfile"
                  type="button"
                  @click="handleOpenDialog('showSkillsDialog')"
                >
                  <Icon name="EditV2" />
                </button>
              </div>

              <Notfound
                :title="'Nada para ver por enquanto'"
                :subtitle="'Quando você adicionar novas habilidades, elas serão exibidas aqui.'"
                v-if="
                  !!userProfile &&
                  userProfile?.skills &&
                  userProfile.skills.lenght < 1
                "
              />

              <template v-else>
                <div class="my-skills">
                  <div class="skill-item" v-for="(item, index) in userProfile.skills" :key="index">{{ item.name }}</div>
                </div>
              </template>
            </Card>

            <h1 class="experience-professional">
              Experiência profissional
              <button
                v-if="isMyProfile"
                type="button"
                @click="handleOpenDialog('showAddNewExperienceDialog')"
              >
                <Icon name="Add" />
              </button>
            </h1>
            <p class="subtitle">
              (descreva os principais serviços ou projetos que você executou):
            </p>

            <Card
              v-if="
                !!userProfile &&
                userProfile?.jobExperiences &&
                userProfile.jobExperiences.lenght < 1
              "
            >
              <Notfound
                :title="'Nada para ver por enquanto'"
                :subtitle="'Quando você adicionar novas experiências, elas serão exibidas aqui.'"
              />
            </Card>

            <template v-else>
              <Card
                v-for="experience in userProfile.jobExperiences"
                :key="experience.id"
              >
                <div class="experience-professional-item">
                  <div class="experience-actions">
                    <button
                      v-if="isMyProfile"
                      type="button"
                      @click="onEditJobExpirience(experience)"
                      class="ml-auto"
                    >
                      <Icon name="Edit" />
                    </button>

                    <button
                      v-if="isMyProfile"
                      type="button"
                      @click="deleteJobExperienceById(experience.id)"
                    >
                      <Icon name="Delete" />
                    </button>
                  </div>

                  <div class="experience-actions-content">
                    <div class="experience-color"></div>
                    <div class="experience-infos">
                      <h2>{{ experience.name }}</h2>
                      <p>
                        <span>{{ experience.company }}</span>
                      </p>
                      <p class="time">
                        <span class="date">{{ experienceDateDiff(experience) }}</span>
                        <span class="period">{{ comparedDates(experience.jobStartDate, experience.jobEndDate, true)
                                                  }}</span>
                      </p>
                      <p class="description">
                        {{ experience.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </template>

            <h1 class="experience-professional">
              Certificados
              <button
                v-if="isMyProfile"
                type="button"
                @click="handleOpenDialog('showCertificatesDialog')"
              >
                <Icon name="Add" />
              </button>
            </h1>
            <p class="subtitle">
              (adicione os cursos técnicos e complementares que você fez pelo
              Parceiro da Construção)
            </p>

            <Card
              v-if="
                !!userProfile &&
                userProfile?.certificates &&
                userProfile.certificates.lenght < 1
              "
            >
              <Notfound
                :title="'Nada para ver por enquanto'"
                :subtitle="'Quando você adicionar novas experiências, elas serão exibidas aqui.'"
              />
            </Card>

            <template v-else>
              <Card
                v-for="certificate in userProfile.certificates"
                :key="certificate.id"
              >
                <div class="experience-professional-item">
                  <div class="experience-actions">
                    <button
                      v-if="isMyProfile"
                      type="button"
                      @click="deleteCertificate(certificate)"
                    >
                      <Icon name="Delete" />
                    </button>
                    <button type="button">
                      <a :href="certificate.url" target="_blank">
                        <img
                          src="../../assets/icons/matchmaking/eye.svg"
                          alt="Ver Certificado"
                          tooltip="Ver Certificado"
                        />
                      </a>
                    </button>
                  </div>

                  <div class="experience-actions-content">
                    <img
                      src="/android-chrome-192x192.png"
                      class="certificate-icon"
                    />
                    <div class="experience-infos">
                      <h2>{{ certificate.title }}</h2>
                    </div>
                  </div>
                </div>
              </Card>
            </template>

            <h1 class="experience-professional">
              Formação acadêmica
              <button
                v-if="isMyProfile"
                type="button"
                @click="handleOpenDialog('showAcademicEducationDialog')"
              >
                <Icon name="Add" />
              </button>
            </h1>
            <p class="subtitle">
              (adicione os cursos de nível superior que realizou em instituições
              presenciais ou à distância)
            </p>

            <Card
              v-if="
                !!userProfile &&
                userProfile?.academicEducations &&
                userProfile.academicEducations.lenght < 1
              "
            >
              <Notfound
                :title="'Nada para ver por enquanto'"
                :subtitle="'Quando você adicionar novas experiências, elas serão exibidas aqui.'"
              />
            </Card>
            <template v-else>
              <Card
                v-for="academicEducation in userProfile.academicEducations"
                :key="academicEducation.id"
              >
                <div class="experience-professional-item">
                  <div class="experience-actions">
                    <button
                      v-if="isMyProfile"
                      type="button"
                      @click="onEditAcademicEducation(academicEducation)"
                      class="ml-auto"
                    >
                      <Icon name="Edit" />
                    </button>

                    <button
                      v-if="isMyProfile"
                      type="button"
                      @click="
                        deleteAcademicEducationFromProfile(academicEducation)
                      "
                    >
                      <Icon name="Delete" />
                    </button>
                  </div>

                  <div class="experience-actions-content">
                    <img
                      src="../../assets/icons/matchmaking/graduation-hat.svg"
                      class="certificate-icon"
                    />
                    <div class="experience-infos">
                      <h2>{{ academicEducation?.studyArea }}</h2>
                      <p class="description">
                        {{ academicEducation?.educationalInstitution }}
                      </p>
                      <p class="description">
                        {{ academicEducation?.degree }}
                      </p>
                      <p class="description">
                        {{
                          comparedDates(
                            academicEducation?.startDate,
                            academicEducation?.endDate,
                            false
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </template>
          </div>
        </div>
      </template>

      <InfoGeneralDialog
        :differentialOfYourWork="userProfile.differentialOfYourWork"
        v-if="showInfoGeneralDialog"
        @handleClose="handleCloseDialog('showInfoGeneralDialog')"
      />

      <EditUserProfileDialog
        v-if="showEditUserProfileDialog"
        :address="userAddress[0]"
        @handleClose="handleCloseDialog('showEditUserProfileDialog')"
        :item="userProfile"
      />

      <AddNewExperienceDialog
        v-if="showAddNewExperienceDialog"
        @handleClose="handleCloseDialog('showAddNewExperienceDialog')"
      />

      <AddCertificatesDialog
        v-if="showCertificatesDialog"
        @handleClose="handleCloseDialog('showCertificatesDialog')"
      />

      <AddressDialog
        v-if="showAddressDialog"
        @saveAddress="saveSameAddressOnProfessionalProfile"
        @close="handleCloseDialog('showAddressDialog')"
        :loading_save="isLoadingAddress"
      />

      <AddAcademicEducation
        v-if="showAcademicEducationDialog"
        @handleClose="handleCloseDialog('showAcademicEducationDialog')"
      />

      <AddNewSkillsDialog
        v-if="showSkillsDialog"
        @handleClose="handleCloseDialog('showSkillsDialog')"
      />

      <CreateProfessionalProfileDialog
        v-if="showCreateProfessionalProfileDialog"
        @handleClose="handleCloseDialog('showCreateProfessionalProfileDialog')"
      />
    </template>
  </TemplateAdmin>
</template>

<script>
// eslint-disable-next-line import/no-unresolved

import { mapActions, mapGetters } from 'vuex';

import Card from '@/components/atoms/Card/Card.vue';
import Icon from '@/components/atoms/Icon/Icon.vue';
import TemplateAdmin from '@/components/organisms/Templates/AdminTemplate.vue';
import Notfound from '@/components/atoms/Notfound/Notfound.vue';
import QrcodeVue from 'qrcode.vue';

import AddNewExperienceDialog from '@/components/organisms/AddNewExperienceDialog/AddNewExperienceDialog.vue';
import { formatJobDuration, compareDates } from '@/utils/functions/date';
import getBase64 from '@/utils/functions/file';
import EditUserProfileDialog from '@/components/organisms/EditUserProfileDialog/EditUserProfileDialog.vue';
import CreateProfessionalProfileDialog from '@/components/organisms/CreateProfessionalProfileDialog/CreateProfessionalProfileDialog.vue';
import QrCodeDialog from '@/components/organisms/QrCodeDialog/QrCodeDialog.vue';
import AddCertificatesDialog from '@/components/organisms/AddCertificatesDialog/AddCertificatesDialog.vue';
import InfoGeneralDialog from '@/components/organisms/InfoGeneralDialog/InfoGeneralDialog.vue';
import AddNewSkillsDialog from '@/components/organisms/AddNewSkillsDialog/AddNewSkillsDialog.vue';
import ProfessionalProfileSkeleton from '@/components/organisms/Skeletons/ProfessionalProfileSkeleton.vue';
import AddAcademicEducation from '@/components/organisms/AddAcademicEducation/AddAcademicEducation.vue';
import AddressDialog from '@/components/organisms/AddressDialog/AddressModal.vue';
import Loading from '@/components/atoms/Loading/Loading.vue';

export default {
  name: 'UserProfile',
  components: {
    TemplateAdmin,
    Card,
    Icon,
    AddNewExperienceDialog,
    EditUserProfileDialog,
    InfoGeneralDialog,
    AddNewSkillsDialog,
    Notfound,
    CreateProfessionalProfileDialog,
    ProfessionalProfileSkeleton,
    AddCertificatesDialog,
    AddAcademicEducation,
    Loading,
    QrcodeVue,
    QrCodeDialog,
    AddressDialog,
  },

  data: () => ({
    showAddNewExperienceDialog: false,
    showEditUserProfileDialog: false,
    showCreateProfessionalProfileDialog: false,
    showCertificatesDialog: false,
    showAcademicEducationDialog: false,
    showInfoGeneralDialog: false,
    showSkillsDialog: false,
    profileImage: {},
    loadingProfile: false,
    showAddressDialog: false,
    imgCoverLoading: true,
    imgAvatarLoading: true,
    showQrCodeModal: false,
    profileUrl: '',
    addressType: {},
    isLoadingAddress: false,
  }),
  computed: {
    ...mapGetters('courses', [
      'show_welcome_banner',
      'recommended_collections',
    ]),
    ...mapGetters('application', ['loading_app', 'tenantConfig']),
    ...mapGetters('profile', ['toastMessage', 'certificates', 'userProfile', 'currentProfession']),
    ...mapGetters('auth', ['account']),
    ...mapGetters('address', ['userAddress', 'useAddressOn']),

    userImage() {
      const userAvatar = this.userProfile?.avatar;
      const loadedImage = this.profileImage;
      return userAvatar || loadedImage;
    },
    userCoverImage() {
      const userAvatar = this.userProfile?.coverImage;
      const loadedImage = this.profileImage;
      return userAvatar || loadedImage;
    },
    userImageIcon() {
      return this.userProfile?.avatar ? 'Edit' : 'Add';
    },
    coverImageIcon() {
      return this.userProfile?.coverImage ? 'Edit' : 'Add';
    },
    qrCodeColor() {
      const colors = { dark: '#000000ff', light: '#ffffffff' };
      return colors;
    },
    isMyProfile() {
      const myProfile = this.account.id;
      const userByRoute = Number(this.$route.params.accountId);
      if (userByRoute === myProfile) return true;
      return false;
    },
    userBirthday() {
      const birthday = this.userProfile?.birthday;
      if (birthday) {
        const date = new Date(birthday);
        const day = date.getDate() + 1;
        const month = date.getMonth() + 1;
        return `Aniversário ${day >= 10 ? day : `0${day}`}/${
          month >= 10 ? month : `0${month}`
        }`;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('profile', [
      'loadProfessionalProfile',
      'editSkillSelected',
      'deleteExperience',
      'editJobExpirienceSelected',
      'uploadImage',
      'deleteCertificates',
      'editAcademicEducation',
      'deleteAcademicEducation',
      'getProfession',
    ]),
    userUrl() {
      const { id } = this.account;
      const { origin } = window.location;
      this.profileUrl = `${origin}/community/?redirect=user-profile/${id}`;
      return `${origin}/community/?redirect=user-profile/${id}`;
    },
    ...mapActions('address', [
      'getAccountAddress',
      'saveAddressOnState',
      'saveAddressOnProfile',
    ]),
    handleOpenDialog(modalName) {
      this[modalName] = true;
    },
    handleCloseDialog(modalName) {
      this[modalName] = false;
    },
    deleteCertificate(certificate) {
      this.$swal
        .fire({
          title: 'Deletar Certificado',
          text: 'Você tem certeza de que deseja deletar este certificado?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sim, Deletar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await this.deleteCertificates(certificate.id);
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Deletado!',
              'Seu certificado foi deletada com sucesso',
              'success',
            );
          }
        });
    },
    openAddressModal() {
      this.$swal
        .fire({
          title:
            'Vimos que você ainda não tem endereço no seu perfil profissional',
          text: 'Adicione seu endereço para que possamos te ajudar a encontrar oportunidades próximas a você',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok, ir para endereço',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.handleOpenDialog('showAddressDialog');
          }
        });
    },
    loadImage(imageType, imgState) {
      if (imageType === 'cover') {
        this.imgCoverLoading = imgState;
      } else {
        this.imgAvatarLoading = imgState;
      }
    },
    async deleteAcademicEducationFromProfile(academicEducation) {
      this.$swal
        .fire({
          title: 'Deletar Formação Acadêmica',
          text: 'Você tem certeza de que deseja deletar esta formação acadêmica?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sim, Deletar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await this.deleteAcademicEducation(academicEducation.id);
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Deletado!',
              'Sua formação acadêmica foi deletada com sucesso',
              'success',
            );
          }
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    // eslint-disable-next-line consistent-return
    deleteJobExperienceById(jobExperienceId) {
      this.$swal
        .fire({
          title: 'Deletar Experiência profissional',
          text: 'Você tem certeza de que deseja deletar esta Experiência profissional?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sim, Deletar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await this.deleteExperience(jobExperienceId);
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Deletado!',
              'Sua experiência profissional foi deletada com sucesso',
              'success',
            );
          }
        });
    },
    experienceDateDiff(experience) {
      const formatedDate = formatJobDuration(
        experience?.jobStartDate,
        experience?.jobEndDate,
      );
      return formatedDate;
    },
    academicEducationDateDiff(experience) {
      const formatedDate = formatJobDuration(
        experience?.startDate,
        experience?.endDate,
      );
      return formatedDate;
    },
    comparedDates(startDate, endDate = new Date(), hasMonths) {
      return compareDates(startDate, endDate, hasMonths);
    },
    // eslint-disable-next-line consistent-return
    async uploadProfileImage(event, imageOption) {
      this.loadImage(imageOption, true);
      [this.profileImage] = event.target.files;
      const extensionType = this.profileImage?.name.split('.')[1];
      const imageType = this.profileImage.type;
      const convertedFile = await getBase64(this.profileImage);
      if (convertedFile) {
        const body = {
          extensionFile: extensionType,
          professionalProfileId: this.userProfile.id,
          base64File: convertedFile.split(',')[1],
          contentType: imageType,
          type: imageOption,
          enviroment: 'prod',
          imagePreview: URL.createObjectURL(event.target.files[0]),
        };

        await this.uploadImage(body);
        if (this.toastMessage) {
          this.$swal.fire(
            this.toastMessage.title,
            this.toastMessage.description,
            this.toastMessage.type,
          );
          this.loadImage(imageOption, false);
        }
      }
    },
    onEditSkill(skill) {
      this.editSkillSelected(skill);
      this.handleOpenDialog('showSkillsDialog');
    },
    onEditJobExpirience(job) {
      this.editJobExpirienceSelected(job);
      this.handleOpenDialog('showAddNewExperienceDialog');
    },
    onEditAcademicEducation(academicEducation) {
      this.editAcademicEducation(academicEducation);
      this.handleOpenDialog('showAcademicEducationDialog');
    },
    showAlertDialog({ id }) {
      this.$swal
        .fire({
          title: 'Deletar Habilidade',
          text: 'Você tem certeza de que deseja deletar esta habilidade?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sim, Deletar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await this.deleteSkill(id);
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Deletado!',
              'Sua habilidade foi deletada com sucesso',
              'success',
            );
          }
        });
    },
    searchProfessionalAddress(addressList = []) {
      const response = addressList?.find(
        (address) => address?.professionalProfileId === this.userProfile?.id,
      );
      return response;
    },
    async saveSameAddressOnProfessionalProfile() {
      try {
        this.isLoadingAddress = true;
        await this.saveAddressOnProfile({
          body: this.userAddress,
          typeAddress: this.useAddressOn,
        });
        this.$swal.fire('Sucesso!', 'Endereço salvo com sucesso', 'success');
      } catch (error) {
        this.isLoadingAddress = false;
        this.$swal.fire(
          'Erro!',
          'Ocorreu um erro ao salvar o endereço',
          'error',
        );
      } finally {
        this.isLoadingAddress = false;
        this.handleCloseDialog('showAddressDialog');
      }
    },
    async addressFlow() {
      await this.getAccountAddress('professional');
      if (!this.userAddress && this.userProfile?.id && this.isMyProfile) {
        this.openAddressModal();
      }
    },
  },
  async mounted() {
    if (!this.$route.params.accountId) {
      this.$router.push({ name: 'UserProfile', params: { accountId: this.account?.id } });
    }
    const userId = this.$route.params.accountId;
    this.userUrl();
    this.loadingProfile = true;
    await Promise.all([
      this.getProfession(userId),
      this.loadProfessionalProfile(userId || ''),
    ]);
    if (!this.userProfile) {
      this.$swal
        .fire({
          title: 'Crie seu perfil profissional',
          text: 'Vimos que você ainda não tem perfil profissional, acrescente suas informações no seu perfil',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok, ir para perfil',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.handleOpenDialog('showCreateProfessionalProfileDialog');
            this.loadingProfile = false;
          }
        });
    }
    this.loadingProfile = false;
    this.imgCoverLoading = false;
    this.imgAvatarLoading = false;
    if (this.isMyProfile) {
      await this.addressFlow();
    }
  },
};
</script>

<style lang="scss">
@import './UserProfile.scss';

.user-profile-card {
  border-radius: 20px 20px 0px 0px;
  background-color: #fff;
  border: 0.5px solid #e0dfdc;
  padding-bottom: 15px;
  margin-bottom: 23px;

  .user-profile-hero-image {
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;

    .profile-background-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
    }

    .btn-upload-profile-image {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 0;
      height: 40px;
      width: 40px;

      svg {
        height: 1.6rem;
        right: 7px;
        top: 7rem;
        width: 1.6rem;
        border: 1px solid rgb(88, 88, 88);
        background: white;
        border-radius: 13px;
        cursor: pointer;
        padding: 0.2rem;
      }
    }
  }

  .user-profile-infos {
    display: flex;
    align-items: center;

    .profile-main-image {
      width: 150px;
      height: 150px;
      border: 2px solid #fff;
      border-radius: 50%;
      margin-top: -60px;
      z-index: 4;
      position: relative;

      svg {
        height: 1.6rem;
        position: absolute;
        right: 7px;
        top: 7rem;
        width: 1.6rem;
        border: 1px solid rgb(88, 88, 88);
        background: white;
        border-radius: 13px;
        cursor: pointer;
        padding: 0.2rem;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .profile-description {
      margin-top: 10px;
      width: 82%;

      .profile-actions {
        display: flex;
        gap: 1rem;
      }
    }

    .profile-title {
      color: #585858;
      font-weight: 500;
      width: 100%;
      font-size: 1rem;
      margin-bottom: 23px;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0 1rem;
      }
    }

    .profile-qrcode {
      width: 120px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.2rem;

      button {
        font-size: 0.7rem;
      }
    }

    .user-status {
      background: #37cd2c;
      border: 2px solid #fff;
      position: absolute;
      top: 0;
      right: 10px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title,
  .subtitle {
    color: #181818;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.3rem;

    p {
      color: #64479c;
      font-family: Poppins;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;

      img {
        height: 1.6rem;
        margin: 0;
      }

      font-size: 1.1rem;
      margin: 0;
      font-style: normal;
      padding: 0;
      border-radius: 1rem;
      font-weight: 500;
      line-height: 110%;
      /* 17.6px */
      letter-spacing: -0.48px;
    }
  }

  &.geral-data {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.user-info-data {
  margin-top: 1.2rem;

  p:first-child {
    color: #181818;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0.2rem 0;
  }

  p {
    margin: 0.4rem 0;
  }
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  line-height: 0;
}

.experience-professional {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #181818;
  font-weight: 500;
  gap: 0.3rem;
  margin-bottom: 0.2rem;

  button {
    border-radius: 2rem;
    padding: 0.3rem;

    &:hover {
      background: #64479c;
      color: #64479c;
    }
  }
}

.experience-actions {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.experience-actions-content {
  display: grid;
  grid-template-columns: 60px 1fr;

  .experience-color {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: linear-gradient(215.49deg, #8870ff 5.4%, #64479c 93.45%);
  }
}

.certificate-icon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.experience-infos {
  margin-left: 1rem;
  color: #181818;
  font-size: 0.8rem;

  h2 {
    margin-top: 0;
    font-size: 1rem;
    font-weight: 600;
  }

  .period {
    margin-left: 10px;
    color: #64479c;
    font-weight: 600;
  }
}

.followers {
  color: #949494;
  margin-bottom: 1rem;
  font-weight: 500;
}

.subtitle {
  margin-top: 0;
  opacity: 0.5;
}

.hidden {
  display: none;
}
</style>
