<template>
  <div class="request-jobs">
    <ElDialog
      :visible.sync="showDialog"
      width="60%"
      height="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
    <div class="jobs-dialog">

      <div class="header">
        <h2>Olá {{ accountComplete.name }}, antes de continuar <br> precisamos saber sua profissão</h2>
      </div>
      <div class="content">
        <p>Basta selecioná-la e clicar em registrar profissão.</p>
       <select v-model="profession" class="select-jobs">
          <option value="" disabled>Selecione uma profissão</option>
          <option v-for="job in jobs" :value="job.value" :key="job.value">{{ job.label }}</option>
        </select>
      </div>
      <div class="footer">
        <PdcButton @click="postJob" :disabled="!profession" :loading="loading" variant="primary" class="btn btn-primary">
          Registrar profissão
        </PdcButton>
      </div>
    </div>
    </ElDialog>
  </div>
</template>

<script>
import PROFESSIONALS_OPTIONS from '@/utils/constants/professions';
import { mapGetters } from 'vuex';
import PdcButton from '@/components/atoms/Button/Button.vue';
import { createJob } from '@/services/account.service';

export default {
  name: 'RequestJobDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PdcButton,
  },
  data() {
    return {
      dialogVisible: false,
      profession: '',
      jobs: PROFESSIONALS_OPTIONS,
      loading: false,
    };
  },
  methods: {
    async postJob() {
      this.loading = true;
      const body = {
        accountId: this.accountComplete?.id,
        job: this.profession,
      };
      const response = await createJob(body);
      if (response) {
        this.$emit('closeDialog');
        this.loading = false;
        this.$swal.fire('Profissão registrada com sucesso!', '', 'success');
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters('auth', ['accountComplete']),
  },
};
</script>

<style lang="scss" scoped>
.request-jobs {
  .jobs-dialog {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .header {
      text-align: center;
      margin-bottom: 20px;
      padding: 0 1rem;
      width: 80%;
      h2 {
        font-size: 1.5rem;
        margin: 0;
      }
    }
    .content {
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 60%;
      select {
        min-height: 2rem;
        padding: 1rem;
        border: 1px solid #D1D5DB;
        font-size: 1rem;
        border-radius: 5px;
      }
      p {
        font-size: 1rem;
      }

      .select-jobs {
        width: 100%;
        margin: 0 auto;
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      margin-left: 0;

      button {
        padding: 1.5rem 1rem;
        width: 100%;
        font-size: 1.25rem;
        font-weight: bold;
        border-radius: 5px;
        max-height: 2rem;
        background-color: #5D4A99;
        color: white;

        &:disabled {
          background-color: #8073ae;
          cursor: not-allowed;
        }

        &:hover {
          background-color: #4C1D95;
        }
      }
    }
  }
}
</style>
