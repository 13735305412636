<template>
  <div class="card__specs">

    <div class="card__specs-item">

      <div class="card__specs-item">
        <div class="card__specs-item-text dark f-11">
          <img src="@/assets/icons/card/graduation.svg" alt="" />
          Nível {{ $courseDifficulty(course) }}
        </div>
        <!-- <div class="card__specs-item-icon clock">
        </div> -->
        <div class="card__specs-item-text dark f-11">
          <img src="@/assets/icons/card/clock-gray.svg" alt="" />
          {{ course_time }}
        </div>
        <div class="card__specs-item-text dark f-11">
          <LikeComponent entity="course" :entity_id="course.id" mode="counter" :is_liked="course.isLike ? true : false"
            :qty_likes="course.qtyLikes ? course.qtyLikes : 0" />
        </div>
      </div>

    </div>

  </div>
</template>
<script>

export default {
  props: ['course'],

  computed: {
    course_time() {
      const courseTimeFromBack = this.course?.courseTime;
      if (courseTimeFromBack && courseTimeFromBack !== '0') {
        const [hours, minutes] = this.course?.courseTime.split('.');
        const courseTime = this.course?.courseTime ? `${hours}:${minutes}` : '1';
        if (minutes === '0') return `${hours}h`;
        return `${courseTime}h`;
      } return '1h';
    },
  },
};
</script>
