<template>
  <div class="w-100 courses-skeleton mt-32">

    <template v-if="['comments', 'all'].includes(type)">
      <template>
        <div v-for="i in 5" :key="`comments${i}`">
          <div class="flex-row w-100 justify-content-between mb-48">
            <VueSkeletonLoader type="circle" :width="48" :height="48" class="w-36px h-36px mb-0 mt-0 mr-16" style="min-width: 48px;" />
            <div class="w-100">
              <div class="flex-row w-100 justify-content-between mb-16">
                <VueSkeletonLoader class="w-50 mr-16 h-24px" />
                <VueSkeletonLoader class="w-50 h-24px" />
              </div>
              <VueSkeletonLoader class="w-100 h-16px mb-8" />
              <VueSkeletonLoader class="w-100 h-16px mb-8" />
              <VueSkeletonLoader class="w-75 h-16px mb-8" />
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  props: {
    type: {
      type: String,
      default: 'all',
    },
  },
  name: 'CommentsSkeleton',
  components: { VueSkeletonLoader },
};
</script>
