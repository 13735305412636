<template>
  <ElDialog :visible.sync="visible" :fullscreen="true" :show-close="false">
    <div class="exame-correcting flex-col align-items-center">
      <img class="answer-correting--image" src="@/assets/icons/course-exame/exame-correcting.png"
        alt="exame-correcting" />
      <div class="answer-correting--progress-row">
        <div class="answer-correting--progress-row--value">{{ percentage }}%</div>
        <ProgressBar :percentage="percentage" bgColor="#F1F3F5" :colors="['#3E297F']" :height="8" />
      </div>
      <b class="text-align-center exame-correcting--text">Estamos validando suas respostas</b>
      <div class="exame-correcting--description">Em instantes você terá acesso ao resultado do exame.</div>
    </div>
  </ElDialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import './ExameCorrectingDialog.scss';
import { ProgressBar } from 'vue-pdc-ext-frontend';

export default {
  name: 'ExameCorrectingDialog',
  components: {
    ProgressBar,
  },
  data() {
    return {
      percentage: 0,
      visible: true,
      intervalUpdate: 100,
    };
  },
  computed: {
    ...mapGetters('courses', ['course']),
    ...mapGetters('exame', ['answers', 'is_correcting_exame']),
  },
  created() {
    this.proccessAnswers().then(() => {
      this.intervalUpdate = 40;
    });
    this.calculateProgress();
  },
  methods: {
    ...mapActions('exame', ['setLastStepPage', 'proccessAnswers', 'fetchExameStarter']),
    ...mapActions('application', ['openLink']),
    calculateProgress() {
      if (this.percentage < 100) {
        setTimeout(() => {
          this.percentage += 1;
          this.calculateProgress();
        }, this.intervalUpdate);
      } else {
        setTimeout(() => {
          this.$router.push({ name: 'ExameResult', params: { course: this.$router.currentRoute.params.course } });
        }, 1000);
      }
    },
  },
};
</script>
