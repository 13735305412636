<template>
  <CountdownTimer :time="timeToCountdownInSeconds" v-slot="{ current_time, minutes, seconds }"
    @on-finish="handleFinishedTimeout">
    <VueSkeletonLoader v-if="!visible" class="h-24px w-128px" />
    <div class="course-exame-timer flex-row align-items-center" v-else>
      <div class="course-exame-timer--icon clock mr-8" />
      <span :class="['course-exame-timer--text', getTimeClass(current_time)]">
        <template v-if="current_time > 0 && !finish_questions">
          <span class="course-exame-timer--time"> {{ formatValue(minutes) }}:{{ formatValue(seconds) }} </span>
          {{ current_time >= 60 ? 'min' : 'seg' }}
        </template>
        <template v-else>Finalizado</template>
      </span>
      <template v-if="getShowBadge(current_time) && !finish_questions">
        <transition name="fade">
          <div class="course-exame-timer--badge">
            {{ `${seconds > 1 ? 'Restam' : 'Resta'}` }} apenas <b>{{ seconds }}</b> {{ `${seconds > 1 ? 'segundos' :
              'segundo'}` }}.<br />
            Finalize o exame antes que <br />
            o tempo acabe.
          </div>
        </transition>
      </template>
    </div>
  </CountdownTimer>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import './CourseExameTimer.scss';
import { CountdownTimer } from 'vue-pdc-ext-frontend';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'CourseExameTimer',
  components: {
    CountdownTimer,
    VueSkeletonLoader,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters('exame', ['current_step', 'exame_is_finished', 'exame_minutes', 'attempt']),
    ...mapGetters('auth', ['account']),
    ...mapGetters('questions', ['finish_questions']),
    timeToCountdownInSeconds() {
      const totalInSeconds = this.exame_minutes * 60;
      const currentTime = this.$moment();
      const restTime = this.$moment(this.attempt.createdAt).add(totalInSeconds, 'seconds').diff(currentTime, 'seconds');
      return restTime;
    },
  },
  mounted() {
    if (!this.finish_questions) {
      setTimeout(() => {
        this.visible = true;
      }, 1000);
    }
  },
  methods: {
    ...mapActions('exame', ['setTimeoutExceeded', 'setLastStepPage']),
    getShowBadge(currentTime) {
      return currentTime <= 30 && currentTime > 0;
    },
    getTimeClass(time) {
      if (time === 0) {
        return 'finished';
      }
      if (time <= 5) {
        return 'time-flashing sleep-5';
      }
      if (time <= 10) {
        return 'time-flashing sleep-10';
      }
      if (time <= 15) {
        return 'time-flashing sleep-15';
      }
      if (time <= 20) {
        return 'time-flashing sleep-20';
      }
      if (time <= 25) {
        return 'time-flashing sleep-25';
      }
      if (time <= 30) {
        return 'time-flashing sleep-30';
      }
      return 'default';
    },
    formatValue(value) {
      return value.toString().padStart(2, 0);
    },
    handleFinishedTimeout() {
      if (!this.exame_is_finished) {
        return this.setTimeoutExceeded(true);
      }
      return this.setLastStepPage('correcting');
    },
  },
};
</script>
