<template>
  <div class="course-title" :style="{ '--primary-color': this.tenantConfig.primary_color }">
    <div class="course-title--description sm-w-100">
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
    </div>
    <div class="course-title--buttons sm-mt-24 sm-w-100">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CourseTitle',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Introdução',
    },
    description: {
      type: String,
      required: true,
      default: 'Aqui vai o nome do curso',
    },
  },
  computed: {
    ...mapGetters('application', ['tenantConfig']),
  },
};
</script>

<style lang="scss" scoped>
.course-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  padding-top: 16px;

  &--description {
    h1 {
      font-family: Poppins;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: -0.02em;
      color: var(--primary-color);
      margin: 0;
      margin-bottom: 4px;
    }

    p {
      color: #5D4A99;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    }
  }
}
</style>
